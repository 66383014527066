import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import ZipInput from "../ZipInput";
import MuiPhoneNumber from "material-ui-phone-number";
import { useTranslation } from "react-i18next";

export const normalizeZIP = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 3) {
    return currentValue;
  }
  return `${currentValue.slice(0, 2)}-${currentValue.slice(2, 5)}`;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },

  cssLabelGreen: {
    color: "green",
  },

  cssLabelRed: {
    color: "red",
  },

  cssOutlinedInputGreen: {
    "&$cssFocused $notchedOutlineGreen": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssOutlinedInputRed: {
    "&$cssFocused $notchedOutlineRed": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutlineGreen: {
    borderWidth: "1px",
    borderColor: "green !important",
  },
  notchedOutlineRed: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
}));

const ShippingAddressForm = ({
  provideShippingData,
  cartItemEdition,
  name,
  setName,
  street,
  setStreet,
  city,
  setCity,
  zip,
  setZip,
  post,
  setPost,
  phone,
  setPhone,
  dialCode,
  setDialCode,
  deliveryInfo,
  setDeliveryInfo,
  isValidListener,
  enableProfileDataEdition,
  setDataChanged,
  preferredChannel,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [phoneValid, setPhoneValid] = useState(false);
  const [deletingPhone, setDeletingPhone] = useState(false);

  useEffect(() => {
    setPhoneValid(
      !phone || phone.length === 12 || phone.length < dialCode.length + 2
    );
  }, [phone]);

  const isPhoneEmpty = (phone, dialCode) =>
    !phone || phone == "+" + dialCode || phone == "+";

  return (
    <Grid item container sm={12} lg={8} xl={6} spacing={1}>
      <Grid item xs={12}>
        <TextField
          label={t("user_profile.address_data.firstname/name")}
          variant="outlined"
          value={!cartItemEdition ? name : deliveryInfo.name || ""}
          disabled={!provideShippingData || !enableProfileDataEdition}
          fullWidth
          onChange={({ target: { value: name } }) => {
            setDataChanged(true);
            !cartItemEdition
              ? setName(name)
              : setDeliveryInfo((prevState) => ({
                  ...prevState,
                  name: name,
                }));
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t("user_profile.address_data.street")}
          variant="outlined"
          value={!cartItemEdition ? street : deliveryInfo.street || ""}
          disabled={!provideShippingData || !enableProfileDataEdition}
          fullWidth
          onChange={({ target: { value: street } }) => {
            setDataChanged(true);
            !cartItemEdition
              ? setStreet(street)
              : setDeliveryInfo((prevState) => ({
                  ...prevState,
                  street: street,
                }));
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t("user_profile.address_data.city")}
          variant="outlined"
          value={!cartItemEdition ? city : deliveryInfo.city || ""}
          disabled={!provideShippingData || !enableProfileDataEdition}
          fullWidth
          onChange={({ target: { value: city } }) => {
            setDataChanged(true);
            !cartItemEdition
              ? setCity(city)
              : setDeliveryInfo((prevState) => ({
                  ...prevState,
                  city: city,
                }));
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <ZipInput
          t={t}
          zipCode={!cartItemEdition ? zip : deliveryInfo.zipCode || ""}
          setZipCode={
            !cartItemEdition
              ? setZip
              : (newZip) =>
                  setDeliveryInfo((prevState) => ({
                    ...prevState,
                    zipCode: normalizeZIP(newZip, deliveryInfo.zipCode),
                  }))
          }
          disabled={!provideShippingData || !enableProfileDataEdition}
          setDataChanged={setDataChanged}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={t("user_profile.address_data.post")}
          variant="outlined"
          value={!cartItemEdition ? post : deliveryInfo.post || ""}
          disabled={!provideShippingData || !enableProfileDataEdition}
          fullWidth
          onChange={({ target: { value: post } }) => {
            setDataChanged(true);
            !cartItemEdition
              ? setPost(post)
              : setDeliveryInfo((prevState) => ({
                  ...prevState,
                  post: post,
                }));
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <MuiPhoneNumber
          fullWidth
          name="phone"
          label={t("user_profile.address_data.phone")}
          data-cy="user-phone"
          defaultCountry={"pl"}
          variant="outlined"
          value={!cartItemEdition ? phone : deliveryInfo.phone || ""}
          disabled={!provideShippingData || !enableProfileDataEdition}
          onChange={(phone, { dialCode }) => {
            setDataChanged(true);
            setDialCode(dialCode);
            (!phone || phone === "+") && setDeletingPhone(true);
            !cartItemEdition
              ? setPhone(!isPhoneEmpty(phone, dialCode) ? phone : null)
              : setDeliveryInfo((prevState) => ({
                  ...prevState,
                  ...(!isPhoneEmpty(phone, dialCode) ? phone : null),
                }));
          }}
          helperText={
            deletingPhone && preferredChannel === "phone"
              ? "Preferowany kanał do komunikacji zostanie zmieniony na email."
              : ""
          }
        />
        {/* <MuiPhoneNumber
          name="phone"
          label="Numer telefonu"
          variant="outlined"
          data-cy="user-phone"
          defaultCountry={"pl"}
          regions={"europe"}
          autoFormat={false}
          countryCodeEditable={false}
          fullWidth
          value={!cartItemEdition ? phone : deliveryInfo.phone || ""}
          disabled={!provideShippingData || !enableProfileDataEdition}
          fullWidth
          onChange={(phone, country) => {
            setDialCode(country.dialCode);
            !cartItemEdition
              ? setPhone(phone)
              : setDeliveryInfo((prevState) => ({
                  ...prevState,
                  phone: phone,
                }));
          }}
          error={!phoneValid}
          helperText={!phoneValid && "Nieprawidłowy format numeru telefonu"}
          inputProps={{
            maxLength: 12,
          }}
        /> */}
      </Grid>
    </Grid>
  );
};

export default ShippingAddressForm;
