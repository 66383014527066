import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { alertAdd, resetGivenUserPassword } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { normalizeZIP } from "../address-form/shipping-info";

const useStyles = makeStyles((theme) => ({
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}));

const PersonFormTextField = ({
  addressField = false,
  fieldName,
  value,
  multiline = false,
  t,
  dispatch,
}) => {
  const my = useSelector((s) => s.my);
  const isOwner = my.user.authorities.includes("ROLE_OWNER");

  return (
    <TextField
      label={t(`user_edition.${fieldName}`)}
      disabled={!isOwner}
      variant="outlined"
      type="text"
      value={value}
      name={fieldName}
      size="small"
      fullWidth
      onChange={({ target: { value, name } }) =>
        !addressField
          ? dispatch({
              type: "HANDLE_INPUT_CHANGE",
              payload: { value, name },
            })
          : dispatch({
              type: "HANDLE_INPUT_ADDRESS_CHANGE",
              payload: { value, name },
            })
      }
      multiline={multiline}
      rows={multiline ? 2 : 1}
    />
  );
};
export const PersonForm = ({
  userData: { firstName, surname, title, description, gender, address, mail },
  dispatch,
  isOwner,
  t,
}) => {
  const [isPostalCodeValid, setIsPostalCodeValid] = useState(true);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12} md={6}>
          <PersonFormTextField
            fieldName={"firstName"}
            value={firstName}
            t={t}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PersonFormTextField
            fieldName={"surname"}
            value={surname}
            t={t}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12}>
          <PersonFormTextField
            fieldName={"mail"}
            value={mail}
            t={t}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12}>
          <PersonFormTextField
            fieldName={"title"}
            value={title}
            t={t}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12}>
          <PersonFormTextField
            fieldName={"description"}
            value={description}
            t={t}
            dispatch={dispatch}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          {" "}
          <FormControl
            disabled={!isOwner}
            size="small"
            variant="outlined"
            fullWidth
          >
            <InputLabel id="open-gender-select-label">
              {" "}
              {t("user_edition.gender")}
            </InputLabel>
            <Select
              labelId="open-gender-select-label"
              id="open-gender-select"
              value={gender}
              name="gender"
              onChange={({ target: { value, name } }) => {
                dispatch({
                  type: "HANDLE_INPUT_CHANGE",
                  payload: { value, name },
                });
              }}
              label={t("user_edition.gender")}
              autoWidth={true}
            >
              <MenuItem value="Female">{t("user_edition.female")}</MenuItem>
              <MenuItem value="Male">{t("user_edition.male")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">{t("user_edition.address_data")}</Typography>
        </Grid>
        <Grid item xs={12}>
          <br />
        </Grid>
        <Grid item xs={12}>
          <PersonFormTextField
            fieldName={"name"}
            value={address.name}
            t={t}
            dispatch={dispatch}
            addressField
          />
        </Grid>
        <Grid item xs={12}>
          <PersonFormTextField
            fieldName={"street"}
            value={address.street}
            t={t}
            dispatch={dispatch}
            addressField
          />
        </Grid>
        <Grid item xs={12}>
          <PersonFormTextField
            fieldName={"city"}
            value={address.city}
            t={t}
            dispatch={dispatch}
            addressField
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={t("user_edition.zipCode")}
            variant="outlined"
            type="text"
            disabled={!isOwner}
            value={address.zipCode}
            name={"zipCode"}
            size="small"
            fullWidth
            onChange={({ target: { value, name } }) => {
              const v = normalizeZIP(value, value);
              dispatch({
                type: "HANDLE_INPUT_ADDRESS_CHANGE",
                payload: { value: v, name },
              });
              let postalCode = "";
              v.length > 6
                ? (postalCode = v.substring(0, 6))
                : (postalCode = v);

              let isOk;
              if (
                (postalCode && /^\d{2}-\d{3}$/.test(postalCode)) ||
                postalCode === "" ||
                postalCode === undefined
              ) {
                isOk = true;
              } else {
                isOk = false;
              }

              setIsPostalCodeValid(isOk);
            }}
            error={!isPostalCodeValid}
            helperText={!isPostalCodeValid && t("user_edition.zipCodeInvalid")}
          />
        </Grid>
        <Grid item xs={12}>
          <PersonFormTextField
            fieldName={"post"}
            value={address.post}
            t={t}
            dispatch={dispatch}
            addressField
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
