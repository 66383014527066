import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../loader";
import { useDispatch } from "react-redux";
import { fetchImageData, fetchImageByUrl } from "../../redux/actions";
import noImage from "../../assets/no-image.png";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "auto",
    maxHeight: "90vh",
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    // minWidth: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    minWidth: "400px",
  },
  horseImg: {
    minWidth: "300px",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
      maxWidth: "100$",
    },
  },
}));

function ImagePanel({ open, hide, horseId }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [imgUrl, setImgUrl] = useState("");
  const [imgNotFound, setImgNotFound] = useState(false);
  const [busy, setBusy] = useState(true);

  const { t } = useTranslation();
  const ws = useRef(null);

  useEffect(() => {
    (async () => {
      if (horseId) {
        try {
          const { data } = await dispatch(fetchImageData(horseId));
          if (data?.url) {
            const image = await dispatch(fetchImageByUrl(data.url));
            setImgUrl(data?.url + "/serve");
          }
          setBusy(false);
          const domain = window.location.hostname;
          const proto = window.location.protocol === "https:" ? "wss" : "ws";
          const port = window.location.port;
          const optionalPort = port ? `:${port}` : "";
          ws.current = new WebSocket(
            // `wss://stadnina-qa.it-learn.pl/ws/horse/image/latest`
            `${proto}://${domain}${optionalPort}/ws/horse/image/latest`
          );
          ws.current.onopen = function (e) {};
          ws.current.onmessage = function (e) {
            if (e.data.includes("Connected")) {
              ws.current.send(JSON.stringify({ horseId, command: "Register" }));
            }
            if (e.data.includes("HorseImageUpdated")) {
              const responseData = JSON.parse(e.data);
              setImgUrl(responseData?.url + "/serve");
            }
          };
          ws.current.onclose = function (e) {
            hide();
          };
          ws.current.onerror = function (e) {
            setImgUrl("");
            setImgNotFound(true);
          };
          const wsCurrent = ws.current;
          return () => {
            wsCurrent.close();
          };
        } catch (e) {
          setImgNotFound(true);
          setBusy(false);
        }
      }
    })();
  }, []);

  const title = (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        {t("image_panel.label")}
      </Typography>
    </Box>
  );
  const body = (
    <Box
      p={1}
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box>
        {!imgNotFound ? (
          <img className={classes.horseImg} src={imgUrl} alt="horse" />
        ) : (
          <Box style={{ textAlign: "center" }}>
            <img style={{ maxWidth: "200px", padding: "20px" }} src={noImage} />
            <Typography
              variant="h4"
              gutterBottom
              style={{ padding: "10px 0", color: "#696969" }}
            >
              {t("image_panel.not_available")}
            </Typography>
          </Box>
        )}
      </Box>

      <Button
        size="small"
        variant="contained"
        style={{ margin: "8px 16px", padding: "8px 16px" }}
        onClick={() => {
          if (ws.current) {
            ws.current.close();
          }

          hide();
        }}
      >
        {t("close")}
      </Button>
    </Box>
  );
  const foot = <div>foot</div>;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={hide}
    >
      <Box style={modalStyle} className={classes.paper}>
        {title}
        {!busy ? (
          <>{body}</>
        ) : (
          <Box p={1}>
            <Loader loading={true} text={t("fetching")} />
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default ImagePanel;
