import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  fetchAllowedUsersToForm,
  fetchForm,
  fetchOrgUsers,
  fetchOrgUsersPaged,
  fetchOrgUsersPagedByStudId,
  updateUsersAccessToForm,
} from "../../redux/actions";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import DateFnsUtils from "@date-io/date-fns";
import { addDays, endOfDay, startOfDay } from "date-fns";

import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton, TextField, Tooltip } from "@material-ui/core";
import Loader from "../../components/loader";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Clear } from "@material-ui/icons";
import { ConfirmDialogMUI } from "../confirm-dialog-mui";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { columnsTotalWidthSelector } from "@material-ui/data-grid";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "scroll",
    maxHeight: "90vh",
  };
}

const getTimezoneOffset = (date) => {
  return date.getTimezoneOffset() * 60_000;
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    minWidth: "50%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
      width: "100vw",
    },
  },
}));

const FormPermissionsPanel = ({
  form,
  open,
  hide,
  orgForms,
  allowedUsersToFormFetched,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const my = useSelector((s) => s.my);
  const isOwner = !!my?.user.authorities.includes("ROLE_OWNER");

  const [modalStyle] = useState(getModalStyle);

  const [horseOwners, setHorseOwners] = useState([]);
  const [grooms, setGrooms] = useState([]);
  const [vets, setVets] = useState([]);

  const [horseOwnersFiltered, setHorseOwnersFiltered] = useState([]);
  const [groomsFiltered, setGroomsFiltered] = useState([]);
  const [vetsFiltered, setVetsFiltered] = useState([]);

  const [userFilter, setUserFilter] = useState("");

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [fetchingData, setFetchingData] = useState(false);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    const filterNormalized = userFilter.trim().toLowerCase();

    setHorseOwnersFiltered(
      horseOwners.filter((u) => {
        return (
          filterNormalized === "" ||
          (u.firstName || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.surname || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.mail || "").toLowerCase().indexOf(filterNormalized) !== -1
        );
      })
    );

    setGroomsFiltered(
      grooms.filter((u) => {
        return (
          filterNormalized === "" ||
          (u.firstName || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.surname || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.mail || "").toLowerCase().indexOf(filterNormalized) !== -1
        );
      })
    );
    setVetsFiltered(
      vets.filter((u) => {
        return (
          filterNormalized === "" ||
          (u.firstName || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.surname || "").toLowerCase().indexOf(filterNormalized) !== -1 ||
          (u.mail || "").toLowerCase().indexOf(filterNormalized) !== -1
        );
      })
    );
  }, [userFilter, horseOwners, grooms, vets]);

  useEffect(() => {
    (async () => {
      setFetchingData(true);
      const assignedUsers = await dispatch(fetchAllowedUsersToForm(form.id));
      setSelectedUsers(
        assignedUsers.map((user) => ({
          selected: user.selected,
          userId: user.userId,
        }))
      );
      let studUsers = [];
      if (isOwner) {
        studUsers = await dispatch(
          fetchOrgUsersPagedByStudId({ page: 0, studId: form.studId })
        );
      } else {
        studUsers = await dispatch(fetchOrgUsersPaged({ page: 0 }));
      }
      if (studUsers.length) {
        setHorseOwners(
          studUsers.filter((user) => user.roles.includes("ROLE_USER"))
        );
        setGrooms(
          studUsers.filter((user) => user.roles.includes("ROLE_GROOM"))
        );
        setVets(studUsers.filter((user) => user.roles.includes("ROLE_VET")));
      }
      setFetchingData(false);
    })();
  }, [open]);

  const handleClose = () => {
    hide();
  };

  const handleUserCheck = (e, id) => {
    const userExists = selectedUsers.some((u) => u.userId === id);
    if (userExists) {
      setSelectedUsers(
        selectedUsers.map((selectedUser) =>
          selectedUser.userId === id
            ? {
                selected: e.target.checked,
                userId: id,
              }
            : selectedUser
        )
      );
    } else {
      setSelectedUsers([
        ...selectedUsers,
        { selected: e.target.checked, userId: id },
      ]);
    }
  };

  const tableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell style={{ width: "80%" }}>
            {t("permissions_panel.user_name")}
          </TableCell>
          <TableCell align="right" style={{ width: "20%" }}>
            {t("permissions_panel.assigned")}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const title = (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        {/* {t("permissions_panel.label")} "{form.name}": */}
        {t("permissions_panel.label")} {form.name}
      </Typography>
    </Box>
  );

  const body = (
    <Box p={1}>
      <TextField
        type="text"
        placeholder="Filtruj"
        size="small"
        variant="outlined"
        value={userFilter}
        onChange={({ target: { value } }) => setUserFilter(value)}
      />
      <a
        style={{ marginTop: "10px", marginBottom: "10px", display: "block" }}
        onClick={() => history.push("/person/add")}
      >
        {t("permissions_panel.add_new_user")}
      </a>
      <Typography variant="h6" gutterBottom>
        {process.env.REACT_APP_USE_PATIENT === "true"
          ? t("permissions_panel.horse_owner")
          : "Użykownicy:"}
      </Typography>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
          size="small"
        >
          {tableHead()}

          <TableBody>
            {horseOwnersFiltered.map((p) => {
              const isUserSelected = selectedUsers.find(
                ({ userId }) => userId === p.userId
              )?.selected;

              return (
                <TableRow key={p.userId}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ width: "80%" }}
                  >
                    {(p.firstName || p.surname) &&
                      `${p.firstName || ""} ${p.surname || ""}`}
                    <br />
                    {p.mail
                      ? `${p.mail || ""}`
                      : p.phone
                      ? `${p.phone || ""}`
                      : ""}
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isUserSelected || false}
                          onChange={(e) => handleUserCheck(e, p.userId)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div>
        <Typography variant="h6" gutterBottom>
          {process.env.REACT_APP_USE_PATIENT === "true"
            ? t("permissions_panel.groom")
            : "Redaktorzy:"}
        </Typography>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "80%" }}>
                  {t("permissions_panel.user_name")}
                </TableCell>
                <TableCell align="right" style={{ width: "20%" }}>
                  {t("permissions_panel.assigned")}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {groomsFiltered.map((p) => {
                const isUserSelected = selectedUsers.find(
                  ({ userId }) => userId === p.userId
                )?.selected;

                return (
                  <TableRow key={p.userId} style={{ width: "80%" }}>
                    <TableCell component="th" scope="row">
                      {(p.firstName || p.surname) &&
                        `${p.firstName || ""} ${p.surname || ""}`}
                      <br />
                      {p.mail
                        ? `${p.mail || ""}`
                        : p.phone
                        ? `${p.phone || ""}`
                        : ""}
                    </TableCell>
                    <TableCell align="right">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isUserSelected || false}
                            onChange={(e) => handleUserCheck(e, p.userId)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <br />{" "}
      <div>
        <Typography variant="h6" gutterBottom>
          {process.env.REACT_APP_USE_PATIENT === "true"
            ? t("permissions_panel.vet")
            : "Redaktorzy:"}
        </Typography>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "80%" }}>
                  {t("permissions_panel.user_name")}
                </TableCell>
                <TableCell align="right" style={{ width: "20%" }}>
                  {t("permissions_panel.assigned")}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {vetsFiltered.map((p) => {
                const selectedUser = selectedUsers.find(
                  ({ userId }) => userId === p.userId
                );
                const isUserSelected = selectedUser
                  ? selectedUser?.selected
                  : false;

                return (
                  <TableRow key={p.userId}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: "80%" }}
                    >
                      {(p.firstName || p.surname) &&
                        `${p.firstName || ""} ${p.surname || ""}`}
                      <br />
                      {p.mail
                        ? `${p.mail || ""}`
                        : p.phone
                        ? `${p.phone || ""}`
                        : ""}
                    </TableCell>
                    <TableCell align="right" style={{ width: "20%" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isUserSelected || false}
                            onChange={(e) => handleUserCheck(e, p.userId)}
                            name="checkedB"
                            color="primary"
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );

  const save = async () => {
    setBusy(true);

    try {
      await dispatch(updateUsersAccessToForm(form.id, selectedUsers));
      setBusy(false);
      hide();
    } catch (e) {
      const hasUserAccessToStudError =
        e.data.message === "person_has_no_access_to_stud";
      hide();
      dispatch(
        alertAdd({
          text: t(
            hasUserAccessToStudError
              ? "permissions_panel.error_user_no_stud"
              : "permissions_panel.error"
          ),
          isError: true,
        })
      );
      setBusy(false);
    }
  };

  const foot = (
    <Grid>
      <Button
        variant={"contained"}
        color="primary"
        onClick={save}
        disabled={busy}
      >
        {busy && <CircularProgress size={24} style={{ color: "white" }} />}
        {!busy && t("save")}
      </Button>
      &nbsp;&nbsp;&nbsp;
      <Button variant={"contained"} onClick={() => hide()}>
        {t("cancel")}
      </Button>
    </Grid>
  );

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
      style={{ overflow: "scroll" }}
    >
      <Box style={modalStyle} className={classes.paper}>
        {title}
        {!fetchingData ? (
          <>
            {body} {foot}
          </>
        ) : (
          <Box p={1}>
            <Loader loading={true} text={t("permissions_panel.fetching")} />
          </Box>
        )}
        <hr />
      </Box>
    </Modal>
  );
};

export default FormPermissionsPanel;
