import {
  ACTION_1,
  ACTIVATE_FORM,
  ADD_ITEM_TO_CART,
  ALERT_HIDE,
  ALERT_SHOW,
  ALERT_TIMEOUT,
  BUY_CART_ITEM,
  CHECK_AUTH_DONE,
  CHECK_AUTH_FAILED,
  CHECK_AUTH_START,
  DEACTIVATE_FORM,
  DONE,
  DUPLICATE_FORM,
  EDIT_ITEM_CART,
  FAILED,
  FETCH_ACTIVATED_KITS,
  FETCH_ACTIVE_FORMS,
  FETCH_ACTIVE_FORMS_ALL,
  FETCH_USER_ALL_FORMS,
  FETCH_USER_ALL_AVAILABLE_FORMS,
  FETCH_PLANNED_FORMS,
  FETCH_PLANNED_FORMS_ALL,
  FETCH_ARCHIVED_FORMS,
  FETCH_ARCHIVED_FORMS_BAG,
  FETCH_ALL_MY_TESTS,
  FETCH_CART_ITEMS,
  FETCH_CONFIG_GLOBAL_DONE,
  FETCH_FILLED_FORMS_DONE,
  FETCH_FILLED_FORMS_EXTENDED,
  FETCH_FILLED_TESTS,
  FETCH_FORM_TEMPLATE_DONE,
  FETCH_FORM_DONE,
  FETCH_FORMS_DONE,
  FETCH_FORMS_START,
  FETCH_FORMS_FAILED,
  FETCH_HORSES_DONE,
  FETCH_HORSES_START,
  FETCH_HORSES_FAILED,
  FETCH_OLD_FORM_EDITIONS,
  FETCH_ORG_NAMES_DONE,
  FETCH_ORG_USERS_DONE,
  FETCH_ORG_USERS_FAILED,
  FETCH_ORG_USERS_START,
  FETCH_ALLOWED_STUD_USERS_DONE,
  FETCH_ALLOWED_STUD_USERS_FAILED,
  FETCH_ALLOWED_STUD_USERS_START,
  FETCH_ALL_ORG_USERS_DONE,
  FETCH_ALL_ORG_USERS_FAILED,
  FETCH_ALL_ORG_USERS_START,
  FETCH_ORGANIZATION_DONE,
  FETCH_PRICE_LIST,
  FETCH_SCHEDULED_FORMS,
  FETCH_HORSE_NOTIFICATIONS,
  JOIN_ORGANIZATION_DONE,
  JOIN_ORGANIZATION_FAILED,
  JOIN_ORGANIZATION_START,
  LOGIN_DONE,
  LOGIN_FAILED,
  LOGIN_START,
  LOGOUT_DONE,
  RECOVER_PASSWORD_DONE,
  RECOVER_PASSWORD_FAILED,
  RECOVER_PASSWORD_START,
  REGISTER_ORGANIZATION_DONE,
  REGISTER_ORGANIZATION_FAILED,
  REGISTER_ORGANIZATION_START,
  REMOVE_CART_ITEM_DONE,
  REMOVE_FORM_DONE,
  REMOVE_FORM_START,
  REMOVE_ITEM_FROM_CART,
  START,
  UPDATE_ENTITLED_USERS,
  X_AUTH_TOKEN_KEY,
  RESET_BY_MAIL_PASSWORD_DONE,
  RESET_BY_MAIL_PASSWORD_FAILED,
  RESET_BY_MAIL_PASSWORD_START,
  UPDATE_FORM_DATA_IS_SHARED,
  TOGGLE_REQUESTED_SHARING_BY_OWNER,
  SET_APP_BAR_TITLE,
  FETCH_ALL_TERMS,
  FETCH_FORMS_GROUPS_START,
  FETCH_FORMS_GROUPS_FAILED,
  FETCH_FORMS_GROUPS_DONE,
  FETCH_ALL_FORMS_GROUPS_START,
  FETCH_ALL_FORMS_GROUPS_FAILED,
  FETCH_ALL_FORMS_GROUPS_DONE,
  COPY_FORM_TO_FORM_GROUP_DONE,
  MOVE_FORM_TO_FORM_GROUP_DONE,
  REMOVE_FORM_FROM_FORM_GROUP_DONE,
  FETCH_FORM_GROUP_DONE,
  REMOVE_FORM_GROUP,
  EDIT_USER,
  DUPLICATE_FORM_GROUP,
  FETCH_ALLOWED_USERS_TO_GROUP_DONE,
  FETCH_ALLOWED_USERS_TO_FORM_DONE,
  FETCH_ALLOWED_USERS_TO_FORM_START,
  UPDATE_USERS_ACCESS_TO_GROUP,
  REMOVE_USER,
  UPDATE_USERS_ACCESS_TO_FORM,
  FETCH_UPLOADABLE_FILE_TYPES,
  FETCH_SAVED_FORM_DATA,
  FETCH_FILLED_FORM_DATA,
  REMOVE_FORM_DATA_DRAFT,
  FETCH_FORMS_SCHEDULED_FOR_FUTURE,
  FETCH_HAS_SINGLE_SCHEDULED_FORM,
  ALERT_HIDE_CUSTOM_ID,
  TOGGLE_GLOBAL_THEME,
  READ_GLOBAL_THEME,
  SMALL_FONT_SIZE,
  MEDIUM_FONT_SIZE,
  LARGE_FONT_SIZE,
  FETCH_CONFIG,
  CLEAR_ALL_FORM,
  FETCH_TERMS_ACCEPTED_BY_USER,
  FETCH_ORGANIZATION_CURRENT_TERMS,
  FETCH_ORGANIZATION_FUTURE_TERMS,
  FETCH_API_VERSION,
  UNSUPPORTED_BROWSER,
  ACTIVATE_USER,
  FETCH_MATCHING_USERS,
  CLEAR_MATCHING_USERS,
  SAVE_HORSE_FAILED,
  CHECK_CONTEXT_DONE,
  FETCH_HORSES_BY_STUD_DONE,
  FETCH_VACCINATIONS,
  FETCH_VETERINARY_VISITS,
  FETCH_FOALINGS,
  REMOVE_FORM_FAILED,
  FETCH_ALERTS,
  FETCH_ANOMALIES,
  FETCH_CAMERAS,
  FETCH_BOXES,
  FETCH_ALL_HORSES_DONE,
  FETCH_ALL_HORSES_START,
  FETCH_ALL_HORSES_FAILED,
  FETCH_SIGNAL_INFO,
} from "../../constants";
import { FETCH_MY, UPDATE_MY, AFTER_FIRST_LOGIN } from "../person/actions";
import {
  FETCH_TERMS_ORGANIZATION,
  UPDATE_MY_ORGANIZATION,
} from "../organization/actions";

const initialState = {
  organization: null,
  loggedInUser: null,
  authToken: null,
  creatingOrganization: false,
  joiningOrganization: false,
  joinedOrganization: undefined,
  loggingIn: undefined,
  loggedIn: undefined,
  errorMessage: "w",
  allowedStudUsers: [],
  orgUsers: [],
  orgUsersLength: null,
  orgForms: [],
  orgFormDuplicated: false,
  formRemoved: false,
  formRemoving: false,
  orgFormsGroups: [],
  formDuplicated: false,
  orgFormsGroupsLength: null,
  scheduledForms: [],
  orgOldFromEditions: [],
  orgReports: [],
  joiningComplete: false,
  formData: null,
  filledForms: {},
  filledFormsExtended: {},
  filledFormsExtendedLength: {},
  recoveringPassword: false,
  resettingByMailPassword: false,
  orgUsersFetching: false,
  orgUsersFetched: false,
  orgUsersFailed: false,
  allowedUsersToFormFetching: false,
  allowedUsersToFormFetched: false,
  formsGroupsFetched: false,
  alerts: [],
  my: null,
  checkingAuth: true,
  roles: ["GUEST"],
  activeForms: null,
  activeFormsLength: null,
  configGlobal: null,
  cart: [],
  cartItems: [],
  filledTests: [],
  priceList: {},
  ordersInProgress: [],
  terms: [],
  fetchingOrdersInProgress: false,
  allMyTests: [],
  allMyTestsFetching: false,
  activatedKits: [],
  appBarTitle: "",
  removedUser: false,
  activatedUser: false,
  matchingUser: null,
  allowedUsersToForm: [],
  allowedUsersToGroup: [],
  userAllForms: [],
  uploadableFileTypes: [],
  archivedForms: null,
  archivedFormsLength: null,
  archivedFormsBag: {},
  savedForms: null,
  savedFormsLength: null,
  allFilledForms: [],
  plannedForms: null,
  scheduledForFutureForms: null,
  isEmployeeLastLogout: false,
  fetchingForms: false,
  formsFetched: false,
  hasSingleScheduledForm: false,
  globalTheme: "light", // supported: 'light|high-contrast'
  baseFontSize: 0,
  config: {},
  configFetched: false,
  fetchingActiveForms: false,
  fetchingArchivedForms: false,
  archivedFormsFetched: false,
  fetchingPlannedForms: false,
  fetchingSavedForms: false,
  fetchingFilledFormData: false,
  fetchingFormsScheduledForFuture: false,
  termsAcceptedByUser: null,
  fetchingTermsAcceptedByUser: false,
  orgCurrentTerms: null,
  orgFutureTerms: null,
  fetchingOrgCurrentTerms: false,
  fetchingOrgFutureTerms: false,
  apiVersion: null,
  apiVersionFetched: false,
  unsupportedBrowser: null,
  firstLogin: null,
  userAllAvailableForms: [],
  studHorses: [],
  currentContext: {},
  allStudsContext: [],
  vaccinations: [],
  vaccinationsFetching: false,
  vaccinationsFetched: false,
  veterinaryVisits: [],
  veterinaryVisitsFetching: false,
  veterinaryVisitsFetched: false,
  foalings: [],
  foalingsFetching: false,
  foalingsFetched: false,
  horseAlerts: [],
  horseAlertsTotal: 0,
  horseAlertsFetching: false,
  horseAlertsFetched: false,
  anomalies: [],
  anomaliesTotal: 0,
  anomaliesFetching: false,
  anomaliesFetched: false,
  cameras: [],
  camerasTotal: 0,
  camerasFetching: false,
  camerasFetched: false,
  boxes: [],
  boxesTotal: 0,
  signalFetching: false,
  boxesFetching: false,
  boxesFetched: false,
  signalInfo: {},
  notifications: {},
  fetchingNotifications: false,
};

function rootReducer(state = { ...initialState }, action) {
  if (action.type === ACTION_1) {
    return state;
  }

  if (action.type === REGISTER_ORGANIZATION_START) {
    return {
      ...state,
      creatingOrganization: true,
    };
  }

  if (action.type === REGISTER_ORGANIZATION_FAILED) {
    return {
      ...state,
      creatingOrganization: false,
    };
  }

  if (action.type === REGISTER_ORGANIZATION_DONE) {
    return {
      ...state,
      organization: action.payload,
      creatingOrganization: false,
    };
  }

  if (action.type === JOIN_ORGANIZATION_START) {
    return {
      ...state,
      joiningOrganization: true,
      joiningComplete: false,
      joinedOrganization: null,
    };
  }

  if (action.type === JOIN_ORGANIZATION_FAILED) {
    return {
      ...state,
      joiningOrganization: false,
      joinedOrganization: false,
    };
  }

  if (action.type === JOIN_ORGANIZATION_DONE) {
    return {
      ...state,
      organization: action.payload,
      joiningComplete: true,
      joiningOrganization: false,
    };
  }

  if (action.type === LOGIN_START) {
    return {
      ...state,
      authToken: null,
      loggingIn: true,
      loggedIn: false,
    };
  }

  if (action.type === LOGIN_DONE) {
    sessionStorage.setItem(X_AUTH_TOKEN_KEY, action.payload.data.token);

    return {
      ...state,
      authToken: action.payload.data.token,
      loggingIn: false,
      loggedIn: true,
      my: action.payload.my,
      roles: action.payload.my.user.authorities.split(","),
      firstLogin: action.payload.my.firstLogin,
    };
  }

  if (action.type === LOGIN_FAILED) {
    return {
      ...state,
      authToken: null,
      loggingIn: false,
      loggedIn: false,
      errorMessage: action.errorMessage,
    };
  }

  if (action.type === LOGOUT_DONE) {
    sessionStorage.removeItem(X_AUTH_TOKEN_KEY);

    if (action.payload.config.url.includes("employee")) {
      return {
        ...initialState,
        isEmployeeLastLogout: true,
      };
    } else {
      return {
        ...initialState,
      };
    }
  }

  if (action.type === CHECK_AUTH_START) {
    return {
      ...state,
      authToken: null,
      loggedIn: false,
      loggingIn: true,
    };
  }

  if (action.type === CHECK_AUTH_DONE) {
    sessionStorage.setItem(X_AUTH_TOKEN_KEY, action.payload.token);

    return {
      ...state,
      authToken: action.payload.token,
      loggedIn: true,
      loggingIn: false,
      checkingAuth: false,
      my: action.payload.my,
      roles: action.payload.my.user.authorities.split(","),
      firstLogin: action.payload.my.firstLogin,
    };
  }

  if (action.type === CHECK_CONTEXT_DONE) {
    return {
      ...state,
      currentContext: action.payload.acutalContext,
      allStudsContext: action.payload.myStuds,
    };
  }

  if (action.type === CHECK_AUTH_FAILED) {
    return {
      ...state,
      authToken: null,
      loggedIn: false,
      loggingIn: false,
      checkingAuth: false,
    };
  }

  if (action.type === FETCH_ORG_USERS_DONE) {
    return {
      ...state,
      orgUsersFetching: false,
      orgUsersFailed: false,
      orgUsersFetched: true,
      orgUsers: action.payload.users.content,
      orgUsersLength: action.payload.users.pagination.total,
    };
  }

  if (action.type === FETCH_ALLOWED_STUD_USERS_DONE) {
    return {
      ...state,
      orgUsersFetching: false,
      orgUsersFailed: false,
      orgUsersFetched: true,
      allowedStudUsers: action.payload,
    };
  }

  if (action.type === FETCH_ALL_ORG_USERS_DONE) {
    return {
      ...state,
      orgUsersFetching: false,
      orgUsersFailed: false,
      orgUsersFetched: true,
      orgUsers: action.payload.users,
    };
  }
  if (action.type === FETCH_ORG_USERS_START) {
    return {
      ...state,
      orgUsersFetching: true,
      orgUsersFailed: false,
      orgUsersFetched: false,
    };
  }
  if (action.type === FETCH_ORG_USERS_FAILED) {
    return {
      ...state,
      orgUsersFetching: false,
      orgUsersFailed: true,
      orgUsersFetched: false,
      orgUsers: [],
      orgUsersLength: 0,
    };
  }

  if (action.type === FETCH_ORG_NAMES_DONE) {
    return {
      ...state,
      orgNamesFetching: false,
      orgNamesFailed: false,
      orgNamesFetched: true,
      orgNames: action.payload.orgNames,
    };
  }

  if (action.type === FETCH_ORGANIZATION_DONE) {
    return {
      ...state,
      organization: action.payload[0],
    };
  }

  if (action.type === FETCH_CONFIG_GLOBAL_DONE) {
    return {
      ...state,
      configGlobal: {
        ...action.payload,
      },
    };
  }

  if (action.type === FETCH_FORMS_DONE) {
    return {
      ...state,
      fetchingForms: false,
      formsFetched: true,
      orgFormsLength: action.payload.pagination.total,
      studHorses: action.payload.content,
    };
  }

  if (action.type === FETCH_ALL_HORSES_DONE) {
    return {
      ...state,
      fetchingForms: false,
      formsFetched: true,
      // orgFormsLength: action.payload.pagination.total,
      studHorses: action.payload.sort((a, b) => a.name.localeCompare(b.name)),
    };
  }

  if (action.type === FETCH_ALL_HORSES_START) {
    return {
      ...state,
      fetchingForms: true,
      formsFetched: false,
    };
  }

  if (action.type === FETCH_ALL_HORSES_FAILED) {
    return {
      ...state,
      fetchingForms: false,
      formsFetched: false,
      studHorses: [],
      orgFormsLength: 0,
    };
  }

  if (action.type === FETCH_FORMS_START) {
    return {
      ...state,
      fetchingForms: true,
      formsFetched: false,
    };
  }

  if (action.type === FETCH_FORMS_FAILED) {
    return {
      ...state,
      fetchingForms: false,
      formsFetched: false,
      studHorses: [],
    };
  }

  if (action.type === FETCH_HORSES_DONE) {
    return {
      ...state,
      fetchingForms: false,
      formsFetched: true,
      orgFormsLength: action.payload.pagination.total,
      studHorses: action.payload.content,
    };
  }

  if (action.type === FETCH_HORSES_BY_STUD_DONE) {
    return {
      ...state,
      fetchingForms: false,
      formsFetched: true,
      // orgFormsLength: action.payload.pagination.total,
      studHorses: action.payload,
    };
  }

  if (action.type === FETCH_HORSES_START) {
    return {
      ...state,
      fetchingForms: true,
      formsFetched: false,
    };
  }

  if (action.type === FETCH_HORSES_FAILED) {
    return {
      ...state,
      fetchingForms: false,
      formsFetched: false,
      studHorses: [],
      orgFormsLength: 0,
    };
  }

  if (action.type === FETCH_FORMS_GROUPS_START) {
    return {
      ...state,
      formsGroupsFetched: false,
    };
  }

  if (action.type === FETCH_FORMS_GROUPS_FAILED) {
    return {
      ...state,
      formsGroupsFetched: false,
    };
  }

  if (action.type === FETCH_FORMS_GROUPS_DONE) {
    return {
      ...state,
      orgFormsGroups: action.payload,
      orgFormsGroupsLength: action.payload.pagination.total,
      formsGroupsFetched: true,
    };
  }

  if (action.type === FETCH_ALL_FORMS_GROUPS_DONE) {
    return {
      ...state,
      orgFormsGroups: action.payload.content,
      orgFormsGroupsLength: action.payload.pagination.total,
      formsGroupsFetched: true,
    };
  }

  if (action.type === FETCH_ALLOWED_USERS_TO_GROUP_DONE) {
    return {
      ...state,
      allowedUsersToGroup: action.payload,
    };
  }

  if (action.type === UPDATE_USERS_ACCESS_TO_GROUP + DONE) {
    return {
      ...state,
      allowedUsersToGroup: action.payload,
    };
  }

  if (action.type === REMOVE_USER + DONE) {
    return {
      ...state,
      removedUser: true,
    };
  }

  if (action.type === REMOVE_USER + START) {
    return {
      ...state,
      removedUser: false,
    };
  }

  if (action.type === ACTIVATE_USER + DONE) {
    return {
      ...state,
      activatedUser: true,
    };
  }

  if (action.type === ACTIVATE_USER + START) {
    return {
      ...state,
      activatedUser: false,
    };
  }

  if (action.type === FETCH_MATCHING_USERS + DONE) {
    return {
      ...state,
      matchingUser: {
        ...action.payload[0],
        user: { ...action.payload[0].user },
      },
    };
  }

  if (action.type === CLEAR_MATCHING_USERS) {
    return {
      ...state,
      matchingUser: null,
    };
  }

  if (action.type === FETCH_MATCHING_USERS + START) {
    return {
      ...state,
      matchingUser: null,
    };
  }

  if (action.type === FETCH_MATCHING_USERS + FAILED) {
    return {
      ...state,
      matchingUser: null,
    };
  }

  if (action.type === UPDATE_USERS_ACCESS_TO_FORM + DONE) {
    return {
      ...state,
      allowedUsersToForm: action.payload,
    };
  }

  if (action.type === FETCH_ALLOWED_USERS_TO_FORM_DONE) {
    return {
      ...state,
      allowedUsersToFormFetched: true,
      allowedUsersToFormFetching: false,
      allowedUsersToForm: action.payload,
    };
  }
  if (action.type === FETCH_ALLOWED_USERS_TO_FORM_START) {
    return {
      ...state,
      allowedUsersToFormFetched: false,
      allowedUsersToFormFetching: true,
      allowedUsersToForm: action.payload,
    };
  }

  if (action.type === COPY_FORM_TO_FORM_GROUP_DONE) {
    return {
      ...state,
      orgForms: state.orgForms.map((f) => {
        if (f.id === action.payload.id) {
          return action.payload;
        }
        return f;
      }),
    };
  }

  if (action.type === REMOVE_FORM_FROM_FORM_GROUP_DONE) {
    return {
      ...state,
      orgForms: state.orgForms.map((f) => {
        if (f.id === action.payload.id) {
          return action.payload;
        }
        return f;
      }),
    };
  }

  if (action.type === MOVE_FORM_TO_FORM_GROUP_DONE) {
    return {
      ...state,
      orgForms: state.orgForms.map((f) => {
        if (f.id === action.payload.id) {
          return action.payload;
        }
        return f;
      }),
    };
  }

  if (action.type === FETCH_SCHEDULED_FORMS + DONE) {
    return {
      ...state,
      scheduledForms: action.payload,
    };
  }

  if (action.type === REMOVE_FORM_DONE) {
    return {
      ...state,
      formRemoved: true,
      formRemoving: false,
    };
  }
  if (action.type === REMOVE_FORM_START) {
    return {
      ...state,
      formRemoving: true,
    };
  }
  if (action.type === REMOVE_FORM_FAILED) {
    return {
      ...state,
      formRemoved: false,
      formRemoving: false,
    };
  }

  if (action.type === REMOVE_FORM_START) {
    return {
      ...state,
      formRemoved: false,
    };
  }

  if (action.type === FETCH_FORM_DONE) {
    const exists = state.orgForms.find(({ id }) => id === action.payload.id);

    if (exists) {
      return {
        ...state,
        orgForms: state.orgForms.map((f) => {
          if (f.id === action.payload.id) {
            return action.payload;
          }
          return f;
        }),
      };
    } else {
      return {
        ...state,
        orgForms: [action.payload],
      };
    }
  }
  if (action.type === FETCH_FORM_GROUP_DONE) {
    const exists = state.orgFormsGroups.find(
      ({ id }) => id === action.payload.id
    );

    if (exists) {
      return {
        ...state,
        orgFormsGroups: state.orgFormsGroups.map((group) => {
          if (group.id === action.payload.id) {
            return action.payload;
          }
          return group;
        }),
      };
    } else {
      return {
        ...state,
        orgFormsGroups: [action.payload],
      };
    }
  }

  if (action.type === REMOVE_FORM_GROUP + DONE) {
    return {
      ...state,
      orgFormsGroupsLength: state.orgFormsGroupsLength - 1,
      orgFormsGroups: state.orgFormsGroups.filter(
        ({ id }) => id !== action.payload
      ),
    };
  }

  if (action.type === DUPLICATE_FORM_GROUP + DONE) {
    return {
      ...state,
      formDuplicated: true,
      orgFormsGroups: [...state.orgFormsGroups, action.payload],
    };
  }

  if (action.type === DUPLICATE_FORM_GROUP + START) {
    return {
      ...state,
      formDuplicated: false,
    };
  }

  if (action.type === ACTIVATE_FORM + DONE) {
    const exists = state.orgForms.find(({ id }) => id === action.payload.id);

    if (exists) {
      return {
        ...state,
        orgForms: state.orgForms.map((f) => {
          if (f.id === action.payload.id) {
            return {
              ...action.payload,
              numberOfUsers: f.numberOfUsers,
              numberOfUsersActiveNow: f.numberOfUsersActiveNow,
            };
          }
          return f;
        }),
      };
    } else {
      return {
        ...state,
        orgForms: [action.payload],
      };
    }
  }

  if (action.type === FETCH_FORM_TEMPLATE_DONE) {
    return {
      ...state,
      formData: action.payload,
    };
  }

  if (action.type === FETCH_FILLED_FORMS_DONE) {
    return {
      ...state,
      filledForms: {
        ...state.filledForms,
        [action.payload.formId]: action.payload.data,
      },
    };
  }

  if (action.type === FETCH_FILLED_FORMS_EXTENDED + DONE) {
    // const exists = state.orgForms.find(({ id }) => id === action.payload.id);

    // if (exists) {
    //   return {
    //     ...state,
    //     orgForms: state.orgForms.map((f) => {
    //       if (f.id === action.payload.id) {
    //         return action.payload;
    //       }
    //       return f;
    //     }),
    //     filledFormsExtended: {
    //       ...state.filledFormsExtended,
    //       [action.payload.formId]: action.payload.data,
    //     },
    //   };
    // } else {
    return {
      ...state,
      // orgForms: [action.payload],
      filledFormsExtended: {
        ...state.filledFormsExtended,
        [action.payload.formId]: action.payload.result.data,
      },
      filledFormsExtendedLength: {
        ...state.filledFormsExtended,
        [action.payload.formId]: action.payload.result.pagination.total,
      },
    };
    // }
  }

  if (
    action.type === RECOVER_PASSWORD_DONE ||
    action.type === RECOVER_PASSWORD_FAILED
  ) {
    return {
      ...state,
      recoveringPassword: false,
    };
  }

  if (action.type === RECOVER_PASSWORD_START) {
    return {
      ...state,
      recoveringPassword: true,
    };
  }

  if (
    action.type === RESET_BY_MAIL_PASSWORD_DONE ||
    action.type === RESET_BY_MAIL_PASSWORD_FAILED
  ) {
    return {
      ...state,
      resettingByMailPassword: false,
    };
  }

  if (action.type === RESET_BY_MAIL_PASSWORD_START) {
    return {
      ...state,
      resettingByMailPassword: true,
    };
  }

  if (action.type === ALERT_SHOW) {
    return {
      ...state,
      alerts: [...state.alerts, { ...action.payload, id: +new Date() }],
    };
  }

  if (action.type === ALERT_HIDE || action.type === ALERT_TIMEOUT) {
    return {
      ...state,
      alerts: state.alerts.filter((alert) => alert.id !== action.payload),
    };
  }

  if (action.type === ALERT_HIDE_CUSTOM_ID) {
    return {
      ...state,
      alerts: state.alerts.filter((alert) => alert.customId !== action.payload),
    };
  }

  if (action.type === FETCH_MY + DONE) {
    return {
      ...state,
      my: action.payload,
      firstLogin: action.payload.my.firstLogin,
    };
  }

  if (action.type === UPDATE_MY + DONE) {
    return {
      ...state,
      my: action.payload,
    };
  }

  if (action.type === FETCH_ACTIVE_FORMS + START) {
    return {
      ...state,
      fetchingActiveForms: true,
    };
  }

  if (action.type === FETCH_ACTIVE_FORMS + FAILED) {
    return {
      ...state,
      fetchingActiveForms: false,
    };
  }

  if (action.type === FETCH_ACTIVE_FORMS + DONE) {
    return {
      ...state,
      activeForms: action.payload.content,
      activeFormsLength: action.payload.pagination.total,
      fetchingActiveForms: false,
    };
  }
  if (action.type === FETCH_ACTIVE_FORMS_ALL + DONE) {
    return {
      ...state,
      activeFormsAll: action.payload,
      activeFormsAllLength: action.payload.length,
      fetchingActiveForms: false,
    };
  }

  if (action.type === FETCH_USER_ALL_FORMS + DONE) {
    return {
      ...state,
      userAllForms: action.payload,
    };
  }

  if (action.type === FETCH_PLANNED_FORMS + START) {
    return {
      ...state,
      fetchingPlannedForms: true,
    };
  }

  if (action.type === FETCH_PLANNED_FORMS + FAILED) {
    return {
      ...state,

      fetchingPlannedForms: false,
    };
  }

  if (action.type === FETCH_USER_ALL_AVAILABLE_FORMS + DONE) {
    return {
      ...state,
      userAllAvailableForms: action.payload,
    };
  }

  if (action.type === FETCH_PLANNED_FORMS + DONE) {
    return {
      ...state,
      plannedForms: action.payload.content,
      plannedFormsLength: action.payload.pagination.total,
      fetchingPlannedForms: false,
    };
  }

  if (action.type === FETCH_PLANNED_FORMS_ALL + DONE) {
    return {
      ...state,
      plannedFormsAll: action.payload,
      plannedFormsAllLength: action.payload.length,
      fetchingPlannedForms: false,
    };
  }

  if (action.type === FETCH_FORMS_SCHEDULED_FOR_FUTURE + START) {
    return {
      ...state,
      fetchingFormsScheduledForFuture: true,
    };
  }

  if (action.type === FETCH_FORMS_SCHEDULED_FOR_FUTURE + FAILED) {
    return {
      ...state,
      fetchingFormsScheduledForFuture: false,
    };
  }

  if (action.type === FETCH_FORMS_SCHEDULED_FOR_FUTURE + DONE) {
    return {
      ...state,
      scheduledForFutureForms: action.payload.content,
      scheduledForFutureFormsLength: action.payload.pagination.total,
      fetchingFormsScheduledForFuture: false,
    };
  }

  if (action.type === FETCH_ARCHIVED_FORMS + START) {
    return {
      ...state,
      fetchingArchivedForms: true,
      archivedFormsFetched: false,
    };
  }

  if (action.type === FETCH_ARCHIVED_FORMS + FAILED) {
    return {
      ...state,
      fetchingArchivedForms: false,
      archivedFormsFetched: false,
    };
  }

  if (action.type === FETCH_ARCHIVED_FORMS + DONE) {
    return {
      ...state,
      archivedForms: action.payload.content,
      archivedFormsLength: action.payload.pagination.total,
      fetchingArchivedForms: false,
      archivedFormsFetched: true,
    };
  }

  if (action.type === FETCH_ARCHIVED_FORMS_BAG + DONE) {
    return {
      ...state,
      archivedFormsBag: {
        ...state.archivedFormsBag,
        [`${action.bagId}`]: action.payload.content,
      },
      // archivedFormsBagLength: action.payload.pagination.total,
    };
  }

  if (action.type === FETCH_SAVED_FORM_DATA + START) {
    return {
      ...state,
      fetchingSavedForms: true,
    };
  }

  if (action.type === FETCH_SAVED_FORM_DATA + FAILED) {
    return {
      ...state,
      fetchingSavedForms: false,
    };
  }

  if (action.type === FETCH_SAVED_FORM_DATA + DONE) {
    return {
      ...state,
      savedForms: action.payload.content,
      savedFormsLength: action.payload.pagination.total,
      fetchingSavedForms: false,
    };
  }

  if (action.type === FETCH_FILLED_FORM_DATA + START) {
    return {
      ...state,
      fetchingSavedForms: true,
    };
  }

  if (action.type === FETCH_FILLED_FORM_DATA + FAILED) {
    return {
      ...state,
      fetchingSavedForms: false,
    };
  }

  if (action.type === FETCH_FILLED_FORM_DATA + DONE) {
    return {
      ...state,
      allFilledForms: action.payload,
      fetchingSavedForms: false,
    };
  }

  if (action.type === UPDATE_ENTITLED_USERS + DONE) {
    return {
      ...state,
      orgForms: state.orgForms.map((form) => {
        if (form.id === action.payload.id) {
          return action.payload;
        }
        return form;
      }),
    };
  }

  if (action.type === DUPLICATE_FORM + START) {
    return {
      ...state,
      orgFormDuplicated: false,
    };
  }

  if (action.type === DUPLICATE_FORM + DONE) {
    return {
      ...state,
      orgForms: [...state.orgForms, action.payload],
      orgFormDuplicated: true,
    };
  }

  if (action.type === DEACTIVATE_FORM + DONE) {
    return {
      ...state,
      orgForms: state.orgForms.map((form) => {
        if (form.id === action.payload.id) {
          return {
            ...action.payload,
            numberOfUsers: form.numberOfUsers,
            numberOfUsersActiveNow: form.numberOfUsersActiveNow,
          };
        }
        return form;
      }),
    };
  }

  if (action.type === FETCH_OLD_FORM_EDITIONS + DONE) {
    const firstForm = action.payload[0];
    return {
      ...state,
      orgOldFromEditions: [
        ...state.orgOldFromEditions.filter((form) => {
          return !firstForm || firstForm.bag.id !== form.bag.id;
        }),
        ...action.payload,
      ].sort((a, b) => b.id - a.id), // newer first
    };
  }

  if (action.type === UPDATE_MY_ORGANIZATION + DONE) {
    return {
      ...state,
      organization: action.payload,
    };
  }

  if (action.type === ADD_ITEM_TO_CART + DONE) {
    return {
      ...state,
      cartItems: [...state.cartItems, action.payload],
    };
  }

  if (action.type === EDIT_ITEM_CART + DONE) {
    return {
      ...state,
      cartItems: state.cartItems.map((cartItem) => {
        if (cartItem.id === action.payload.id) {
          return action.payload;
        }
        return cartItem;
      }),
    };
  }

  if (action.type === REMOVE_ITEM_FROM_CART + DONE) {
    return {
      ...state,
      cartItems: state.cartItems.filter(({ id }) => id !== action.payload),
    };
  }

  // if (action.type === ADD_ITEM_TO_CART + DONE) {
  //   return {
  //     ...state,
  //     cart: [...state.cart, action.payload],
  //   };
  // }

  if (action.type === FETCH_CART_ITEMS + DONE) {
    return {
      ...state,
      cartItems: action.payload,
    };
  }

  if (action.type === REMOVE_CART_ITEM_DONE) {
    return {
      ...state,
      cartItems: state.cartItems.filter(({ id }) => id !== action.payload),
    };
  }

  if (action.type === FETCH_PRICE_LIST + DONE) {
    const products = action.payload.products || {};
    return {
      ...state,
      priceList: Object.keys(products).reduce((all, current) => {
        return {
          ...all,
          [current]: {
            price: products[current].prices[0].unitPrice,
            vat: products[current].vatRate,
            currency: action.payload.currency,
          },
        };
      }, {}),
    };
  }

  if (action.type === FETCH_ALL_MY_TESTS + DONE) {
    return {
      ...state,
      cartItems: state.cartItems.filter(({ id }) => id !== action.payload),
    };
  }

  if (action.type === FETCH_FILLED_TESTS + DONE) {
    return {
      ...state,
      filledTests: action.payload,
    };
  }

  if (action.type === FETCH_FILLED_TESTS + FAILED) {
    return {
      ...state,
      filledTests: [],
    };
  }

  if (action.type === BUY_CART_ITEM + DONE) {
    const boughtCartItemId = action.payload;
    return {
      ...state,
      cartItems: state.cartItems.filter((c) => c.id !== boughtCartItemId),
      allMyTests: action.payload,
      allMyTestsFetching: true,
    };
  }

  if (action.type === BUY_CART_ITEM + FAILED) {
    return {
      ...state,
      allMyTestsFetching: false,
    };
  }

  if (action.type === BUY_CART_ITEM + START) {
    return {
      ...state,
      allMyTestsFetching: true,
    };
  }

  if (action.type === FETCH_ACTIVATED_KITS + DONE) {
    return {
      ...state,
      activatedKits: action.payload,
    };
  }

  if (action.type === TOGGLE_REQUESTED_SHARING_BY_OWNER + DONE) {
    return {
      ...state,
      allMyTests: state.allMyTests.map((test) => {
        if (test.id === action.payload.id) {
          return {
            ...test,
            isSharingWithOwnerRequested:
              action.payload.isSharingWithOwnerRequested,
          };
        }
        return test;
      }),
      filledTests: state.filledTests.map((test) => {
        if (test.id === action.payload.id) {
          return {
            ...test,
            isSharingWithOwnerRequested:
              action.payload.isSharingWithOwnerRequested,
          };
        }
        return test;
      }),
    };
  }

  if (action.type === UPDATE_FORM_DATA_IS_SHARED + DONE) {
    return {
      ...state,
      filledTests: state.filledTests.map((data) => {
        if (data.id === action.payload.id) {
          return {
            ...data,
            isShared: action.payload.isShared,
          };
        }
        return data;
      }),
    };
  }

  if (action.type === SET_APP_BAR_TITLE) {
    return {
      ...state,
      appBarTitle: action.payload.title,
    };
  }

  if (action.type === FETCH_TERMS_ORGANIZATION + DONE) {
    return {
      ...state,
      terms: action.payload,
    };
  }

  if (action.type === EDIT_USER + DONE) {
    return {
      ...state,
      my: action.payload.my,
    };
  }

  if (action.type === FETCH_UPLOADABLE_FILE_TYPES + DONE) {
    return {
      ...state,
      uploadableFileTypes: action.payload,
    };
  }

  if (action.type === REMOVE_FORM_DATA_DRAFT + DONE) {
    return {
      ...state,
      // savedForms: state.savedForms.filter((f) => f.id !== action.payload.id),
    };
  }

  if (action.type === FETCH_HAS_SINGLE_SCHEDULED_FORM + DONE) {
    return {
      ...state,
      hasSingleScheduledForm: action.payload,
    };
  }

  if (action.type === TOGGLE_GLOBAL_THEME) {
    let newTheme;

    if (state.globalTheme === "light") {
      newTheme = "high-contrast";
    } else {
      newTheme = "light";
    }

    localStorage.setItem("globalTheme", newTheme);

    return {
      ...state,
      globalTheme: newTheme,
    };
  }

  if (action.type === READ_GLOBAL_THEME) {
    let newTheme = localStorage.getItem("globalTheme") || "light";

    return {
      ...state,
      globalTheme: newTheme,
    };
  }

  if (action.type === SMALL_FONT_SIZE) {
    return {
      ...state,
      baseFontSize: 0, // 0, 1, 2
    };
  }
  if (action.type === MEDIUM_FONT_SIZE) {
    return {
      ...state,
      baseFontSize: 1, // 0, 1, 2
    };
  }
  if (action.type === LARGE_FONT_SIZE) {
    return {
      ...state,
      baseFontSize: 2, // 0, 1, 2
    };
  }

  if (action.type === FETCH_CONFIG + DONE) {
    return {
      ...state,
      configFetched: true,
      config: action.payload,
    };
  }

  if (action.type === CLEAR_ALL_FORM) {
    return {
      ...state,
      activeForms: null,
      archivedForms: null,
      plannedForms: null,
      savedForms: null,
      filledFormData: null,
      formsScheduledForFuture: null,
    };
  }

  if (action.type === FETCH_TERMS_ACCEPTED_BY_USER + START) {
    return {
      ...state,
      fetchingTermsAcceptedByUser: true,
    };
  }

  if (action.type === FETCH_TERMS_ACCEPTED_BY_USER + FAILED) {
    return {
      ...state,
      fetchingTermsAcceptedByUser: false,
    };
  }

  if (action.type === FETCH_TERMS_ACCEPTED_BY_USER + DONE) {
    return {
      ...state,
      termsAcceptedByUser: action.payload,
      fetchingTermsAcceptedByUser: false,
    };
  }

  if (action.type === FETCH_ORGANIZATION_CURRENT_TERMS + START) {
    return {
      ...state,
      fetchingOrgCurrentTerms: true,
    };
  }

  if (action.type === FETCH_ORGANIZATION_CURRENT_TERMS + FAILED) {
    return {
      ...state,
      fetchingOrgCurrentTerms: false,
    };
  }

  if (action.type === FETCH_ORGANIZATION_CURRENT_TERMS + DONE) {
    return {
      ...state,
      orgCurrentTerms: action.payload,
      fetchingOrgCurrentTerms: false,
    };
  }

  if (action.type === FETCH_ORGANIZATION_FUTURE_TERMS + START) {
    return {
      ...state,
      fetchingOrgFutureTerms: true,
    };
  }

  if (action.type === FETCH_ORGANIZATION_FUTURE_TERMS + FAILED) {
    return {
      ...state,
      fetchingOrgFutureTerms: false,
    };
  }

  if (action.type === FETCH_ORGANIZATION_FUTURE_TERMS + DONE) {
    return {
      ...state,
      orgFutureTerms: action.payload,
      fetchingOrgFutureTerms: false,
    };
  }

  if (action.type === FETCH_API_VERSION + DONE) {
    return {
      ...state,
      apiVersion: action.payload,
      apiVersionFetched: true,
    };
  }

  if (action.type === UNSUPPORTED_BROWSER) {
    return {
      ...state,
      unsupportedBrowser: action.payload,
    };
  }

  if (action.type === AFTER_FIRST_LOGIN + DONE) {
    return {
      ...state,
      firstLogin: false,
    };
  }

  if (action.type === FETCH_VACCINATIONS + DONE) {
    return {
      ...state,
      vaccinations: action.payload.content,
      vaccinationsFetched: true,
      vaccinationsFetching: false,
    };
  }

  if (action.type === FETCH_VACCINATIONS + START) {
    return {
      ...state,
      vaccinationsFetched: false,
      vaccinationsFetching: true,
    };
  }
  if (action.type === FETCH_VACCINATIONS + FAILED) {
    return {
      ...state,
      vaccinations: [],
      vaccinationsFetching: false,
    };
  }

  if (action.type === FETCH_VETERINARY_VISITS + DONE) {
    return {
      ...state,
      veterinaryVisits: action.payload.content,
      veterinaryVisitsFetched: true,
      veterinaryVisitsFetching: false,
    };
  }

  if (action.type === FETCH_VETERINARY_VISITS + START) {
    return {
      ...state,
      veterinaryVisitsFetched: false,
      veterinaryVisitsFetching: true,
    };
  }
  if (action.type === FETCH_VETERINARY_VISITS + FAILED) {
    return {
      ...state,
      veterinaryVisits: [],
      veterinaryVisitsFetching: false,
    };
  }

  if (action.type === FETCH_FOALINGS + DONE) {
    return {
      ...state,
      foalings: action.payload.content,
      foalingsFetched: true,
      foalingsFetching: false,
    };
  }

  if (action.type === FETCH_FOALINGS + START) {
    return {
      ...state,
      foalingsFetched: false,
      foalingsFetching: true,
    };
  }
  if (action.type === FETCH_FOALINGS + FAILED) {
    return {
      ...state,
      foalings: [],
      foalingsFetching: false,
    };
  }

  if (action.type === FETCH_ALERTS + DONE) {
    return {
      ...state,
      horseAlertsTotal: action.payload.pagination.total,
      horseAlerts: action.payload.content,
      horseAlertsFetched: true,
      horseAlertsFetching: false,
    };
  }

  if (action.type === FETCH_ALERTS + START) {
    return {
      ...state,
      horseAlertsFetched: false,
      horseAlertsFetching: true,
    };
  }
  if (action.type === FETCH_ALERTS + FAILED) {
    return {
      ...state,
      horseAlerts: [],
      horseAlertsTotal: 0,
      horseAlertsFetching: false,
    };
  }

  if (action.type === FETCH_ANOMALIES + DONE) {
    return {
      ...state,
      anomaliesTotal: action.payload.pagination.total,
      anomalies: action.payload.content,
      anomaliesFetched: true,
      anomaliesFetching: false,
    };
  }

  if (action.type === FETCH_ANOMALIES + START) {
    return {
      ...state,
      anomaliesFetched: false,
      anomaliesFetching: true,
    };
  }
  if (action.type === FETCH_ANOMALIES + FAILED) {
    return {
      ...state,
      anomalies: [],
      anomaliesTotal: 0,
      anomaliesFetching: false,
    };
  }

  if (action.type === FETCH_CAMERAS + DONE) {
    return {
      ...state,
      camerasTotal: action.payload.pagination.total,
      cameras: action.payload.content,
      camerasFetched: true,
      camerasFetching: false,
    };
  }

  if (action.type === FETCH_CAMERAS + START) {
    return {
      ...state,
      camerasFetched: false,
      camerasFetching: true,
    };
  }
  if (action.type === FETCH_CAMERAS + FAILED) {
    return {
      ...state,
      cameras: [],
      camerasTotal: 0,
      camerasFetching: false,
    };
  }
  if (action.type === FETCH_BOXES + DONE) {
    return {
      ...state,
      boxesTotal: action.payload.pagination.total,
      boxes: action.payload.content,
      boxesFetched: true,
      boxesFetching: false,
    };
  }

  if (action.type === FETCH_BOXES + START) {
    return {
      ...state,
      boxesFetched: false,
      boxesFetching: true,
    };
  }
  if (action.type === FETCH_BOXES + FAILED) {
    return {
      ...state,
      boxes: [],
      boxesTotal: 0,
      boxesFetching: false,
    };
  }

  if (action.type === FETCH_SIGNAL_INFO + START) {
    return {
      ...state,
      signalInfo: {},
      signalFetching: true,
    };
  }
  if (action.type === FETCH_SIGNAL_INFO + DONE) {
    return {
      ...state,
      signalInfo: action.payload,
      signalFetching: false,
    };
  }

  if (action.type === FETCH_SIGNAL_INFO + FAILED) {
    return {
      ...state,
      signalInfo: {},
      signalFetching: false,
    };
  }

  if (action.type === FETCH_HORSE_NOTIFICATIONS + START) {
    return {
      ...state,
      fetchingNotifications: true,
    };
  }
  if (action.type === FETCH_HORSE_NOTIFICATIONS + DONE) {
    return {
      ...state,
      notifications: action.payload.push,
      fetchingNotifications: false,
    };
  }
  if (action.type === FETCH_HORSE_NOTIFICATIONS + FAILED) {
    return {
      ...state,
      notifications: [],
      fetchingNotifications: false,
    };
  }

  return state;
}

export default rootReducer;
