import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  fetchHorse,
  fetchHorseFarms,
  saveHorse,
  updateHorse,
} from "../../redux/actions";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import PageTitle from "../../components/page-title";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import ClearIcon from "@material-ui/icons/Clear";
import DateFnsUtils from "@date-io/date-fns";
import EventIcon from "@material-ui/icons/Event";
import { genderList } from "./gender";
import { colors as rawColors } from "./color";
import { breeds as rawBreeds } from "./breed";
import { disciplines as rawDisciplines } from "./discipline";
import { Autocomplete } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import FoalingPanel from "../../components/foaling-panel";
import FormPermissionsPanel from "../../components/form-permissions";
import NotificationPanel from "./notifiactionPanel";

const useStyles = makeStyles((theme) => ({
  formHint: {
    fontSize: "0.6rem",
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: "#999",
  },
}));

const AddNewHorse = ({ match, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [horseId, setHorseId] = useState(match.params.id);
  const [name, setName] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [wasInPregnantRender, setWasInPregnantRender] = useState(false);
  const [wasNotInPregnant, setWasNotInPregnant] = useState(false);
  const [pregnant, setPregnant] = useState(false);
  const [color, setColor] = useState("");
  const [breed, setBreed] = useState("");
  const [stud, setStud] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [disciplines, setDisciplines] = useState([]);

  const [formValid, setFormValid] = useState(false);
  const [openFoalingPanel, setOpenFoalingPanel] = useState(false);
  const [openNotificationPanel, setOpenNotificationPanel] = useState(false);

  const [busy, setBusy] = useState(false);

  const studList = useSelector((s) => s.orgFormsGroups);
  const my = useSelector((s) => s.my);
  const currentContext = useSelector((s) => s.currentContext);
  const isOwner = my.user.authorities.indexOf("ROLE_OWNER") !== -1;

  const [openPermissionsPanel, setOpenPermissionsPanel] = useState(false);

  const id = match.params.id;

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (isOwner) {
        dispatch(fetchHorseFarms({ page: 0, pageSize: 1000, sort: "name" }));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (id) {
        const {
          name,
          dateOfBirth,
          passportNumber,
          gender,
          color,
          breed,
          discipline,
          studId,
          pregnant,
          mareWasNeverPregnant,
        } = await dispatch(fetchHorse(id));
        setName(name);
        setDateOfBirth(dateOfBirth);
        setPassportNumber(passportNumber);
        setGender(gender);
        setStud(studId);
        setPregnant(pregnant);
        setWasNotInPregnant(mareWasNeverPregnant);
        setColor(colorsList.find((c) => c.value === color));
        setBreed(breedsList.find((b) => b.value === breed));
        setDiscipline(disciplinesList.find((d) => d.value === discipline));
      }
    })();
  }, [id]);

  const colorsList = rawColors
    .map((c) => ({ label: t(`color.${c.value}`), ...c }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const selectedLang = localStorage.getItem("i18nextLng") || "pl";

  const breedsList = rawBreeds
    .map((c) => ({ label: t(`breed.${c.value}`), ...c }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const disciplinesList = rawDisciplines
    .map((c) => ({ label: t(`discipline.${c.value}`), ...c }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (isOwner && !id) {
      setFormValid(name && dateOfBirth && gender && color && stud);
    } else {
      setFormValid(name && dateOfBirth && gender && color);
    }
  }, [name, dateOfBirth, gender, color, stud]);

  useEffect(() => {
    if (gender === "Mare") {
      setWasInPregnantRender(true);
    } else {
      setWasInPregnantRender(false);
      setWasNotInPregnant(false);
    }
  }, [gender]);

  useEffect(() => {
    if (color) {
      const c = colorsList?.find((tmpC) => tmpC.value === color.value);
      if (c) {
        setColor(c);
      }
    }

    if (breed) {
      const b = breedsList?.find((tmpB) => tmpB.value === breed.value);
      if (b) {
        setBreed(b);
      }
    }

    if (discipline) {
      const d = disciplinesList?.find(
        (tmpD) => tmpD.value === discipline.value
      );
      if (d) {
        setDiscipline(d);
      }
    }
  }, [selectedLang]);

  const handleClearData = () => {
    setName("");
    setDateOfBirth("");
    setGender("");
    setColor("");
    setBreed("");
    setDiscipline("");
    setPassportNumber("");
    setStud("");
  };

  const handleStudSubmit = async () => {
    let r = null;
    setBusy(true);
    if (id) {
      r = await dispatch(
        updateHorse(id, {
          ...(name && { name }),
          ...(color && { color: color.value }),
          ...(breed ? { breed: breed.value } : { breed: null }),
          ...(dateOfBirth && { dateOfBirth }),
          ...(discipline
            ? { discipline: discipline.value }
            : { discipline: null }),
          ...(gender && { gender }),
          ...(passportNumber ? { passportNumber } : { passportNumber: null }),
          ...(gender === "Mare" && { mareWasNeverPregnant: wasNotInPregnant }),
          studId: isOwner ? stud : currentContext.studId,
        })
      );
    } else {
      r = await dispatch(
        saveHorse({
          ...(name && { name }),
          ...(color && { color: color.value }),
          ...(breed && { breed: breed.value }),
          ...(dateOfBirth && { dateOfBirth }),
          ...(discipline && { discipline: discipline.value }),
          ...(gender && { gender }),
          ...(passportNumber && { passportNumber }),
          ...(gender === "Mare" && { mareWasNeverPregnant: wasNotInPregnant }),
          studId: isOwner ? stud : currentContext.studId,
        })
      );
      setHorseId(r.data.id);
    }

    if (r?.status === 200) {
      dispatch(
        alertAdd({
          text: id
            ? t("horse_form.successOnUpdate")
            : t("horse_form.successOnAdd"),
          isSuccess: true,
        })
      );
      // history.push("/horses");
      if (!wasNotInPregnant && gender === "Mare") {
        setOpenFoalingPanel(true);
      } else {
        setOpenPermissionsPanel(true);
      }
      setBusy(false);
    } else {
      dispatch(alertAdd({ text: t("horse_form.errorOnAdd"), isError: true }));
    }
  };

  return (
    <Box p={1}>
      <PageTitle
        title={id ? t("horse_form.label_edition") : t("horse_form.label_add")}
      />
      <Box p={1} style={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={2} sm={12} lg={6}>
          <Grid item xs={12}>
            <TextField
              label={t("horse_form.name")}
              variant="outlined"
              required
              value={name}
              fullWidth
              onChange={({ target: { value: name } }) => setName(name)}
            />
          </Grid>
          <Grid item xs={12}>
            <MuiPickersUtilsProvider
              locale={selectedLang === "pl" ? plLocale : null}
              utils={DateFnsUtils}
            >
              <KeyboardDatePicker
                style={{ margin: 0 }}
                fullWidth
                inputVariant="outlined"
                margin="normal"
                id="date-picker-dialog"
                label={t("horse_form.birth_date")}
                format="dd/MM/yyyy"
                required
                value={dateOfBirth || null}
                onChange={(date) => setDateOfBirth(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                inputProps={{
                  readOnly: true,
                }}
                cancelLabel={t("cancel")}
                okLabel={t("submit")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t("horse_form.passport")}
              variant="outlined"
              value={passportNumber}
              fullWidth
              onChange={({ target: { value: passportNumber } }) =>
                setPassportNumber(passportNumber)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="open-gender-select-label">
                {t("horse_form.gender")}
              </InputLabel>
              <Select
                labelId="open-gender-select-label"
                id="open-gender-select"
                label={t("horse_form.gender") + "*"}
                value={gender || ""}
                onChange={({ target: { value } }) => {
                  setGender(value);
                }}
                autoWidth={true}
              >
                {genderList.map(({ value }) => (
                  <MenuItem key={value} value={value}>
                    {t(`gender.${value}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {wasInPregnantRender && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={wasNotInPregnant || false}
                    // onChange={() => setOpenFoalingPanel(true)}
                    onChange={() => setWasNotInPregnant(!wasNotInPregnant)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={t("horse_form.pregnant")}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              id="color-assign"
              options={colorsList}
              value={color}
              required
              getOptionLabel={(option) => option.label || ""}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              onChange={(e, v) => setColor(v)}
              noOptionsText={t("no_results")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t("horse_form.color")}
                  variant="outlined"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: "200px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              id="breed-assign"
              options={breedsList}
              value={breed}
              getOptionLabel={(option) => option.label || ""}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              onChange={(e, v) => {
                setBreed(v);
              }}
              noOptionsText={t("no_results")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("horse_form.breed")}
                  variant="outlined"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: "200px",
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              id="discipline-assign"
              options={disciplinesList}
              value={discipline}
              getOptionLabel={(option) => option.label || ""}
              onChange={(e, v) => {
                setDiscipline(v);
              }}
              noOptionsText={t("no_results")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("horse_form.discipline")}
                  variant="outlined"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: "200px",
                },
              }}
            />
          </Grid>
          {isOwner && !id && (
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" size="small" required>
                <InputLabel id="select-outlined-label" required>
                  {t("horse_form.stud")}
                </InputLabel>
                <Select
                  labelId="stud-outlined-label"
                  id="stud-outlined"
                  value={stud}
                  onChange={(event) => setStud(event.target.value)}
                  label={`${t("horse_form.stud")} *`}
                  required
                >
                  {studList.map((stud, index) => {
                    return (
                      <MenuItem key={index} value={stud.id}>
                        {stud.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <NotificationPanel
              open={openNotificationPanel}
              hide={() => setOpenNotificationPanel(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <span className={classes.formHint}>
              * {t("horse_form.required")}
            </span>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              size="small"
              variant="contained"
              disabled={!formValid || busy}
              color="primary"
              style={{ padding: "8px 16px", marginRight: "15px" }}
              onClick={() => {
                handleStudSubmit();
              }}
            >
              {!id ? t("horse_form.add") : t("save")}
            </Button>
            <Button
              size="small"
              variant="contained"
              style={{ padding: "8px 16px" }}
              onClick={handleClearData}
            >
              {t("horse_form.clear")}
            </Button>
          </Grid>
        </Grid>
      </Box>
      {openPermissionsPanel && (
        <FormPermissionsPanel
          form={{ studId: stud, id: horseId }}
          hide={() => {
            setOpenPermissionsPanel(false);
            history.push("/horses");
          }}
          open={openPermissionsPanel}
        />
      )}
      {openFoalingPanel && (
        <FoalingPanel
          open={openFoalingPanel}
          onClose={() => {
            setOpenFoalingPanel(false);
            setOpenPermissionsPanel(true);
          }}
          horseId={horseId}
          pregnant={pregnant}
        />
      )}
    </Box>
  );
};

export default AddNewHorse;
