export const disciplines = [
  { value: "pleasure" },
  { value: "driving" },
  { value: "jumping" },
  { value: "dressage" },
  { value: "eventing" },
  { value: "breeding_purpose" },
  { value: "voulting" },
  { value: "western" },
  { value: "endurance" },
  { value: "other" },
  { value: "racing" },
];
