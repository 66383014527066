import { Box, Button, Grid, InputLabel, Typography } from "@material-ui/core";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import s from "./index.module.css";

const AvatarUpload = ({
  avatarPath,
  newAvatarPath,
  enableProfileDataEdition,
  setDataChanged,
  handleUploadedFiles,
  removeAvatar,
  deletingAvatar,
  t,
}) => {
  const globalTheme = useSelector((s) => s.globalTheme);
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h6">
        {t("user_profile.profile_photo.label")}
      </Typography>
      <Grid item xs={12}>
        <InputLabel style={{ color: "black" }}>
          {" "}
          {t("user_profile.profile_photo.current_photo")}
        </InputLabel>
        <Box style={{ margin: "10px" }}>
          {avatarPath ? (
            <img
              className={s.avatarImg}
              src={`/org-media/files${avatarPath}`}
              alt={t("user_profile.profile_photo.alt_label")}
            />
          ) : (
            <span style={{ fontWeight: "bold" }}>
              {t("user_profile.profile_photo.empty")}
            </span>
          )}
        </Box>
      </Grid>
      {newAvatarPath && (
        <Grid item xs={12}>
          <InputLabel style={{ color: "black" }}>
            {t("user_profile.profile_photo.new")}
          </InputLabel>
          <Box style={{ margin: "10px" }}>
            <img
              className={s.avatarImg}
              src={newAvatarPath}
              alt={t("user_profile.profile_photo.alt")}
            />
          </Box>
        </Grid>
      )}
      {deletingAvatar && (
        <Grid item xs={12}>
          {t("user_profile.profile_photo.remove_photo_text")}
        </Grid>
      )}
      <Button
        className="button is-primary"
        variant="contained"
        component="label"
        className={classes.fontSize}
        style={{
          backgroundColor:
            globalTheme === "high-contrast" ? "#000" : "lightgrey",
          color: globalTheme === "high-contrast" ? "#fff200" : "black",
          border: globalTheme === "high-contrast" && "1px solid #fff200",
        }}
        disabled={!enableProfileDataEdition}
      >
        {!avatarPath
          ? t("user_profile.profile_photo.upload_photo")
          : t("user_profile.profile_photo.change_photo")}
        <input
          type="file"
          hidden
          disabled={!enableProfileDataEdition}
          onChange={({ target: { files } }) => {
            setDataChanged(true);
            handleUploadedFiles(files);
          }}
        />
      </Button>
      {avatarPath && (
        <Button
          className="button is-primary"
          variant="contained"
          component="label"
          className={classes.fontSize}
          style={{
            backgroundColor:
              globalTheme === "high-contrast" ? "#000" : "lightgrey",
            color: globalTheme === "high-contrast" ? "#fff200" : "black",
            border: globalTheme === "high-contrast" && "1px solid #fff200",
          }}
          disabled={!enableProfileDataEdition}
          onClick={() => {
            setDataChanged(true);
            removeAvatar();
          }}
        >
          {t("user_profile.profile_photo.remove_photo")}
        </Button>
      )}
    </Box>
  );
};

export default AvatarUpload;
