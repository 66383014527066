import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import Can from "../components/can";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { normalizeZIP } from "../components/address-form/shipping-info";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { validate } from "email-validator";
import {
  addPerson,
  editUser,
  alertAdd,
  fetchMatchingUsers,
  clearMatchingUser,
  addExistingPersonToStud,
  fetchFormsGroups,
  fetchHorseFarms,
  addExistingPersonByStudId,
  fetchAllOrgUsersPaginated,
  addPersonByStudAdmin,
} from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { checkPermission } from "../rbac";
import { PersonForm } from "../components/person-form";
import Alert from "@material-ui/lab/Alert";
import PageTitle from "../components/page-title";
import { ConfirmDialogMUI } from "../components/confirm-dialog-mui";
import IconButton from "@material-ui/core/IconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ClearIcon from "@material-ui/icons/Clear";
import GroupsAndFormsPermissionsDialog from "../components/groups-and-forms-permissions";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import ExistingUserModal from "../components/user-existing-modal";
import { useTranslation } from "react-i18next";
import { fetchUsersByStudContext } from "../redux/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary,
    background: "WhiteSmoke",
  },
  formHint: {
    fontSize: "0.6rem",
    lineHeight: "1.4",
    margin: "-5px auto 5px",
    color: "#999",
  },
  userExists: {
    fontSize: "1.25rem",
    fontWeight: "500",
    color: "#808080",
  },
}));

const AddNewPersonsPage = ({ history, match }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [alertMessage, setAlertMessage] = useState("");

  const my = useSelector((state) => state.my);
  const isOwner = !!my?.user.authorities.includes("ROLE_OWNER");

  const [userId, setUserId] = useState("");
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [stud, setStud] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [checkUserExisiting, setCheckUserExisting] = useState(false);

  const [accessSubResources, setAccessSubResources] = useState(false);

  const id = match.params.id;

  const [openConfirmPanel, setOpenConfirmPanel] = useState(false);
  const [emailsMatch, setEmailsMatch] = useState(false);

  const matchingUser = useSelector((s) => s.matchingUser);
  const allStuds = useSelector((s) => s.orgFormsGroups);
  const users = useSelector((s) => s.orgUsers);

  const roleTypes = [
    {
      type: "ROLE_USER",
      description: t("add_user.user_type.horse_owner"),
    },
    {
      type: "ROLE_VET",
      description: t("add_user.user_type.vet"),
    },
    {
      type: "ROLE_GROOM",
      description: t("add_user.user_type.groom"),
    },
    {
      type: "ROLE_STUD_OWNER",
      description: t("add_user.user_type.horse_farm_owner"),
    },
  ];

  useEffect(() => {
    if (email && email2 && email !== email2) {
      setEmailsMatch(false);
    } else {
      setEmailsMatch(true);
    }
    if (isOwner) {
      setFormValid(
        userType &&
          firstName &&
          surname &&
          email &&
          email2 &&
          email === email2 &&
          stud &&
          validateEmail(email)
      );
    } else {
      matchingUser
        ? setFormValid(
            userType &&
              firstName &&
              surname &&
              email &&
              email2 &&
              email === email2 &&
              validateEmail(email) &&
              !users.find((u) => u.id === matchingUser.id)
          )
        : setFormValid(
            userType &&
              firstName &&
              surname &&
              email &&
              email2 &&
              email === email2 &&
              validateEmail(email)
          );
    }
  }, [firstName, surname, email, email2, userType, stud]);

  useEffect(() => {
    (async () => {
      if (isOwner) {
        await dispatch(
          fetchHorseFarms({ page: 0, pageSize: 1000, sort: "name" })
        );
      }
    })();
  }, [isOwner]);

  useEffect(() => {
    if (userType !== "ROLE_GROOM") {
      setAccessSubResources(false);
    }
  }, [userType]);

  useEffect(() => {
    setCheckUserExisting(
      email === email2 && validateEmail(email) ? true : false
    );
  }, [email, email2]);

  useEffect(() => {
    (async () => {
      if (checkUserExisiting) {
        await dispatch(
          fetchMatchingUsers({
            email,
          })
        );

        if (isOwner) {
          await dispatch(fetchAllOrgUsersPaginated({ page: 0 }));
        } else {
          await dispatch(fetchUsersByStudContext({ page: 0 }));
        }
      }
    })();
  }, [checkUserExisiting]);

  useEffect(() => {
    if (matchingUser) {
      setEmail(matchingUser.mail);
      setEmail2(matchingUser.mail);
      setUserType(matchingUser.user.authorities);
      setFirstName(matchingUser.firstName);
      setSurname(matchingUser.surname);
      setStud(matchingUser.studId);
    }
  }, [matchingUser]);

  const [personToEditPermissions, setPersonToEditPermissions] = useState(false);
  const [
    openFormsAndGroupsPermissionsDialog,
    setOpenFormsAndGroupsPermissionsDialog,
  ] = useState(false);

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  };

  const handleClearData = () => {
    dispatch(clearMatchingUser());
    setFirstName("");
    setSurname("");
    setUsername("");
    setEmail("");
    setEmail2("");
    setUserType("");
  };

  const handleSubmit = async () => {
    try {
      if (!matchingUser) {
        let r = null;
        if (isOwner) {
          r = await dispatch(
            addPerson({ role: userType, firstName, surname, mail: email })
          );
        } else {
          r = await dispatch(
            addPersonByStudAdmin({
              role: userType,
              firstName,
              surname,
              mail: email,
            })
          );
        }

        if (r) {
          let payload = {};
          switch (userType) {
            case "ROLE_VET":
              payload = { userId: r.id, accessSubResources: true };
              break;
            case "ROLE_GROOM":
              payload = { userId: r.id, accessSubResources };
              break;
            default:
              payload = { userId: r.id };
          }
          if (isOwner) {
            await dispatch(addExistingPersonByStudId(payload, stud));
          } else {
            await dispatch(addExistingPersonToStud(payload));
          }
          setUserId(r.id);
        }
      } else {
        let payload = {};
        switch (userType) {
          case "ROLE_VET":
            payload = { userId: matchingUser.id, accessSubResources: true };
            break;
          case "ROLE_GROOM":
            payload = { userId: matchingUser.id, accessSubResources };
            break;
          default:
            payload = { userId: matchingUser.id };
        }

        if (isOwner) {
          await dispatch(addExistingPersonByStudId(payload, stud));
        } else {
          await dispatch(addExistingPersonToStud(payload));
        }
        setUserId(matchingUser.id);
      }

      if (
        userType === "ROLE_VET" ||
        (userType === "ROLE_GROOM" && accessSubResources) ||
        userType === "ROLE_STUD_OWNER"
      ) {
        history.push("/persons");
      } else {
        setOpenFormsAndGroupsPermissionsDialog(true);
      }
    } catch (e) {
      const msgError = e.response.data.message;

      if (
        msgError === "current_person_cannot_assign_role" ||
        msgError === "no_context"
      ) {
        dispatch(
          alertAdd({
            text: t("add_user.exists_stud_owner"),
            isError: true,
            timeout: 7000,
          })
        );
      }

      if (msgError === "user_already_in_stud") {
        dispatch(
          alertAdd({
            text: t("add_user.exists_stud"),
            isError: true,
            timeout: 7000,
          })
        );
      }
    }
  };

  const userVerifyToEdit = () =>
    id && email === location.state.userToEdit.user.email;

  const page = () => (
    <div className={classes.root}>
      <Grid container spacing={3}>
        {openFormsAndGroupsPermissionsDialog && (
          <GroupsAndFormsPermissionsDialog
            person={{ name: username, mail: email, role: userType, id: userId }}
            handleClose={() => {
              setOpenFormsAndGroupsPermissionsDialog(false);
              handleClearData();

              history.push("/persons");
            }}
            stud={stud}
            isOwner={isOwner}
            open={openFormsAndGroupsPermissionsDialog}
          />
        )}

        <Grid item xs={12}>
          <Box p={1}>
            <PageTitle
              title={!id ? t("add_user.label") : t("add_user.label_edition")}
            />
          </Box>
          {alertMessage !== "" && (
            <Box p={1}>
              <Alert severity="warning">{alertMessage}</Alert>
            </Box>
          )}
          <Box p={1} flexDirection="row">
            <IconButton
              color="primary"
              size="small"
              onClick={() => history.push("/persons")}
            >
              <NavigateBeforeIcon />
              {t("add_user.back")}
            </IconButton>
          </Box>
          <Box p={1} style={{ display: "flex", justifyContent: "center" }}>
            <Grid container spacing={2} sm={12} lg={6}>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={matchingUser}
                  label={t("add_user.user_firstname")}
                  variant="outlined"
                  required
                  value={firstName}
                  fullWidth
                  onChange={({ target: { value: name } }) => setFirstName(name)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={matchingUser}
                  label={t("add_user.user_surname")}
                  variant="outlined"
                  required
                  value={surname}
                  fullWidth
                  onChange={({ target: { value: surname } }) =>
                    setSurname(surname)
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled={matchingUser}
                  label={t("add_user.email")}
                  variant="outlined"
                  required
                  value={email}
                  fullWidth
                  onChange={({ target: { value: email } }) => setEmail(email)}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  disabled={matchingUser}
                  label={t("add_user.repeat_email")}
                  variant="outlined"
                  required
                  value={email2}
                  fullWidth
                  error={!emailsMatch}
                  helperText={!emailsMatch && t("add_user.emails_match_error")}
                  onChange={({ target: { value: email2 } }) =>
                    setEmail2(email2)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  disabled={matchingUser}
                  fullWidth
                  required
                >
                  <InputLabel id="open-gender-select-label">
                    {t("add_user.user_type.label")}
                  </InputLabel>
                  <Select
                    disabled={matchingUser}
                    labelId="open-gender-select-label"
                    id="open-gender-select"
                    label={t("add_user.user_type.label") + " *"}
                    value={userType || ""}
                    onChange={({ target: { value } }) => {
                      setUserType(value);
                    }}
                    autoWidth={true}
                  >
                    {roleTypes
                      .filter((role) =>
                        isOwner ? role : role.type !== "ROLE_STUD_OWNER"
                      )
                      .map(({ type, description }) => (
                        <MenuItem key={type} value={type}>
                          {description}
                        </MenuItem>
                      ))}
                    {/* <MenuItem value="breed_1">Rasa 1</MenuItem>
                    <MenuItem value="breed_2">Rasa 2</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              {userType === "ROLE_GROOM" && (
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      labelPlacement="end"
                      control={
                        <Checkbox
                          color="primary"
                          checked={accessSubResources}
                          onChange={(e) =>
                            setAccessSubResources(e.target.checked)
                          }
                          name="accessSubResources"
                        />
                      }
                      label={t("add_user.access")}
                    />
                  </FormControl>
                </Grid>
              )}
              {isOwner && (
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                  >
                    <InputLabel id="select-outlined-label" required>
                      {t("horse_form.stud")}
                    </InputLabel>
                    <Select
                      labelId="stud-outlined-label"
                      id="stud-outlined"
                      value={stud}
                      onChange={(event) => setStud(event.target.value)}
                      label={`${t("horse_form.stud")} *`}
                      required
                    >
                      {allStuds.map((stud, index) => {
                        return (
                          <MenuItem key={index} value={stud.id}>
                            {stud.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <span className={classes.formHint}>
                  {"* "}
                  {t("add_user.required")}
                </span>
              </Grid>
              {matchingUser && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <span className={classes.userExists}>
                    {t("user_exists.message")}
                  </span>
                </Grid>
              )}
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  size="small"
                  variant="contained"
                  disabled={!formValid}
                  color="primary"
                  style={{ padding: "8px 16px", marginRight: "15px" }}
                  onClick={handleSubmit}
                >
                  {id ? t("edit") : t("add")}
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  style={{ padding: "8px 16px" }}
                  onClick={handleClearData}
                >
                  {t("clear")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </div>
  );

  const redirect = () => <Redirect to="/" />;

  return <Can permission="add-persons-page:view" ok={page} not={redirect} />;
};

export default AddNewPersonsPage;
