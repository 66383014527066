import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  activateForm,
  duplicateForm,
  fetchFilledForms,
  fetchHorseFarms,
  fetchOrgUsers,
  removeForm,
  updateForm,
} from "../../redux/actions";
import { NavLink, Redirect } from "react-router-dom";
import Can from "../../components/can";
import PageTitle from "../../components/page-title";
import { Box, Paper } from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { checkPermission } from "../../rbac";
import SingleGroupRow from "./single-group-row";
import styled from "styled-components";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const GreenButton = styled(Button)`
  background-color: #4caf50;
`;

const GroupsPage = ({
  history,
  orgForms,
  loggedIn,
  removeForm,
  fetchFilledForms,
  filledForms,
  updateForm,
  activateForm,
  duplicateForm,
}) => {
  const { t } = useTranslation();
  const roles = useSelector((s) => s.roles);
  const formsGroups = useSelector((s) => s.orgFormsGroups);
  const orgFormsGroupsLength = useSelector(
    (s) => s.orgFormsGroupsLength || null
  );
  const formDuplicated = useSelector((s) => s.formDuplicated || false);
  const orgUsers = useSelector((s) => s.orgUsers);
  const canAddGroup = checkPermission("admin:addGroup", roles);
  const canFetchGroups = checkPermission("groups:fetch", roles);

  const classes = useStyles();
  const dispatch = useDispatch();

  const my = useSelector((s) => s.my);
  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    Cookies.get(`groups-per-page[${my.user.id}]`) || 100
  );
  const [orderBy, setOrderBy] = useState("createdAt");
  const [order, setOrder] = useState("desc");

  const [busy, setBusy] = useState(false);

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [multiplier, setMultiplier] = useState(null);
  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    (async () => {
      if (loggedIn && canFetchGroups) {
        setBusy(true);
        await dispatch(
          fetchHorseFarms({
            page: pageNr,
            checkContainedHorses: true,
            ...(rowsPerPage ? { pageSize: parseInt(rowsPerPage) } : {}),
            ...(orderBy
              ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
              : {}),
          })
        );
        setBusy(false);
      }
    })();
  }, [
    loggedIn,
    fetchHorseFarms,
    canFetchGroups,
    pageNr,
    rowsPerPage,
    order,
    orderBy,
  ]);

  useEffect(() => {
    (async () => {
      setBusy(true);
      await dispatch(
        fetchHorseFarms({
          page: pageNr,
          ...(rowsPerPage ? { pageSize: parseInt(rowsPerPage) } : {}),
          ...(orderBy
            ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
            : {}),
        })
      );
      setBusy(false);
    })();
  }, [formDuplicated]);

  useEffect(() => {
    if (
      // !(orgFormsGroupsLength / ((pageNr + 1) * rowsPerPage) > 0.5) &&
      // pageNr > 0
      Number.isInteger(orgFormsGroupsLength / rowsPerPage) &&
      orgFormsGroupsLength / rowsPerPage < pageNr + 1 &&
      pageNr > 0
    ) {
      setPageNr(pageNr - 1);
    }
  }, [orgFormsGroupsLength]);

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    Cookies.set(`groups-per-page[${my.user.id}]`, event.target.value, {
      sameSite: "strict",
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  useEffect(() => {
    if (dimensions.height > 800 || dimensions.height === 800) {
      setMultiplier(dimensions.height * 0.00075);
    } else if (dimensions.height < 800 && dimensions.height > 700) {
      setMultiplier(dimensions.height * 0.00085);
    } else if (dimensions.height < 701 && dimensions.height > 580) {
      setMultiplier(dimensions.height * 0.00095);
    } else {
      setMultiplier(dimensions.height * 0.0011);
    }
  }, [dimensions]);

  const headerCells = [
    { id: "name", label: t("horse_farm.name") },
    { id: "description", label: t("horse_farm.description") },
    // { id: "welcomeSmsMessage", label: "Wiadomość SMS" },
    { id: "phone", label: t("horse_farm.phone") },
    { id: "welcomeMessage", label: t("horse_farm.email_text") },
  ];

  const page = () => (
    <Box>
      <Box p={1}>
        <PageTitle title={t("horse_farm.label")} />
      </Box>
      <Box p={1}>
        <TableContainer
          component={Paper}
          style={{ maxHeight: dimensions.height * multiplier }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headerCells.map(({ label, id }) => (
                  <TableCell key={id} className={classes.tableCell}>
                    <TableSortLabel
                      active={orderBy === id}
                      direction={orderBy === id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(id);
                      }}
                    >
                      {label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell>{t("horse_farm.option")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formsGroups.map((formsGroup, index) => {
                return (
                  <SingleGroupRow
                    pageNr={pageNr}
                    rowsPerPage={rowsPerPage}
                    orderBy={orderBy}
                    order={order}
                    key={index}
                    group={formsGroup}
                    users={orgUsers}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          style={{ display: "flex" }}
          component="div"
          page={pageNr}
          rowsPerPageOptions={pages}
          rowsPerPage={rowsPerPage}
          count={orgFormsGroupsLength || 0}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t("pagination.rows")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${t("pagination.out_of")} ${count}`
          }
        />
        <Can
          permission="admin:addGroup"
          ok={() => (
            <Box position="fixed" bottom={20} right={25}>
              <NavLink to="/form_group/add">
                <GreenButton variant="contained" color="primary">
                  + {t("horse_farm.add_new_horse_farm")}
                </GreenButton>
              </NavLink>
            </Box>
          )}
        />
      </Box>
    </Box>
  );

  const fetching = () => (
    <Box>
      <Box p={1}>
        <Loader loading={true} text={t("fetching")} />
      </Box>
    </Box>
  );

  const redirect = () => <Redirect to="/" />;

  return (
    <Can
      permission="groups-page:view"
      ok={busy ? fetching : page}
      not={redirect}
    />
  );
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  orgForms: state.orgForms,
  orgFormsGroupsLength: state.orgFormsGroupsLength,
  loggedIn: state.loggedIn,
  formDuplicated: state.formDuplicated,
  filledForms: state.filledForms,
});

const mapDispatchToProps = (dispatch) => ({
  activateForm: (form) => dispatch(activateForm(form)),
  removeForm: (formId) => dispatch(removeForm(formId)),
  fetchFilledForms: (formId) => dispatch(fetchFilledForms(formId)),
  updateForm: (id, payload) => dispatch(updateForm(id, payload)),
  duplicateForm: (id) => dispatch(duplicateForm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsPage);
