import React from "react";

import image from "../assets/image-left.png";
import imageEquinity from "../assets/img-left-3.svg";
import image1 from "../assets/1.png";
import image2 from "../assets/2.png";
import image3 from "../assets/3.png";
import { Box } from "@material-ui/core";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  box: {
    [theme.breakpoints.down("xs")]: {
      height: "",
    },
    [theme.breakpoints.between("sm", "md")]: {
      height: "",
    },
    "@media (min-width: 900px)": {
      height: "100vh",
    },
    backgroundColor:
      theme.palette.primary.main === "#fff200"
        ? theme.palette.background
        : "#181641",
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
  },
}));

const FeatureBox = styled(({ image, number, text, subtext, highContrast }) => (
  <Box
    display="block"
    color="#fff"
    textAlign="center"
    m={3}
    style={{ color: highContrast && "yellow" }}
  >
    <img src={image} alt="" />

    <Box fontWeight="bold">
      <Box display="inline" as="span" color="orange">
        {number}.&nbsp;
      </Box>
      {text}
    </Box>
    <Box fontSize={10}>{subtext}</Box>
  </Box>
))`
  width: 300px;
  height: 300px;
`;

const LeftComponentMui = () => {
  const classes = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  return (
    <Box
      className={classes.box}
      display="flex"
      flexDirection="row"
      // bgcolor="#001171"
      width="100%"
    >
      <Box display="block" width="100%">
        <Box display="flex" justifyContent="center" mt={1}>
          {/* <NavLink to="/"> */}
          <img src={imageEquinity} alt="equinity" />
          {/* </NavLink> */}
        </Box>
        {/* 
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          mt={5}
        >
          <FeatureBox
            image={image1}
            number={1}
            text="Zbuduj z gotowych bloków!"
            subtext="Zbuduj formularz w prosty sposób przeciągając pole formularza."
            highContrast={globalTheme === "high-contrast"}
          />
          <FeatureBox
            image={image2}
            number={2}
            text="Udostępnij użytkownikom"
            subtext="Zarządzaj grupami użytkowników udostępniając im formularze."
            highContrast={globalTheme === "high-contrast"}
          />
          <FeatureBox
            image={image3}
            number={3}
            text="Zbieraj wyniki"
            subtext="Przeglądaj wyniki i eksportuj do własnych systemów."
            highContrast={globalTheme === "high-contrast"}
          />
        </Box> */}
      </Box>
    </Box>
  );
};

export default LeftComponentMui;
