import { createMuiTheme } from "@material-ui/core/styles";

export const fontSize1Mixin = {
  typography: {
    h6: {
      fontSize: "14pt",
    },
    body1: {
      fontSize: "1rem",
      iconSize: "25px",
    },
    body2: {
      fontSize: "0.875rem",
      iconSize: "25px",
      hintText: "0.7rem",
    },
    subtitle1: {
      fontSize: "0.875rem",
      iconSize: "25px",
    },
  },
};

export const fontSize2Mixin = {
  typography: {
    h6: {
      fontSize: "30pt",
    },
    body1: {
      fontSize: "1.17rem",
      iconSize: "45px",
    },
    body2: {
      fontSize: "1rem",
      iconSize: "45px",
    },
    subtitle1: {
      fontSize: "1.17rem",
      iconSize: "30px",
    },
  },
};

export const fontSize3Mixin = {
  typography: {
    h6: {
      fontSize: "40pt",
    },
    body1: {
      fontSize: "1.5rem",
      iconSize: "60px",
    },
    body2: {
      fontSize: "1.2rem",
      iconSize: "60px",
    },
    subtitle1: {
      fontSize: "1.5rem",
      iconSize: "60px",
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: "#181641",
    },
    secondary: {
      main: "#AC1426",
    },
    text: {
      primary: "#000",
      blueHeader: "#0021db",
      whiteHeader: "#ffffff",
    },
    background: {
      default: "#fbfbfb",
    },
  },
  drawer: {
    backgroundColor: "#353535",
  },
  // typography: {
  //   h6: {
  //     color: "#0021db",
  //   },
  // },
};

export const highContrastTheme = {
  palette: {
    primary: {
      main: "#fff200",
    },
    secondary: {
      main: "#AC1426",
    },
    text: {
      primary: "#fff200",
      blueHeader: "#0021db",
      whiteHeader: "#ffffff",
    },
    background: {
      default: "#000",
    },
  },
  drawer: {
    backgroundColor: "#000",
  },
  typography: {
    h6: {
      //color: "#0021db",
    },
  },
  border: {
    primary: "#fff200",
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "#000",
      },
    },
    MuiFilledInput: {
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #000 inset",
          WebkitTextFillColor: "#ffff00",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0 100px #000 inset",
          WebkitTextFillColor: "#ffff00",
        },
      },
    },
  },
};
