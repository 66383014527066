import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch } from "react-redux";
import {
  duplicateFormGroup,
  removeFormGroup,
  fetchFormsGroups,
} from "../../redux/actions";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import PersonIcon from "@material-ui/icons/Person";
import UserAccessToGroup from "../user-access-to-group";
import Can from "../../components/can";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

const NO_ID_TO_REMOVE = -1;

const SingleGroupRow = ({
  group,
  index,
  users,
  orderBy,
  order,
  rowsPerPage,
  pageNr,
}) => {
  const dispatch = useDispatch();
  const [openModalPanel, setOpenModalPanel] = useState(false);
  const [openFormRemovingPanel, setOpenFormRemovingPanel] = useState(false);
  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);

  const { t } = useTranslation();

  useEffect(() => {
    idToRemove !== NO_ID_TO_REMOVE && setOpenFormRemovingPanel(true);
  }, [idToRemove]);

  let history = useHistory();

  const handleOpen = () => {
    setOpenModalPanel(true);
  };

  const [
    openDuplicateUsersGroupPanel,
    setOpenDuplicateUsersGroupPanel,
  ] = useState(false);

  const askToRemove = (groupId) => {
    setIdToRemove(groupId);
  };

  const removeClick = (id) => {
    dispatch(removeFormGroup(id));
    dispatch(
      fetchFormsGroups({
        page: pageNr,
        pageSize: rowsPerPage,
        ...(orderBy
          ? { sortBy: `${order === "asc" ? orderBy : "-" + orderBy}` }
          : {}),
      })
    );
  };

  const editClick = () => {
    history.push(`/form_group/edit/${group.id}`);
  };

  return (
    <TableRow key={index}>
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenFormRemovingPanel(false);
          setIdToRemove(NO_ID_TO_REMOVE);
        }}
        open={openFormRemovingPanel}
        text={t("horse_farm.tooltip.remove_label") + group.name + "?"}
        yesAction={() => {
          removeClick(idToRemove);
          setIdToRemove(NO_ID_TO_REMOVE);
        }}
        noAction={() => setIdToRemove(NO_ID_TO_REMOVE)}
      />
      <TableCell>{group.name}</TableCell>
      <TableCell>{group.description}</TableCell>
      {/* <TableCell>{group.welcomeSmsMessage}</TableCell> */}
      <TableCell>{group.phone}</TableCell>
      <TableCell>{group.welcomeMessage}</TableCell>
      <TableCell>
        {/* <Can
          permission="project:duplicate"
          ok={() => (
            <Tooltip placement="top" title={"Duplikuj"}>
              <span>
                <IconButton
                  onClick={() => {
                    setOpenDuplicateUsersGroupPanel(true);
                  }}
                  size="small"
                >
                  <FileCopyIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        /> */}
        <Tooltip
          placement="top"
          title={t("horse_farm.tooltip.users_management")}
        >
          <span>
            <IconButton onClick={handleOpen} size="small">
              <PersonIcon />
            </IconButton>
          </span>
        </Tooltip>
        &nbsp;
        <Can
          permission="project:edit"
          ok={() => (
            <Tooltip placement="top" title={t("horse_farm.tooltip.edition")}>
              <span>
                <IconButton onClick={editClick} size="small">
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        />
        &nbsp;
        <Can
          permission="project:remove"
          ok={() => (
            <Tooltip
              placement="top"
              title={
                group.containsHorses
                  ? t("horse_farm.tooltip.removeDisabled")
                  : t("horse_farm.tooltip.remove")
              }
            >
              <span>
                <IconButton
                  disabled={group.containsHorses}
                  onClick={() => askToRemove(group.id)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        />
      </TableCell>

      <ConfirmDialogMUI
        handleClose={() => {
          setOpenDuplicateUsersGroupPanel(false);
        }}
        open={openDuplicateUsersGroupPanel}
        text={"Czy skopiować także użytkowników?"}
        yesAction={() => {
          dispatch(duplicateFormGroup(group.id, { withUsers: true }));
          setOpenDuplicateUsersGroupPanel(false);
        }}
        noAction={() => {
          dispatch(duplicateFormGroup(group.id, { withUsers: false }));
          setOpenDuplicateUsersGroupPanel(false);
        }}
      />

      <UserAccessToGroup
        open={openModalPanel}
        setOpen={setOpenModalPanel}
        group={group}
        users={users}
      />
    </TableRow>
  );
};

export default SingleGroupRow;
