import React from "react";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

export const ConfirmDialogMUI = ({
  text,
  yesAction,
  noAction,
  busy,
  handleClose,
  open,
  yesLabel = "",
  noLabel = "",
  type = "confirm",
  submitAction,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <div>
          <Typography variant="h6" gutterBottom>
            {text}
          </Typography>
        </div>
      </DialogTitle>
      {/*<DialogContent>*/}
      {/*  <DialogContentText id="alert-dialog-description">*/}
      {/*  </DialogContentText>*/}
      {/*</DialogContent>*/}
      {type === "confirm" ? (
        <DialogActions>
          <Button
            onClick={() => {
              noAction();
              handleClose();
            }}
            color="primary"
            // autoFocus
          >
            {noLabel === "" ? t("user_exists.no") : noLabel}
          </Button>
          <Button
            onClick={() => {
              yesAction();
              handleClose();
            }}
            color="primary"
          >
            {yesLabel === "" ? t("user_exists.yes") : yesLabel}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button
            onClick={() => {
              submitAction();
              handleClose();
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
