import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import Loader from "../loader";

const alertMapper = {
  1: "foaling",
  2: "colic",
};

const HorseAlerts = () => {
  const horseAlerts = useSelector((s) => s.horseAlerts);
  const horseAlertsTotal = useSelector((s) => s.horseAlertsTotal);
  const horseAlertsFetching = useSelector((s) => s.horseAlertsFetching);

  const studHorses = useSelector((s) => s.studHorses);

  const { t } = useTranslation();

  const selectedLang = localStorage.getItem("i18nextLng") || "pl";

  return !horseAlertsTotal ? (
    <Box p={1} style={{ textAlign: "center" }}>
      <Typography variant="h6">{t("alert.no_alert")}</Typography>
    </Box>
  ) : (
    <Box>
      {horseAlerts.map((alert) => (
        <Alert severity="error" style={{ margin: "10px" }}>
          <AlertTitle>Alert</AlertTitle>
          <div style={{ marginBottom: "0.35em" }}>
            <p style={{ fontSize: "1rem" }}>
              {studHorses.find((h) => h.id === alert.horseId)?.name ? (
                <>
                  <span>{t("alert.filter.horse")}: </span>
                  <strong>
                    {studHorses.find((h) => h.id === alert.horseId)?.name}
                  </strong>
                </>
              ) : (
                <>
                  <strong>{`<${t("alert.filter.horse_removed")}>`}</strong>
                </>
              )}
            </p>
          </div>

          <h6>
            <strong>
              {format(new Date(alert.timestamp), "yyyy-MM-dd HH:mm")}
            </strong>
          </h6>
          {selectedLang === "pl" ? (
            alert.alertType !== 2 ? (
              <p>
                Z{" "}
                <strong>
                  {Math.round(alert.probability * 100 * 100) / 100}%
                </strong>{" "}
                prawdopodobieństwem{" "}
                {alert.horizon > 0 ? (
                  <>
                    <strong>
                      {alertMapper[alert.alertType]
                        ? t(`alert.${alertMapper[alert.alertType]}`)
                        : alert.alertType}
                    </strong>{" "}
                    rozpocznie się w ciągu <strong>{alert.horizon} min.</strong>
                  </>
                ) : (
                  <strong>
                    {" "}
                    {t(`alert.${alertMapper[alert.alertType]}`)} właśnie się
                    rozpoczyna
                  </strong>
                )}
              </p>
            ) : (
              <p>
                Z dużym prawdopodobieństwem występują objawy{" "}
                <strong>kolki</strong>.
              </p>
            )
          ) : alert.alertType !== 2 ? (
            <p>
              There is{" "}
              <strong>
                {" "}
                {Math.round(alert.probability * 100 * 100) / 100}%
              </strong>{" "}
              chance that{" "}
              {alert.horizon > 0 ? (
                <>
                  <strong>
                    {" "}
                    {alertMapper[alert.alertType]
                      ? t(`alert.${alertMapper[alert.alertType]}`)
                      : alert.alertType}
                  </strong>{" "}
                  will start within <strong>{alert.horizon} minutes</strong>.
                </>
              ) : (
                <strong>
                  {" "}
                  {t(`alert.${alertMapper[alert.alertType]}`)} is about to start
                </strong>
              )}
            </p>
          ) : (
            <p>
              It is highly likely that the <strong>colic</strong> occur.
            </p>
          )}
        </Alert>
      ))}
    </Box>
  );
};

export default HorseAlerts;
