import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import PageTitle from "../../components/page-title";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBox,
  alertAdd,
  editBox,
  fetchBox,
  fetchBoxes,
  fetchCameras,
  fetchHorseFarms,
  fetchHorsesByStud,
  fetchHorsesByStudId,
} from "../../redux/actions";
import Loader from "../../components/loader";

const initialBoxData = {
  cameraId: "",
  horseId: "",
  name: "",
};

const boxReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE_INPUT_CHANGE":
      return {
        ...state,
        [action.property]: action.payload,
      };
    case "BOX_CHANGE":
      return { ...action.payload };
    case "CLEAR_DATA":
      return { ...state, ...initialBoxData };
    default:
      return state;
  }
};

const BoxAddPage = ({ history }) => {
  const { t } = useTranslation();
  const { boxId, studId } = useParams();
  const dispatch = useDispatch();

  const my = useSelector((s) => s.my);
  const boxes = useSelector((s) => s.boxes);
  const boxesFetching = useSelector((s) => s.boxesFetching);
  const studList = useSelector((s) => s.orgFormsGroups);
  const cameras = useSelector((s) => s.cameras);
  const currentContext = useSelector((s) => s.currentContext);
  const isOwner = my.user.authorities.indexOf("ROLE_OWNER") !== -1;

  const studHorses = useSelector((s) =>
    s.studHorses.sort((a, b) => a.name.localeCompare(b.name))
  );

  const [boxState, dispatchBoxForm] = useReducer(boxReducer, initialBoxData);
  const [isValid, setIsValid] = useState(false);
  const [stud, setStud] = useState(studId || "");

  const [filteredCameras, setFilteredCameras] = useState([]);
  const [filteredStudHorses, setFilteredStudHorses] = useState([]);

  const [busy, setBusy] = useState(true);

  useEffect(() => {
    setFilteredCameras(
      cameras.filter(
        (camera) =>
          !boxes.some((box) =>
            !boxId
              ? box.cameraId === camera.id
              : box.cameraId === camera.id && box.id !== boxId
          )
      )
    );

    setFilteredStudHorses(
      studHorses.filter(
        (horse) =>
          !boxes.some((box) =>
            !boxId
              ? box.horseId === horse.id
              : box.horseId === horse.id && box.id !== boxId
          )
      )
    );
  }, [boxes, cameras, studHorses]);

  useEffect(() => {
    (async () => {
      try {
        setBusy(true);
        if (isOwner) {
          await dispatch(
            fetchHorseFarms({ page: 0, pageSize: 1000, sort: "name" })
          );
          await dispatch(fetchHorsesByStudId({ stud: studId }));
        } else {
          await dispatch(fetchHorsesByStud({ page: 0, pageSize: 1000 }));
        }
        await dispatch(
          fetchBoxes({
            isOwner,
            studId: isOwner ? stud : null,
            page: 0,
            pageSize: 1000,
            sort: "name",
          })
        );
        await dispatch(
          fetchCameras({
            isOwner,
            studId: isOwner ? stud : null,
            page: 0,
            pageSize: 1000,
            sort: "model",
          })
        );
        setBusy(false);
      } catch (e) {
        setBusy(false);
      }
    })();
  }, [isOwner, studId, currentContext]);

  useEffect(() => {
    return () => {
      if (studId) {
        // && history.location.pathname === "any specific path")
        if (history.action === "POP") {
          history.replace(`/boxes/${studId}` /* the new state */);
        }
      }
    };
  }, [history]);

  useEffect(() => {
    (async () => {
      if (boxId) {
        const response = await dispatch(
          fetchBox({
            boxId,
            ...(isOwner && { isOwner }),
            ...(studId && { studId }),
          })
        );
        dispatchBoxForm({
          type: "BOX_CHANGE",
          payload: response,
        });
      }
    })();
  }, [boxId]);

  useEffect(() => {
    const { name, cameraId, horseId } = boxState;
    const isHorseExists = filteredStudHorses.some(
      (horse) => horse.id === horseId
    );
    const isCameraExists = filteredCameras.some(
      (camera) => camera.id === cameraId
    );

    if (boxId) {
      if (isOwner) {
        setIsValid(
          isHorseExists &&
            isCameraExists &&
            name &&
            cameraId &&
            horseId &&
            (stud || studId)
        );
      } else {
        setIsValid(
          isHorseExists && isCameraExists && name && cameraId && horseId
        );
      }
    } else {
      if (isOwner) {
        setIsValid(name && cameraId && (stud || studId));
      } else {
        setIsValid(name && cameraId);
      }
    }
  }, [boxState, stud, boxId, filteredCameras, filteredStudHorses]);

  const handleClearData = () => {
    dispatchBoxForm({
      type: "CLEAR_DATA",
    });
  };

  const handleSubmit = async () => {
    try {
      if (boxId) {
        await dispatch(
          editBox({
            boxId,
            ...boxState,
            ...(boxId && { horseId: boxState.horseId }),
            ...(isOwner && { isOwner }),
            ...(studId && { studId }),
          })
        );
      } else {
        await dispatch(
          addBox({
            ...boxState,
            ...(isOwner && { isOwner }),
            ...(stud && { studId: stud }),
          })
        );
      }
      if (isOwner) {
        history.push(`/boxes/${studId}`);
      } else {
        history.push(`/boxes`);
      }
    } catch (e) {
      if (e.response.data.message === "camera_already_assigned") {
        dispatch(
          alertAdd({
            text: t("boxes_page.error_camera_already_assigned"),
            isError: true,
          })
        );
      } else if (e.response.data.message === "horse_already_assigned") {
        dispatch(
          alertAdd({
            text: t("boxes_page.error_horse_already_assigned"),
            isError: true,
          })
        );
      } else {
        dispatch(
          alertAdd({ text: t("cameras_page.no_context_error"), isError: true })
        );
      }
    }
  };

  return (
    <Box p={1}>
      <PageTitle
        title={boxId ? t("breadcrumbs.edit_box") : t("breadcrumbs.add_box")}
      />
      {busy ? (
        <Loader loading={true} text={t("fetching")}></Loader>
      ) : (
        <Box p={1} style={{ display: "flex", justifyContent: "center" }}>
          <Grid container sm={12} lg={6}>
            {isOwner && !boxId && (
              <Grid item xs={12}>
                <FormControl
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  required
                >
                  <InputLabel id="select-outlined-label" required>
                    {t("horse_form.stud")}
                  </InputLabel>
                  <Select
                    labelId="stud-outlined-label"
                    id="stud-outlined"
                    disabled={stud}
                    value={stud}
                    onChange={(event) => setStud(event.target.value)}
                    label={`${t("horse_form.stud")} *`}
                    required
                  >
                    {studList.map((stud, index) => {
                      return (
                        <MenuItem key={index} value={stud.id}>
                          {stud.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                label={t("boxes_page.table.name")}
                margin="normal"
                name="name"
                fullWidth
                required
                value={boxState.name}
                variant="outlined"
                onChange={({ target: { value } }) =>
                  dispatchBoxForm({
                    type: "HANDLE_INPUT_CHANGE",
                    property: "name",
                    payload: value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                fullWidth
                variant="outlined"
                required
              >
                <InputLabel id="select-outlined-label" required>
                  {t("boxes_page.camera")}
                </InputLabel>
                <Select
                  labelId="stud-outlined-label"
                  id="stud-outlined"
                  value={boxState.cameraId}
                  onChange={({ target: { value } }) =>
                    dispatchBoxForm({
                      type: "HANDLE_INPUT_CHANGE",
                      property: "cameraId",
                      payload: value,
                    })
                  }
                  label={`${t("boxes_page.camera")} *`}
                  required
                >
                  <MenuItem disabled style={{ opacity: 0.5 }}>
                    {`${t("boxes_page.recorder_channel")}`}
                  </MenuItem>
                  {filteredCameras.map((camera, index) => {
                    return (
                      <MenuItem key={index} value={camera.id}>
                        {`${camera.recorderChannel}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {boxId ? (
              <Grid item xs={12}>
                <FormControl
                  margin="normal"
                  fullWidth
                  variant="outlined"
                  required
                >
                  <InputLabel id="select-outlined-label" required>
                    {t("boxes_page.horse")}
                  </InputLabel>
                  <Select
                    labelId="stud-outlined-label"
                    id="stud-outlined"
                    value={boxState.horseId}
                    onChange={({ target: { value } }) =>
                      dispatchBoxForm({
                        type: "HANDLE_INPUT_CHANGE",
                        property: "horseId",
                        payload: value,
                      })
                    }
                    label={`${t("boxes_page.horse")} *`}
                    required
                  >
                    {filteredStudHorses.map((horse, index) => {
                      return (
                        <MenuItem key={index} value={horse.id}>
                          {horse.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <br />
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                disabled={!isValid}
                color="primary"
                style={{ padding: "8px 16px", marginRight: "15px" }}
                onClick={handleSubmit}
              >
                {boxId ? t("edit") : t("add")}
              </Button>
              <Button
                variant="contained"
                style={{ padding: "8px 16px" }}
                onClick={handleClearData}
              >
                {t("clear")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default BoxAddPage;
