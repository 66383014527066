import React from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialogMUI } from "../confirm-dialog-mui";

function ExistingUserModal({ ...props }) {
  const { t } = useTranslation();
  return (
    <ConfirmDialogMUI
      // handleClose={handleClose}
      // open={openConfirmPanel}
      text={t("user_exists.label")}
      {...props}
      // yesAction={yesAction}
      // noAction={() => {}}
    />
  );
}

export default ExistingUserModal;
