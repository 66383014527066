export const breeds = [
  { value: "arabian_horse" },
  { value: "thoroughbred" },
  { value: "anglo_arabian" },
  { value: "half_bred" },
  { value: "fresian" },
  { value: "pony" },
  { value: "haflinger" },
  { value: "trakehner" },
  { value: "warmblood" },
  { value: "draft_horse" },
  { value: "american_quater_horse" },
  { value: "andalusian" },
  { value: "hanoverian" },
  { value: "clydesdale" },
  { value: "irish_sport_horse" },
  { value: "connemara" },
  { value: "other" },
];
