import React, { useEffect, useState } from "react";
import Can from "../../components/can";
import { useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  deactivateForm,
  fetchOldFormEditions,
  removeFormFromFormGroup,
} from "../../redux/actions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PersonIcon from "@material-ui/icons/Person";
import VideocamIcon from "@material-ui/icons/Videocam";
import { NavLink } from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import FormToGroup from "../form-to-project";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import FormPermissionsPanel from "../../components/form-permissions";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import EventIcon from "@material-ui/icons/Event";
import ImageIcon from "@material-ui/icons/Image";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BoxPermissionsPanel from "../../components/box-permissions";
import EventPermissionsPanel from "../../components/event-component";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ImagePanel from "../../components/image-component";
import NotificationPanel from "../add-new-horse/notifiactionPanel";
import NotificationsIcon from "@material-ui/icons/Notifications";
const NO_ID_TO_REMOVE = -1;

const statusToText = (status) => {
  switch (status) {
    // case 'ZERO': return 'ZERO';
    // case 'TODO': return 'TODO';
    case "PAUSED":
      return "Wstrzymany";
    case "DRAFT":
      return "Szkic";
    case "SENT":
      return "Wysłany";
    case "ACTIVE":
      return "Aktywny";
    case "ARCHIVE":
      return "Zarchiwizowany";
    case "DELETED":
      return "Usunięty";
  }
};

const statusToTip = (status) => {
  switch (status) {
    // case 'ZERO':
    //   return 'nieuzywany w widoku';
    // case 'TODO':
    //   return 'nieuzywany w widoku';
    case "PAUSED":
      return "Aktywny formularz został zatrzymany i obecnie nie jest dostępny do wypełnienia";
    case "DRAFT":
      return "Formularz jest w trakcie tworzenia lub edycji";
    // case 'SENT':
    //   return 'nieuzywany w widoku'
    case "ACTIVE":
      return "Formularz jest dostępny do wypełnienia dla wskazanych osób";
    case " PREVIOUS_VERSION":
      return "Poprzednia wersja formularza";
    // case 'ARCHIVE':
    //   return 'nieuzywany w widoku'
    // case 'DELETED':
    //   return 'nieuzywany w widoku'
  }
};

const useStyles = makeStyles((theme) => ({
  nonCurrentRow: {
    backgroundColor: "#e4e4e4",
  },
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    paddingRight: 2,
    paddingLeft: 3,
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
  },
  icon: {
    color: theme.palette.text.primary,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  grey: {
    color: "grey",
  },
}));

const SingleVersionRow = ({
  horse,
  setOpenPermissionsPanel,
  setOpenNotificationPanel,
  setOpenBoxPermissionsPanel,
  openPermissionsPanel,
  setOpenEventPermissionsPanel,
  setOpenImagePanel,
  openImagePanel,
  isCurrent = false,
  history,
  remove,
  askToRemove,
  isOwner,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const globalTheme = useSelector((s) => s.globalTheme);
  const [openModalPanel, setOpenModalPanel] = useState(false);
  const [openRemovingPanel, setOpenRemovingPanel] = useState(false);
  const [currentOptionValue, setCurrentOptionValue] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickMenuItem = (option) => {
    if (option.key !== undefined && option.key !== "remove")
      setOpenModalPanel(true);
    else if (option.key !== undefined && option.key === "remove")
      setOpenRemovingPanel(true);
    // dispatch(removeFormFromFormGroup(form.id, form.formGroup.id));

    setCurrentOptionValue(option.key);
    setAnchorEl(null);
  };

  const numberToText = (number) => {
    var text;
    switch (number) {
      case 1:
        text = "osoba jest przypisana";
        break;
      case 2:
      case 3:
      case 4:
        text = "osoby są przypisane";
        break;
      default:
        text = "osób jest przypisanych";
        break;
    }
    return `${number} ${text}`;
  };

  return (
    <TableRow className={isCurrent ? undefined : classes.nonCurrentRow}>
      <TableCell component="th" scope="row" className={classes.tableCell}>
        {horse.name}
      </TableCell>

      {/* 
      <TableCell align="right" className={classes.tableCell}>
        <Tooltip
          title={
            <span style={{ whiteSpace: "pre-line" }}>              
              {`${t("horses_page.tooltip.assignation")}${form.numberOfUsers}
              ${t("horses_page.tooltip.access")}${form.numberOfUsersActiveNow}`}
            </span>
          }
        >
          {isActivated ? (
            <span>{`${form.numberOfUsersActiveNow}/${form.numberOfUsers}`}</span>
          ) : (
            <span>
              <span
                className={classes.grey}
              >{`${form.numberOfUsersActiveNow}`}</span>
              <span>{`/${form.numberOfUsers}`}</span>
            </span>
          )}
        </Tooltip>
      </TableCell> */}

      <TableCell align="right" className={classes.tableCell}>
        {horse.gender === "Mare" ? (
          horse.pregnant ? (
            <CheckCircleIcon style={{ fill: "green" }} />
          ) : (
            <CancelIcon style={{ fill: "red" }} />
          )
        ) : (
          t("horses_page.table.n/a")
        )}
      </TableCell>
      {isOwner && (
        <TableCell component="th" scope="row" className={classes.tableCell}>
          {horse.stud?.name}
        </TableCell>
      )}
      <TableCell align="right" className={classes.tableCell}>
        {isCurrent && (
          <>
            <Can
              permission="form:edit"
              ok={() => (
                <Tooltip
                  placement="top"
                  title={t("horses_page.tooltip.edition")}
                >
                  <span>
                    <IconButton
                      onClick={() => history.push(`/horses/edit/${horse.id}`)}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            />

            <Tooltip
              placement="top"
              title={t("horses_page.tooltip.permissions")}
            >
              <IconButton
                size="small"
                onClick={() => {
                  setOpenPermissionsPanel(true);
                }}
              >
                <PersonIcon
                  className={`${
                    globalTheme === "high-contrast" && classes.icon
                  } ${classes.iconSize}`}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="top"
              title={t("horses_page.tooltip.redirect_text")}
            >
              <IconButton
                size="small"
                onClick={() => {
                  setOpenNotificationPanel(true);
                }}
              >
                <NotificationsIcon
                  className={`${
                    globalTheme === "high-contrast" && classes.icon
                  } ${classes.iconSize}`}
                />
              </IconButton>
            </Tooltip>
            {/* <Tooltip placement="top" title={t("horses_page.tooltip.box")}>
              <IconButton
                size="small"
                onClick={() => {
                  setOpenBoxPermissionsPanel(true);
                }}
              >
                <VideocamIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip placement="top" title={t("horses_page.tooltip.events")}>
              <IconButton
                size="small"
                onClick={() => {
                  setOpenEventPermissionsPanel(true);
                }}
              >
                <EventIcon />
              </IconButton>
            </Tooltip>

            <Tooltip placement="top" title={t("horses_page.tooltip.image")}>
              <IconButton
                size="small"
                onClick={() => {
                  setOpenImagePanel(true);
                }}
              >
                <ImageIcon />
              </IconButton>
            </Tooltip>

            {remove && (
              <Can
                permission="form:remove"
                ok={() => (
                  <>
                    <span
                      style={{
                        color: "#7c7d7c",
                        borderLeft: "1.5px solid #000",
                        height: "20px",
                      }}
                    ></span>
                    &nbsp;&nbsp;&nbsp;
                    <Tooltip
                      placement="top"
                      title={
                        // isActivated
                        //   ? t("horses_page.tooltip.remove_not_allowed")
                        // :
                        t("horses_page.tooltip.remove")
                      }
                    >
                      <span>
                        <IconButton
                          onClick={() => {
                            askToRemove(horse.id);
                          }}
                          size="small"
                        >
                          <DeleteIcon
                            className={`${
                              globalTheme === "high-contrast" && classes.icon
                            } ${classes.iconSize}`}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                )}
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

const SingleForm = ({ horse, history, remove, fetchHorses, isOwner }) => {
  const [openNotificationPanel, setOpenNotificationPanel] = useState(false);
  const [openPermissionsPanel, setOpenPermissionsPanel] = useState(false);
  const [openBoxPermissionsPanel, setOpenBoxPermissionsPanel] = useState(false);
  const [openImagePanel, setOpenImagePanel] = useState(false);
  const [openEventPermissionsPanel, setOpenEventPermissionsPanel] = useState(
    false
  );
  const { t } = useTranslation();
  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);

  const dispatch = useDispatch();

  const [openFormRemovingPanel, setOpenFormRemovingPanel] = useState(false);

  useEffect(() => {
    idToRemove !== NO_ID_TO_REMOVE && setOpenFormRemovingPanel(true);
  }, [idToRemove]);

  const askToRemove = (id) => {
    setIdToRemove(id);
  };

  return (
    <>
      <ConfirmDialogMUI
        handleClose={() => {
          setOpenFormRemovingPanel(false);
          setIdToRemove(NO_ID_TO_REMOVE);
        }}
        open={openFormRemovingPanel}
        text={t("horses_page.remove_label") + horse.name + "?"}
        yesAction={() => {
          remove(idToRemove);
          setIdToRemove(NO_ID_TO_REMOVE);
        }}
        noAction={() => setIdToRemove(NO_ID_TO_REMOVE)}
      />

      <SingleVersionRow
        horse={horse}
        // setShowPermissions={setShowPermissions}
        setOpenPermissionsPanel={setOpenPermissionsPanel}
        setOpenNotificationPanel={setOpenNotificationPanel}
        setOpenBoxPermissionsPanel={setOpenBoxPermissionsPanel}
        setOpenEventPermissionsPanel={setOpenEventPermissionsPanel}
        openImagePanel={openImagePanel}
        setOpenImagePanel={setOpenImagePanel}
        openPermissionsPanel={openPermissionsPanel}
        // showPermissions={showPermissions}
        isCurrent={true}
        history={history}
        remove={remove}
        askToRemove={askToRemove}
        isOwner={isOwner}
      />

      {openNotificationPanel && (
        <NotificationPanel
          id={horse.id}
          open={openNotificationPanel}
          hide={() => setOpenNotificationPanel(false)}
        />
      )}

      {openPermissionsPanel && (
        <FormPermissionsPanel
          form={horse}
          hide={() => setOpenPermissionsPanel(false)}
          open={openPermissionsPanel}
        />
      )}
      {openBoxPermissionsPanel && (
        <BoxPermissionsPanel
          hide={() => setOpenBoxPermissionsPanel(false)}
          open={openBoxPermissionsPanel}
        />
      )}
      {openEventPermissionsPanel && (
        <EventPermissionsPanel
          horse={horse}
          hide={() => setOpenEventPermissionsPanel(false)}
          open={openEventPermissionsPanel}
          fetchHorses={fetchHorses}
        />
      )}
      {openImagePanel && (
        <ImagePanel
          horseId={horse.id}
          hide={() => setOpenImagePanel(false)}
          open={openImagePanel}
        />
      )}
    </>
  );
};

export default SingleForm;
