import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ISO6391 from "iso-639-1-plus";
import PL from "../../assets/poland.svg";
import UK from "../../assets/uk.svg";

const languageMap = {
  en: {
    label: "English",
    dir: "ltr",
    active: false,
    flag: UK,
  },
  pl: {
    label: "Polski",
    dir: "ltr",
    active: true,
    flag: PL,
  },
};

const LanguageSelect = ({ color = "white" }) => {
  const isLanguageValid = () => {
    const selectedLng = localStorage.getItem("i18nextLng").slice(0, 2) || "";
    localStorage.setItem(
      "i18nextLng",
      selectedLng === "pl" || selectedLng === "en" ? selectedLng : "pl"
    );
    return selectedLng === "pl" || selectedLng === "en" ? selectedLng : "pl";
  };
  const selected = isLanguageValid();

  useEffect(() => {
    if (!ISO6391.validate(localStorage.getItem("i18nextLng"))) {
      if (!ISO6391.validate(localStorage.getItem("i18nextLng").substr(0, 2))) {
        localStorage.setItem("i18nextLng", "pl");
      } else {
        localStorage.setItem(
          "i18nextLng",
          localStorage.getItem("i18nextLng").substr(0, 2)
        );
      }
    }
  }, [selected]);

  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState(null);

  useEffect(() => {
    document.body.dir = languageMap[selected]?.dir;
  }, [menuAnchor, selected]);

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        style={{ color }}
      >
        <img
          style={{ width: 22, height: 12 }}
          src={languageMap[selected]?.flag}
          alt={languageMap[selected]?.label}
        />
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List style={{ background: "#d8d8d8" }}>
            {/* <ListSubheader>{t("header.select_language")}</ListSubheader> */}
            {Object.keys(languageMap)?.map((item) => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                }}
              >
                <img
                  style={{ width: 22, height: 12 }}
                  src={languageMap[item].flag}
                  alt={languageMap[item].label}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
