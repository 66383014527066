import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import plLocale from "date-fns/locale/pl";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ClearIcon from "@material-ui/icons/Clear";
import { format } from "date-fns";
import ScheduleIcon from "@material-ui/icons/Schedule";

const FilterAlerts = ({
  filterState,
  dispatchFilterForm,
  handlerFilterSubmit,
  handlerFilterClearSubmit,
}) => {
  const { t } = useTranslation();

  const horseAlertsTotal = useSelector((s) => s.horseAlertsTotal);
  const anomaliesTotal = useSelector((s) => s.anomaliesTotal);

  const studHorses = useSelector((s) => s.studHorses);

  const selectedLang = localStorage.getItem("i18nextLng") || "pl";

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: "5px 10px 10px" }}>
          <Box
            component="fieldset"
            p={1}
            style={{ border: "1px solid lightgray" }}
          >
            <legend style={{ color: "gray", fontSize: "0.75rem" }}>
              {" "}
              {t("alert.filter.horse")}
            </legend>
            <Autocomplete
              id="horse-filter"
              options={studHorses}
              value={filterState.horse}
              getOptionLabel={(option) => option.name || ""}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(e, v) =>
                dispatchFilterForm({
                  type: "HORSE_FILTER_INPUT_CHANGE",
                  payload: v ? v : null,
                })
              }
              noOptionsText={t("no_results")}
              renderInput={(params) => (
                <TextField {...params} label={t("alert.filter.horse_name")} />
              )}
              ListboxProps={{
                style: {
                  maxHeight: "200px",
                },
              }}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper style={{ padding: "5px 10px 10px" }}>
          <MuiPickersUtilsProvider
            locale={selectedLang === "pl" ? plLocale : null}
            utils={DateFnsUtils}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Box
                  component="fieldset"
                  p={1}
                  style={{ border: "1px solid lightgray" }}
                >
                  <legend style={{ color: "gray", fontSize: "0.75rem" }}>
                    {t("alert.filter.since")}
                  </legend>
                  <KeyboardDatePicker
                    fullWidth
                    size="small"
                    name="date"
                    id="date-picker-dialog"
                    label={t("alert.filter.day")}
                    format="dd/MM/yyyy"
                    value={filterState.date?.from || null}
                    onChange={(value, name, t) => {
                      dispatchFilterForm({
                        type: "DATE_FROM_FILTER_INPUT_CHANGE",
                        payload: value ? value : null,
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                    InputProps={{
                      startAdornment: filterState.date?.from ? (
                        <IconButton
                          style={{ padding: "5px", marginBottom: "5px" }}
                          onClick={() => {
                            dispatchFilterForm({
                              type: "DATE_FROM_CLEAR_FILTER_INPUT_CHANGE",
                              payload: null,
                            });
                            // dispatchFilterForm({
                            //   type: "TIME_FROM_CLEAR_FILTER_INPUT_CHANGE",
                            //   payload: null,
                            // });
                            // dispatchFilterForm({
                            //   type: "TIME_TO_CLEAR_FILTER_INPUT_CHANGE",
                            //   payload: null,
                            // });
                          }}
                        >
                          <ClearIcon size="small" />
                        </IconButton>
                      ) : null,
                    }}
                    cancelLabel={t("cancel")}
                    okLabel={t("submit")}
                  />
                  {filterState.date?.from ? (
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      id="time-picker"
                      label={t("alert.filter.time")}
                      value={filterState.date?.start || null}
                      onChange={(date) =>
                        dispatchFilterForm({
                          type: "TIME_FROM_FILTER_INPUT_CHANGE",
                          payload: date,
                        })
                      }
                      inputProps={{
                        readOnly: true,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      keyboardIcon={<ScheduleIcon />}
                      InputProps={{
                        startAdornment: filterState.date?.date ? (
                          <IconButton
                            style={{ padding: "5px" }}
                            onClick={() =>
                              dispatchFilterForm({
                                type: "TIME_FROM_CLEAR_FILTER_INPUT_CHANGE",
                                payload: null,
                              })
                            }
                          >
                            <ClearIcon size="small" />
                          </IconButton>
                        ) : null,
                      }}
                    />
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  component="fieldset"
                  p={1}
                  style={{ border: "1px solid lightgray" }}
                >
                  <legend style={{ color: "gray", fontSize: "0.75rem" }}>
                    {t("alert.filter.to")}
                  </legend>
                  <KeyboardDatePicker
                    fullWidth
                    size="small"
                    name="date"
                    id="date-picker-dialog"
                    label={t("alert.filter.day")}
                    format="dd/MM/yyyy"
                    value={filterState.date?.to || null}
                    onChange={(value, name, t) => {
                      dispatchFilterForm({
                        type: "DATE_TO_FILTER_INPUT_CHANGE",
                        payload: value ? value : null,
                      });
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                    InputProps={{
                      startAdornment: filterState.date?.to ? (
                        <IconButton
                          style={{ padding: "5px", marginBottom: "5px" }}
                          onClick={() => {
                            dispatchFilterForm({
                              type: "DATE_TO_CLEAR_FILTER_INPUT_CHANGE",
                              payload: null,
                            });
                            // dispatchFilterForm({
                            //   type: "TIME_FROM_CLEAR_FILTER_INPUT_CHANGE",
                            //   payload: null,
                            // });
                            // dispatchFilterForm({
                            //   type: "TIME_TO_CLEAR_FILTER_INPUT_CHANGE",
                            //   payload: null,
                            // });
                          }}
                        >
                          <ClearIcon size="small" />
                        </IconButton>
                      ) : null,
                    }}
                    cancelLabel={t("cancel")}
                    okLabel={t("submit")}
                  />{" "}
                  {filterState.date?.to ? (
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      id="time-picker"
                      label={t("alert.filter.time")}
                      value={filterState.date?.end || null}
                      onChange={(date) =>
                        dispatchFilterForm({
                          type: "TIME_TO_FILTER_INPUT_CHANGE",
                          payload: date,
                        })
                      }
                      keyboardIcon={<ScheduleIcon />}
                      inputProps={{
                        readOnly: true,
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change time",
                      }}
                      InputProps={{
                        startAdornment: filterState.date?.date ? (
                          <IconButton
                            style={{ padding: "5px" }}
                            onClick={() =>
                              dispatchFilterForm({
                                type: "TIME_TO_CLEAR_FILTER_INPUT_CHANGE",
                                payload: null,
                              })
                            }
                          >
                            <ClearIcon size="small" />
                          </IconButton>
                        ) : null,
                      }}
                    />
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" onClick={handlerFilterSubmit}>
          {t("alert.filter_button")}
        </Button>
        <Button onClick={handlerFilterClearSubmit}>
          {t("alert.filter_button_clear")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterAlerts;
