export const colors = [
  { value: "bay" },
  { value: "black" },
  { value: "chestnut" },
  { value: "grey" },
  { value: "roan" },
  { value: "dun" },
  { value: "piebald" },
  { value: "palomino" },
  { value: "cremello" },
  { value: "white" },
  { value: "other" },
];
