import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import Can from "../../components/can";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Loader from "../../components/loader";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/page-title";
import { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  fetchCameras,
  fetchFormsGroups,
  fetchHorseFarms,
  removeCamera,
} from "../../redux/actions";
import Cookies from "js-cookie";
import { useParams } from "react-router";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import { vendorList } from "../add-new-camera";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    paddingRight: 2,
    paddingLeft: 3,
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
  },
  t: {
    maxHeight: "50vh",
    "@media (min-height: 400px)": {
      maxHeight: "60vh",
    },
    "@media (min-height: 500px)": {
      maxHeight: "65vh",
    },
    "@media (min-height: 600px)": {
      maxHeight: "70vh",
    },
    "@media (min-height: 800px)": {
      maxHeight: "75vh",
    },
    "@media (min-height: 900px)": {
      maxHeight: "78vh",
    },
    "@media (min-height: 960px)": {
      maxHeight: "80vh",
    },
  },
}));

const Cameras = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { studId } = useParams();

  const roles = useSelector((s) => s.roles);
  const my = useSelector((s) => s.my);
  const currentContext = useSelector((s) => s.currentContext);

  const cameras = useSelector((s) => s.cameras);
  const camerasTotal = useSelector((s) => s.camerasTotal);
  const camerasFetching = useSelector((s) => s.camerasFetching);
  const camerasRemoving = useSelector((s) => s.camerasRemoving);

  const allStuds = useSelector((s) => s.orgFormsGroups);
  const [orderBy, setOrderBy] = useState("vendor");
  const [order, setOrder] = useState("asc");
  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [busy, setBusy] = useState(false);

  const [stud, setStud] = useState(studId || "");
  const [openConfirmPanelCamera, setOpenConfirmPanelCamera] = useState(false);

  const [cameraIdToRemove, setCameraIdToRemove] = useState("");

  const isStudOwner =
    my?.user.authorities.localeCompare("ROLE_STUD_OWNER") === 0;
  const isOwner = !!my?.user.authorities.includes("ROLE_OWNER");

  useEffect(() => {
    if (
      Number.isInteger(camerasTotal / rowsPerPage) &&
      camerasTotal / rowsPerPage < pageNr + 1 &&
      pageNr > 0
    ) {
      setPageNr(pageNr - 1);
    }
  }, [camerasTotal]);

  useEffect(() => {
    (async () => {
      if (isOwner) {
        await dispatch(
          fetchHorseFarms({ page: 0, pageSize: 1000, sort: "name" })
        );
      }
    })();
  }, [isOwner]);

  useEffect(() => {
    if (isOwner) {
      if (stud) {
        fetchCamerasList();
        setBusy(false);
      }
    } else {
      setBusy(true);
      fetchCamerasList();
      if (currentContext.studId) {
        setBusy(false);
      }
    }
  }, [stud, pageNr, rowsPerPage, order, orderBy, currentContext]);

  useEffect(() => {
    if (cameraIdToRemove) {
      setOpenConfirmPanelCamera(true);
    }
  }, [cameraIdToRemove]);

  const handleCameraDelete = async () => {
    const r = await dispatch(
      removeCamera({
        isOwner,
        studId: isOwner ? stud : null,
        cameraId: cameraIdToRemove,
      })
    );
    if (r) {
      setCameraIdToRemove("");
      setOpenConfirmPanelCamera(false);
      fetchCamerasList();
    }
  };

  const fetchCamerasList = async () => {
    await dispatch(
      fetchCameras({
        isOwner,
        studId: isOwner ? stud : null,
        page: pageNr,
        pageSize: rowsPerPage,
        ...(orderBy
          ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
          : {}),
      })
    );
  };

  const headerCells = [
    // { id: "model", label: t("cameras_page.table.model") },
    { id: "vendor", label: t("cameras_page.table.vendor") },
    {
      id: "recorderChannel",
      label: t("cameras_page.table.recorderChannelTable"),
    },
    { id: "recorderIP", label: t("cameras_page.table.recorderAddressTable") },
  ];

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    Cookies.set(`cameras-per-page[${my.user.id}]`, event.target.value, {
      sameSite: "strict",
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };
  const page = () => (
    <Box>
      {camerasFetching || busy ? (
        <Box p={3}>
          <Loader loading={true} text={t("cameras_page.fetching")} />
        </Box>
      ) : camerasRemoving ? (
        <Box p={3}>
          <Loader loading={true} text={t("horses_page.removing")} />
        </Box>
      ) : (
        <>
          <Box p={1}>
            <PageTitle title={t("cameras_page.label")} />
          </Box>
          <Box p={1}>
            {isOwner && (
              <FormControl fullWidth variant="outlined" size="small" required>
                <InputLabel id="select-outlined-label" required>
                  {t("horse_form.stud")}
                </InputLabel>
                <Select
                  labelId="stud-outlined-label"
                  id="stud-outlined"
                  value={stud}
                  onChange={(event) => setStud(event.target.value)}
                  label={`${t("horse_form.stud")} *`}
                  required
                >
                  {allStuds.map((stud, index) => {
                    return (
                      <MenuItem key={index} value={stud.id}>
                        {stud.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Box>

          {(isOwner && stud) || isStudOwner ? (
            <Box p={1}>
              <TableContainer component={Paper} className={classes.t}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headerCells.map(({ label, id }) => (
                        <TableCell key={id} className={classes.tableCell}>
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : "asc"}
                            onClick={() => {
                              handleSortRequest(id);
                            }}
                          >
                            {label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell align="right" className={classes.tableCell}>
                        {t("cameras_page.table.options")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cameras.map((camera, index) => {
                      const vendorName = vendorList?.find(
                        (vendor) => vendor.value === camera.vendor
                      );
                      return (
                        <TableRow key={camera.id}>
                          {/* <TableCell>{camera.model}</TableCell> */}{" "}
                          <TableCell>{vendorName?.name || ""}</TableCell>
                          <TableCell>{camera.recorderChannel}</TableCell>
                          <TableCell>{camera.recorderIP}</TableCell>
                          <TableCell>
                            {" "}
                            <Can
                              permission="form:edit"
                              ok={() => (
                                <Tooltip
                                  placement="top"
                                  title={t("cameras_page.tooltip.edition")}
                                >
                                  <span>
                                    <IconButton
                                      onClick={() =>
                                        history.push(
                                          isStudOwner
                                            ? `/camera/edit/${currentContext.studId}/${camera.id}`
                                            : `/camera/edit/${stud}/${camera.id}`
                                        )
                                      }
                                      size="small"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              )}
                            />{" "}
                            |{" "}
                            <a
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                              onClick={() => setCameraIdToRemove(camera.id)}
                            >
                              {t("remove")}
                            </a>{" "}
                          </TableCell>{" "}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={{ display: "flex" }}
                component="div"
                page={pageNr}
                rowsPerPageOptions={pages}
                rowsPerPage={rowsPerPage}
                count={camerasTotal}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t("pagination.rows")}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t("pagination.out_of")} ${count}`
                }
              />
              {openConfirmPanelCamera && (
                <ConfirmDialogMUI
                  handleClose={(reason) => {
                    setCameraIdToRemove("");
                    setOpenConfirmPanelCamera(false);
                  }}
                  open={openConfirmPanelCamera}
                  text={t("cameras_page.remove_info")}
                  yesAction={() => {
                    handleCameraDelete(cameraIdToRemove);
                  }}
                  noAction={() => {
                    setOpenConfirmPanelCamera(false);
                    setCameraIdToRemove("");
                  }}
                />
              )}
            </Box>
          ) : (
            <Box p={1} style={{ textAlign: "center" }}>
              <Typography variant="h5">
                {t("cameras_page.select_stud_label")}
              </Typography>
            </Box>
          )}

          {(roles.includes("ROLE_OWNER") ||
            roles.includes("ROLE_STUD_OWNER")) &&
            (stud || currentContext.studId) && (
              // <Can
              //   permission="form-add-page:view"
              //   ok={() => (
              <Box position="fixed" bottom={20} right={20}>
                <NavLink
                  to={
                    isOwner
                      ? `/camera/add/${stud}`
                      : `/camera/add/${currentContext.studId}`
                  }
                >
                  <Button
                    style={{ background: "#4caf50" }}
                    variant="contained"
                    color="primary"
                    className={classes.fontSize}
                  >
                    {`+ ${t("cameras_page.add_camera_button")}`}
                  </Button>
                </NavLink>
              </Box>
              //   )}
              // />
            )}
        </>
      )}
    </Box>
  );
  const redirect = () => <Redirect to="/" />;

  return <Can permission="forms-page:view" ok={page} not={redirect} />;
};

export default Cameras;
