import React, { useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import { statusList } from "../status";

const AddVaccinationEvent = ({
  formState: {
    vaccination: { id, date, type, isValid, status },
  },
  dispatch,
  handleSubmit,
  t,
}) => {
  const selectedLang = localStorage.getItem("i18nextLng") || "pl";
  useEffect(() => {
    if (date && type && status) {
      dispatch({
        type: "VALID_FORM",
        property: "vaccination",
      });
    } else {
      dispatch({
        type: "NOT_VALID_FORM",
        property: "vaccination",
      });
    }
  }, [date, type, status]);

  const handleClearData = () => {
    if (id) {
      dispatch({
        type: "CLEAR_FORM_DATA_TO_EDIT",
        payload: id,
        property: "vaccination",
      });
    } else {
      dispatch({
        type: "CLEAR_FORM_DATA",
        payload: true,
        property: "vaccination",
      });
    }
  };

  return (
    <Box p={1}>
      <hr style={{ width: "100%" }} />

      <Grid container direction="column">
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" gutterBottom>
            {t("events.vaccinations.add_new_vaccination")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MuiPickersUtilsProvider
            required
            locale={selectedLang === "pl" ? plLocale : null}
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              style={{ margin: 0 }}
              fullWidth
              required
              inputVariant="outlined"
              margin="normal"
              size="small"
              name="date"
              id="date-picker-dialog"
              label={t("events.vaccinations.date")}
              format="dd/MM/yyyy"
              value={date || null}
              onChange={(value, name, t) => {
                dispatch({
                  type: "HANDLE_INPUT_CHANGE",
                  field: "date",
                  property: "vaccination",
                  payload: value,
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              inputProps={{
                readOnly: true,
              }}
              cancelLabel={t("cancel")}
              okLabel={t("submit")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            label={t("events.vaccinations.type")}
            margin="normal"
            name="type"
            size="small"
            fullWidth
            multiline
            required
            rows={2}
            maxRows={3}
            value={type}
            variant="outlined"
            onChange={({ target: { value } }) =>
              dispatch({
                type: "HANDLE_INPUT_CHANGE",
                field: "type",
                property: "vaccination",
                payload: value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl
            margin="normal"
            size="small"
            variant="outlined"
            fullWidth
            required
          >
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              label={"Status *"}
              value={status || ""}
              size="small"
              onChange={({ target: { value } }) => {
                dispatch({
                  type: "HANDLE_INPUT_CHANGE",
                  field: "status",
                  property: "vaccination",
                  payload: value,
                });
              }}
              autoWidth={true}
            >
              {statusList.map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`events.status.${value}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            size="small"
            variant="contained"
            disabled={!isValid}
            color="primary"
            style={{ padding: "8px 16px", marginRight: "15px" }}
            onClick={handleSubmit}
          >
            {id ? t("edit") : t("add")}
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ padding: "8px 16px" }}
            onClick={handleClearData}
          >
            {t("clear")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddVaccinationEvent;
