import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchActiveForms,
  fetchActiveFormsAll,
  fetchForms,
  fetchScheduledForms,
  fetchOrganization,
  fetchOrgUsers,
  fetchPlannedForms,
  fetchPlannedFormsAll,
  fetchFilledFormsExtended,
  fetchArchivedForms,
  fetchSavedFormData,
  fetchFormsScheduledForFuture,
  fetchFilledFormData,
  fetchAlerts,
  fetchAnomalies,
  fetchHorsesByStud,
  fetchAllHorses,
  fetchSignalInfo,
} from "../redux/actions";
import {
  fetchOrganizationFutureTerms,
  fetchTermsAcceptedByUser,
} from "../redux/organization/actions";
import Can from "../components/can";
import { NavLink, Redirect } from "react-router-dom";
import { checkPermission } from "../rbac";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import PageTitle from "../components/page-title";
import Duration from "duration";
import Loader from "../components/loader";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TermsAcceptance from "../components/terms-acceptance";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import HorseAlerts from "../components/horse-alerts";
import Anomaly from "../components/anomaly";
import FilterAlerts from "../components/filter-alerts";
import { useReducer } from "react";
import { Pagination } from "@material-ui/lab";
import SignalPower from "../components/signal-power";

const useStyles = makeStyles((theme) => ({
  closeIconOnTop: {
    "align-self": "flex-start",
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  alertHighContrast: {
    "& .MuiAlert-icon": {
      color: theme.palette.text.primary,
    },
    backgroundColor: "#000",
    border: "1px solid #fff200",
    color: theme.palette.text.primary,
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  buttonContrast: {
    backgroundColor: "black",
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
    "&:hover": {
      color: theme.palette.text.primary,
      backgroundColor: "black",
    },
  },
  accordion: {
    boxShadow: "none",
    border: "1px solid lightgray",
    borderRadius: "0px",
  },
}));

const initialFilterData = {
  page: 0,
  pageSize: 10,
  horse: null,
  date: { from: null, to: null, start: null, end: null },
};

const filterReducer = (state, action) => {
  const areDatesEqual =
    new Date(state.date?.from?.toDateString()).valueOf() ===
    new Date(state.date?.to?.toDateString()).valueOf();

  switch (action.type) {
    case "PAGE_INPUT_CHANGE":
      return {
        ...state,
        page: action.payload,
      };
    case "ROWS_PER_PAGE_INPUT_CHANGE":
      return {
        ...state,
        pageSize: action.payload,
        page: 0,
      };
    case "HORSE_FILTER_INPUT_CHANGE":
      return {
        ...state,
        horse: action.payload,
        // page: 0,
      };
    case "DATE_FROM_FILTER_INPUT_CHANGE":
      const startTimeGreaterThanEndTime =
        action.payload && state.date?.to
          ? new Date(action.payload?.toDateString()).valueOf() <
            new Date(state.date?.to?.toDateString()).valueOf()
          : false;
      return {
        ...state,
        // page: 0,
        date: {
          ...state.date,
          from: !state.date?.to
            ? action.payload
            : action.payload > state.date?.to
            ? state.date?.to
            : action.payload,
          start:
            state.date?.start > state.date?.end && !startTimeGreaterThanEndTime
              ? state.date?.end
              : state.date?.start,
        },
      };
    case "DATE_FROM_CLEAR_FILTER_INPUT_CHANGE":
      return {
        ...state,
        // page: 0,
        date: {
          ...state.date,
          from: action.payload,
          start: action.payload,
        },
      };
    case "DATE_TO_CLEAR_FILTER_INPUT_CHANGE":
      return {
        ...state,
        // page: 0,
        date: {
          ...state.date,
          to: action.payload,
          end: action.payload,
        },
      };
    case "DATE_TO_FILTER_INPUT_CHANGE":
      return {
        ...state,
        // page: 0,
        date: {
          ...state.date,
          to:
            state.date?.from < action.payload
              ? action.payload
              : state.date?.from,
          end:
            state.date?.end < state.date?.start &&
            !(action.payload > state.date?.from) &&
            state.date?.end
              ? state.date?.start
              : state.date?.end,
        },
      };
    case "TIME_FROM_FILTER_INPUT_CHANGE":
      return {
        ...state,
        // page: 0,
        date: {
          ...state.date,
          start: areDatesEqual
            ? !state.date?.end
              ? action.payload
              : action.payload > state.date?.end
              ? state.date?.end
              : action.payload
            : action.payload,
        },
      };
    case "TIME_FROM_CLEAR_FILTER_INPUT_CHANGE":
      return {
        ...state,
        date: {
          ...state.date,
          start: null,
        },
      };
    case "TIME_TO_FILTER_INPUT_CHANGE":
      return {
        ...state,
        date: {
          ...state.date,
          end: areDatesEqual
            ? state.date?.start < action.payload
              ? action.payload
              : state.date?.start
            : action.payload,
        },
      };
    case "TIME_TO_CLEAR_FILTER_INPUT_CHANGE":
      return {
        ...state,
        date: {
          ...state.date,
          end: null,
        },
      };
    case "CLEAR_ALL_DATA":
      return initialFilterData;
    case "CLEAR_DATA":
      return { ...initialFilterData, page: 0 };
    default:
      return state;
  }
};

const Main = ({}) => {
  const my = useSelector((state) => state.my);
  const roles = useSelector((state) => state.roles);
  const termsAcceptedByUser = useSelector((s) => s.termsAcceptedByUser);
  const orgFutureTerms = useSelector((s) => s.orgFutureTerms);
  const fetchingOrgFutureTerms = useSelector((s) => s.fetchingOrgFutureTerms);
  const isUser =
    my?.user.authorities.localeCompare("ROLE_GROOM") === 0 ||
    my?.user.authorities.localeCompare("ROLE_VET") === 0 ||
    my?.user.authorities.localeCompare("ROLE_USER") === 0;
  const isOwner = my.user.authorities.includes("ROLE_OWNER");
  const isStudOwner =
    my?.user.authorities.localeCompare("ROLE_STUD_OWNER") === 0;

  const dispatch = useDispatch();

  const [filterState, dispatchFilterForm] = useReducer(
    filterReducer,
    initialFilterData
  );

  const [initialization, setInitialization] = useState(true);

  const [futureTermsAccepted, setFutureTermsAccepted] = useState(false);

  const globalTheme = useSelector((s) => s.globalTheme);
  const baseFontSize = useSelector((s) => s.baseFontSize);

  const horseAlertsTotal = useSelector((s) => s.horseAlertsTotal);
  const anomaliesTotal = useSelector((s) => s.anomaliesTotal);

  const horseAlertsFetching = useSelector((s) => s.horseAlertsFetching);
  const anomaliesFetching = useSelector((s) => s.anomaliesFetching);
  const signalFetching = useSelector((s) => s.signalFetching);

  const currentContext = useSelector((s) => s.currentContext);

  const classes = useStyles();

  const itemsLength =
    horseAlertsTotal > anomaliesTotal ? horseAlertsTotal : anomaliesTotal;

  const rowsPerPageOptions = [5, 10, 15, 20];

  useEffect(() => {
    (async () => {
      if (isStudOwner && currentContext.studId) {
        dispatchFilterForm({ type: "CLEAR_ALL_DATA" });
        await dispatch(
          fetchAlerts({
            page: filterState.page,
            pageSize: filterState.pageSize,
          })
        );
        await dispatch(
          fetchAnomalies({
            page: filterState.page,
            pageSize: filterState.pageSize,
          })
        );
        await dispatch(fetchSignalInfo());
        await dispatch(fetchHorsesByStud({ page: 0, pageSize: 1000 }));
      } else if (isOwner) {
        await dispatch(
          fetchAlerts({
            page: filterState.page,
            pageSize: filterState.pageSize,
            isOwner,
          })
        );
        await dispatch(
          fetchAnomalies({
            page: filterState.page,
            pageSize: filterState.pageSize,
            isOwner,
          })
        );
        await dispatch(fetchAllHorses());
      }
    })();
  }, [isStudOwner, currentContext, isOwner]);

  useEffect(() => {
    (async () => {
      const dateFilter = dateFormat();
      if (currentContext.studId || isOwner) {
        await dispatch(
          fetchAlerts({
            page: filterState.page,
            pageSize: filterState.pageSize,
            isOwner: isOwner,
            ...((filterState.horse || dateFilter) && {
              q: {
                ...(filterState.horse && {
                  horseId: { is: filterState.horse.id },
                }),
                ...(dateFilter && {
                  timestamp: dateFilter,
                }),
              },
            }),
          })
        );
        await dispatch(
          fetchAnomalies({
            page: filterState.page,
            pageSize: filterState.pageSize,
            isOwner: isOwner,
            ...((filterState.horse || dateFilter) && {
              q: {
                ...(filterState.horse && {
                  horseId: { is: filterState.horse.id },
                }),
                ...(dateFilter && {
                  timestamp: dateFilter,
                }),
              },
            }),
          })
        );
      }
    })();
  }, [filterState.page, filterState.pageSize]);

  useEffect(() => {
    if (isUser && !orgFutureTerms && !fetchingOrgFutureTerms) {
      dispatch(fetchOrganizationFutureTerms());
    }
  }, [isUser]);

  // useEffect(() => {
  //   dispatchFilterForm({
  //     type: "PAGE_INPUT_CHANGE",
  //     payload: 0,
  //   });
  // }, [filterState.horse]);

  useEffect(() => {
    isUser && !termsAcceptedByUser && dispatch(fetchTermsAcceptedByUser());
  }, [isUser]);

  useEffect(() => {
    if (isUser && termsAcceptedByUser && orgFutureTerms) {
      const founded = termsAcceptedByUser.find(
        ({ terms }) => terms.id === orgFutureTerms.id
      );
      if (founded && !founded.revokedAt) {
        setFutureTermsAccepted(true);
      } else {
        setFutureTermsAccepted(false);
      }
    }
  }, [termsAcceptedByUser, orgFutureTerms]);

  const handlerFilterSubmit = async () => {
    const dateFilter = dateFormat();
    if (isStudOwner || isOwner) {
      if (filterState.page !== 0) {
        dispatchFilterForm({
          type: "PAGE_INPUT_CHANGE",
          payload: 0,
        });
      } else {
        await dispatch(
          fetchAlerts({
            page: filterState.page,
            pageSize: filterState.pageSize,
            isOwner: isOwner,
            ...((filterState.horse || dateFilter) && {
              q: {
                ...(filterState.horse && {
                  horseId: { is: filterState.horse.id },
                }),
                ...(dateFilter && {
                  timestamp: dateFilter,
                }),
              },
            }),
          })
        );

        await dispatch(
          fetchAnomalies({
            page: filterState.page,
            pageSize: filterState.pageSize,
            isOwner: isOwner,
            ...((filterState.horse || dateFilter) && {
              q: {
                ...(filterState.horse && {
                  horseId: { is: filterState.horse.id },
                }),
                ...(dateFilter && {
                  timestamp: dateFilter,
                }),
              },
            }),
          })
        );
      }
    }
  };

  const dateFormat = () => {
    if (filterState.date.from || filterState.date.to) {
      const dateFrom = filterState.date.from || null;
      const dateTo = filterState.date.to || null;
      const start = filterState.date.start || null;
      const end = filterState.date.end || null;

      const lowerBoundary = !dateFrom
        ? null
        : start
        ? new Date(
            dateFrom.getFullYear(),
            dateFrom.getMonth(),
            dateFrom.getDate(),
            start.getHours(),
            start.getMinutes(),
            0,
            0
          ).toISOString()
        : new Date(
            dateFrom.getFullYear(),
            dateFrom.getMonth(),
            dateFrom.getDate(),
            0,
            0,
            0,
            0
          ).toISOString();

      const upperBoundary = !dateTo
        ? null
        : end
        ? new Date(
            dateTo.getFullYear(),
            dateTo.getMonth(),
            dateTo.getDate(),
            end.getHours(),
            end.getMinutes(),
            59,
            999
          ).toISOString()
        : new Date(
            dateTo.getFullYear(),
            dateTo.getMonth(),
            dateTo.getDate(),
            23,
            59,
            59,
            999
          ).toISOString();
      if (dateFrom && dateTo) {
        return {
          between: [lowerBoundary, upperBoundary],
        };
      } else if (dateFrom) {
        return { gt: lowerBoundary };
      } else if (dateTo) {
        return { lt: upperBoundary };
      }
    } else return null;
  };

  const handlerFilterClearSubmit = async () => {
    dispatchFilterForm({ type: "CLEAR_ALL_DATA" });
    if (isStudOwner && currentContext.studId) {
      await dispatch(
        fetchAlerts({
          page: 0,
          pageSize: filterState.pageSize,
        })
      );
      await dispatch(
        fetchAnomalies({
          page: 0,
          pageSize: filterState.pageSize,
        })
      );
      await dispatch(fetchHorsesByStud({}));
    } else if (isOwner) {
      await dispatch(
        fetchAlerts({
          page: 0,
          pageSize: filterState.pageSize,
          isOwner,
        })
      );
      await dispatch(
        fetchAnomalies({
          page: 0,
          pageSize: filterState.pageSize,
          isOwner,
        })
      );
    }
  };

  const handlePaginationChange = (e, v) => {
    dispatchFilterForm({
      type: "PAGE_INPUT_CHANGE",
      payload: v - 1,
    });
  };

  const { t } = useTranslation();
  const loggedUserMainPage = () => (
    <Box>
      {isUser ? (
        <Box p={1}>
          <Alert severity="error">{t("invalid_user_error")}</Alert>
        </Box>
      ) : (
        <Box p={1}>
          {(
            isOwner
              ? horseAlertsFetching || anomaliesFetching || signalFetching
              : horseAlertsFetching ||
                signalFetching ||
                anomaliesFetching ||
                !("studId" in currentContext)
          ) ? (
            <Box p={1}>
              <Loader loading={true} text={t("fetching")} />
            </Box>
          ) : (
            <Box>
              {!isOwner && (
                <>
                  <SignalPower />
                  <hr />
                </>
              )}
              <Accordion className={classes.accordion} expanded={true}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6">{t("alert.filters")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FilterAlerts
                    filterState={filterState}
                    dispatchFilterForm={dispatchFilterForm}
                    handlerFilterSubmit={handlerFilterSubmit}
                    handlerFilterClearSubmit={handlerFilterClearSubmit}
                  />
                </AccordionDetails>
              </Accordion>
              <Grid container>
                <Grid item xs={12} md={anomaliesTotal ? 6 : 12}>
                  <HorseAlerts />
                </Grid>

                {anomaliesTotal && horseAlertsTotal ? (
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ marginRight: "-1px" }}
                  />
                ) : null}

                <Grid item xs={12} md={horseAlertsTotal ? 6 : 12}>
                  <Anomaly />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Pagination
                    count={Math.ceil(itemsLength / filterState.pageSize)}
                    defaultPage={filterState.page + 1}
                    onChange={handlePaginationChange}
                    style={{ display: "flex", alignContent: "center" }}
                  />

                  <FormControl
                    size="small"
                    variant="outlined"
                    style={{ minWidth: "140px" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {t("alert.filter.rows_per_page")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterState.pageSize}
                      label={t("alert.filter.rows_per_page")}
                      onChange={(e) =>
                        dispatchFilterForm({
                          type: "ROWS_PER_PAGE_INPUT_CHANGE",
                          payload: e.target.value,
                        })
                      }
                    >
                      {rowsPerPageOptions.map((v) => (
                        <MenuItem key={v} value={v}>
                          {v}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}

          {!my.firstName && (
            <Box mt={1}>
              <Alert
                icon={<PersonIcon />}
                severity="warning"
                className={`${
                  globalTheme === "high-contrast" && classes.alertHighContrast
                } ${classes.fontSize}`}
              >
                Prosimy o wypełnienie profilu.{" "}
                <NavLink
                  to="/profile"
                  className={`${
                    globalTheme === "high-contrast" &&
                    classes.aalertHighContrast
                  } ${classes.fontSize}`}
                >
                  PRZEJDŹ DO PROFILU
                </NavLink>
              </Alert>
            </Box>
          )}

          {orgFutureTerms && !futureTermsAccepted && (
            <Box mt={1}>
              <Alert
                severity="warning"
                className={`${
                  globalTheme === "high-contrast" && classes.alertHighContrast
                } ${classes.fontSize}`}
              >
                {`Regulamin zostanie zmieniony w dniu ${format(
                  new Date(orgFutureTerms.activeSince),
                  "yyyy-MM-dd"
                )}. Prosimy o zapoznanie
                              się i akceptację warunków.`}

                <TermsAcceptance
                  terms={orgFutureTerms}
                  acceptance={true}
                  future={true}
                ></TermsAcceptance>
              </Alert>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <Can
        permission="main:view"
        ok={loggedUserMainPage}
        not={() => <Redirect to="/login" />}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  orgUsers: state.orgUsers,
  orgReports: state.orgReports,
  organization: state.organization,
  activeForms: state.activeFormsAll,
  plannedForms: state.plannedFormsAll,
  archivedForms: state.archivedForms,
  savedForms: state.savedForms,
  scheduledForFutureForms: state.scheduledForFutureForms,
  allFilledForms: state.allFilledForms,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
