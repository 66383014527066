import React, { useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import { Autocomplete } from "@material-ui/lab";
import { statusList } from "../status";
import { typeList } from "./type";

const AddVeterinaryVisitEvent = ({
  formState: {
    veterinaryVisit: {
      id,
      date,
      purpose,
      otherVisitPurpose,
      course,
      medicaments,
      recommendations,
      isValid,
      status,
    },
  },
  dispatch,
  handleSubmit,
  t,
}) => {
  const selectedLang = localStorage.getItem("i18nextLng") || "pl";
  useEffect(() => {
    if (date && purpose && course && status) {
      if (purpose !== "Other") {
        dispatch({
          type: "VALID_FORM",
          property: "veterinaryVisit",
        });
      } else if (purpose === "Other" && otherVisitPurpose) {
        dispatch({
          type: "VALID_FORM",
          property: "veterinaryVisit",
        });
      } else {
        dispatch({
          type: "NOT_VALID_FORM",
          property: "veterinaryVisit",
        });
      }
    } else {
      dispatch({
        type: "NOT_VALID_FORM",
        property: "veterinaryVisit",
      });
    }
  }, [date, purpose, status, otherVisitPurpose, course]);
  const purposes = [
    {
      label: t("events.veterinaryVisits.check_up_visit"),
      value: "checkUp",
    },
    {
      label: t("events.veterinaryVisits.emergency_visit"),
      value: "emergency",
    },
  ];

  const handleClearData = () => {
    if (id) {
      dispatch({
        type: "CLEAR_FORM_DATA_TO_EDIT",
        payload: id,
        property: "veterinaryVisit",
      });
    } else {
      dispatch({
        type: "CLEAR_FORM_DATA",
        payload: true,
        property: "veterinaryVisit",
      });
    }
  };

  return (
    <Box p={1}>
      <hr style={{ width: "100%" }} />

      <Grid container direction="column">
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" gutterBottom>
            {t("events.veterinaryVisits.add_new_visit")}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <MuiPickersUtilsProvider
            locale={selectedLang === "pl" ? plLocale : null}
            utils={DateFnsUtils}
          >
            <KeyboardDatePicker
              style={{ margin: 0 }}
              fullWidth
              inputVariant="outlined"
              margin="normal"
              size="small"
              name="date"
              id="date-picker-dialog"
              label={t("events.veterinaryVisits.date")}
              format="dd/MM/yyyy"
              required
              value={date || null}
              onChange={(value, name, t) => {
                dispatch({
                  type: "HANDLE_INPUT_CHANGE",
                  field: "date",
                  property: "veterinaryVisit",
                  payload: value,
                });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              inputProps={{
                readOnly: true,
              }}
              cancelLabel={t("cancel")}
              okLabel={t("submit")}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} lg={6} container spacing={1}>
          <Grid item xs={12} lg={6}>
            <FormControl
              margin="normal"
              size="small"
              variant="outlined"
              fullWidth
              required
            >
              <InputLabel id="status-label">
                {t("events.veterinaryVisits.purpose")}
              </InputLabel>
              <Select
                labelId="purpose-label"
                id="purpose-select"
                label={t("events.veterinaryVisits.purpose") + " *"}
                value={purpose || ""}
                size="small"
                onChange={({ target: { value } }) => {
                  if (value !== "Other") {
                    dispatch({
                      type: "HANDLE_INPUT_CHANGE",
                      field: "otherVisitPurpose",
                      property: "veterinaryVisit",
                      payload: "",
                    });
                  }
                  dispatch({
                    type: "HANDLE_INPUT_CHANGE",
                    field: "purpose",
                    property: "veterinaryVisit",
                    payload: value,
                  });
                }}
                autoWidth={true}
              >
                {typeList.map((value) => (
                  <MenuItem key={value} value={value}>
                    {t(`events.veterinaryVisits.${value}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              margin="normal"
              size="small"
              fullWidth
              disabled={purpose !== "Other"}
              value={otherVisitPurpose}
              id="outlined-start-adornment"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ color: purpose !== "Other" ? "#cecece" : "grey" }}
                  >
                    {purpose === "Other" && " * "}
                    {t("events.veterinaryVisits.reason")}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={({ target: { value } }) => {
                dispatch({
                  type: "HANDLE_INPUT_CHANGE",
                  field: "otherVisitPurpose",
                  property: "veterinaryVisit",
                  payload: value,
                });
              }}
            />
          </Grid>
        </Grid>{" "}
        <Grid item xs={12} lg={6}>
          <TextField
            label={t("events.veterinaryVisits.course")}
            margin="normal"
            name="course"
            size="small"
            required
            fullWidth
            multiline
            rows={2}
            maxRows={3}
            value={course}
            variant="outlined"
            onChange={({ target: { value } }) =>
              dispatch({
                type: "HANDLE_INPUT_CHANGE",
                field: "course",
                property: "veterinaryVisit",
                payload: value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            label={t("events.veterinaryVisits.medicaments")}
            margin="normal"
            name="medicaments"
            size="small"
            fullWidth
            multiline
            rows={2}
            maxRows={3}
            value={medicaments}
            variant="outlined"
            onChange={({ target: { value } }) =>
              dispatch({
                type: "HANDLE_INPUT_CHANGE",
                field: "medicaments",
                property: "veterinaryVisit",
                payload: value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            label={t("events.veterinaryVisits.recommendations")}
            margin="normal"
            name="recommendations"
            size="small"
            fullWidth
            multiline
            rows={2}
            maxRows={3}
            value={recommendations}
            variant="outlined"
            onChange={({ target: { value } }) =>
              dispatch({
                type: "HANDLE_INPUT_CHANGE",
                field: "recommendations",
                property: "veterinaryVisit",
                payload: value,
              })
            }
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl
            margin="normal"
            size="small"
            variant="outlined"
            fullWidth
            required
          >
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              label={"Status *"}
              value={status || ""}
              size="small"
              onChange={({ target: { value } }) => {
                dispatch({
                  type: "HANDLE_INPUT_CHANGE",
                  field: "status",
                  property: "veterinaryVisit",
                  payload: value,
                });
              }}
              autoWidth={true}
            >
              {statusList.map((value) => (
                <MenuItem key={value} value={value}>
                  {t(`events.status.${value}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            size="small"
            variant="contained"
            disabled={!isValid}
            color="primary"
            style={{ padding: "8px 16px", marginRight: "15px" }}
            onClick={handleSubmit}
          >
            {id ? t("edit") : t("add")}
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ padding: "8px 16px" }}
            onClick={handleClearData}
          >
            {t("clear")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddVeterinaryVisitEvent;
