import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, Prompt } from "react-router-dom";
import Can from "../../components/can";
import s from "./index.module.css";
import {
  updateMyProfile,
  updateMyProfileExt,
} from "../../redux/person/actions";
import * as classnames from "classnames";
import {
  updateMyOrganization,
  fetchOrganizationCurrentTerms,
  fetchTermsAcceptedByUser,
  fetchOrganizationFutureTerms,
} from "../../redux/organization/actions";
import { checkPermission } from "../../rbac";
import { Box } from "@material-ui/core";
import { alertAdd, recoverPassword } from "../../redux/actions";
import ShippingAddressForm from "../../components/address-form/shipping-info";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PageTitle from "../../components/page-title";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import plLocale from "date-fns/locale/pl";
import Tooltip from "@material-ui/core/Tooltip";
import EventIcon from "@material-ui/icons/Event";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TermsAcceptance from "../../components/terms-acceptance";
import OrganizationTerms from "../organization-terms";
import AvatarUpload from "../../components/avatar-upload";
import ApiVersionSpan from "../../components/api-version-span/api-version-span";
import { testEmailPattern } from "../register-organization";
import { useTranslation } from "react-i18next";

const ContrastCheckbox = withStyles((theme) => ({
  root: {
    color:
      theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    "&$checked": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
  },
}))(Checkbox);

const ContrastRadio = withStyles((theme) => ({
  root: {
    color:
      theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
  },
  checked: {},
}))(Radio);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.primary,
    border:
      theme.palette.primary.main === "#fff200" &&
      `1px solid ${theme.palette.text.primary}`,
  },
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
  },
}));

const channelOptions = [
  {
    type: "mail",
    description: "email",
  },
  {
    type: "phone",
    description: "telefon",
  },
];

let actualDate = new Date();
actualDate.setUTCHours(0);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProfilePage = ({ history }) => {
  const my = useSelector((s) => s.my);
  const globalTheme = useSelector((s) => s.globalTheme);
  const enableProfileDataEdition = useSelector(
    (s) => s.my.enableProfileDataEdition
  );
  const organization = useSelector((s) => s.organization);
  const roles = useSelector((s) => s.roles);
  const orgCurrentTerms = useSelector((s) => s.orgCurrentTerms);
  const fetchingOrgCurrentTerms = useSelector((s) => s.fetchingOrgCurrentTerms);
  const termsAcceptedByUser = useSelector((s) => s.termsAcceptedByUser);
  const orgFutureTerms = useSelector((s) => s.orgFutureTerms);
  const fetchingOrgFutureTerms = useSelector((s) => s.fetchingOrgFutureTerms);

  const isOwner = my.user.authorities.indexOf("ROLE_OWNER") !== -1;
  const isUser = my?.user.authorities.localeCompare("ROLE_USER") === 0;
  const enableEmailEdition =
    roles && roles.length === 1 && roles[0] === "ROLE_USER";

  const classes = useStyles();
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState((my && my.firstName) || "");
  const [lastName, setLastName] = useState((my && my.surname) || "");
  const [selectedGender, setSelectedGender] = useState((my && my.gender) || "");

  const [yearOfBirth, setYearOfBirth] = useState(
    (my && my.yearOfBirth?.toString()) || null
  );

  const [username, setUsername] = useState(
    (my && my.user && my.user.username) || ""
  );
  const [email, setEmail] = useState((my && my.user && my.user.email) || "");
  const avatarPath = (my && my.avatarPath) || "";
  const [newAvatarPath, setNewAvatarPath] = useState(null);
  const [avatarImage, setAvatarImage] = useState();

  const [name, setName] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [post, setPost] = useState("");
  const [phone, setPhone] = useState(null);

  const [nameFV, setNameFV] = useState("");
  const [streetFV, setStreetFV] = useState("");
  const [zipFV, setZipFV] = useState("");
  const [cityFV, setCityFV] = useState("");
  const [nipFV, setNipFV] = useState("");
  const [postFV, setPostFV] = useState("");

  const [orgName, setOrgName] = useState("");
  const [customFooter, setCustomFooter] = useState("");
  const [orgMessage, setOrgMessage] = useState("");
  const [orgCode, setOrgCode] = useState("");
  const [formDataCreatedWebhookUrl, setFormDataCreatedWebhookUrl] = useState(
    ""
  );
  const [selfRegistration, setSelfRegistration] = useState(false);
  const [userActivation, setUserActivation] = useState(false);
  const [orgCodeRequired, setOrgCodeRequired] = useState(false);
  const [busy, setBusy] = useState(false);
  const [displayHint, setDisplayHint] = useState(false);

  const [dialCode, setDialCode] = useState("48");

  const [dataProvidedValid, setDataProvidedValid] = useState(false);
  const [yearProvidedValid, setYearProvidedValid] = useState(false);
  const [emailProvidedValid, setEmailProvidedValid] = useState(true);

  const [editorsCanManageForms, setEditorsCanManageForms] = useState(false);
  const [editorsCanManageUsers, setEditorsCanManageUsers] = useState(false);
  const dispatch = useDispatch();

  const [dataChanged, setDataChanged] = useState(false);

  const [currentTermsAccepted, setCurrentTermsAccepted] = useState(false);
  const [futureTermsAccepted, setFutureTermsAccepted] = useState(false);

  const [value, setValue] = React.useState(0);

  const canUpdateOrgData = checkPermission("orgDetails:change", roles);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [deletingAvatar, setDeletingAvatar] = useState(false);
  const [deletingEmail, setDeletingEmail] = useState(false);
  //const enableProfileDataEdition = my.enableProfileDataEdition || true;

  const [preferredChannel, setPreferredChannel] = useState(
    (my && my.preferredChannel) || ""
  );
  const [enableReminders, setEnableReminders] = useState(
    my && my.enableReminders
  );

  const genderTypes = [
    {
      type: "Female",
      description: t("user_profile.personal_data.gender_female"),
    },
    {
      type: "Male",
      description: t("user_profile.personal_data.gender_male"),
    },
  ];

  const handleUploadedFiles = (files) => {
    if (files.length > 0) {
      const file = files[0];

      let reader = new FileReader();
      reader.onloadend = () => {
        setAvatarImage(file);
        setNewAvatarPath(reader.result);
        setDeletingAvatar(false);
      };

      reader.readAsDataURL(file);
    }
  };

  const removeAvatar = () => {
    setAvatarImage(null);
    setNewAvatarPath("");
    setDeletingAvatar(true);
  };

  useEffect(() => {
    if (my?.phone) {
      setPhone(my.phone);
    }
    if (organization) {
      setOrgName(organization.orgName || "");
      setCustomFooter(organization.customFooter || "");

      setOrgMessage(organization.orgMessage || "");
      setOrgCode(organization.orgCodeRegistration || "");
      setFormDataCreatedWebhookUrl(
        organization.formDataCreatedWebhookUrl || ""
      );
      setOrgCodeRequired(organization.enableCodeRegistration || false);
      setUserActivation(organization.enableUserActivation || false);
      setSelfRegistration(organization.enableSelfRegistration || false);
      setEditorsCanManageForms(organization.editorsCanManageForms || false);
      setEditorsCanManageUsers(organization.editorsCanManageUsers || false);

      if (organization.billingInfo) {
        setNameFV(organization.billingInfo.name || "");
        setStreetFV(organization.billingInfo.street || "");
        setZipFV(organization.billingInfo.zipCode || "");
        setCityFV(organization.billingInfo.city || "");
        setNipFV(organization.billingInfo.taxId || "");
        setPostFV(organization.billingInfo.post || "");
      }
    }
  }, [organization, my]);

  useEffect(() => {
    setDataProvidedValid(
      !zip || zip.length === 6
      // &&
      //   (!phone || phone.length === 12 || phone.length < dialCode.length + 2)
    );
  }, [phone, zip]);

  useEffect(() => {
    if (yearOfBirth) {
      setYearProvidedValid(
        yearOfBirth?.length === 4 &&
          yearOfBirth > 1899 &&
          yearOfBirth <= new Date().getFullYear()
      );
    } else {
      setYearProvidedValid(true);
    }
  }, [yearOfBirth]);

  useEffect(() => {
    if (email) {
      setEmailProvidedValid(testEmailPattern(email));
    } else {
      setEmailProvidedValid(true);
    }
  }, [email]);

  useEffect(() => {
    if (my) {
      if (my.address) {
        setName(my.address.name || `${my.firstName} ${my.surname}` || "");
        setStreet(my.address.street || "");
        setZip(my.address.zipCode || "");
        setCity(my.address.city || "");
        setPost(my.address.post || "");
        setPhone(my.phone || null);
      }
    }
  }, [my]);

  useEffect(() => {
    isUser &&
      !orgCurrentTerms &&
      organization &&
      !fetchingOrgCurrentTerms &&
      dispatch(fetchOrganizationCurrentTerms(organization.id));
  }, [organization?.id]);

  useEffect(() => {
    isUser &&
      !orgFutureTerms &&
      !fetchingOrgFutureTerms &&
      dispatch(fetchOrganizationFutureTerms());
  }, [isUser]);

  useEffect(() => {
    isUser && !termsAcceptedByUser && dispatch(fetchTermsAcceptedByUser());
  }, [isUser]);

  useEffect(() => {
    if (isUser && termsAcceptedByUser && orgCurrentTerms) {
      const founded = termsAcceptedByUser.find(
        ({ terms }) => terms.id === orgCurrentTerms.id
      );
      if (founded && !founded.revokedAt) {
        setCurrentTermsAccepted(true);
      } else {
        setCurrentTermsAccepted(false);
      }
    }
  }, [termsAcceptedByUser, orgCurrentTerms]);

  useEffect(() => {
    if (isUser && termsAcceptedByUser && orgFutureTerms) {
      const founded = termsAcceptedByUser.find(
        ({ terms }) => terms.id === orgFutureTerms.id
      );
      if (founded && !founded.revokedAt) {
        setFutureTermsAccepted(true);
      } else {
        setFutureTermsAccepted(false);
      }
    }
  }, [termsAcceptedByUser, orgFutureTerms]);

  const save = async () => {
    setBusy(true);

    const loginToChange =
      (my.user.email && my.user.email?.toLowerCase() !== email) ||
      (my.user.phone &&
        my.user.phone !== phone?.replace(/[^+\d]+/g, "") &&
        roles &&
        roles.length === 1 &&
        roles[0] === "ROLE_USER");

    let response = {};

    if (avatarImage || deletingAvatar) {
      response = await dispatch(
        updateMyProfileExt({
          firstName,
          surname: lastName,
          ...(yearOfBirth ? { yearOfBirth: parseInt(yearOfBirth, 10) } : {}),
          ...(selectedGender ? { gender: selectedGender } : {}),
          ...(phone && phone !== "+48"
            ? { phone: phone?.replace(/[^+\d]+/g, "") }
            : {}),
          street,
          mail: email,
          city,
          post,
          zip,
          avatarImage,
          address: {
            name,
            street,
            zipCode: zip,
            city,
            post,
            ...(phone ? { phone } : {}),
          },
          preferredChannel,
          enableReminders,
        })
      );
    } else {
      response = await dispatch(
        updateMyProfile({
          firstName,
          surname: lastName,
          ...(yearOfBirth ? { yearOfBirth: parseInt(yearOfBirth, 10) } : {}),
          ...(selectedGender ? { gender: selectedGender } : {}),
          ...(phone ? { phone: phone?.replace(/[^+\d]+/g, "") } : {}),
          street,
          mail: email,
          city,
          post,
          zip,
          avatarImage,
          address: {
            name,
            street,
            zipCode: zip,
            city,
            post,
            ...(phone ? { phone } : {}),
          },
          preferredChannel,
          enableReminders,
        })
      );
    }

    if (response.status === 200) {
      setBusy(false);

      if (loginToChange) {
        dispatch(
          alertAdd({
            text:
              "Zaktualizowano profil. Z uwagi na zmianę loginu może być konieczne ponowne zalogowanie.",
            isWarning: true,
            timeout: 4000,
          })
        );

        setTimeout(() => {
          window.location.reload();
        }, 4000);
      } else {
        setDataChanged(false);
        dispatch(
          alertAdd({ text: t("user_profile.label_updated"), isSuccess: true })
        );

        setNewAvatarPath(null);
      }
    } else if (response.data.message.includes("Phone is not unique")) {
      setBusy(false);
      dispatch(
        alertAdd({
          text:
            "Nie udało się dokonać edycji. Podany numer telefonu istnieje już w systemie.",
          isError: true,
        })
      );
    } else if (response.data.message.includes("Email is not unique")) {
      setBusy(false);
      dispatch(
        alertAdd({
          text:
            "Nie udało się dokonać edycji. Podany adres email istnieje już w systemie.",
          isError: true,
        })
      );
    } else {
      dispatch(alertAdd({ text: "Błąd aktualizacji profilu", isError: true }));
    }

    if (canUpdateOrgData) {
      const orgCodeRegistration = orgCodeRequired ? orgCode : "";

      response = await dispatch(
        updateMyOrganization({
          orgName,
          customFooter,
          orgMessage,
          orgCodeRegistration,
          enableUserActivation: userActivation,
          enableSelfRegistration: selfRegistration,
          enableCodeRegistration: orgCodeRequired,
          editorsCanManageForms,
          editorsCanManageUsers,
          billingInfo: {
            name: nameFV,
            street: streetFV,
            zipCode: zipFV,
            city: cityFV,
            post: postFV,
            taxId: nipFV,
          },
          ...(formDataCreatedWebhookUrl ? { formDataCreatedWebhookUrl } : {}),
        })
      );

      if (response.status === 200) {
        dispatch(
          alertAdd({
            text: t("org_profile.label_updated"),
            isSuccess: true,
          })
        );
      } else {
        dispatch(
          alertAdd({
            text: t("org_profile.label_updated_error"),
            isError: true,
          })
        );
      }

      setBusy(false);
    }
  };

  const handleStartDateChange = (date) => {
    setDataChanged(true);
    if (date) {
      setYearOfBirth(new Date(date).getFullYear().toString());
    } else {
      setYearOfBirth("");
    }
  };

  const isNameAutoGenerated = () => {
    return (
      name === firstName ||
      name === lastName ||
      name === "".concat(firstName, " ", lastName)
    );
  };

  const handleChangeNameInAddress = (firstName, lastName) => {
    if (!name || isNameAutoGenerated()) {
      var tmpName =
        firstName && lastName
          ? "".concat(firstName, " ", lastName)
          : firstName
          ? firstName
          : lastName;
      setName(tmpName);
    }
  };

  const notifications = () => (
    <Paper className={classes.paper}>
      <Typography variant="h6">Powiadomienia:</Typography>
      <br />
      Wybierz kanał komunikacji:
      <br />
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="preferred-channel"
          name="controlled-radio-buttons-group"
        >
          {channelOptions.map((channel) => {
            var disabled;
            if (channel.type === "phone") {
              disabled = !phone;
            } else if (channel.type === "mail") {
              disabled = !email;
            }
            return disabled ? (
              <Tooltip
                key={channel.type}
                title={`Uzupełnij swój ${channel.description}, aby wybrać ten rodzaj komunikacji.`}
                className={classes.fontSize}
              >
                <FormControlLabel
                  value={channel.type}
                  control={
                    <ContrastRadio
                      disabled={disabled || !enableProfileDataEdition}
                      checked={preferredChannel === channel.type}
                      onClick={() => {
                        setDataChanged(true);
                        setPreferredChannel(channel.type);
                      }}
                    />
                  }
                  label={channel.description}
                />
              </Tooltip>
            ) : (
              <FormControlLabel
                key={channel.type}
                value={channel.type}
                control={
                  <ContrastRadio
                    disabled={disabled || !enableProfileDataEdition}
                    checked={preferredChannel === channel.type}
                    onClick={() => {
                      setDataChanged(true);
                      setPreferredChannel(channel.type);
                    }}
                  />
                }
                label={channel.description}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
      <br />
      <br />
      {my.user.authorities.localeCompare("ROLE_USER") === 0 && (
        <>
          <div>
            Czy chcesz otrzymywać ewentualne przypomnienia odnośnie wypełnień
            formularzy?
          </div>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="enable-reminders"
              name="controlled-radio-buttons-group"
            >
              <FormControlLabel
                value={true}
                control={
                  <ContrastRadio
                    disabled={!enableProfileDataEdition}
                    checked={enableReminders}
                    onClick={() => {
                      setDataChanged(true);
                      setEnableReminders(true);
                    }}
                  />
                }
                label={"tak"}
              />
              <FormControlLabel
                value={false}
                control={
                  <ContrastRadio
                    disabled={!enableProfileDataEdition}
                    checked={!enableReminders}
                    onClick={() => {
                      setDataChanged(true);
                      setEnableReminders(false);
                    }}
                  />
                }
                label={"nie"}
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
    </Paper>
  );

  const page = () => (
    <>
      <div className={classes.root}>
        <Prompt
          key="block-nav"
          when={dataChanged}
          message="Edytowane dane nie zostały zapisane, czy mimo to chcesz opuścić stronę?"
        />{" "}
        {canUpdateOrgData && (
          <AppBar
            position="static"
            style={{ backgroundColor: "#e3e5e6", color: "black" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label={t("user_profile.label")} {...a11yProps(0)} />
              <Tab label={t("org_profile.label")} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
        )}
        <TabPanel value={value} index={0}>
          <PageTitle title={t("user_profile.label")} />
          <div className={classes.root}>
            {" "}
            <Box>
              {" "}
              <Box display="flex" flexDirection="row" p={1}>
                {enableEmailEdition && (
                  <Box
                    p={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Tooltip
                      title={t("user_profile.id")}
                      className={classes.fontSize}
                    >
                      <span
                        style={{
                          // fontSize: "0.8rem",
                          lineHeight: "1.4",
                          color:
                            globalTheme === "high-contrast"
                              ? "#fff200"
                              : "#999",
                        }}
                      >
                        {my?.id}
                      </span>
                    </Tooltip>
                  </Box>
                )}
              </Box>
              <Box p={1}>
                {" "}
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={8} lg={6}>
                    <Paper className={classes.paper}>
                      <Typography variant="h6">
                        {t("user_profile.personal_data.label")}
                      </Typography>
                      <br />

                      <Grid item container sm={12} lg={8} xl={6} spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                            label={t("user_profile.personal_data.first_name")}
                            variant="outlined"
                            disabled={!enableProfileDataEdition}
                            value={firstName}
                            fullWidth
                            onChange={({ target: { value: firstNameTmp } }) => {
                              setDataChanged(true);
                              setFirstName(firstNameTmp);
                              handleChangeNameInAddress(firstNameTmp, lastName);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                            label={t("user_profile.personal_data.surname")}
                            variant="outlined"
                            disabled={!enableProfileDataEdition}
                            value={lastName}
                            fullWidth
                            onChange={({ target: { value: lastNameTmp } }) => {
                              setDataChanged(true);
                              setLastName(lastNameTmp);
                              handleChangeNameInAddress(firstName, lastNameTmp);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <MuiPickersUtilsProvider
                            locale={plLocale}
                            utils={DateFnsUtils}
                          >
                            <KeyboardDatePicker
                              InputLabelProps={{
                                className: classes.floatingLabelFocusStyle,
                              }}
                              style={{ color: "red" }}
                              className={classes.textFieldCSS}
                              disabled={!enableProfileDataEdition}
                              id="start-date-picker-dialog"
                              label={t("user_profile.personal_data.year_birth")}
                              format="yyyy"
                              value={yearOfBirth}
                              fullWidth
                              inputVariant="outlined"
                              onChange={handleStartDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              cancelLabel={t("cancel")}
                              okLabel={t("submit")}
                              views={["year"]}
                              invalidDateMessage={
                                yearOfBirth?.length > 0 &&
                                "Nieprawidłowy format"
                              }
                              maxDateMessage="Błędny rok urodzenia"
                              minDateMessage="Błędny rok urodzenia"
                              maxDate={actualDate}
                              keyboardIcon={
                                <EventIcon
                                  style={{
                                    color:
                                      globalTheme === "high-contrast" &&
                                      "#fff200",
                                  }}
                                />
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="select-outlined-label">
                              {t("user_profile.personal_data.gender")}
                            </InputLabel>
                            <Select
                              labelId="select-outlined-label"
                              id="select-outlined"
                              disabled={!enableProfileDataEdition}
                              fullWidth
                              value={selectedGender}
                              onChange={(event) => {
                                setDataChanged(true);
                                setSelectedGender(event.target.value);
                              }}
                              label={t("user_profile.personal_data.gender")}
                            >
                              {genderTypes.map((genderType, index) => {
                                return (
                                  <MenuItem key={index} value={genderType.type}>
                                    {genderType.description}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                            label={t("user_profile.personal_data.username")}
                            variant="outlined"
                            disabled
                            fullWidth
                            value={username}
                            onChange={({ target: { value: username } }) => {
                              setDataChanged(true);
                              setUsername(username);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            InputLabelProps={{
                              className: classes.floatingLabelFocusStyle,
                            }}
                            label="Email"
                            variant="outlined"
                            disabled={
                              !enableProfileDataEdition || !enableEmailEdition
                            }
                            value={email}
                            fullWidth
                            onChange={({ target: { value: email } }) => {
                              setDataChanged(true);
                              !email && setDeletingEmail(true);
                              setEmail(email);
                            }}
                            error={!emailProvidedValid}
                            helperText={
                              deletingEmail && preferredChannel === "mail"
                                ? "Preferowany kanał do komunikacji zostanie zmieniony na telefon."
                                : !emailProvidedValid
                                ? "Nieprawidłowy format"
                                : ""
                            }
                          />
                        </Grid>
                        <AvatarUpload
                          avatarPath={avatarPath}
                          newAvatarPath={newAvatarPath}
                          enableProfileDataEdition={enableProfileDataEdition}
                          setDataChanged={setDataChanged}
                          handleUploadedFiles={handleUploadedFiles}
                          removeAvatar={removeAvatar}
                          deletingAvatar={deletingAvatar}
                          t={t}
                        />
                        <Grid item xs={12}>
                          {" "}
                          <a
                            href="#"
                            className={`${
                              globalTheme === "high-contrast" &&
                              classes.aalertHighContrast
                            } ${classes.fontSize}`}
                            onClick={async () => {
                              const response = await dispatch(
                                recoverPassword({
                                  ...(email && { email }),
                                  ...(phone &&
                                    my.user.authorities.localeCompare(
                                      "ROLE_STUD_OWNER"
                                    ) === 0 && { phone }),
                                  ...(my?.user.authorities && {
                                    role:
                                      my.user.authorities.localeCompare(
                                        "ROLE_STUD_OWNER"
                                      ) === 0
                                        ? "patient"
                                        : "employee",
                                  }),
                                })
                              );
                              if (response.status === 200) {
                                dispatch(
                                  alertAdd({
                                    text: t("recovery.link_sent"),
                                  })
                                );
                              } else {
                                dispatch(
                                  alertAdd({
                                    text: "recovery.link_sent_error",
                                    isError: true,
                                  })
                                );
                              }
                            }}
                          >
                            {t("user_profile.change_password")}
                          </a>
                        </Grid>
                        {isUser && orgCurrentTerms && currentTermsAccepted && (
                          <TermsAcceptance
                            terms={orgCurrentTerms}
                            acceptance={false}
                            future={false}
                          ></TermsAcceptance>
                        )}
                        {isUser && orgFutureTerms && futureTermsAccepted && (
                          <TermsAcceptance
                            terms={orgFutureTerms}
                            acceptance={false}
                            future={true}
                          ></TermsAcceptance>
                        )}
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={8} lg={6}>
                    <Paper className={classes.paper}>
                      <Typography variant="h6">
                        {t("user_profile.address_data.label")}
                      </Typography>
                      <br />

                      <ShippingAddressForm
                        provideShippingData={true}
                        name={name || ""}
                        setName={setName}
                        street={street}
                        setStreet={setStreet}
                        city={city}
                        setCity={setCity}
                        zip={zip}
                        setZip={setZip}
                        post={post}
                        setPost={setPost}
                        phone={phone}
                        setPhone={setPhone}
                        dialCode={dialCode}
                        setDialCode={setDialCode}
                        enableProfileDataEdition={enableProfileDataEdition}
                        setDataChanged={setDataChanged}
                        preferredChannel={preferredChannel}
                      />
                    </Paper>
                  </Grid>
                  {/* {!isOwner && (
                    <Grid item xs={12} sm={8} lg={6}>
                      {notifications()}
                    </Grid>
                  )} */}

                  <Grid item xs={12}>
                    <Button
                      className={classnames(
                        "button is-primary",
                        {
                          "is-loading": busy,
                        },
                        classes.fontSize
                      )}
                      type="submit"
                      variant={"contained"}
                      style={{
                        backgroundColor:
                          globalTheme === "high-contrast" ? "yellow" : "blue",
                        color:
                          globalTheme === "high-contrast" ? "black" : "white",
                        border:
                          globalTheme === "high-contrast" && "1px solid yellow",
                      }}
                      disabled={
                        !enableProfileDataEdition ||
                        !dataProvidedValid ||
                        !yearProvidedValid ||
                        !emailProvidedValid ||
                        (!phone && !email)
                      }
                      onClick={() => {
                        if (orgCodeRequired === false || orgCode.length >= 8) {
                          save();
                          setDisplayHint(false);
                        } else {
                          setDisplayHint(true);
                        }
                      }}
                    >
                      {t("save")}
                    </Button>
                    <Button
                      // className=
                      className={classes.fontSize}
                      type="cancel"
                      variant={"contained"}
                      style={{
                        marginLeft: "10px",
                        backgroundColor:
                          globalTheme === "high-contrast"
                            ? "black"
                            : "lightgrey",
                        color:
                          globalTheme === "high-contrast" ? "yellow" : "black",
                        border:
                          globalTheme === "high-contrast" && "1px solid yellow",
                      }}
                      onClick={() => history.push("/")}
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        </TabPanel>
        <Can
          permission="orgDetails:change"
          ok={() => (
            <TabPanel value={value} index={1}>
              <PageTitle title={t("org_profile.label")} />
              <div className={classes.root}>
                {" "}
                <Box>
                  {" "}
                  <Box display="flex" flexDirection="row" p={1}></Box>
                  <Box p={1}>
                    {" "}
                    <Grid container spacing={3}>
                      <Can
                        permission="orgDetails:change"
                        ok={() => (
                          <Grid item xs={12} sm={8} lg={6}>
                            <Paper className={classes.paper}>
                              <Typography variant="h6">
                                {t("org_profile.org_data.label")}
                              </Typography>
                              <br />
                              <Grid
                                item
                                container
                                lg={12}
                                xl={8}
                                spacing={1}
                                direction="column"
                              >
                                <Grid item xs={12}>
                                  <TextField
                                    label={t("org_profile.org_data.name")}
                                    variant="outlined"
                                    fullWidth
                                    value={orgName || ""}
                                    onChange={({
                                      target: { value: orgName },
                                    }) => {
                                      setDataChanged(true);
                                      setOrgName(orgName);
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    label={t(
                                      "org_profile.org_data.footer_email"
                                    )}
                                    variant="outlined"
                                    fullWidth
                                    value={customFooter}
                                    onChange={({
                                      target: { value: customFooter },
                                    }) => {
                                      setDataChanged(true);
                                      setCustomFooter(customFooter);
                                    }}
                                  />
                                </Grid>
                                {/* <Grid item xs={12}>
                                  <FormControlLabel
                                    style={{
                                      color:
                                        globalTheme === "high-contrast" &&
                                        "#fff200",
                                    }}
                                    control={
                                      <ContrastCheckbox
                                        checked={selfRegistration || false}
                                        name="checkedB"
                                        color="primary"
                                        onChange={(e) => {
                                          setDataChanged(true);
                                          setSelfRegistration(
                                            !selfRegistration
                                          );
                                          selfRegistration &&
                                            setUserActivation(false);
                                          selfRegistration &&
                                            setOrgCodeRequired(false);
                                        }}
                                      />
                                    }
                                    label={t(
                                      "org_profile.org_data.self_registration"
                                    )}
                                  />
                                </Grid> */}
                                {/* <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <ContrastCheckbox
                                        checked={userActivation || false}
                                        name="checkedB"
                                        color="primary"
                                        onChange={() => {
                                          setDataChanged(true);
                                          setUserActivation(!userActivation);
                                        }}
                                        disabled={!selfRegistration}
                                      />
                                    }
                                    label={t(
                                      "org_profile.org_data.activation_registration"
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <ContrastCheckbox
                                        checked={orgCodeRequired || false}
                                        name="checkedB"
                                        color="primary"
                                        onChange={() => {
                                          setDataChanged(true);
                                          setOrgCodeRequired(!orgCodeRequired);
                                          setOrgCode("");
                                        }}
                                        disabled={!selfRegistration}
                                      />
                                    }
                                    label={t(
                                      "org_profile.org_data.code_registration"
                                    )}
                                  />
                                </Grid>
                                {orgCodeRequired && selfRegistration && (
                                  <Grid item xs={12}>
                                    <TextField
                                      label="Kod dołączenia do organizacji"
                                      variant="outlined"
                                      fullWidth
                                      value={orgCode}
                                      onChange={({ target: { value } }) => {
                                        setDataChanged(true);
                                        setOrgCode(value);
                                      }}
                                      disabled={!selfRegistration}
                                    />
                                  </Grid>
                                )}
                                {displayHint && selfRegistration && (
                                  <span className={s.formHint}>
                                    Kod musi zawierać co najmniej 8 znaków.
                                  </span>
                                )}
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <ContrastCheckbox
                                        checked={editorsCanManageForms || false}
                                        name="checkedB1"
                                        color="primary"
                                        onChange={() => {
                                          setDataChanged(true);
                                          setEditorsCanManageForms(
                                            !editorsCanManageForms
                                          );
                                        }}
                                      />
                                    }
                                    label={t(
                                      "org_profile.org_data.additonal_right_form"
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControlLabel
                                    control={
                                      <ContrastCheckbox
                                        checked={editorsCanManageUsers || false}
                                        name="checkedB1"
                                        color="primary"
                                        onChange={() => {
                                          setDataChanged(true);
                                          setEditorsCanManageUsers(
                                            !editorsCanManageUsers
                                          );
                                        }}
                                      />
                                    }
                                    label={t(
                                      "org_profile.org_data.additonal_right_users"
                                    )}
                                  />
                                </Grid> */}

                                {/* {
                                  <Can
                                    permission="apikeys"
                                    ok={() => (
                                      <Grid item xs={12}>
                                        <NavLink
                                          className={
                                            globalTheme === "high-contrast"
                                              ? classes.aalertHighContrast
                                              : undefined
                                          }
                                          to="/profile/api-keys"
                                        >
                                          {t("org_profile.org_data.api_keys")}
                                        </NavLink>
                                      </Grid>
                                    )}
                                  />
                                } */}
                                {/* {
                                  <Can
                                    permission="webhooks"
                                    ok={() => (
                                      <Grid item xs={12}>
                                        <TextField
                                          label={t(
                                            "org_profile.org_data.webhook"
                                          )}
                                          variant="outlined"
                                          fullWidth
                                          value={formDataCreatedWebhookUrl}
                                          onChange={({ target: { value } }) => {
                                            setDataChanged(true);
                                            setFormDataCreatedWebhookUrl(value);
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  />
                                } */}
                              </Grid>
                            </Paper>
                          </Grid>
                        )}
                      />
                      {/* <Can
                        permission="orgDetails:change"
                        ok={() => (
                          <Grid item xs={12} sm={8} lg={6}>
                            <Paper className={classes.paper}>
                              <br />
                              <OrganizationTerms
                                history={history}
                                t={t}
                              ></OrganizationTerms>
                            </Paper>
                          </Grid>
                        )}
                      /> */}

                      <Grid item xs={12}>
                        <Button
                          className={classnames(
                            "button is-primary",
                            {
                              "is-loading": busy,
                            },
                            classes.fontSize
                          )}
                          type="submit"
                          variant={"contained"}
                          style={{
                            backgroundColor:
                              globalTheme === "high-contrast"
                                ? "yellow"
                                : "blue",
                            color:
                              globalTheme === "high-contrast"
                                ? "black"
                                : "white",
                            border:
                              globalTheme === "high-contrast" &&
                              "1px solid yellow",
                          }}
                          disabled={
                            !enableProfileDataEdition ||
                            !dataProvidedValid ||
                            !yearProvidedValid ||
                            (!phone && !email)
                          }
                          onClick={() => {
                            if (
                              orgCodeRequired === false ||
                              orgCode.length >= 8
                            ) {
                              save();
                              setDisplayHint(false);
                            } else {
                              setDisplayHint(true);
                            }
                          }}
                        >
                          {t("save")}
                        </Button>
                        <Button
                          className={classes.fontSize}
                          type="cancel"
                          variant={"contained"}
                          style={{
                            marginLeft: "10px",
                            backgroundColor:
                              globalTheme === "high-contrast"
                                ? "black"
                                : "lightgrey",
                            color:
                              globalTheme === "high-contrast"
                                ? "yellow"
                                : "black",
                            border:
                              globalTheme === "high-contrast" &&
                              "1px solid yellow",
                          }}
                          onClick={() => history.push("/")}
                        >
                          {t("cancel")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </div>
            </TabPanel>
          )}
        />
      </div>
      <ApiVersionSpan />
    </>
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="profilePage:view" ok={page} not={redirect} />;
};

export default ProfilePage;
