import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useDispatch, useSelector } from "react-redux";
import {
  alertAdd,
  contextChange,
  fetchUsersByStudContext,
} from "../../redux/actions";
import { checkContext } from "../../redux/actions";
import { Typography, makeStyles } from "@material-ui/core";
import styled from "styled-components";
const contextList = [
  { id: "39b8a777-0081-4867-b202-f8b3ced8ded7", label: "stadnina 1" },
  { id: "39b8a777-0081-4867-b202-f8b3ced8ded7", label: "stadnina 2" },
];

const useStyles = makeStyles((theme) => ({
  trimTypo: {
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "215px",
    },
    "@media (max-width:800px)": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "175px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
    "@media (max-width:400px)": {
      width: "120px",
    },
    "@media (max-width:400px)": {
      width: "80px",
    },
    "@media (max-width:350px)": {
      width: "40px",
    },
  },
}));

const ContextSelect = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentContext = useSelector((s) => s.currentContext);
  const allStudsContext = useSelector((s) => s.allStudsContext);
  // const [context, setContext] = useState(currentContext);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [currentContextName, setCurrentContextName] = useState("");

  useEffect(() => {
    (async () => {
      if (currentContext && allStudsContext.length) {
        setCurrentContextName(
          allStudsContext.find((c) => c.id === currentContext.studId)?.name
        );
      }
      if (!currentContext.studId && allStudsContext.length) {
        const r = await dispatch(contextChange(allStudsContext[0].id));
        if (r) {
          await dispatch(checkContext());
        }
      }
    })();
  }, [currentContext, allStudsContext]);

  const submit = async (id) => {
    const r = await dispatch(contextChange(id));
    setMenuAnchor(null);
    if (r) {
      await dispatch(checkContext());
    }

    await dispatch(
      alertAdd({
        text: r
          ? t("header.context.change_succes")
          : t("header.context.change_error"),
        isSuccess: r,
        isError: !r,
      })
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        style={{ color: "white" }}
      >
        {/* {currentContextName || ""} */}

        <Typography className={classes.trimTypo}>
          {currentContextName || ""}
        </Typography>

        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List>
            <ListSubheader>{t("header.context.label")}</ListSubheader>
            {allStudsContext.map((context) => (
              <ListItem
                button
                key={context.id}
                onClick={() => submit(context.id)}
              >
                {context.name}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default ContextSelect;
