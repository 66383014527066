import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Can from "../../components/can";
import { Redirect, Prompt } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@material-ui/core/Box";
import { PersonForm } from "../../components/person-form";
import Typography from "@material-ui/core/Typography";
import {
  editUser,
  alertAdd,
  fetchMatchingUsers,
  fetchOrgUsers,
} from "../../redux/actions";
import { normalizeZIP } from "../../components/address-form/shipping-info";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";
import { useTranslation } from "react-i18next";

const EditPerson = ({ editUser, history, persons, match: { params } }) => {
  const organization = useSelector((s) => s.organization);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [userToEdit, setUserToEdit] = useState({});
  const [busy, setBusy] = useState(false);

  const [openFormEditionPanel, setOpenFormEditionPanel] = useState(false);

  const [openConfirmPanel, setOpenConfirmPanel] = useState(false);
  const [editedPerson, setEditedPerson] = useState({});

  const [isPhoneInvalid, setIsPhoneInvalid] = useState(false);
  const [isMailInvalid, setIsMailInvalid] = useState(false);
  const [checked, setChecked] = useState(true);
  const [checkIfExist, setCheckIfExist] = useState(false);
  const [
    isHospitalIdentifierInvalid,
    setIsHospitalIdentifierInvalid,
  ] = useState(false);

  const [dataToCheck, setDataToCheck] = useState({});

  const [valid, setValid] = useState(
    userToEdit?.mail || userToEdit?.phone ? true : false
  );

  const dataIsUsed = async (value, fieldProperty) => {
    if (userToEdit.role !== "") {
      if (fieldProperty === "mail") {
        setDataToCheck({
          ...dataToCheck,
          ...(value || value === "" ? { email: value } : {}),
        });
      } else {
        setDataToCheck({
          ...dataToCheck,
          ...(value || value === "" ? { [fieldProperty]: value } : {}),
        });
      }
    }
  };

  useEffect(() => {
    // console.log();
    // if (
    //   JSON.stringify(userToEdit) !==
    //   JSON.stringify(persons?.find((p) => p.id === params.personId))
    // ) {
    //   setChecked(false);
    // }
    if (userToEdit) {
      const p = persons && persons.find((p) => p.id === params.personId);
      if (
        userToEdit?.mail !== p?.mail ||
        userToEdit?.hospitalIdentifier !== p?.hospitalIdentifier ||
        userToEdit?.phone !== p?.phone
      ) {
        setChecked(false);
        setCheckIfExist(true);
      } else {
        setChecked(true);
        setCheckIfExist(false);
      }
      if (!(userToEdit?.address?.zipCode.length > 0)) {
        setValid(
          (
            userToEdit.phone
              ? userToEdit.phone.length > 3 || userToEdit.phone.length < 4
              : userToEdit.mail
          )
            ? true
            : false
        );
      } else {
        setValid(
          userToEdit.mail ||
            (userToEdit.phone && userToEdit.phone.length === 15)
            ? true
            : false
        );

        setValid(
          (userToEdit.mail || userToEdit.phone) &&
            userToEdit.address.zipCode.length === 6
            ? true
            : false
        );
      }
    }
  }, [userToEdit]);

  useEffect(() => {
    async function fetchMatchingUser() {
      if (
        dataToCheck &&
        !(Object.keys(dataToCheck).length === 0) &&
        checkIfExist
      ) {
        const response = await dispatch(
          fetchMatchingUsers({
            ...(userToEdit.phone !== dataToCheck.phone
              ? {
                  phone: dataToCheck.phone,
                }
              : {}),
            ...(userToEdit.mail !== dataToCheck.email
              ? {
                  email: dataToCheck.email,
                }
              : {}),
            ...(userToEdit.hospitalIdentifier !== dataToCheck.hospitalIdentifier
              ? {
                  hospitalIdentifier: dataToCheck.hospitalIdentifier,
                }
              : {}),
          })
        );
        if (response.status === 200 && response.data.length !== 0) {
          setOpenConfirmPanel(true);
          setEditedPerson(response.data[0]);
          setValid(false);
          setChecked(true);
        } else if (response.status === 409) {
          setIsMailInvalid(response.data.message.includes("email-exists"));
          setIsHospitalIdentifierInvalid(
            response.data.message.includes("hospital-identifier")
          );
          setIsPhoneInvalid(response.data.message.includes("phone-exists"));
          setChecked(true);
        } else if (response.status === 200 && response.data.length === 0) {
          setIsMailInvalid(false);
          setIsPhoneInvalid(false);
          setIsHospitalIdentifierInvalid(false);
          setChecked(true);
        }
      }
      if (dataToCheck?.phone === "+48") {
        setIsPhoneInvalid(false);
      }
    }
    fetchMatchingUser();
  }, [dataToCheck]);

  useEffect(() => {
    if (organization?.editorsCanManageUsers) {
      dispatch(fetchOrgUsers());
    } else if (organization?.editorsCanManageUsers === false) {
      history.push("/");
    }
  }, [organization]);

  useEffect(() => {
    persons && setUserToEdit(persons.find((p) => p.id === params.personId));
  }, [persons]);

  const profileDataChange = (value, user, fieldProperty) => {
    setUserToEdit({
      ...userToEdit,
      [fieldProperty]: value,
      ...(fieldProperty === "phone" && {
        address: {
          ...userToEdit.address,
          phone: value,
        },
      }),
    });
  };

  const addressDataChange = (value, user, fieldProperty) => {
    setUserToEdit({
      ...userToEdit,
      address: {
        ...userToEdit.address,
        [fieldProperty]:
          fieldProperty.localeCompare("zipCode") === 0
            ? normalizeZIP(value, userToEdit.address.zipCode)
            : value,
      },
    });
  };

  const handleChecbox = (event, user) => {
    setUserToEdit({
      ...userToEdit,
      [event.target.name]: event.target.checked,
    });
  };

  const editUserHandler = async (e) => {
    if (
      dataToCheck &&
      !(Object.keys(dataToCheck).length === 0) &&
      checkIfExist
    ) {
      const response = await dispatch(fetchMatchingUsers(dataToCheck));
      if (response.status === 200 && response.data.length !== 0) {
        setOpenConfirmPanel(true);
        setEditedPerson(response.data[0]);
        setValid(false);
        setChecked(true);
      } else if (response.status === 409) {
        setIsMailInvalid(response.data.message.includes("email-exists"));
        setIsHospitalIdentifierInvalid(
          response.data.message.includes("hospital-identifier")
        );
        setIsPhoneInvalid(response.data.message.includes("phone-exists"));
        setChecked(true);
      } else if (response.status === 200 && response.data.length === 0) {
        setIsMailInvalid(false);
        setIsPhoneInvalid(false);
        setIsHospitalIdentifierInvalid(false);
        setChecked(true);
      }
    }
    if (userToEdit) {
      setBusy(true);

      const response = await editUser({
        ...userToEdit,
        phone: userToEdit.phone?.replace(/[^+\d]+/g, ""),
      });
      const message = response.data.message;

      if (response.status === 200) {
        dispatch(
          alertAdd({
            text: "Edycja użytkownika przebiegła pomyślnie.",
            isSuccess: true,
          })
        );
        setUserToEdit(null);
      } else if (message.includes("Phone is not unique")) {
        dispatch(
          alertAdd({
            text:
              "Nie udało się dokonać edycji użytkownika. Podany numer telefonu istnieje już w systemie.",
            isError: true,
          })
        );
      } else if (message === "hospital-identifier-exists") {
        dispatch(
          alertAdd({
            text: "Wprowadzony identyfikator już istnieje",
            isError: true,
          })
        );
      } else if (message === "hospital-identifier-empty") {
        dispatch(
          alertAdd({
            text: "Wprowadzony identyfikator jest pusty",
            isError: true,
          })
        );
      }

      setBusy(false);
      history.goBack();
    }
  };

  const redirect = () => <Redirect to="/login" />;

  const page = () =>
    organization ? (
      <Box p={1}>
        <Grid container spacing={3}>
          {userToEdit && (
            <ConfirmDialogMUI
              handleClose={() => {
                setOpenFormEditionPanel(false);
              }}
              open={openFormEditionPanel}
              text={`Czy na pewno edytować użytkownika ${userToEdit.firstName} ${userToEdit.surname} (${userToEdit.mail})?`}
              yesAction={editUserHandler}
              noAction={() => setOpenFormEditionPanel(false)}
            />
          )}
          {
            <ConfirmDialogMUI
              handleClose={() => {
                setOpenConfirmPanel(false);
              }}
              open={openConfirmPanel && !openFormEditionPanel}
              text={t("user_exists.label")}
              yesAction={() => {
                history.push(`/persons/${editedPerson.id}`);
                setUserToEdit(editedPerson);
              }}
              noAction={() => setOpenConfirmPanel(false)}
            />
          }
          <Prompt
            when={
              userToEdit &&
              persons.find((p) => p.id === params.personId) !== userToEdit
            }
            message="Edytowane dane nie zostały zapisane, czy mimo to chcesz opuścić stronę?"
          />
          {userToEdit && (
            <>
              <Grid item xs={12}>
                <Typography
                  style={{ marginLeft: 15, fontWeight: "bold" }}
                  variant="h6"
                >
                  Panel edycji
                </Typography>
                {userToEdit?.user?.authorities === "ROLE_USER" && (
                  <Box
                    p={1}
                    display="flex"
                    justifyContent="start"
                    alignItems="start"
                  >
                    <Tooltip title={"Identyfikator systemowy"}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          lineHeight: "1.4",
                          color: "#999",
                          marginLeft: 15,
                        }}
                      >
                        {userToEdit?.id}
                      </span>
                    </Tooltip>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <PersonForm
                  data={userToEdit}
                  profileDataChangeHandler={profileDataChange}
                  addressDataChangeHandler={addressDataChange}
                  handleChecbox={handleChecbox}
                  dataIsUsed={dataIsUsed}
                  edit={true}
                  isPhoneInvalid={isPhoneInvalid}
                  isMailInvalid={isMailInvalid}
                  isHospitalIdentifierInvalid={isHospitalIdentifierInvalid}
                />

                <Box mt={1} display="center">
                  <Button
                    variant={"contained"}
                    color="primary"
                    disabled={
                      !checked ||
                      !valid ||
                      isPhoneInvalid ||
                      isMailInvalid ||
                      isHospitalIdentifierInvalid
                    }
                    onClick={() => {
                      setOpenFormEditionPanel(true);
                    }}
                  >
                    Edytuj
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    Anuluj
                  </Button>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    ) : (
      <div></div>
    );

  return <Can permission="editor:editPerson" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  persons: state.orgUsers,
  roles: state.roles,
});

const mapDispatchToProps = (dispatch) => ({
  editUser: (user) => dispatch(editUser(user)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditPerson);
