import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Modal,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DividerWithText from './divider.js';
import Loader from '../../components/loader/index.js';
import { createGlobalStyle } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  alertAdd,
  fetchHorse,
  fetchHorseNotifications,
  updateHorseNotifications,
} from '../../redux/actions/index.js';

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: 'auto',
    maxHeight: '90vh',
    minWidth: '50%',
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    // minWidth: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    minWidth: '400px',
  },
  horseImg: {
    minWidth: '300px',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
      maxWidth: '100$',
    },
  },
}));

const NotificationPanel = ({ open, hide, id }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const { t } = useTranslation();

  const notifications = useSelector((s) => s.notifications);

  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);

  const [isMare, setIsMare] = useState(false);

  const [anomalyNotifications, setAnomalyNotifications] = useState({});
  const [alertNotifications, setAlertNotifications] = useState({});

  useEffect(() => {
    if (!id) return;
    (async () => {
      try {
        setFetchingData(true);

        const [{ gender }] = await Promise.all([
          dispatch(fetchHorse(id)),
          dispatch(fetchHorseNotifications(id)),
        ]);

        setFetchingData(false);
        setIsMare(gender === 'Mare');
      } catch (e) {
        setFetchingData(false);
        dispatch(
          alertAdd({
            text: t('add_horse_farm.error'),
            isError: true,
          })
        );
        hide();
      }
    })();
  }, [id]);

  useEffect(() => {
    if (!Object.keys(notifications).length) return;

    setAlertNotifications(filterByKey(notifications, 'alert'));
    setAnomalyNotifications(filterByKey(notifications, 'anomaly'));
  }, [notifications]);

  const filterByKey = function (o, filterQuery) {
    return Object.keys(o)
      .filter((key) => key.includes(filterQuery))
      .reduce((obj, key) => {
        obj[key] = o[key];
        return obj;
      }, {});
  };

  const handleAnomalyChange = function (e) {
    const { name, checked } = e.target;

    setAnomalyNotifications({ ...anomalyNotifications, [name]: checked });
  };

  const handleAlertChange = function (e) {
    const { name, checked } = e.target;

    setAlertNotifications({ ...alertNotifications, [name]: checked });
  };

  const handleSave = async function () {
    try {
      setBusy(true);
      const payload = { ...anomalyNotifications, ...alertNotifications };
      const diff = getDifference(notifications, payload);

      if (Object.keys(diff).length)
        await dispatch(updateHorseNotifications(id, { push: diff }));
      setBusy(false);
      dispatch(
        alertAdd({
          text: t('notifications.saved_data'),
          isSuccess: true,
        })
      );
      hide();
    } catch (e) {
      setBusy(false);
      dispatch(
        alertAdd({
          text: t('add_horse_farm.error'),
          isError: true,
        })
      );
    }
  };

  const getDifference = function (o1, o2) {
    return Object.keys(o2).reduce((diff, key) => {
      if (o1[key] === o2[key]) return diff;
      return {
        ...diff,
        [key]: o2[key],
      };
    }, {});
  };

  const alertToMap = {
    1: {
      label: t('notifications.alerts.foaling'),
      id: 'alertFoaling',
    },
  };

  const anomalyToMap = {
    1: {
      label: t('notifications.anomalies.lie_down_first'),
      id: 'anomalyLieDownFirst',
    },
    2: {
      label: t('notifications.anomalies.next_lie_down'),
      id: 'anomalyLieDownNot',
    },
    3: {
      label: t('notifications.anomalies.frequency_of_lie_down'),
      id: 'anomalyLieDownOften',
    },
    4: {
      label: t('notifications.anomalies.shortened_lie_down_break'),
      id: 'anomalyLieDownShortBreak',
    },
    5: {
      label: t('notifications.anomalies.brief_lie_down'),
      id: 'anomalyLieDownShortLyingTime',
    },
    6: {
      label: t('notifications.anomalies.mobility'),
      id: 'anomalyMovementOften',
    },
  };
  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={open}
      onClose={hide}
    >
      <Box style={modalStyle} className={classes.paper}>
        {fetchingData || busy ? (
          <Box p={1}>
            <Loader
              loading={true}
              text={t(busy ? 'notifications.saving_data' : 'fetching')}
            />
          </Box>
        ) : (
          <Box>
            <DividerWithText>{t('notifications.title')}</DividerWithText>
            {isMare ? (
              <>
                <Box
                  component='fieldset'
                  p={1}
                  style={{
                    border: '1px solid lightgray',
                    borderRadius: '5px',
                    paddingLeft: '16px',
                  }}
                >
                  <legend style={{ color: 'gray', fontSize: '0.85rem' }}>
                    {t('notifications.alerts.enable')}
                  </legend>
                  <FormControl component='fieldset'>
                    <FormGroup>
                      {Object.values(alertToMap).map(({ label, id }) => (
                        <FormControlLabel
                          key={id}
                          control={
                            <Checkbox
                              checked={alertNotifications[id] || false}
                              color={'primary'}
                              onChange={handleAlertChange}
                              name={id}
                            />
                          }
                          label={label}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Box>
                <br />
              </>
            ) : null}
            <Box
              component='fieldset'
              p={1}
              style={{
                border: '1px solid lightgray',
                borderRadius: '5px',
                paddingLeft: '16px',
              }}
            >
              <legend style={{ color: 'gray', fontSize: '0.85rem' }}>
                {t('notifications.anomalies.enable')}
              </legend>

              {Object.values(anomalyToMap).map(({ label, id }) => (
                <Box>
                  <FormControlLabel
                    key={id}
                    control={
                      <Checkbox
                        checked={anomalyNotifications[id] || false}
                        color={'primary'}
                        onChange={handleAnomalyChange}
                        name={id}
                      />
                    }
                    label={label}
                  />
                </Box>
              ))}
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                color='primary'
                size='small'
                variant='contained'
                style={{ margin: '8px 16px', padding: '8px 16px' }}
                onClick={handleSave}
              >
                {t('save')}
              </Button>
              <Button
                size='small'
                variant='contained'
                style={{ margin: '8px 16px', padding: '8px 16px' }}
                onClick={hide}
              >
                {t('close')}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default NotificationPanel;
