import React, { useEffect, useState } from "react";
import {
  alertAdd,
  register_organization,
  join_organization,
  fetchOrgNames,
} from "../../redux/actions";
import { fetchOrganizationCurrentTerms } from "../../redux/organization/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import ReCAPTCHA from "react-google-recaptcha";
import LeftComponentMui from "../../left-component-mui";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Box } from "@material-ui/core";
import { LockOpen, Visibility } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import s from "./index.module.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

const useStyles = makeStyles((theme) => ({
  aalertHighContrast: {
    color: theme.palette.text.primary,
    fontWeight: "bold",
    fontFamily: "helvetica",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  icon: {
    color: theme.palette.text.primary,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  inputLabel: {
    // "&.Mui-focused": {
    color: theme.palette.primary.main === "#fff200" && "#fff200",
    opacity: theme.palette.primary.main === "#fff200" && 0.7,
    "&.Mui-focused": {
      opacity: 1,
    },
    // },
  },
}));

const ContrastCheckbox = withStyles((theme) => ({
  root: {
    color:
      theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    "&$checked": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
  },
}))(Checkbox);

const CssTextField = withStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      color: theme.palette.primary.main === "#fff200" && "#c2b90c",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color:
        theme.palette.primary.main === "#fff200" && theme.palette.primary.main,
    },
    backgroundColor: theme.palette.primary.main !== "#fff200" && "white",
    // border: theme.palette.primary.main !== "#fff200" && "1px solid lightgrey",
    borderRadius: "4px",
  },
}))(TextField);

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#fff200",
    },
    "&:hover $notchedOutline": {
      borderColor: "#fff200",
    },
    "&$focused $notchedOutline": {
      borderColor: "#fff200",
    },
  },
  focused: {},
  notchedOutline: {},
}));

const registerStatusLabels = [
  { value: "join", label: "Dołącz do organizacji" },
  { value: "create", label: "Załóż organizację" },
];

export const testEmailPattern = (mail) => {
  let polishChars1 = String(mail)
    .toLowerCase()
    .normalize("NFD")
    .search(/[\u0300-\u036f]/g);
  let polishChars2 = String(mail)
    .toLowerCase()
    .normalize("NFD")
    .search(/\u0142/g);

  return (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(mail).toLowerCase()
    ) &&
    (polishChars2 !== 0
      ? polishChars1 + polishChars2 < 0
      : polishChars1 + polishChars2 < -1)
  );
};

const RegisterOrganisation = ({
  creatingOrganization,
  joiningComplete,
  joiningOrganization,
  joinedOrganization,
  history,
  fetchOrgNames,
  names,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);
  //const [termList, setTermList] = useState([]);                     -----we don't need this since there is only one terms
  //const [allTermsAccepted, setAllTermsAccepted] = useState(false);
  const [orgCurrentTerms, setOrgCurrentTerms] = useState(null);
  const [acceptedTerms, setAcceptedTerms] = useState(null);

  const [orgNames, setOrgNames] = useState([]);
  const [orgCode, setOrgCode] = useState("");
  const [showOrgCode, setShowOrgCode] = useState(false);

  const [selectedOrg, setSelectedOrg] = useState("");
  const [isRegisterInProgress, setIsRegisterInProgress] = useState(false);
  const [registerCompleted, setRegisterCompleted] = useState(false);

  const [invalidPasswordInputChar, setInvalidPasswordInputChar] = useState(
    false
  );

  const selectedLang = localStorage.getItem("i18nextLng") || "pl";

  const [registerStatus, setRegisterStatus] = useState("join");

  const outlinedInputClasses = useOutlinedInputStyles();
  const dispatch = useDispatch();
  const styles = useStyles();
  const globalTheme = useSelector((s) => s.globalTheme);
  const theme = useTheme();
  useEffect(() => {
    fetchOrgNames();
  }, []);

  useEffect(() => {
    if (names) {
      setOrgNames(
        names.map(({ id: value, orgName: label }) => ({
          value,
          label,
        }))
      );
    }
  }, [names]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleInstructionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    async function fetchOrgCurrentTerms() {
      if (selectedOrg) {
        const result = await dispatch(
          fetchOrganizationCurrentTerms(selectedOrg)
        );
        result !== false
          ? setOrgCurrentTerms(result)
          : setOrgCurrentTerms(null);
      }
    }
    fetchOrgCurrentTerms();
    setAcceptedTerms(null);
  }, [selectedOrg]);

  ////////we don't need this since there is only one terms
  // useEffect(() => {
  //   setAllTermsAccepted(
  //     termList.reduce((all, current) => all && current.accepted, true)
  //   );
  // }, [termList]);

  // useEffect(() => {
  //   if (orgCurrentTerms) {
  //     setTermList(
  //       orgCurrentTerms.map((t) => ({
  //         ...t,
  //         accepted: false,
  //       }))
  //     );
  //   }
  // }, [orgCurrentTerms]);

  const handleRegistrationStatus = (event) => {
    if (names) {
      setOrgNames(
        names.map(({ id: value, orgName: label }) => ({
          value,
          label,
        }))
      );
    }

    setRegisterStatus(event.currentTarget.value);
  };

  useEffect(() => {
    if (registerCompleted) {
      dispatch(
        alertAdd({
          text: "Rejestracja przebiegła pomyślnie.",
          isSuccess: true,
        })
      );
      history.push("/login");
    }
  }, [registerCompleted]);

  const submit = async (e) => {
    e.preventDefault();

    if (formIsValid) {
      try {
        if (registerStatus === "create") {
          const domain = window.location.hostname;
          const ws = new WebSocket(
            `wss://${domain}/tenants/v1/ws/registration?email=${mail.toLowerCase()}`
          );
          ws.onopen = async function (e) {
            setIsRegisterInProgress(true);
            const response = await dispatch(
              register_organization({
                name,
                mail: mail.toLowerCase(),
                password,
              })
            );

            if (response.status === 200) {
            } else {
              const message = response.data.message;

              if (message.indexOf("Email is not unique") !== -1) {
                dispatch(
                  alertAdd({
                    text: "Email nie jest unikalny.",
                    isError: true,
                  })
                );
              } else if (
                message.indexOf("Organization name is not unique") !== -1
              ) {
                dispatch(
                  alertAdd({
                    text: "Nazwa organizacji nie jest unikalna",
                    isError: true,
                  })
                );
              }

              setIsRegisterInProgress(false);
            }
          };
          ws.onmessage = function (e) {
            setIsRegisterInProgress(
              e.data.includes("TenantCreatedWsPayload") && false
            );
            setRegisterCompleted(e.data.includes("TenantCreatedWsPayload"));
          };
        } else {
          await dispatch(
            join_organization({
              selectedOrg,
              mail,
              password,
              orgCode,
              acceptedTerms,
            })
          );
          dispatch(
            alertAdd({
              text: "Rejestracja przebiegła pomyślnie.",
              isSuccess: true,
            })
          );
          history.push("/login");
        }
      } catch (e) {
        let errorMessage;
        if (e.response.status === 409) {
          if (e.response.data.message === "Invalid organization code") {
            errorMessage = "Błąd rejestracji, nieprawidłowy kod organizacji.";
          } else if (
            e.response.data.message.includes("Invalid email address")
          ) {
            errorMessage = "Błąd rejestracji, niepoprawny format adresu email.";
          } else {
            errorMessage =
              "Błąd rejestracji, email jest już używany w systemie.";
          }
        } else if (e.response.status === 400) {
          if (e.response.data.message === "terms-not-accepted") {
            errorMessage = "Błąd rejestracji, należy zaakceptować regulamin.";
          } else if (
            e.response.data.message === "accepted-terms-are-not-current"
          ) {
            errorMessage =
              "Błąd rejestracji, zaakceptowano regulamin, który nie jest aktualny.";
          } else if (e.response.data.message === "current-terms-are-not-set") {
            errorMessage =
              "Błąd rejestracji, zaakceptowano regulamin mimo, że organizacja nie ma aktualnego regulaminu.";
          }
        } else {
          errorMessage = `Błąd rejestracji, (${e.message}).`;
        }

        dispatch(
          alertAdd({
            text: errorMessage,
            isError: true,
          })
        );
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  useEffect(() => {
    const isCodeRequried =
      names?.find((org) => org.id === selectedOrg)?.enableCodeRegistration ||
      false;
    registerStatus === "create"
      ? setFormIsValid(
          name &&
            testEmailPattern(mail) &&
            password &&
            passwordsMatch &&
            recaptchaValid &&
            //allTermsAccepted &&
            passwordScore > 4
        )
      : isCodeRequried
      ? setFormIsValid(
          selectedOrg &&
            testEmailPattern(mail) &&
            password &&
            orgCode &&
            passwordsMatch &&
            recaptchaValid &&
            //allTermsAccepted &&
            passwordScore > 4
        )
      : setFormIsValid(
          selectedOrg &&
            testEmailPattern(mail) &&
            password &&
            passwordsMatch &&
            recaptchaValid &&
            //allTermsAccepted &&
            passwordScore > 4
        );
  }, [
    name,
    mail,
    passwordsMatch,
    password,
    recaptchaValid,
    //allTermsAccepted,
    orgCode,
    passwordScore,
    selectedOrg,
  ]);

  useEffect(() => {
    setPasswordsMatch(password === password2);
  }, [password, password2]);

  const onChange = (value) => {
    setRecaptchaValid(!!value);
  };

  useEffect(() => {
    clearTimeout(typingTimer);
  }, []);

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      t("register.lowercase"),
      t("register.uppercase"),
      t("register.special_char"),
      t("register.number"),
      t("register.length"),
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        t("register.length")
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        t("register.lowercase")
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        t("register.uppercase")
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        t("register.number")
      );
    }
    if (/[@#$%^&+=]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        t("register.special_char")
      );
    }
    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };

  return (
    <Grid container>
      <Grid item sm={12} xs={12} md={8}>
        <LeftComponentMui />
      </Grid>

      <Grid item sm={12} xs={12} md={4}>
        <Box p={1}>
          <Box textAlign="center">
            <LockOpen />
          </Box>
          {registerStatus === "create" ? (
            <Loader
              loading={creatingOrganization || isRegisterInProgress}
              text="Tworzenie organizacji"
            />
          ) : (
            <Loader
              loading={joiningOrganization || isRegisterInProgress}
              text="Dołączanie do organizacji"
            />
          )}

          {!creatingOrganization &&
            !joiningOrganization &&
            !isRegisterInProgress && (
              <>
                <Box as="h1" fontSize={20} textAlign="center">
                  {t("register.label")}
                </Box>
                {/* {registerStatus === "create" && (
                  <Box mt={1} as="h1" fontSize={15} textAlign="left">
                    Rejestrujesz się jako administrator oraz jednocześnie
                    zakładasz własną{" "}
                    <Tooltip title="W ramach organizacji, możesz budować formularze, zarządzać grupami użytkowników, udostępniając im formularze oraz przeglądać wyniki i eksportować je do własnych systemów.">
                      <a>organizację</a>
                    </Tooltip>
                    .
                  </Box>
                )}
                {registerStatus === "join" && (
                  <Box mt={1} as="h1" fontSize={15} textAlign="left">
                    Rejestrujesz się jako{" "}
                    {process.env.REACT_APP_USE_PATIENT === "true"
                      ? `pacjent`
                      : `użytkownik`}{" "}
                    oraz jednocześnie dołączasz do istniejącej już organizacji.
                  </Box>
                )} */}
                {/* <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      value={registerStatus || "create"}
                      name="customized-radios"
                    >
                      {registerStatusLabels.map((status, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            value={status.value}
                            label={status.label}
                            control={<Radio color={"primary"} />}
                            onChange={handleRegistrationStatus}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Box> */}
                <form onSubmit={submit} noValidate autoComplete="off">
                  {registerStatus === "create" ? (
                    <Box mt={1}>
                      <CssTextField
                        label="Imię i nazwisko"
                        variant="outlined"
                        value={name}
                        required
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Box>
                  ) : (
                    <Box mt={1}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel
                          id="select-outlined-label"
                          className={styles.inputLabel}
                        >
                          {t("register.org")}
                        </InputLabel>
                        <Select
                          labelId="select-outlined-label"
                          id="select-outlined"
                          value={selectedOrg}
                          fullWidth
                          required
                          onChange={(event) =>
                            setSelectedOrg(event.target.value)
                          }
                          input={
                            <OutlinedInput
                              label={t("register.org")}
                              name="org"
                              id="outlined-age-simple"
                              classes={
                                globalTheme === "high-contrast" &&
                                outlinedInputClasses
                              }
                            />
                          }
                          label="Wybierz organizację"
                        >
                          {orgNames.map((org, index) => {
                            return (
                              <MenuItem key={index} value={org.value}>
                                {org.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Box mt={1}>
                        {names !== undefined &&
                          selectedOrg !== "" &&
                          names.find((tmpOrg) => {
                            return tmpOrg.id === selectedOrg;
                          }).enableCodeRegistration && (
                            <CssTextField
                              required
                              label={t("register.org_code")}
                              variant="outlined"
                              type={showOrgCode ? "text" : "password"}
                              value={orgCode}
                              fullWidth
                              onChange={(e) => setOrgCode(e.target.value)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowOrgCode(!showOrgCode)
                                      }
                                      aria-label="toggle org code visibility"
                                    >
                                      <Visibility
                                        className={`${
                                          globalTheme === "high-contrast" &&
                                          styles.icon
                                        } ${styles.iconSize}`}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                      </Box>
                    </Box>
                  )}

                  <Box mt={1}>
                    <CssTextField
                      error={!(mail === "" || testEmailPattern(mail))}
                      helperText={
                        !(mail === "" || testEmailPattern(mail)) &&
                        "Nieprawidłowy adres email"
                      }
                      label={"Email"}
                      variant="outlined"
                      required
                      value={mail}
                      fullWidth
                      onChange={(e) => setMail(e.target.value)}
                    />
                  </Box>
                  <Box mt={1} display="flex">
                    <CssTextField
                      label={t("register.password")}
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      error={invalidPasswordInputChar}
                      helperText={
                        invalidPasswordInputChar
                          ? "Spacja nie jest dozwolona."
                          : ""
                      }
                      required
                      onChange={(e) => {
                        if (e.target.value.includes(" ")) {
                          setInvalidPasswordInputChar(true);
                          setTimeout(
                            () => setInvalidPasswordInputChar(false),
                            3000
                          );
                        } else {
                          setInvalidPasswordInputChar(false);
                        }
                        setPassword(e.target.value.split(" ").join(""));
                        testStrengthPassword(e);
                      }}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              aria-label="toggle password visibility"
                            >
                              <Visibility
                                className={`${
                                  globalTheme === "high-contrast" && styles.icon
                                } ${styles.iconSize}`}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <CssTextField
                      error={password2 && !passwordsMatch}
                      helperText={
                        password2 && !passwordsMatch
                          ? "Powtórzone hasło nie jest zgodne"
                          : ""
                      }
                      label={t("register.confirm_password")}
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      value={password2}
                      required
                      onChange={(e) =>
                        setPassword2(e.target.value.split(" ").join(""))
                      }
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              aria-label="toggle password visibility"
                            >
                              <Visibility
                                className={`${
                                  globalTheme === "high-contrast" && styles.icon
                                } ${styles.iconSize}`}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box mt={1}>
                    <span
                      className={
                        theme.palette.primary.main === "#fff200"
                          ? s.formHintContrast
                          : s.formHint
                      }
                    >
                      {t("register.required")}
                    </span>
                  </Box>
                  <Box mt={1} display="flex">
                    <span
                      className={
                        theme.palette.primary.main === "#fff200"
                          ? s.formHintContrast
                          : s.formHint
                      }
                    >
                      {t("register.password_policy")}
                    </span>
                  </Box>
                  {passwordScore > 0 && (
                    <div>
                      <label
                        className={
                          theme.palette.primary.main === "#fff200"
                            ? s.labelStrengthPasswordContrast
                            : s.labelStrengthPassword
                        }
                      >
                        {t("register.strength_password")}
                      </label>
                      <Box mt={1} display="flex">
                        <span
                          className={s.strengthPassword}
                          dataScore={passwordScore}
                        />
                      </Box>
                      {passwordMissingElements.length > 0 && (
                        <label
                          className={
                            theme.palette.primary.main === "#fff200"
                              ? s.labelStrengthPasswordContrast
                              : s.labelStrengthPassword
                          }
                          style={{ marginTop: "10px" }}
                        >
                          {t("register.password_policy_ext")}
                        </label>
                      )}
                      <span
                        className={
                          theme.palette.primary.main === "#fff200"
                            ? s.formHintContrast
                            : s.formHint
                        }
                      >
                        {passwordMissingElements.map((el, index) => {
                          return <li key={index}>{el}</li>;
                        })}
                      </span>
                    </div>
                  )}

                  {/* <Box mt={1}>
                    {termList && termList.length > 0 && (
                      <label>Akceptuję następujące regulaminy: </label>
                    )}{" "}
                  </Box> */}

                  {/* <Box>
                    {termList &&
                      termList.length > 0 &&
                      termList.map((term) => {
                        return (
                          <div key={term.id}>
                            <input
                              type="checkbox"
                              checked={term.accepted}
                              onChange={() => {
                                setTermList(
                                  termList.map((acceptedTerm) =>
                                    acceptedTerm.id === term.id
                                      ? {
                                          ...acceptedTerm,
                                          accepted: !acceptedTerm.accepted,
                                        }
                                      : acceptedTerm
                                  )
                                );
                              }}
                              style={{ marginRight: "5px", marginLeft: "10px" }}
                            />
                            <span>
                              <label style={{ marginRight: "8px" }}>
                                {term.name}
                              </label>
                              <a
                                href={`/org-media/files${term.link}`}
                                target={"_blank"}
                              >
                                przeczytaj
                              </a>
                            </span>
                          </div>
                        );
                      })}{" "}
                  </Box> */}

                  {orgCurrentTerms && (
                    <FormControlLabel
                      control={
                        <ContrastCheckbox
                          checked={!!acceptedTerms}
                          onChange={({ target: { checked } }) => {
                            setAcceptedTerms(
                              checked ? orgCurrentTerms.id : null
                            );
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="body1">
                          {t("login_page.accept")}
                          <a
                            href={`/org-media/files${orgCurrentTerms.link}`}
                            target="_blank"
                            className={`${
                              globalTheme === "high-contrast" &&
                              styles.aalertHighContrast
                            } ${styles.fontSize}`}
                          >
                            {" "}
                            {t("login_page.term")}
                          </a>{" "}
                          {/* serwisu * */}
                        </Typography>
                      }
                    />
                  )}

                  <Box mt={1}>
                    <ReCAPTCHA
                      hl={selectedLang || "pl"}
                      sitekey="6Lev2u4UAAAAAE0emXI5hh5W-VfQWagdCiqBpXRu"
                      onChange={onChange}
                    />
                  </Box>

                  <Box mt={1} display="flex">
                    <Button
                      variant={"contained"}
                      fullWidth
                      onClick={cancel}
                      style={{
                        backgroundColor:
                          globalTheme === "high-contrast" && "yellow",
                        color: globalTheme === "high-contrast" && "black",
                        border:
                          globalTheme === "high-contrast" && "1px solid yellow",
                      }}
                    >
                      {t("cancel")}
                    </Button>{" "}
                    <OrangeButton
                      style={{
                        backgroundColor:
                          globalTheme === "high-contrast" &&
                          formIsValid &&
                          "yellow",
                        color:
                          globalTheme === "high-contrast" &&
                          formIsValid &&
                          "black",
                        border:
                          globalTheme === "high-contrast" &&
                          formIsValid &&
                          "1px solid yellow",
                      }}
                      type="submit"
                      variant={"contained"}
                      fullWidth
                      disabled={!formIsValid}
                    >
                      {t("register_button")}
                    </OrangeButton>
                  </Box>
                  <Box position="fixed" bottom={20} right={20}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      color="primary"
                      variant="contained"
                      onClick={handleInstructionClick}
                    >
                      {t("login_page.instruction")}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          window.open(
                            "/org-media/files/public/instrukcja_uzytkownika.pdf",
                            "_blank"
                          );
                        }}
                      >
                        Instrukcja dla{" "}
                        {process.env.REACT_APP_USE_PATIENT === "true"
                          ? "pacjenta"
                          : "użytkownika"}{" "}
                        PDF
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setAnchorEl(null);
                          window.open(
                            "/org-media/files/public/zrodlo.mp4",
                            "_blank"
                          );
                        }}
                      >
                        Film instruktażowy dla{" "}
                        {process.env.REACT_APP_USE_PATIENT === "true"
                          ? "pacjenta"
                          : "użytkownika"}
                      </MenuItem>
                    </Menu>
                  </Box>
                </form>
              </>
            )}
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  creatingOrganization: state.creatingOrganization,
  joiningOrganization: state.joiningOrganization,
  joinedOrganization: state.joinedOrganization,
  joiningComplete: state.joiningComplete,
  names: state.orgNames,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrgNames: () => dispatch(fetchOrgNames()),
  fetchOrganizationCurrentTerms: (orgId) =>
    dispatch(fetchOrganizationCurrentTerms(orgId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterOrganisation);
