import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Box,
  Paper,
  FormControl,
  FormControlLabel,
  makeStyles,
  Checkbox,
  TableBody,
} from "@material-ui/core";
import React, { useEffect, useReducer, useState } from "react";

import { useTranslation } from "react-i18next";
import { ConfirmDialogMUI } from "../confirm-dialog-mui";
import AddFoalingEvent, { times } from "../event-component/foalings";
import {
  addFoaling,
  editFoaling,
  fetchFoalings,
  removeFoaling,
  togglePregnant,
} from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { v1 } from "uuid";

const initialEventData = {
  foaling: {
    id: "",
    date: "",
    approximateTime: "",
    insemination: "",
    isValid: false,
    status: "",
  },
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: "80vw",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(1),
      width: "90vw",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
      width: "95vw",
    },
  },
}));

const formReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE_INPUT_CHANGE":
      return {
        ...state,
        [action.property]: {
          ...state[action.property],
          [action.field]: action.payload,
        },
      };
    case "VALID_FORM":
      return {
        ...state,
        [action.property]: {
          ...state[action.property],
          isValid: true,
        },
      };
    case "NOT_VALID_FORM":
      return {
        ...state,
        [action.property]: {
          ...state[action.property],
          isValid: false,
        },
      };
    case "TOGGLED_PANEL":
      return {
        ...initialEventData,
        [action.property]: {
          ...initialEventData[action.property],
          opened: action.payload,
        },
      };
    case "CLEAR_FORM_DATA":
      return {
        ...initialEventData,
        [action.property]: {
          ...initialEventData[action.property],
          opened: action.payload,
        },
      };
    case "CLEAR_FORM_DATA_TO_EDIT":
      return {
        ...initialEventData,
        [action.property]: {
          ...initialEventData[action.property],
          opened: true,
          id: action.payload,
        },
      };
    case "DATA_TO_EDIT":
      return {
        ...initialEventData,
        [action.property]: {
          ...action.payload,
          opened: action.payload,
        },
      };

    default:
      return state;
  }
};

const FoalingPanel = ({ open, onClose, horseId, pregnant }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formState, dispatchFoalingForm] = useReducer(
    formReducer,
    initialEventData
  );
  const [inPregnant, setInPregnant] = useState(pregnant);
  const [openInformPanel, setOpenInformPanel] = useState(false);

  const [openConfirmPanelFoaling, setOpenConfirmPanelFoaling] = useState(false);

  const classes = useStyles();

  const foalings = useSelector((s) => s.foalings);

  useEffect(() => {
    (async () =>
      await dispatch(
        fetchFoalings({
          q: { horseId: { is: horseId } },
          // q: { horseId: { is: horseId } },
        })
      ))();
  }, []);

  const handleSubmit = async () => {
    const {
      date,
      approximateTime,
      insemination,
      isValid,
      status,
    } = formState.foaling;
    let r = null;

    if (!formState.foaling.id) {
      r = await dispatch(
        addFoaling({
          horseId,
          date,
          status,
          foalingEvent: {
            ...(approximateTime
              ? { approximateFoalingTime: approximateTime }
              : {}),
            ...(insemination ? { dateOfInsemination: insemination } : {}),
          },
        })
      );
    } else {
      r = await dispatch(
        editFoaling(
          {
            date,
            status,
            foalingEvent: {
              ...(approximateTime
                ? { approximateFoalingTime: approximateTime }
                : {}),
              ...(insemination ? { dateOfInsemination: insemination } : {}),
            },
          },
          formState.foaling.id
        )
      );
    }

    if (r) {
      dispatchFoalingForm({
        type: "TOGGLED_PANEL",
        payload: !formState.foaling.opened,
        property: "foaling",
      });

      await dispatch(
        fetchFoalings({
          q: { horseId: { is: horseId } },
        })
      );
    }
  };

  const handleFoalingEdit = async ({
    id,
    date,
    foalingEvent: { approximateFoalingTime, dateOfInsemination },
    status,
  }) => {
    dispatchFoalingForm({
      type: "DATA_TO_EDIT",
      payload: {
        id,
        date,
        approximateTime: approximateFoalingTime,
        insemination: dateOfInsemination,
        isValid: true,
        status,
      },
      property: "foaling",
    });
  };

  const handleFoalingDelete = async (foalingId) => {
    const r = await dispatch(removeFoaling(foalingId));
    if (r) {
      await dispatch(
        fetchFoalings({
          q: { horseId: { is: horseId } },
        })
      );
    }
  };

  const handleSubmitPregnant = async (v) => {
    setInPregnant(v);
    setOpenInformPanel(false);
    const r = await dispatch(togglePregnant({ pregnant: v }, horseId));
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xl"
      onClose={(reason) => {
        if (!reason === "backdropClick") {
          onClose();
        }
      }}
    >
      <DialogTitle>{t("foaling_panel.label")}</DialogTitle>
      <DialogContent>
        <Box p={1}>
          <a
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={() => {
              dispatchFoalingForm({
                type: "TOGGLED_PANEL",
                payload: !formState.foaling.opened,
                property: "foaling",
              });

              if (formState.foaling.opened) {
                dispatchFoalingForm({
                  type: "CLEAR_FORM_DATA",
                  payload: !formState.foaling.opened,
                  property: "foaling",
                });
              }
            }}
          >
            {formState.foaling.opened
              ? t("events.close")
              : t("events.foalings.add_historical_foaling")}
          </a>
          {formState.foaling.opened && (
            <AddFoalingEvent
              formState={formState}
              dispatch={dispatchFoalingForm}
              handleSubmit={handleSubmit}
              t={t}
              historical
            />
          )}
          <Box>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={inPregnant}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setOpenInformPanel(true);
                      } else {
                        setInPregnant(false);
                        handleSubmitPregnant(false);
                      }
                    }}
                    name="inPregnant"
                  />
                }
                label={t("events.pregnant.label")}
              />
            </FormControl>
          </Box>
          {
            <ConfirmDialogMUI
              handleClose={() => {
                setOpenInformPanel(false);
              }}
              open={openInformPanel}
              text={t("events.pregnant.info")}
              type="info"
              submitAction={() => handleSubmitPregnant(true)}
            />
          }

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("events.foalings.date")}</TableCell>
                  <TableCell align="right">
                    {t("events.foalings.time")}
                  </TableCell>
                  <TableCell align="right">
                    {t("events.foalings.insemination")}
                  </TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {foalings.map((foaling) => (
                  <TableRow key={foaling.id}>
                    <TableCell align="right">
                      {format(new Date(foaling.date), "yyyy-MM-dd")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {times.find(
                        (t) =>
                          t.value ===
                          foaling.foalingEvent.approximateFoalingTime
                      ).label || ""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {format(
                        new Date(foaling.foalingEvent.dateOfInsemination),
                        "yyyy-MM-dd"
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {t(`events.status.${foaling?.status}`)}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <a
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        onClick={() => handleFoalingEdit(foaling)}
                      >
                        {t("edit")}
                      </a>
                      |{" "}
                      <a
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        onClick={() => setOpenConfirmPanelFoaling(true)}
                      >
                        {t("remove")}
                      </a>{" "}
                    </TableCell>
                    {openConfirmPanelFoaling && (
                      <ConfirmDialogMUI
                        handleClose={() => {
                          setOpenConfirmPanelFoaling(false);
                        }}
                        open={openConfirmPanelFoaling}
                        text={t("events.remove_info")}
                        yesAction={() => {
                          handleFoalingDelete(foaling.id);
                        }}
                        noAction={() => {
                          setOpenConfirmPanelFoaling(false);
                        }}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FoalingPanel;
