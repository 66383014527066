import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  activateUser,
  activateUserByDoc,
  alertAdd,
  editUser,
  fetchOrgUsersWithTerms,
  fetchOrgUsersPaged,
  removeUser,
  fetchMatchingUsers,
  fetchOrgUsers,
  fetchUserAllAvailableForms,
  removeUserFromStud,
  fetchAllOrgUsersPaginated,
  fetchUsersByStudContext,
} from "../redux/actions";
import { fetchOrganizationCurrentTerms } from "../redux/organization/actions";
import { Prompt, Redirect, useHistory } from "react-router-dom";
import { checkPermission } from "../rbac";
import PageTitle from "../components/page-title";
import Box from "@material-ui/core/Box";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TablePagination from "@material-ui/core/TablePagination";
import { normalizeZIP } from "../components/address-form/shipping-info";
import { PersonForm } from "../components/person-form";
import { ConfirmDialogMUI } from "../components/confirm-dialog-mui";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Pagination from "@material-ui/lab/Pagination";
import Cookies from "js-cookie";
import { TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import Loader from "../components/loader";
import IconButton from "@material-ui/core/IconButton";
import Can from "../components/can";
import { useTranslation } from "react-i18next";
import { useReducer } from "react";

const stateToProps = (state) => ({
  persons: state.orgUsers,
  personsLength: state.orgUsersLength,
  roles: state.roles,
  userAllAvailableForms: state.userAllAvailableForms,
  removedUser: state.removedUser,
  activatedUser: state.activatedUser,
});

const dispatchToProps = (dispatch) => ({
  fetchOrgUsersWithTerms: (payload) =>
    dispatch(fetchOrgUsersWithTerms(payload)),
  fetchOrgUsersPaged: (payload) => dispatch(fetchOrgUsersPaged(payload)),
  editUser: (user, id) => dispatch(editUser(user, id)),
  activateUser: (id) => dispatch(activateUser(id)),
  activateUserByDoc: (id) => dispatch(activateUserByDoc(id)),
  fetchUserAllAvailableForms: (id) => dispatch(fetchUserAllAvailableForms(id)),
});

const roleToDisplay = (authorities, t) => {
  return authorities
    .split(",")
    .map((r) => {
      switch (r) {
        case "ROLE_OWNER":
          return t("users.role.org_owner");
        case "ROLE_VET":
          return t("users.role.vet");
        case "ROLE_USER":
          return t("users.role.horse_owner");
        case "ROLE_STUD_OWNER":
          return t("users.role.horse_farm_owner");
        case "ROLE_GROOM":
          return t("users.role.groom");
        default:
          return "";
      }
    })
    .filter((name) => !!name)
    .join(", ");
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}));

const genderTypes = [
  {
    type: "Female",
    description: "Kobieta",
  },
  {
    type: "Male",
    description: "Mężczyzna",
  },
];

const initialUserData = {
  roles: [],
  firstName: "",
  surname: "",
  title: "",
  description: "",
  gender: "",
  enableProfileDataEdition: true,
  address: {
    name: "",
    street: "",
    city: "",
    zipCode: "",
    post: "",
    phone: "",
  },
  mail: "",
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_USER_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "CLEAR_USER_DATA":
      return {
        ...initialUserData,
      };
    case "HANDLE_INPUT_CHANGE":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case "HANDLE_INPUT_ADDRESS_CHANGE":
      return {
        ...state,
        address: {
          ...state.address,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

const Person = ({
  key,
  data,
  preventEdition,
  expanded,
  setExpanded,
  editUserData,
  setMailToCheck,
  dispatchUserToEdit,
  removeFunction,
  setOpenFormRemovingPanel,
  setOpenFormActivationPanel,
  setOpenFormEditionPanel,
  editFunction,
  activateFunction,
  index,
  profileDataChangeHandler,
  addressDataChangeHandler,
  handleChecbox,
  isOwner,
  t,
}) => {
  useEffect(() => {
    if (editUserData.id === data.id) {
      const emailValid = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(editUserData?.mail).toLowerCase()
      );
      if (editUserData.mail && editUserData.mail !== data.mail && emailValid) {
        setMailToCheck(editUserData.mail);
      }
    }
  }, [editUserData.mail]);

  return (
    <Accordion key={index} square expanded={expanded === data.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id={data.id}
        style={{
          backgroundColor: "rgba(0, 0, 0, .03)",
          borderBottom: "1px solid rgba(0, 0, 0, .125)",
        }}
        onClick={() => {
          if (!expanded) {
            setExpanded(data.id);
            dispatchUserToEdit({
              type: "FETCH_USER_DATA",
              payload: data,
            });
          } else if (expanded === data.id) {
            setExpanded(null);
            dispatchUserToEdit({
              type: "CLEAR_USER_DATA",
            });
          } else {
            setExpanded(data.id);
            dispatchUserToEdit({
              type: "FETCH_USER_DATA",
              payload: data,
            });
          }
        }}
      >
        <Grid container>
          {" "}
          <Grid
            item
            xs={12}
            container
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <span style={{ fontFamily: "inherit" }}>
                {t("user_edition.role_label")}
                <b>{roleToDisplay(data.user.authorities, t)}</b>
              </span>{" "}
            </Grid>
            {data.firstName && data.surname && (
              <Grid item>
                <span style={{ fontFamily: "inherit" }}>
                  {t("user_edition.name_surname")}{" "}
                  <b>
                    {data.firstName} {data.surname}
                  </b>
                  <br />
                </span>
              </Grid>
            )}
            <Grid item>
              <label style={{ fontFamily: "inherit" }}>
                {t("user_edition.mail")} <b>{data.mail}</b>
              </label>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <Typography style={{ marginLeft: 15, fontWeight: "bold" }} variant="h6">
        {t("user_edition.label")}
      </Typography>
      <Box p={1} display="flex" justifyContent="start" alignItems="start">
        <Tooltip title={t("user_edition.id")}>
          <span
            style={{
              fontSize: "0.8rem",
              lineHeight: "1.4",
              color: "#999",
              marginLeft: 15,
            }}
          >
            {data?.id}
          </span>
        </Tooltip>
      </Box>
      <AccordionDetails style={{ flexDirection: "column" }}>
        <PersonForm
          userData={editUserData}
          dispatch={dispatchUserToEdit}
          t={t}
          isOwner={isOwner}
        />
        <Box p={1}>
          {isOwner && (
            <>
              <Button
                variant={"contained"}
                color="primary"
                onClick={() => {
                  setOpenFormEditionPanel(true);
                  editFunction(data);
                }}
                disabled={preventEdition}
              >
                {t("edit")}
              </Button>
              &nbsp;&nbsp;&nbsp;
            </>
          )}

          <Button
            variant={"contained"}
            color="secondary"
            onClick={() => {
              setOpenFormRemovingPanel(true);
              removeFunction(data);
            }}
          >
            {t("remove")}
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export const Persons = connect(
  stateToProps,
  dispatchToProps
)(
  ({
    persons,
    personsLength,
    fetchOrgUsersWithTerms,
    fetchOrgUsersPaged,
    roles,
    history,
    activateUser,
    activateUserByDoc,
    editUser,
    fetchUserAllAvailableForms,
    userAllAvailableForms,
    removedUser,
    activatedUser,
  }) => {
    const classes = useStyles();

    const [users, setUsers] = useState(
      persons?.sort((a, b) => a?.roles?.toString() === b?.roles?.toString()) ||
        []
    );
    const my = useSelector((s) => s.my);
    const [pageNr, setPageNr] = useState(Cookies.get(`p[${my.user.id}]`) || 1);
    const [rowsPerPage, setRowsPerPage] = useState(
      Cookies.get(`users-per-page[${my.user.id}]`) || 5
    );

    const [orderBy, setOrderBy] = useState("user.authorities,-createdAt");
    const [order, setOrder] = useState("asc");

    const [expanded, setExpanded] = useState(null);

    // const [currentPage, setCurrentPage] = useState(1);
    // const [usersPerPage, setUsersPerPage] = useState(5);
    const [userRoleFilter, setuserRoleFilter] = useState("any");
    const [userCredentialFilter, setUserCredentialFilter] = useState("");
    const [surnameFilter, setSurnameFilter] = useState("");
    const filterRequestId = useRef(0);

    const currentContext = useSelector((s) => s.currentContext);

    const [busy, setBusy] = useState(false);
    const [userToDelete, setUserToDelete] = useState();
    const [userToActivateId, setUsertoActivateId] = useState();
    const [userToActivate, setUsertoActivate] = useState();
    const [userToEdit, setUserToEdit] = useState();
    const [mailToCheck, setMailToCheck] = useState("");
    const [editUserData, dispatchUserToEdit] = useReducer(
      userReducer,
      initialUserData
    );
    const removeFunction = setUserToDelete;
    const activateFunction = setUsertoActivateId;
    const editFunction = setUserToEdit;

    const [openFormRemovingPanel, setOpenFormRemovingPanel] = useState(false);
    const [openFormEditionPanel, setOpenFormEditionPanel] = useState(false);
    const [openFormActivationPanel, setOpenFormActivationPanel] = useState(
      false
    );

    const [userExistPanel, setUserExistPanel] = useState(false);
    const [preventEdition, setPreventEdition] = useState(false);

    const [open, setOpen] = useState(false);
    const [roleFilterOpen, setRoleFilterOpen] = useState(false);
    const dispatch = useDispatch();
    const store = useSelector((s) => s);
    const canSeeTerms = !!my?.user.authorities.includes("ROLE_OWNER");
    const organization = useSelector((s) => s.organization);

    const [allReady, setAllReady] = useState(false);
    const [userForms, setUserForms] = useState([]);
    const [formsReady, setFormsReady] = useState(false);
    const isStudOwner =
      my.user.authorities.localeCompare("ROLE_STUD_OWNER") === 0;
    const isOwner = my.user.authorities.includes("ROLE_OWNER");

    const { t } = useTranslation();

    const queryFilter =
      userCredentialFilter || surnameFilter || userRoleFilter !== "any"
        ? {
            q: {
              ...(userCredentialFilter || surnameFilter
                ? {
                    ...(userCredentialFilter
                      ? {
                          firstName: {
                            contains: userCredentialFilter,
                          },
                        }
                      : {}),
                    ...(surnameFilter
                      ? {
                          surname: {
                            contains: surnameFilter,
                          },
                        }
                      : {}),
                  }
                : {}),
              ...(userRoleFilter !== "any"
                ? {
                    "user.authorities": {
                      contains: userRoleFilter,
                    },
                  }
                : {}),
            },
          }
        : null;

    useEffect(() => {
      const fetchUsers = async () => {
        setAllReady(false);

        if (canSeeTerms) {
          await dispatch(
            fetchAllOrgUsersPaginated({
              page:
                (personsLength < rowsPerPage
                  ? 0
                  : personsLength % rowsPerPage == 1
                  ? pageNr - 2
                  : pageNr - 1) < 0
                  ? 0
                  : personsLength < rowsPerPage
                  ? 0
                  : personsLength % rowsPerPage == 1
                  ? pageNr - 2
                  : pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(queryFilter ? queryFilter : {}),
            })
          );
        } else {
          await dispatch(
            fetchUsersByStudContext({
              page:
                (personsLength < rowsPerPage
                  ? 0
                  : personsLength % rowsPerPage == 1
                  ? pageNr - 2
                  : pageNr - 1) < 0
                  ? 0
                  : personsLength < rowsPerPage
                  ? 0
                  : personsLength % rowsPerPage == 1
                  ? pageNr - 2
                  : pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(queryFilter ? queryFilter : {}),
            })
          );
        }
        setAllReady(true);
      };
      if (removedUser || activatedUser) {
        fetchUsers();
      }
    }, [removedUser, activatedUser]);

    useEffect(() => {
      const fetchUsers = async () => {
        setAllReady(false);

        if (canSeeTerms) {
          await dispatch(
            fetchAllOrgUsersPaginated({
              page: pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(queryFilter ? queryFilter : {}),
            })
          );
        } else {
          await dispatch(
            fetchUsersByStudContext({
              page: pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(queryFilter ? queryFilter : {}),
            })
          );
        }
        setAllReady(true);
      };
      fetchUsers();
      if (
        userRoleFilter !== "any" ||
        userCredentialFilter.length > 0 ||
        surnameFilter.length
      ) {
        Cookies.set(`p[${my.user.id}]`, 1, {
          sameSite: "strict",
        });
        setPageNr(1);
      }
    }, [userRoleFilter, userCredentialFilter, surnameFilter, currentContext]);

    useEffect(() => {
      const fetchUsers = async () => {
        setAllReady(false);

        if (canSeeTerms) {
          await dispatch(
            fetchAllOrgUsersPaginated({
              page: pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(queryFilter ? queryFilter : {}),
            })
          );
        } else {
          await dispatch(
            fetchUsersByStudContext({
              page: pageNr - 1,
              pageSize: rowsPerPage,
              ...(orderBy
                ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
                : {}),
              ...(queryFilter ? queryFilter : {}),
            })
          );
        }
        setAllReady(true);
      };
      fetchUsers();
    }, [roles, pageNr, rowsPerPage, order, orderBy]);

    useEffect(() => {
      if (
        personsLength &&
        Number.isInteger(personsLength / rowsPerPage) &&
        personsLength / rowsPerPage < pageNr &&
        pageNr > 1
      ) {
        Cookies.set(
          `p[${my.user.id}]`,
          parseInt(
            personsLength < rowsPerPage
              ? 0
              : personsLength == 0
              ? pageNr - 2
              : pageNr - 1
          ),
          {
            sameSite: "strict",
          }
        );
        setPageNr(
          parseInt(
            personsLength < rowsPerPage
              ? 0
              : personsLength == 0
              ? pageNr - 2
              : pageNr - 1
          )
        );
      }
    }, [personsLength]);

    useEffect(() => {
      setUsers(
        persons.sort((a, b) => a?.roles?.toString() === b?.roles?.toString())
      );
    }, [persons]);

    useEffect(() => {
      if (userToActivateId) {
        setUsertoActivate(users.find(({ id }) => id === userToActivateId));
      }
    }, [userToActivateId]);

    useEffect(() => {
      organization && dispatch(fetchOrganizationCurrentTerms(organization.id));
    }, [organization?.id]);

    useEffect(() => {
      (async () => {
        const r = await dispatch(
          fetchMatchingUsers({
            email: mailToCheck,
          })
        );
        if (r?.data.length) {
          setUserExistPanel(true);
          setPreventEdition(true);
        } else {
          setPreventEdition(false);
        }
      })();
    }, [mailToCheck]);

    const profileDataChange = (value, user, fieldProperty) => {
      setUsers((prevState) =>
        prevState.map((tmpUser) =>
          user.id === tmpUser.id
            ? {
                ...tmpUser,
                [fieldProperty]: value,
                ...(fieldProperty === "phone" && {
                  address: {
                    ...tmpUser.address,
                    phone: value,
                  },
                }),
              }
            : tmpUser
        )
      );
    };

    const addressDataChange = (value, user, fieldProperty) => {
      setUsers((prevState) =>
        prevState.map((tmpUser) =>
          user.id === tmpUser.id
            ? {
                ...tmpUser,
                address: {
                  ...tmpUser.address,
                  [fieldProperty]:
                    fieldProperty.localeCompare("zipCode") === 0
                      ? normalizeZIP(value, tmpUser.address?.zipCode)
                      : value,
                },
              }
            : tmpUser
        )
      );
    };

    const handleChecbox = (event, user) => {
      setUsers(
        users.map((tmpUser) =>
          tmpUser.id === user.id
            ? {
                ...tmpUser,
                [event.target.name]: event.target.checked,
              }
            : tmpUser
        )
      );
    };

    const editUserHandler = async (e) => {
      if (editUserData) {
        setBusy(true);
        const response = await editUser(
          Object.entries(editUserData).reduce(
            (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
            {}
          ),
          editUserData.userId
        );

        setBusy(false);
      }
    };

    const deleteUser = async () => {
      if (userToDelete) {
        setBusy(true);
        if (isStudOwner) {
          await dispatch(removeUserFromStud(userToDelete.id, canSeeTerms));
        } else {
          await dispatch(removeUser(userToDelete.id, canSeeTerms));
        }

        setUserToDelete(null);
        setBusy(false);
      }
    };

    const setUserActive = async () => {
      if (userToActivateId) {
        setBusy(true);
        let result = null;
        // if (isRole) {
        //   result = await activateUserByDoc(userToActivateId);
        // } else {
        //   result = await activateUser(userToActivateId);
        // }
        result = await activateUser(userToActivateId);
        setUsertoActivateId(null);
        setBusy(false);
      }
    };

    const handleChangePage = (event, newPage) => {
      Cookies.set(`p[${my.user.id}]`, parseInt(newPage) - 1, {
        sameSite: "strict",
      });
      setPageNr(parseInt(newPage) - 1);
    };

    const handleChangePageBack = () => {
      Cookies.set(`p[${my.user.id}]`, parseInt(pageNr) - 1, {
        sameSite: "strict",
      });
      setPageNr(parseInt(pageNr) - 1);
    };

    const handleChangePageForward = () => {
      Cookies.set(`p[${my.user.id}]`, parseInt(pageNr) + 1, {
        sameSite: "strict",
      });
      setPageNr(parseInt(pageNr) + 1);
    };

    const handleChangePageSelect = (event) => {
      Cookies.set(`p[${my.user.id}]`, parseInt(event.target.value), {
        sameSite: "strict",
      });
      setPageNr(event.target.value);
    };

    const handleChangeRowsPerPage = (event) => {
      Cookies.set(`p[${my.user.id}]`, 1, {
        sameSite: "strict",
      });
      Cookies.set(
        `users-per-page[${my.user.id}]`,
        parseInt(event.target.value, 10),
        {
          sameSite: "strict",
        }
      );
      setRowsPerPage(parseInt(event.target.value, 10));

      setPageNr(1);
    };

    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
    };

    ///////////////////////
    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true);
    };

    const handleChangeRoleFilterSelect = (event) => {
      filterRequestId.current++;
      setuserRoleFilter(event.target.value);
    };

    const handleCloseRoleFilterSelect = () => {
      setRoleFilterOpen(false);
    };

    const handleOpenRoleFilterSelect = () => {
      setRoleFilterOpen(true);
    };

    const handleChangeUserCredentialFilter = (event) => {
      const filterQuery = event.target.value;
      filterRequestId.current++;
      const currentRequestId = filterRequestId.current;
      setTimeout(
        () =>
          currentRequestId == filterRequestId.current &&
          setUserCredentialFilter(filterQuery),
        1500
      );
    };

    const handleChangeSurnameFilter = (event) => {
      const filterQuery = event.target.value;
      filterRequestId.current++;
      const currentRequestId = filterRequestId.current;
      setTimeout(
        () =>
          currentRequestId == filterRequestId.current &&
          setSurnameFilter(filterQuery),
        1500
      );
    };

    useEffect(() => {
      if (userToDelete?.id) {
        setFormsReady(true);
        // fetchUserAllAvailableForms(userToDelete.id);
      }
    }, [userToDelete]);

    // useEffect(() => {
    //   if (userToDelete) {
    //     setUserForms(userAllAvailableForms);
    //     setFormsReady(true);
    //   }
    // }, [userAllAvailableForms]);

    const page = () => (
      <Box>
        {userToDelete && formsReady && (
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenFormRemovingPanel(false);
              setUserToDelete(null);
              setUserForms([]);
            }}
            open={openFormRemovingPanel}
            text={t("remove_user.label")}
            yesAction={deleteUser}
            noAction={() => {
              setUserToDelete(null);
              setUserForms([]);
            }}
          />
        )}
        {userToActivateId && userToActivate && (
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenFormActivationPanel(false);
              setUsertoActivateId(null);
            }}
            open={openFormActivationPanel}
            text={`Czy na pewno aktywować użytkownika (${
              userToActivate.user.email || userToActivate.user.phone
            })?`}
            yesAction={setUserActive}
            noAction={() => setUsertoActivateId(null)}
          />
        )}
        <ConfirmDialogMUI
          handleClose={() => {
            setUserExistPanel(false);
          }}
          open={userExistPanel}
          text={t("user_edition.info")}
          type="info"
          submitAction={() => setUserExistPanel(false)}
        />
        {userToEdit && (
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenFormEditionPanel(false);
              setUserToDelete(null);
            }}
            open={openFormEditionPanel}
            text={`Czy na pewno edytować użytkownika ${editUserData.firstName} ${editUserData.surname} (${editUserData.mail})?`}
            yesAction={editUserHandler}
            noAction={() => setUserToEdit(null)}
          />
        )}
        {userToEdit && (
          <ConfirmDialogMUI
            handleClose={() => {
              setOpenFormEditionPanel(false);
              setUserToDelete(null);
            }}
            open={openFormEditionPanel}
            text={`Czy na pewno edytować użytkownika ${editUserData.firstName} ${editUserData.surname} (${editUserData.mail})?`}
            yesAction={editUserHandler}
            noAction={() => setUserToEdit(null)}
          />
        )}
        <Prompt
          when={JSON.stringify(users) !== JSON.stringify(persons)}
          message="Edytowane dane nie zostały zapisane, czy mimo to chcesz opuścić stronę?"
        />
        <Box p={1}>
          {isOwner ? (
            <PageTitle title={t("users.label_owner")} />
          ) : (
            <PageTitle title={t("users.label_stud_admin")} />
          )}
        </Box>

        <Box p={1}>
          <Grid
            item
            xs={12}
            container
            justify="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid
              item
              container
              xs={8}
              lg={10}
              justify="flex-start"
              alignItems="center"
            >
              {(isOwner || isStudOwner) && (
                <Grid item>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                    <InputLabel id="open-role-filter-label">
                      {t("users.role_filter")}
                    </InputLabel>
                    <Select
                      labelId="open-role-filter-label"
                      id="open-role-filter"
                      open={roleFilterOpen}
                      label={t("users.role_filter")}
                      onClose={handleCloseRoleFilterSelect}
                      onOpen={handleOpenRoleFilterSelect}
                      onChange={handleChangeRoleFilterSelect}
                      value={userRoleFilter}
                    >
                      <MenuItem value="any">{t("users.role.any")}</MenuItem>
                      <MenuItem value="ROLE_OWNER">
                        {t("users.role.org_owner")}
                      </MenuItem>
                      <MenuItem value="ROLE_VET">
                        {t("users.role.vet")}
                      </MenuItem>
                      <MenuItem value="ROLE_USER">
                        {t("users.role.horse_owner")}
                      </MenuItem>
                      <MenuItem value="GROOM">{t("users.role.groom")}</MenuItem>
                      <MenuItem value="ROLE_STUD_OWNER">
                        {t("users.role.horse_farm_owner")}
                      </MenuItem>
                    </Select>
                  </FormControl>{" "}
                </Grid>
              )}
              <Grid item>
                <TextField
                  // labelId="input-user-filter-label"
                  id="input-user-filter"
                  label={t("users.name_filter")}
                  variant="outlined"
                  onChange={handleChangeUserCredentialFilter}
                ></TextField>
              </Grid>
              <Grid item>
                <TextField
                  // labelId="input-user-filter-label"
                  id="input-surname-filter"
                  label={t("users.surname_filter")}
                  variant="outlined"
                  onChange={handleChangeSurnameFilter}
                ></TextField>{" "}
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              lg={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {" "}
              <Button
                variant={"contained"}
                size="small"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  dispatchUserToEdit({
                    type: "CLEAR_USER_DATA",
                  });
                  history.push(`/person/add`);
                }}
              >
                {t("users.add_new_user")}
              </Button>
              {/* <NavLink to="/person/add">Dodaj nowego użytkownika</NavLink> */}
            </Grid>
          </Grid>
          {!allReady ? (
            <Loader loading={true} text={t("user_edition.loading")} />
          ) : (
            users
              ?.filter((u) => u.user.authorities)
              .map(
                (user, index) =>
                  !user.user?.removedAt && (
                    <Person
                      key={user.id}
                      data={user}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      setMailToCheck={setMailToCheck}
                      preventEdition={preventEdition}
                      editUserData={editUserData}
                      dispatchUserToEdit={dispatchUserToEdit}
                      removeFunction={removeFunction}
                      activateFunction={activateFunction}
                      editFunction={editFunction}
                      index={index}
                      profileDataChangeHandler={profileDataChange}
                      addressDataChangeHandler={addressDataChange}
                      handleChecbox={handleChecbox}
                      setOpenFormRemovingPanel={setOpenFormRemovingPanel}
                      setOpenFormEditionPanel={setOpenFormEditionPanel}
                      setOpenFormActivationPanel={setOpenFormActivationPanel}
                      isOwner={isOwner}
                      t={t}
                    />
                  )
              )
          )}
          <br />
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <IconButton
              disabled={parseInt(pageNr) === 1}
              onClick={handleChangePageBack}
            >
              <ArrowBackIosIcon />
            </IconButton>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="open-select-label">{t("users.page")}</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label={"Strona"}
                value={parseInt(pageNr)}
                onChange={handleChangePageSelect}
              >
                {Array.apply(null, {
                  length: Math.ceil(personsLength / rowsPerPage),
                })
                  .map(Number.call, Number)
                  .map((element, index) => (
                    <MenuItem value={index + 1}>{element + 1}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            <IconButton
              disabled={
                parseInt(pageNr) === Math.ceil(personsLength / rowsPerPage)
              }
              onClick={handleChangePageForward}
            >
              <ArrowForwardIosIcon />
            </IconButton>
            <FormControl className={classes.formControl} variant="outlined">
              <InputLabel id="open-select-label">
                {t("users.users_per_page")}
              </InputLabel>
              <Select
                labelId="open-select-label"
                id="open-select"
                open={open}
                label={t("users.users_per_page")}
                onClose={handleClose}
                onOpen={handleOpen}
                value={parseInt(rowsPerPage)}
                onChange={handleChangeRowsPerPage}
              >
                <MenuItem value={"5"}>5</MenuItem>
                <MenuItem value={"10"}>10</MenuItem>
                <MenuItem value={"20"}>20</MenuItem>
                <MenuItem value={"50"}>50</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Box>
      </Box>
    );

    const redirect = () => <Redirect to="/" />;
    return organization ? (
      <Can permission="admin:fetchOrgUsers" ok={page} not={redirect} />
    ) : (
      <Box p={1}>
        <Loader loading={true} text={t("users.fetching")} />
      </Box>
    );
  }
);
