import React from "react";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LeftComponentMui from "../../left-component-mui";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Can from "../../components/can";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const PasswordResetMailLinkExpiredPage = ({ history }) => {
  const buttonHandler = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  const { t } = useTranslation();

  const page = () => {
    return (
      <Grid container>
        <Grid item sm={12} xs={12} md={8}>
          <LeftComponentMui />
        </Grid>

        <Grid item sm={12} xs={12} md={4}>
          <Box p={1}>
            <Box style={{ alignItems: "center" }}>
              <Alert severity="error">{t("recovery.link_expired")}</Alert>
            </Box>
          </Box>
          <Box m={1}>
            <Typography variant="subtitle1" gutterBottom>
              <a href="/password-reset">{t("recovery.link_form")}</a>
            </Typography>
          </Box>
          <hr />
          <Box display="flex" justifyContent="center">
            <Button
              variant={"contained"}
              color="primary"
              onClick={buttonHandler}
            >
              {t("recovery.back_button")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };
  return (
    <Can
      permission="password-reset-email-link-expired:view"
      ok={page}
      not={() => {
        return <Redirect to="/" />;
      }}
    />
  );
};

export default PasswordResetMailLinkExpiredPage;
