import React, { useCallback, useEffect } from "react";
import { Redirect } from "react-router";
import Can from "../../components/can";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Loader from "../../components/loader";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/page-title";
import { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  fetchBoxes,
  fetchFormsGroups,
  fetchHorseFarms,
  removeBox,
} from "../../redux/actions";
import Cookies from "js-cookie";
import { useParams } from "react-router";
import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontSize: theme.typography.body1.fontSize,
    paddingRight: 2,
    paddingLeft: 3,
    border: theme.palette.primary.main === "#fff200" && "1px solid #fff200",
  },
  t: {
    maxHeight: "50vh",
    "@media (min-height: 400px)": {
      maxHeight: "60vh",
    },
    "@media (min-height: 500px)": {
      maxHeight: "65vh",
    },
    "@media (min-height: 600px)": {
      maxHeight: "70vh",
    },
    "@media (min-height: 800px)": {
      maxHeight: "75vh",
    },
    "@media (min-height: 900px)": {
      maxHeight: "78vh",
    },
    "@media (min-height: 960px)": {
      maxHeight: "80vh",
    },
  },
}));

const Boxes = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { studId } = useParams();

  const roles = useSelector((s) => s.roles);
  const my = useSelector((s) => s.my);
  const currentContext = useSelector((s) => s.currentContext);

  const boxes = useSelector((s) => s.boxes);
  const boxesTotal = useSelector((s) => s.boxesTotal);
  const boxesFetching = useSelector((s) => s.boxesFetching);
  const boxesRemoving = useSelector((s) => s.boxesRemoving);

  const allStuds = useSelector((s) => s.orgFormsGroups);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const pages = [50, 100, 200, 300, 500];
  const [pageNr, setPageNr] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [busy, setBusy] = useState(false);

  const [stud, setStud] = useState(studId || "");
  const [openConfirmPanelBox, setOpenConfirmPanelBox] = useState(false);

  const [boxIdToRemove, setBoxIdToRemove] = useState("");

  const isStudOwner =
    my?.user.authorities.localeCompare("ROLE_STUD_OWNER") === 0;
  const isOwner = !!my?.user.authorities.includes("ROLE_OWNER");

  useEffect(() => {
    if (
      Number.isInteger(boxesTotal / rowsPerPage) &&
      boxesTotal / rowsPerPage < pageNr + 1 &&
      pageNr > 0
    ) {
      setPageNr(pageNr - 1);
    }
  }, [boxesTotal]);

  useEffect(() => {
    (async () => {
      if (isOwner) {
        await dispatch(
          fetchHorseFarms({ page: 0, pageSize: 1000, sort: "name" })
        );
      }
    })();
  }, [isOwner]);

  useEffect(() => {
    if (isOwner) {
      if (stud) {
        fetchBoxesList();
        setBusy(false);
      }
    } else {
      setBusy(true);
      fetchBoxesList();
      if (currentContext.studId) {
        setBusy(false);
      }
    }
  }, [stud, pageNr, rowsPerPage, order, orderBy, currentContext]);

  useEffect(() => {
    if (boxIdToRemove) {
      setOpenConfirmPanelBox(true);
    }
  }, [boxIdToRemove]);

  const handleBoxDelete = async () => {
    const r = await dispatch(
      removeBox({
        isOwner,
        studId: isOwner ? stud : null,
        boxId: boxIdToRemove,
      })
    );
    if (r) {
      setBoxIdToRemove("");
      setOpenConfirmPanelBox(false);
      fetchBoxesList();
    }
  };

  const fetchBoxesList = async () => {
    await dispatch(
      fetchBoxes({
        isOwner,
        studId: isOwner ? stud : null,
        page: pageNr,
        pageSize: rowsPerPage,
        ...(orderBy
          ? { sort: `${order === "asc" ? orderBy : "-" + orderBy}` }
          : {}),
      })
    );
  };

  const headerCells = [
    { id: "name", label: t("boxes_page.table.name") },
    { id: "horse.name", label: t("boxes_page.table.horse") },
  ];

  const handleChangePage = (event, newPage) => {
    setPageNr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    Cookies.set(`boxes-per-page[${my.user.id}]`, event.target.value, {
      sameSite: "strict",
    });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNr(0);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };
  const page = () => (
    <Box>
      {boxesFetching || busy ? (
        <Box p={3}>
          <Loader loading={true} text={t("boxes_page.fetching")} />
        </Box>
      ) : boxesRemoving ? (
        <Box p={3}>
          <Loader loading={true} text={t("horses_page.removing")} />
        </Box>
      ) : (
        <>
          <Box p={1}>
            <PageTitle title={t("boxes_page.label")} />
          </Box>
          <Box p={1}>
            {isOwner && (
              <FormControl fullWidth variant="outlined" size="small" required>
                <InputLabel id="select-outlined-label" required>
                  {t("horse_form.stud")}
                </InputLabel>
                <Select
                  labelId="stud-outlined-label"
                  id="stud-outlined"
                  value={stud}
                  onChange={(event) => setStud(event.target.value)}
                  label={`${t("horse_form.stud")} *`}
                  required
                >
                  {allStuds.map((stud, index) => {
                    return (
                      <MenuItem key={index} value={stud.id}>
                        {stud.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Box>

          {(isOwner && stud) || isStudOwner ? (
            <Box p={1}>
              <TableContainer component={Paper} className={classes.t}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {headerCells.map(({ label, id }) => (
                        <TableCell key={id} className={classes.tableCell}>
                          <TableSortLabel
                            active={orderBy === id}
                            direction={orderBy === id ? order : "asc"}
                            onClick={() => {
                              handleSortRequest(id);
                            }}
                          >
                            {label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                      <TableCell align="right" className={classes.tableCell}>
                        {t("boxes_page.table.camera")}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {t("boxes_page.table.options")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {boxes.map((box, index) => {
                      return (
                        <TableRow key={box.id}>
                          <TableCell>{box.name}</TableCell>
                          <TableCell>{box.horse?.name || ""}</TableCell>{" "}
                          <TableCell>
                            {
                              <>
                                <p>{box.camera?.vendor}</p>
                                <p>{box.camera?.recorderChannel}</p>{" "}
                                <p>{box.camera?.recorderIP}</p>
                              </>
                            }
                          </TableCell>
                          <TableCell>
                            {" "}
                            <Can
                              permission="form:edit"
                              ok={() => (
                                <Tooltip
                                  placement="top"
                                  title={t("boxes_page.tooltip.edition")}
                                >
                                  <span>
                                    <IconButton
                                      onClick={() =>
                                        history.push(
                                          isStudOwner
                                            ? `/box/edit/${currentContext.studId}/${box.id}`
                                            : `/box/edit/${stud}/${box.id}`
                                        )
                                      }
                                      size="small"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              )}
                            />{" "}
                            |{" "}
                            <a
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                              onClick={() => setBoxIdToRemove(box.id)}
                            >
                              {t("remove")}
                            </a>{" "}
                          </TableCell>{" "}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                style={{ display: "flex" }}
                component="div"
                page={pageNr}
                rowsPerPageOptions={pages}
                rowsPerPage={rowsPerPage}
                count={boxesTotal}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t("pagination.rows")}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${t("pagination.out_of")} ${count}`
                }
              />{" "}
              {openConfirmPanelBox && (
                <ConfirmDialogMUI
                  handleClose={() => {
                    setBoxIdToRemove("");
                    setOpenConfirmPanelBox(false);
                  }}
                  open={openConfirmPanelBox}
                  text={t("boxes_page.remove_info")}
                  yesAction={() => {
                    handleBoxDelete(boxIdToRemove);
                  }}
                  noAction={(reason, event) => {
                    if (reason && reason == "backdropClick") return;
                    setOpenConfirmPanelBox(false);
                    setBoxIdToRemove("");
                  }}
                />
              )}
            </Box>
          ) : (
            <Box p={1} style={{ textAlign: "center" }}>
              <Typography variant="h5">
                {t("boxes_page.select_stud_label")}
              </Typography>
            </Box>
          )}

          {(roles.includes("ROLE_OWNER") ||
            roles.includes("ROLE_STUD_OWNER")) &&
            (stud || currentContext.studId) && (
              // <Can
              //   permission="form-add-page:view"
              //   ok={() => (
              <Box position="fixed" bottom={20} right={20}>
                <NavLink
                  to={
                    isOwner
                      ? `/box/add/${stud}`
                      : `/box/add/${currentContext.studId}`
                  }
                >
                  <Button
                    style={{ background: "#4caf50" }}
                    variant="contained"
                    color="primary"
                    className={classes.fontSize}
                  >
                    {`+ ${t("boxes_page.add_box_button")}`}
                  </Button>
                </NavLink>
              </Box>
              //   )}
              // />
            )}
        </>
      )}
    </Box>
  );
  const redirect = () => <Redirect to="/" />;

  return <Can permission="forms-page:view" ok={page} not={redirect} />;
};

export default Boxes;
