import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import PageTitle from "../../components/page-title";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCamera,
  alertAdd,
  editCamera,
  fetchCamera,
  fetchHorseFarms,
} from "../../redux/actions";

const initialCameraData = {
  // model: "",
  recorderChannel: "",
  recorderIP: "",
  vendor: "",
  isRTSP: false,
  username: null,
  password: null,
  credentialsType: null,
  passwordChanged: false,
};

export const vendorList = [
  { name: "Kamera RTSP", value: "rtsp" },
  { name: "Hikvision", value: "hikvision" },
];

const cameraReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE_INPUT_CHANGE":
      return {
        ...state,
        [action.property]: action.payload,
      };
    case "CAMERA_CHANGE":
      return { ...action.payload };
    case "CAMERA_TYPE_CHANGE":
      return {
        ...state,
        isRTSP: action.payload,
      };
    case "PASSWORD_TYPE_CHANGE":
      return {
        ...state,
        credentialsType: action.payload,
      };
    case "PASSWORD_CHANGED":
      return {
        ...state,
        passwordChanged: true,
      };
    case "PASSWORD_CHANGED_RESET":
      return {
        ...state,
        passwordChanged: false,
      };
    case "CLEAR_PASSWORD":
      return {
        ...state,
        password: null,
      };
    case "CLEAR_USERNAME":
      return {
        ...state,
        username: null,
      };
    case "CLEAR_DATA":
      return { ...state, ...initialCameraData };
    default:
      return state;
  }
};

const CameraAddPage = ({ history }) => {
  const { t } = useTranslation();
  const { cameraId, studId } = useParams();
  const dispatch = useDispatch();

  const my = useSelector((s) => s.my);
  const studList = useSelector((s) => s.orgFormsGroups);
  const isOwner = my.user.authorities.indexOf("ROLE_OWNER") !== -1;

  const [cameraState, dispatchCameraForm] = useReducer(
    cameraReducer,
    initialCameraData
  );
  const [isValid, setIsValid] = useState(false);
  const [stud, setStud] = useState(studId || "");

  const [usernameOrigin, setUsernameOrigin] = useState(null);
  const [passwordOrigin, setPasswordOrigin] = useState(null);
  const [credentialsTypeOrigin, setCredentialsTypeOrigin] = useState(null);

  useEffect(() => {
    (async () => {
      if (isOwner) {
        await dispatch(fetchHorseFarms({ page: 0, pageSize: 1000 }));
      }
    })();
  }, [isOwner]);

  useEffect(() => {
    (async () => {
      if (cameraId) {
        const response = await dispatch(
          fetchCamera({
            cameraId,
            ...(isOwner && { isOwner }),
            ...(studId && { studId }),
          })
        );
        const { credentials, credentialsType } = response; //REMOVE ME AFTER API INTEGRATION
        dispatchCameraForm({
          type: "CAMERA_CHANGE",
          payload: {
            ...response,
            ...(credentials && { username: credentials.username }),
            ...(credentialsType === "Custom" && { password: "12345678" }),
            ...(credentialsType
              ? { credentialsType }
              : { credentialsType: "Default" }),
          },
        });

        if (credentialsType === "Custom") {
          setUsernameOrigin(credentials?.username);
          setCredentialsTypeOrigin(credentialsType);
        }
      }
    })();
  }, [cameraId]);

  useEffect(() => {
    const {
      recorderChannel,
      recorderIP,
      vendor,
      credentialsType,
      username,
      password,
    } = cameraState;
    const passwordRequired = credentialsType === "Custom";
    if (isOwner) {
      passwordRequired
        ? setIsValid(
            recorderChannel &&
              recorderIP &&
              vendor &&
              (stud || studId) &&
              username &&
              password
          )
        : setIsValid(
            recorderChannel &&
              recorderIP &&
              vendor &&
              (stud || studId) &&
              credentialsType
          );
    } else {
      passwordRequired
        ? setIsValid(
            recorderChannel && recorderIP && vendor && username && password
          )
        : setIsValid(
            recorderChannel && recorderIP && vendor && credentialsType
          );
    }
  }, [cameraState, stud]);

  useEffect(() => {
    return () => {
      if (studId) {
        // && history.location.pathname === "any specific path")
        if (history.action === "POP") {
          history.replace(`/cameras/${studId}` /* the new state */);
        }
      }
    };
  }, [history]);

  const handleClearData = () => {
    dispatchCameraForm({
      type: "CLEAR_DATA",
    });
  };

  const handleSubmit = async () => {
    try {
      const {
        credentialsType,
        username,
        password,
        passwordChanged,
        ...rest
      } = cameraState;

      if (cameraId) {
        const credentials =
          credentialsType === "Custom" &&
          (username !== usernameOrigin || passwordChanged)
            ? {
                ...(username !== usernameOrigin || passwordChanged
                  ? { username }
                  : null),
                ...(passwordChanged ? { password } : null),
              }
            : null;
        const appendCredentialType =
          credentialsType !== credentialsTypeOrigin || credentials;

        await dispatch(
          editCamera({
            cameraId,
            ...rest,
            ...(credentials && credentialsType === "Custom" && { credentials }),
            ...(appendCredentialType && {
              credentialsType,
            }),
            ...(isOwner && { isOwner }),
            ...(studId && { studId }),
          })
        );
      } else {
        const credentials =
          credentialsType === "Custom"
            ? {
                username,
                password,
              }
            : null;
        await dispatch(
          addCamera({
            ...rest,
            credentialsType,
            ...(credentials && { credentials }),
            ...(isOwner && { isOwner }),
            ...(stud && { studId: stud }),
          })
        );
      }
      if (isOwner) {
        history.push(`/cameras/${studId}`);
      } else {
        history.push(`/cameras`);
      }
    } catch (e) {
      const { message } = e.response.data;

      if (message === "public_key_not_set") {
        dispatch(
          alertAdd({
            text: t("cameras_page.no_private_key_error"),
            isError: true,
          })
        );
      } else {
        dispatch(
          alertAdd({ text: t("cameras_page.no_context_error"), isError: true })
        );
      }
    }
  };

  const handleCredentialsTypeChange = (e) => {
    const value = e.target.value;
    const fieldName = e.target.name;
    if (value !== "Custom") {
      dispatchCameraForm({
        type: "PASSWORD_CHANGED_RESET",
      });
      dispatchCameraForm({
        type: "HANDLE_INPUT_CHANGE",
        property: "password",
        payload: usernameOrigin ? "12345678" : null,
      });
      dispatchCameraForm({
        type: "HANDLE_INPUT_CHANGE",
        property: "username",
        payload: usernameOrigin || null,
      });
    }
    dispatchCameraForm({
      type: "HANDLE_INPUT_CHANGE",
      property: fieldName,
      payload: value === cameraState.credentialsType ? null : value,
    });
  };

  return (
    <Box p={1}>
      <PageTitle
        title={
          cameraId ? t("breadcrumbs.edit_camera") : t("breadcrumbs.add_camera")
        }
      />
      <Box p={1} style={{ display: "flex", justifyContent: "center" }}>
        <Grid container sm={12} lg={6}>
          {isOwner && !cameraId && (
            <Grid item xs={12}>
              <FormControl
                margin="normal"
                fullWidth
                variant="outlined"
                required
              >
                <InputLabel id="select-outlined-label" required>
                  {t("horse_form.stud")}
                </InputLabel>
                <Select
                  labelId="stud-outlined-label"
                  id="stud-outlined"
                  disabled={stud}
                  value={stud}
                  onChange={(event) => setStud(event.target.value)}
                  label={`${t("horse_form.stud")} *`}
                  required
                >
                  {studList.map((stud, index) => {
                    return (
                      <MenuItem key={index} value={stud.id}>
                        {stud.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <TextField
              label={t("cameras_page.table.model")}
              margin="normal"
              name="model"
              fullWidth
              required
              value={cameraState.model}
              variant="outlined"
              onChange={({ target: { value } }) =>
                dispatchCameraForm({
                  type: "HANDLE_INPUT_CHANGE",
                  property: "model",
                  payload: value,
                })
              }
            />
          </Grid> */}{" "}
          <Grid item xs={12}>
            <FormControl margin="normal" fullWidth variant="outlined" required>
              <InputLabel id="select-outlined-label" required>
                {t("cameras_page.table.vendor")}
              </InputLabel>
              <Select
                labelId="vendor-outlined-label"
                id="vendor-outlined"
                value={cameraState.vendor}
                onChange={({ target: { value } }) => {
                  dispatchCameraForm({
                    type: "HANDLE_INPUT_CHANGE",
                    property: "vendor",
                    payload: value,
                  });
                  dispatchCameraForm({
                    type: "CAMERA_TYPE_CHANGE",
                    payload: value === "rtsp",
                  });
                }}
                label={`${t("cameras_page.table.vendor")} *`}
                required
              >
                {vendorList.map((vendor, index) => {
                  return (
                    <MenuItem key={index} value={vendor.value}>
                      {vendor.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t(
                `cameras_page.table.${
                  cameraState.isRTSP ? `recorderName` : `recorderChannel`
                }`
              )}
              margin="normal"
              name="recorderChannel"
              fullWidth
              required
              value={cameraState.recorderChannel}
              variant="outlined"
              onChange={({ target: { value } }) =>
                dispatchCameraForm({
                  type: "HANDLE_INPUT_CHANGE",
                  property: "recorderChannel",
                  payload: value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t(
                `cameras_page.table.${
                  cameraState.isRTSP ? `recorderAdress` : `recorderIP`
                }`
              )}
              margin="normal"
              name="recorderIP"
              fullWidth
              required
              value={cameraState.recorderIP}
              variant="outlined"
              onChange={({ target: { value } }) =>
                dispatchCameraForm({
                  type: "HANDLE_INPUT_CHANGE",
                  property: "recorderIP",
                  payload: value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" required>
              <FormLabel component="legend">
                {" "}
                {t("cameras_page.table.passwordLabel")}
              </FormLabel>
              <RadioGroup
                row
                aria-label="password-type"
                name="credentialsType"
                value={cameraState.credentialsType}
              >
                <FormControlLabel
                  value="Default"
                  control={
                    <Radio
                      color="primary"
                      onClick={handleCredentialsTypeChange}
                    />
                  }
                  label={t("cameras_page.table.default")}
                />
                <FormControlLabel
                  value="Custom"
                  control={
                    <Radio
                      color="primary"
                      onClick={handleCredentialsTypeChange}
                    />
                  }
                  label={t("cameras_page.table.custom")}
                />
                <FormControlLabel
                  value="None"
                  control={
                    <Radio
                      color="primary"
                      onClick={handleCredentialsTypeChange}
                    />
                  }
                  label={t("cameras_page.table.without")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {cameraState.credentialsType === "Custom" && (
            <>
              <Grid item xs={12}>
                <TextField
                  label={t(`cameras_page.table.username`)}
                  margin="normal"
                  name="username"
                  required
                  fullWidth
                  inputProps={{
                    autoComplete: "off",
                  }}
                  value={cameraState.username}
                  variant="outlined"
                  onChange={({ target: { value, name } }) => {
                    if (cameraId) {
                      value !== usernameOrigin &&
                        cameraState.username === usernameOrigin &&
                        dispatchCameraForm({
                          type: "CLEAR_PASSWORD",
                        });

                      value === usernameOrigin &&
                        dispatchCameraForm({
                          type: "HANDLE_INPUT_CHANGE",
                          property: "password",
                          payload: "12345678",
                        });

                      value === usernameOrigin &&
                        dispatchCameraForm({
                          type: "PASSWORD_CHANGED_RESET",
                        });
                    }
                    dispatchCameraForm({
                      type: "HANDLE_INPUT_CHANGE",
                      property: name,
                      payload: value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t(`cameras_page.table.password`)}
                  margin="normal"
                  name="password"
                  type="password"
                  inputProps={{
                    autocomplete: "new-password",
                  }}
                  required
                  fullWidth
                  value={cameraState.password || ""}
                  variant="outlined"
                  onFocus={() => {
                    cameraState.username === usernameOrigin &&
                      !cameraState.passwordChanged &&
                      dispatchCameraForm({
                        type: "CLEAR_PASSWORD",
                      });
                    dispatchCameraForm({
                      type: "PASSWORD_CHANGED",
                    });
                  }}
                  onBlur={() => {
                    if (
                      cameraState.username === usernameOrigin &&
                      !cameraState.password?.length &&
                      usernameOrigin
                    ) {
                      dispatchCameraForm({
                        type: "HANDLE_INPUT_CHANGE",
                        property: "password",
                        payload: "12345678",
                      });
                      dispatchCameraForm({
                        type: "PASSWORD_CHANGED_RESET",
                      });
                    }
                  }}
                  onChange={({ target: { value, name } }) => {
                    dispatchCameraForm({
                      type: "HANDLE_INPUT_CHANGE",
                      property: name,
                      payload: value,
                    });
                    dispatchCameraForm({
                      type: "PASSWORD_CHANGED",
                    });
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              disabled={!isValid}
              color="primary"
              style={{ padding: "8px 16px", marginRight: "15px" }}
              onClick={handleSubmit}
            >
              {cameraId ? t("edit") : t("add")}
            </Button>
            <Button
              variant="contained"
              style={{ padding: "8px 16px" }}
              onClick={handleClearData}
            >
              {t("clear")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CameraAddPage;
