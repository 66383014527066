import React, { useEffect, useReducer } from "react";
import DateFnsUtils from "@date-io/date-fns";
import plLocale from "date-fns/locale/pl";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import Loader from "../loader";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import AddVeterinaryVisitEvent from "./veterynary-visit";
import AddVaccinationEvent from "./vaccination";
import AddFoalingEvent, { times } from "./foalings";
import { ConfirmDialogMUI } from "../confirm-dialog-mui";
import { useDispatch, useSelector } from "react-redux";
import {
  addFoaling,
  addVaccination,
  addVeterinaryVisit,
  editFoaling,
  editVaccination,
  editVeterinaryVisit,
  fetchFoalings,
  fetchVaccinations,
  fetchVeterinaryVisits,
  removeFoaling,
  removeVaccination,
  removeVeterinaryVisit,
  togglePregnant,
} from "../../redux/actions";
import { format } from "date-fns";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "scroll",
    maxHeight: "90vh",
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: "80vw",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(1),
      width: "90vw",
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
      width: "95vw",
    },
  },
}));

const initialEventData = {
  veterinaryVisit: {
    id: "",
    opened: false,
    date: "",
    purpose: "",
    otherVisitPurpose: "",
    course: "",
    medicaments: "",
    recommendations: "",
    isValid: false,
    status: "",
  },
  vaccination: {
    id: "",
    opened: false,
    date: "",
    type: "",
    isValid: false,
    status: "",
  },
  foaling: {
    id: "",
    date: "",
    approximateTime: "",
    insemination: "",
    isValid: false,
    status: "",
  },
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "HANDLE_INPUT_CHANGE":
      return {
        ...state,
        [action.property]: {
          ...state[action.property],
          [action.field]: action.payload,
        },
      };
    case "VALID_FORM":
      return {
        ...state,
        [action.property]: {
          ...state[action.property],
          isValid: true,
        },
      };
    case "NOT_VALID_FORM":
      return {
        ...state,
        [action.property]: {
          ...state[action.property],
          isValid: false,
        },
      };
    case "TOGGLED_PANEL":
      return {
        ...initialEventData,
        [action.property]: {
          ...initialEventData[action.property],
          opened: action.payload,
        },
      };
    case "CLEAR_FORM_DATA":
      return {
        ...initialEventData,
        [action.property]: {
          ...initialEventData[action.property],
          opened: action.payload,
        },
      };
    case "CLEAR_FORM_DATA_TO_EDIT":
      return {
        ...initialEventData,
        [action.property]: {
          ...initialEventData[action.property],
          opened: true,
          id: action.payload,
        },
      };
    case "DATA_TO_EDIT":
      return {
        ...initialEventData,
        [action.property]: {
          ...action.payload,
          opened: action.payload,
        },
      };
    default:
      return state;
  }
};

function EventPermissionsPanel({
  open,
  hide,
  fetchHorses,
  horse: { gender, id, pregnant },
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalStyle] = useState(getModalStyle);

  const [formState, dispatchForm] = useReducer(formReducer, initialEventData);
  const [openInformPanel, setOpenInformPanel] = useState(false);

  const [openConfirmPanelFoaling, setOpenConfirmPanelFoaling] = useState(false);
  const [
    openConfirmPanelVaccination,
    setOpenConfirmPanelVaccination,
  ] = useState(false);
  const [openConfirmPanelVisit, setOpenConfirmPanelVisit] = useState(false);

  const [busy, setBusy] = useState(false);

  const vaccinations = useSelector((s) => s.vaccinations);
  const veterinaryVisits = useSelector((s) => s.veterinaryVisits);
  const foalings = useSelector((s) => s.foalings);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        setBusy(true);
        await dispatch(
          fetchVaccinations({
            q: { horseId: { is: id } },
          })
        );
        await dispatch(
          fetchVeterinaryVisits({
            q: { horseId: { is: id } },
          })
        );
        if (gender === "Mare") {
          await dispatch(
            fetchFoalings({
              q: { horseId: { is: id } },
            })
          );
        }
        setBusy(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const handleVaccinationSubmit = async () => {
    const { date, status, type } = formState.vaccination;
    let r = null;
    setBusy(true);
    if (!formState.vaccination.id) {
      r = await dispatch(
        addVaccination({
          horseId: id,
          date,
          status,
          vaccinationEvent: { typeOfVaccination: type },
        })
      );
    } else {
      r = await dispatch(
        editVaccination(
          {
            horseId: id,
            date,
            status,
            vaccinationEvent: { typeOfVaccination: type },
          },
          formState.vaccination.id
        )
      );
    }

    if (r) {
      dispatchForm({
        type: "TOGGLED_PANEL",
        payload: !formState.vaccination.opened,
        property: "vaccination",
      });

      await dispatch(
        fetchVaccinations({
          q: { horseId: { is: id } },
        })
      );
      setBusy(false);
    }
  };

  const handleVetVisitSubmit = async () => {
    const {
      date,
      status,
      purpose,
      course,
      medicaments,
      recommendations,
      otherVisitPurpose,
    } = formState.veterinaryVisit;
    let r = null;
    setBusy(true);
    if (!formState.veterinaryVisit.id) {
      r = await dispatch(
        addVeterinaryVisit({
          horseId: id,
          date,
          status,
          veterinaryVisitEvent: {
            ...(purpose ? { type: purpose } : {}),
            ...(otherVisitPurpose ? { otherVisitType: otherVisitPurpose } : {}),
            ...(course ? { courseOfTheVisit: course } : {}),
            ...(medicaments ? { medicaments } : {}),
            ...(recommendations
              ? { furtherRecommendations: recommendations }
              : {}),
          },
        })
      );
    } else {
      r = await dispatch(
        editVeterinaryVisit(
          {
            date,
            status,
            veterinaryVisitEvent: {
              ...(purpose ? { type: purpose } : {}),
              ...(otherVisitPurpose
                ? { otherVisitType: otherVisitPurpose }
                : {}),
              ...(course ? { courseOfTheVisit: course } : {}),
              ...(medicaments ? { medicaments } : {}),
              ...(recommendations
                ? { furtherRecommendations: recommendations }
                : {}),
            },
          },
          formState.veterinaryVisit.id
        )
      );
    }

    if (r) {
      dispatchForm({
        type: "TOGGLED_PANEL",
        payload: !formState.veterinaryVisit.opened,
        property: "veterinaryVisit",
      });

      await dispatch(
        fetchVeterinaryVisits({
          q: { horseId: { is: id } },
        })
      );
      setBusy(false);
    }
  };

  const handleFoalingSubmit = async () => {
    const {
      date,
      approximateTime,
      insemination,
      isValid,
      status,
    } = formState.foaling;
    let r = null;
    setBusy(true);
    if (!formState.foaling.id) {
      r = await dispatch(
        addFoaling({
          horseId: id,
          date,
          status,
          foalingEvent: {
            ...(approximateTime
              ? { approximateFoalingTime: approximateTime }
              : {}),
            ...(insemination ? { dateOfInsemination: insemination } : {}),
          },
        })
      );
    } else {
      r = await dispatch(
        editFoaling(
          {
            date,
            status,
            foalingEvent: {
              ...(approximateTime
                ? { approximateFoalingTime: approximateTime }
                : {}),
              ...(insemination ? { dateOfInsemination: insemination } : {}),
            },
          },
          formState.foaling.id
        )
      );
    }

    if (r) {
      dispatchForm({
        type: "TOGGLED_PANEL",
        payload: !formState.foaling.opened,
        property: "foaling",
      });

      await dispatch(
        fetchFoalings({
          q: { horseId: { is: id } },
        })
      );
      setBusy(false);
    }
  };

  const handleVaccinationDelete = async (vaccinationId) => {
    setBusy(true);
    const r = await dispatch(removeVaccination(vaccinationId));
    if (r) {
      await dispatch(
        fetchVaccinations({
          q: { horseId: { is: id } },
        })
      );
    }
    setBusy(false);
  };
  const handleVeterinaryVisitDelete = async (visitId) => {
    setBusy(true);
    const r = await dispatch(removeVeterinaryVisit(visitId));
    if (r) {
      await dispatch(
        fetchVeterinaryVisits({
          q: { horseId: { is: id } },
        })
      );
    }
    setBusy(false);
  };
  const handleFoalingDelete = async (foalingId) => {
    setBusy(true);
    const r = await dispatch(removeFoaling(foalingId));
    if (r) {
      await dispatch(
        fetchFoalings({
          q: { horseId: { is: id } },
        })
      );
    }
    setBusy(false);
  };

  const handleVaccinationEdit = async ({
    id,
    date,
    vaccinationEvent: { typeOfVaccination },
    status,
  }) => {
    dispatchForm({
      type: "DATA_TO_EDIT",
      payload: {
        id,
        date,
        type: typeOfVaccination,
        isValid: true,
        status,
      },
      property: "vaccination",
    });
  };

  const handleVeterinaryVisitEdit = async ({
    id,
    date,
    veterinaryVisitEvent: {
      type,
      otherVisitType,
      courseOfTheVisit,
      medicaments,
      furtherRecommendations,
    },
    status,
  }) => {
    dispatchForm({
      type: "DATA_TO_EDIT",
      payload: {
        id,
        date,
        purpose: type,
        otherVisitPurpose: otherVisitType,
        course: courseOfTheVisit,
        medicaments,
        recommendations: furtherRecommendations,
        isValid: true,
        status,
      },
      property: "veterinaryVisit",
    });
  };
  const handleFoalingEdit = async ({
    id,
    date,
    foalingEvent: { approximateFoalingTime, dateOfInsemination },
    status,
  }) => {
    dispatchForm({
      type: "DATA_TO_EDIT",
      payload: {
        id,
        date,
        approximateTime: approximateFoalingTime,
        insemination: dateOfInsemination,
        isValid: true,
        status,
      },
      property: "foaling",
    });
  };

  const handleSubmitPregnant = async () => {
    setOpenInformPanel(false);
    const r = await dispatch(togglePregnant({ pregnant: !pregnant }, id));
    if (r) {
      fetchHorses();
    }
  };

  const title = (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        {t("events.label")}
      </Typography>
    </Box>
  );
  const body = (
    <Box>
      {gender === "Mare" && (
        <Box p={1} component={Paper}>
          <Typography
            variant="subtitle1"
            gutterBottom
            style={{ fontWeight: "600", display: "inline" }}
          >
            {t("events.foalings.label")}
          </Typography>
          <a
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={() => {
              dispatchForm({
                type: "TOGGLED_PANEL",
                payload: !formState.foaling.opened,
                property: "foaling",
              });

              if (formState.foaling.opened) {
                dispatchForm({
                  type: "CLEAR_FORM_DATA",
                  payload: !formState.foaling.opened,
                  property: "foaling",
                });
              }
            }}
          >
            {formState.foaling.opened
              ? t("events.close")
              : t("events.foalings.add_new_foaling")}
          </a>
          {formState.foaling.opened && (
            <AddFoalingEvent
              formState={formState}
              dispatch={dispatchForm}
              handleSubmit={handleFoalingSubmit}
              t={t}
            />
          )}
          <Box>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormControlLabel
                labelPlacement="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={pregnant}
                    onChange={() => {
                      if (!pregnant) {
                        setOpenInformPanel(true);
                      } else {
                        handleSubmitPregnant();
                      }
                    }}
                    name="inPregnant"
                  />
                }
                label={t("events.pregnant.label")}
              />
            </FormControl>
          </Box>
          {
            <ConfirmDialogMUI
              handleClose={() => {
                setOpenInformPanel(false);
              }}
              open={openInformPanel}
              text={t("events.pregnant.info")}
              type="info"
              submitAction={handleSubmitPregnant}
            />
          }

          {foalings.length ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("events.foalings.date")}</TableCell>
                    <TableCell align="right">
                      {t("events.foalings.time")}
                    </TableCell>
                    <TableCell align="right">
                      {t("events.foalings.insemination")}
                    </TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {foalings.map((foaling) => (
                    <TableRow key={foaling.id}>
                      <TableCell align="right">
                        {format(new Date(foaling.date), "yyyy-MM-dd")}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {times.find(
                          (t) =>
                            t.value ===
                            foaling.foalingEvent.approximateFoalingTime
                        ).label || ""}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {format(
                          new Date(foaling.foalingEvent.dateOfInsemination),
                          "yyyy-MM-dd"
                        )}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {t(`events.status.${foaling?.status}`)}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <a
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                          onClick={() => handleFoalingEdit(foaling)}
                        >
                          {t("edit")}
                        </a>
                        |{" "}
                        <a
                          style={{ marginLeft: "10px", marginRight: "10px" }}
                          onClick={() => setOpenConfirmPanelFoaling(true)}
                        >
                          {t("remove")}
                        </a>{" "}
                      </TableCell>
                      {openConfirmPanelFoaling && (
                        <ConfirmDialogMUI
                          handleClose={() => {
                            setOpenConfirmPanelFoaling(false);
                          }}
                          open={openConfirmPanelFoaling}
                          text={t("events.remove_info")}
                          yesAction={() => {
                            handleFoalingDelete(foaling.id);
                          }}
                          noAction={() => {
                            setOpenConfirmPanelFoaling(false);
                          }}
                        />
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </Box>
      )}{" "}
      <br />
      <Box p={1} component={Paper}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: "600", display: "inline" }}
        >
          {t("events.veterinaryVisits.label")}
        </Typography>
        <a
          style={{ marginLeft: "10px", marginRight: "10px" }}
          onClick={() => {
            dispatchForm({
              type: "TOGGLED_PANEL",
              payload: !formState.veterinaryVisit.opened,
              property: "veterinaryVisit",
            });

            if (formState.veterinaryVisit.opened) {
              dispatchForm({
                type: "CLEAR_FORM_DATA",
                payload: !formState.veterinaryVisit.opened,
                property: "veterinaryVisit",
              });
            }
          }}
        >
          {formState.veterinaryVisit.opened
            ? t("events.close")
            : t("events.veterinaryVisits.add_new_visit")}
        </a>

        {formState.veterinaryVisit.opened && (
          <AddVeterinaryVisitEvent
            formState={formState}
            dispatch={dispatchForm}
            handleSubmit={handleVetVisitSubmit}
            t={t}
          />
        )}
        {veterinaryVisits.length ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">
                    {t("events.veterinaryVisits.date")}
                  </TableCell>
                  <TableCell>{t("events.veterinaryVisits.purpose")}</TableCell>
                  <TableCell>{t("events.veterinaryVisits.course")}</TableCell>
                  <TableCell align="right">
                    {t("events.veterinaryVisits.medicaments")}
                  </TableCell>
                  <TableCell align="right">
                    {t("events.veterinaryVisits.recommendations")}
                  </TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {veterinaryVisits.map((visit) => (
                  <TableRow>
                    <TableCell align="right">
                      {format(new Date(visit.date), "yyyy-MM-dd")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {t(
                        `events.veterinaryVisits.${visit?.veterinaryVisitEvent?.type}`
                      )}
                      {visit.veterinaryVisitEvent?.type === "Other" &&
                        `: ${visit.veterinaryVisitEvent?.otherVisitType}`}
                    </TableCell>
                    <TableCell align="right">
                      {visit.veterinaryVisitEvent?.courseOfTheVisit}
                    </TableCell>
                    <TableCell align="right">
                      {visit.veterinaryVisitEvent?.medicaments}
                    </TableCell>
                    <TableCell align="right">
                      {visit.veterinaryVisitEvent?.furtherRecommendations}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {t(`events.status.${visit.status}`)}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      <a
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        onClick={() => handleVeterinaryVisitEdit(visit)}
                      >
                        {t("edit")}
                      </a>
                      |{" "}
                      <a
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        onClick={() => setOpenConfirmPanelVisit(true)}
                      >
                        {t("remove")}
                      </a>{" "}
                    </TableCell>

                    {openConfirmPanelVisit && (
                      <ConfirmDialogMUI
                        handleClose={() => {
                          setOpenConfirmPanelVisit(false);
                        }}
                        open={openConfirmPanelVisit}
                        text={t("events.remove_info")}
                        yesAction={() => {
                          handleVeterinaryVisitDelete(visit.id);
                        }}
                        noAction={() => setOpenConfirmPanelVisit(false)}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
      <br />
      <Box p={1} component={Paper}>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: "600", display: "inline" }}
        >
          {t("events.vaccinations.label")}
        </Typography>
        <a
          style={{ marginLeft: "10px", marginRight: "10px" }}
          onClick={() => {
            dispatchForm({
              type: "TOGGLED_PANEL",
              payload: !formState.vaccination.opened,
              property: "vaccination",
            });

            if (formState.vaccination.opened) {
              dispatchForm({
                type: "CLEAR_FORM_DATA",
                payload: !formState.vaccination.opened,
                property: "vaccination",
              });
            }
          }}
        >
          {formState.vaccination.opened
            ? t("events.close")
            : t("events.vaccinations.add_new_vaccination")}
        </a>

        {formState.vaccination.opened && (
          <AddVaccinationEvent
            formState={formState}
            dispatch={dispatchForm}
            handleSubmit={handleVaccinationSubmit}
            t={t}
          />
        )}

        {vaccinations.length ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="right">
                    {t("events.vaccinations.date")}
                  </TableCell>
                  <TableCell>{t("events.vaccinations.type")}</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vaccinations.map((v) => (
                  <TableRow key={v?.id}>
                    <TableCell align="right">
                      {format(new Date(v?.date), "yyyy-MM-dd")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {v.vaccinationEvent.typeOfVaccination}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {t(`events.status.${v?.status}`)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <a
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        onClick={() => handleVaccinationEdit(v)}
                      >
                        {t("edit")}
                      </a>
                      |{" "}
                      <a
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                        onClick={() => setOpenConfirmPanelVaccination(true)}
                      >
                        {t("remove")}
                      </a>{" "}
                    </TableCell>

                    {openConfirmPanelVaccination && (
                      <ConfirmDialogMUI
                        handleClose={() => {
                          setOpenConfirmPanelVaccination(false);
                        }}
                        open={openConfirmPanelVaccination}
                        text={t("events.remove_info")}
                        yesAction={() => {
                          handleVaccinationDelete(v.id);
                        }}
                        noAction={() => setOpenConfirmPanelVaccination(false)}
                      />
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Box>
      <Box pt={2} textAlign="center">
        <Button
          size="small"
          variant="contained"
          style={{ padding: "8px 16px" }}
          onClick={hide}
        >
          {t("events.close")}
        </Button>
      </Box>
    </Box>
  );
  const foot = <div>foot</div>;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={hide}
      style={{ overflow: "scroll" }}
    >
      <Box style={modalStyle} className={classes.paper}>
        {title}
        {busy ? (
          <Box p={1}>
            <Loader loading={true} text={t("fetching")} />
          </Box>
        ) : (
          <>{body}</>
        )}
      </Box>
    </Modal>
  );
}

export default EventPermissionsPanel;
