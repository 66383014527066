import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../loader";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    overflow: "scroll",
    maxHeight: "90vh",
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    minWidth: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1),
      minWidth: "90%",
    },
  },
}));

function BoxPermissionsPanel({ open, hide }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const [box, setBox] = useState("");
  const [streamUrl, setStreamUrl] = useState("");
  const [name, setName] = useState("");
  const [formValid, setFormValid] = useState(false);

  const [newBoxOpen, setNewBoxOpen] = useState(false);

  const { t } = useTranslation();

  const handleClickNewBox = () => {
    setNewBoxOpen(!newBoxOpen);
  };

  useEffect(() => {
    setFormValid(name && streamUrl);
  }, [name, streamUrl]);

  const handleClearData = () => {
    setName("");
    setStreamUrl("");
  };

  const title = (
    <Box mt={1}>
      <Typography variant="h6" gutterBottom>
        {t("box_panel.label")}
      </Typography>
    </Box>
  );
  const body = (
    <Box p={1}>
      <Grid container spacing={2} sm={12} lg={6}>
        <Grid item xs={12}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel id="open-gender-select-label">
              {t("box_panel.placeholder")}
            </InputLabel>
            <Select
              labelId="open-gender-select-label"
              id="open-gender-select"
              label={t("box_panel.placeholder")}
              value={box || ""}
              onChange={({ target: { value } }) => {
                setBox(value);
              }}
              autoWidth={true}
            >
              <MenuItem value="Box 1">Box 1</MenuItem>
              <MenuItem value="Box 2">Box 2</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <a onClick={handleClickNewBox}>
            {newBoxOpen ? (
              <span>{t("box_panel.add_new_box_close")}</span>
            ) : (
              <span>{t("box_panel.add_new_box")}</span>
            )}
          </a>
        </Grid>
        {!newBoxOpen && (
          <Grid item xs={12}>
            <Button
              size="small"
              variant="contained"
              disabled={!box}
              color="primary"
              style={{ padding: "8px 16px", marginRight: "15px" }}
            >
              {t("save")}
            </Button>
          </Grid>
        )}
      </Grid>
      {newBoxOpen && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <hr style={{ width: "100%" }} />

          <Grid container spacing={2} sm={12} lg={6}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                {t("box_panel.add_new_box")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("box_panel.url_stream")}
                variant="outlined"
                required
                value={streamUrl}
                fullWidth
                onChange={({ target: { value: streamUrl } }) =>
                  setStreamUrl(streamUrl)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("box_panel.box_name")}
                variant="outlined"
                required
                value={name}
                fullWidth
                onChange={({ target: { value: name } }) => setName(name)}
              />
            </Grid>{" "}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                size="small"
                variant="contained"
                disabled={!formValid}
                color="primary"
                style={{ padding: "8px 16px", marginRight: "15px" }}
              >
                {t("add")}
              </Button>
              <Button
                size="small"
                variant="contained"
                style={{ padding: "8px 16px" }}
                onClick={handleClearData}
              >
                {t("clear")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
  const foot = <div>foot</div>;

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={hide}
      style={{ overflow: "scroll" }}
    >
      <Box style={modalStyle} className={classes.paper}>
        {title}
        {true ? (
          <>{body}</>
        ) : (
          <Box p={1}>
            <Loader loading={true} text={t("fetching")} />
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default BoxPermissionsPanel;
