import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { recoverPassword } from "../../redux/actions";
import { NavLink } from "react-router-dom";
import emailValidator from "email-validator";
import Loader from "../../components/loader";
import Grid from "@material-ui/core/Grid";
import LeftComponentMui from "../../left-component-mui";
import { Box } from "@material-ui/core";
import { LockOpen } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";

const WhiteButton = styled(Button)`
  background-color: white;
`;

const PasswordEmployeeResetPage = ({
  recoverPassword,
  recoveringPassword,
  history,
}) => {
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);
  const [sent, setSent] = useState(false);
  const [sentMessage, setSentMessage] = useState("");
  const input = useRef();

  const { t } = useTranslation();

  const [emailDisabled, setEmailDisabled] = useState(false);

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  });

  const reset = async (e) => {
    e.preventDefault();

    if (valid) {
      await recoverPassword({ email, role: "employee" });

      setSent(true);

      setSentMessage(`Wiadomość z nowym hasłem została wysłana na
          email ${email}`);
    }
  };

  useEffect(() => {
    setValid(emailValidator.validate(email));
  }, [email]);

  return (
    <Grid container>
      <Grid item sm={12} xs={12} md={8}>
        <LeftComponentMui />
      </Grid>

      <Grid item sm={12} xs={12} md={4}>
        <Box p={1}>
          <Box textAlign="center">
            <LockOpen />
          </Box>

          <Box as="h1" fontSize={20} textAlign="center">
            {t("recovery.label")}
          </Box>

          <form onSubmit={reset} noValidate autoComplete="off">
            <Box mt={1}>
              <TextField
                label="Email"
                variant="outlined"
                value={email}
                disabled={emailDisabled}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>

            <Box mt={1} display="flex">
              <WhiteButton color="#000" variant={"contained"} fullWidth md={5}>
                <NavLink to="/login-employee" color="#000">
                  {t("recovery.back")}
                </NavLink>
              </WhiteButton>

              <Button
                variant={"contained"}
                color="primary"
                onClick={reset}
                disabled={!valid}
                fullWidth
                md={7}
              >
                {t("recovery.reset")}
              </Button>
            </Box>

            <Loader loading={recoveringPassword} text={t("recovery.pedning")} />

            {sent && (
              <div className="field is-grouped is-grouped-right">
                <div className="is-size-6">
                  <Alert severity="success">
                    Wiadomość z nowym hasłem została wysłana na{" "}
                    {`email ${email}`}
                  </Alert>
                </div>
              </div>
            )}
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  recoveringPassword: state.recoveringPassword,
});

const mapDispatchToProps = (dispatch) => ({
  recoverPassword: (args) => dispatch(recoverPassword(args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordEmployeeResetPage);
