import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";
import Loader from "../loader";

const anomalyMapperMetric = {
  1: "horse_activity",
  2: "posture",
};
const anomalyMapper = {
  1: "lie_down_first",
  2: "lie_down_not",
  3: "lie_down_often",
  4: "lie_down_short_break",
  5: "lie_down_short_lying_time",
  6: "movement_often",
};

const anomalyTranslationPLMapper = {
  1: {
    current: (currentValue) => (
      <span>
        W ciągu ostatniej doby koń leżał{" "}
        <strong>{currentValue.toString().replace(".", ",")}</strong>{" "}
        {currentValue == 1 ? "raz" : "razy"}.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        Do tej pory maksymalnie leżał{" "}
        <strong>{historicalValue.toString().replace(".", ",")}</strong>{" "}
        {historicalValue == 1 ? "raz" : "razy"} w ciągu doby.
      </span>
    ),
  },
  2: {
    current: (currentValue) => (
      <span>
        W ciągu ostatniej doby koń leżał{" "}
        <strong>{currentValue.toString().replace(".", ",")}</strong>{" "}
        {currentValue == 1 ? "raz" : "razy"}.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        Do tej pory maksymalnie leżał{" "}
        <strong>{historicalValue.toString().replace(".", ",")}</strong>{" "}
        {historicalValue == 1 ? "raz" : "razy"} w ciągu doby.
      </span>
    ),
  },
  3: {
    current: (currentValue) => (
      <span>
        W ciągu ostatniej doby koń leżał{" "}
        <strong>{currentValue.toString().replace(".", ",")}</strong>{" "}
        {currentValue == 1 ? "raz" : "razy"}.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        Do tej pory maksymalnie leżał{" "}
        <strong>{historicalValue.toString().replace(".", ",")}</strong>{" "}
        {historicalValue == 1 ? "raz" : "razy"} w ciągu doby.
      </span>
    ),
  },
  4: {
    current: (currentValue) => (
      <span>
        Koń znów się położył, przerwa od ostatniego leżenia to:{" "}
        <strong>{currentValue.toString().replace(".", ",")}</strong> min.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        Do tej pory standardowy czas przerwy to:{" "}
        <strong>{historicalValue.toString().replace(".", ",")}</strong> min.
      </span>
    ),
  },
  5: {
    current: (currentValue) => (
      <span>
        Koń wstał, czas ostatniego leżenia to:{" "}
        <strong>{currentValue.toString().replace(".", ",")}</strong> min.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        Do tej pory standardowy czas leżenia to:{" "}
        <strong>{historicalValue.toString().replace(".", ",")}</strong> min.
      </span>
    ),
  },
  6: {
    current: (currentValue) => (
      <span>
        Wzrost ruchliwości konia w ostatnich 30 minutach o{" "}
        <strong>
          {(Math.round(currentValue * 100) / 100).toString().replace(".", ",")}%
        </strong>
        .
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        {/* Do tej pory standardowa ruchliwość konia to:{" "}
        <strong>{Math.round(historicalValue * 100)}%</strong>. */}
      </span>
    ),
  },
};
const anomalyTranslationENMapper = {
  1: {
    current: (currentValue) => (
      <span>
        This horse has been lying <strong>{currentValue}</strong> within the
        last 24 hours.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        It spent <strong>{historicalValue}</strong> times on lying within 24
        hours so far.
      </span>
    ),
  },
  2: {
    current: (currentValue) => (
      <span>
        This horse has been lying <strong>{currentValue}</strong> within the
        last 24 hours.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        It spent <strong>{historicalValue}</strong> times on lying within 24
        hours so far.
      </span>
    ),
  },
  3: {
    current: (currentValue) => (
      <span>
        This horse has been lying <strong>{currentValue}</strong> within the
        last 24 hours.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        It spent <strong>{historicalValue}</strong> times on lying within 24
        hours so far.
      </span>
    ),
  },
  4: {
    current: (currentValue) => (
      <span>
        The horse lay down again. <strong>{currentValue}</strong> minutes is up
        when it happend for the last time.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        Usually it happens every: <strong>{historicalValue}</strong> minutes.
      </span>
    ),
  },
  5: {
    current: (currentValue) => (
      <span>
        The horse got up. Last lying time: <strong>{currentValue}</strong>{" "}
        minutes.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        Lying time: <strong>{historicalValue}</strong> minutes so far.
      </span>
    ),
  },
  6: {
    current: (currentValue) => (
      <span>
        Horse activity has increased by:{" "}
        <strong>{Math.round(currentValue * 100) / 100}%</strong> in the last 30
        minutes.
      </span>
    ),
    historical: (historicalValue) => (
      <span>
        Standard horse's movement was:{" "}
        <strong>{Math.round(historicalValue)}%</strong> so far.
      </span>
    ),
  },
};

const Anomaly = () => {
  const anomalies = useSelector((s) => s.anomalies);
  const anomaliesTotal = useSelector((s) => s.anomaliesTotal);
  const anomaliesFetching = useSelector((s) => s.anomaliesFetching);

  const studHorses = useSelector((s) => s.studHorses);

  const { t } = useTranslation();

  const selectedLang = localStorage.getItem("i18nextLng") || "pl";

  return !anomaliesTotal ? (
    <Box p={1} style={{ textAlign: "center" }}>
      <Typography variant="h6">{t("anomaly.no_anomalies")}</Typography>
    </Box>
  ) : (
    <Box>
      {anomalies.map((anomaly) => (
        <Alert severity="warning" style={{ margin: "10px" }}>
          <AlertTitle>{t("anomaly.label")}</AlertTitle>
          <div style={{ marginBottom: "0.35em" }}>
            <p style={{ fontSize: "1rem" }}>
              {studHorses.find((h) => h.id === anomaly.horseId)?.name ? (
                <>
                  <span>{t("alert.filter.horse")}: </span>
                  <strong>
                    {studHorses.find((h) => h.id === anomaly.horseId)?.name}
                  </strong>
                </>
              ) : (
                <>
                  <strong>{`<${t("alert.filter.horse_removed")}>`}</strong>
                </>
              )}
            </p>
          </div>
          <h6>
            <strong>
              {format(new Date(anomaly.timestamp), "yyyy-MM-dd HH:mm")}
            </strong>
          </h6>
          {selectedLang === "pl" ? (
            <p>
              Wykryto anomalię -{" "}
              <strong>
                {t(`anomaly.${anomalyMapper[anomaly.anomalyType]}`)}.
              </strong>{" "}
              Anomalia związana z metryką monitorującą{" "}
              <strong>
                {t(`anomaly_metric.${anomalyMapperMetric[anomaly.metric]}`)}
              </strong>
              .
              <br />
              {anomalyTranslationPLMapper[anomaly.anomalyType]?.current(
                anomaly.anomalyType === 6
                  ? ((anomaly.currentValue - anomaly.historicalValue) /
                      anomaly.historicalValue) *
                      100
                  : Math.round(anomaly.currentValue * 100) / 100
              )}{" "}
              {/* {anomalyTranslationPLMapper[anomaly.anomalyType]?.historical(
                anomaly.anomalyType === 6
                  ? (anomaly.currentValue - anomaly.historicalValue) /
                      anomaly.historicalValue /
                      100
                  : Math.round(anomaly.historicalValue * 100) / 100
              )} */}
            </p>
          ) : (
            <p>
              {/* Anomaly detected -{" "}
              <strong>
                {t(`anomaly.${anomalyMapper[anomaly.anomalyType]}`)}.
              </strong>{" "}
              The anomaly is related to the metric monitoring:{" "}
              <strong>
                {t(`anomaly_metric.${anomalyMapperMetric[anomaly.metric]}`)}.
              </strong>{" "}
              {anomalyTranslationENMapper[anomaly.anomalyType]?.current(
                Math.round(anomaly.currentValue * 100) / 100
              )}{" "}
              {anomalyTranslationENMapper[anomaly.anomalyType]?.historical(
                Math.round(anomaly.historicalValue * 100) / 100
              )} */}
              An anomaly has been detected -{" "}
              <strong>
                {t(`anomaly.${anomalyMapper[anomaly.anomalyType]}`)}.
              </strong>{" "}
              The anomaly is related to the metric monitoring{" "}
              <strong>
                {t(`anomaly_metric.${anomalyMapperMetric[anomaly.metric]}`)}
              </strong>
              .
              <br />
              {anomalyTranslationENMapper[anomaly.anomalyType]?.current(
                anomaly.anomalyType === 6
                  ? ((anomaly.currentValue - anomaly.historicalValue) /
                      anomaly.historicalValue) *
                      100
                  : Math.round(anomaly.currentValue * 100) / 100
              )}{" "}
            </p>
          )}
        </Alert>
      ))}
    </Box>
  );
};

export default Anomaly;
