import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
  TableContainer,
} from "@material-ui/core";
import {
  addPerson,
  alertAdd,
  fetchForms,
  fetchHorseFarms,
  fetchHorsesByStud,
  fetchHorsesByStudId,
  saveUsersAccessToHorse,
} from "../../redux/actions";
import { getAxiosInstance } from "../../redux/common";
import { checkPermission } from "../../rbac";
import Loader from "../../components/loader";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const MAX_GROUP_AND_FORM_NAME_LENTGH = 25;

const GroupsAndFormsPermissionsDialog = ({
  person,
  stud = "",
  isOwner,
  fetchHorseFarms,
  open,
  handleClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [studHorses, setStudHorses] = useState([]);

  const [selectedHorses, setSelectedHorses] = useState([]);
  const [allDataSubmitted, setAllDataSubmitted] = useState(false);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    if (allDataSubmitted) {
      handleClose();
    }
  }, [allDataSubmitted]);

  useEffect(() => {
    let horsesList = [];

    if (isOwner) {
      (async () => {
        horsesList = await dispatch(fetchHorsesByStudId({ page: 0, stud }));
        setStudHorses(horsesList);
      })();
    } else {
      (async () => {
        horsesList = await dispatch(fetchHorsesByStud({ page: 0 }));
        setStudHorses(horsesList.content);
      })();
    }
    setBusy(false);
  }, []);

  const personData = `${person.name ? person.name : ""} ${
    person.mail ? `(${person.mail})` : ""
  }`;

  const save = async () => {
    const promises = selectedHorses.map(async (selectedHorse) => {
      const response = await dispatch(
        saveUsersAccessToHorse(selectedHorse, person.id)
      );
      return response;
    });

    const responseAll = await Promise.all(promises);

    if (
      responseAll.length === selectedHorses.length &&
      responseAll.every((r) => r === true)
    ) {
      setAllDataSubmitted(true);
    }
  };

  const tableHead = () => {
    return (
      <TableHead>
        <TableRow>
          <TableCell> {t("assign_user_panel.horse_name")}</TableCell>
          <TableCell> {t("assign_user_panel.access")}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      // onClose={(reason) => {
      //   if (!reason === "backdropClick") {
      //     handleClose();
      //   }
      // }}
    >
      <DialogTitle>
        <div>
          <Typography variant="h6" gutterBottom>
            {t("assign_user_panel.label")}
            {personData}
          </Typography>
        </div>
      </DialogTitle>
      {busy ? (
        <Box p={1}>
          {" "}
          <Loader loading={true} text={t("horses_page.fetching")} />
        </Box>
      ) : (
        <Grid container justify="center">
          <Grid item container xs={12} alignItems="center" direction="column">
            <Typography variant="h6" gutterBottom>
              {t("assign_user_panel.horses")}
            </Typography>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
                size="small"
              >
                {tableHead()}

                <TableBody>
                  {studHorses?.map((horse) => {
                    const isHorseSelected = selectedHorses.filter(
                      (id) => id === horse.id
                    ).length;
                    return (
                      <TableRow key={horse.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ width: "80%" }}
                        >
                          {horse.name}
                          <br />
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={isHorseSelected}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedHorses([
                                      ...selectedHorses,
                                      horse.id,
                                    ]);
                                  } else {
                                    setSelectedHorses(
                                      selectedHorses.filter(
                                        (selectedHorse) =>
                                          selectedHorse !== horse.id
                                      )
                                    );
                                  }
                                }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          disabled={busy}
          color="primary"
          onClick={() => {
            save();
          }}
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchHorseFarms: () => dispatch(fetchHorseFarms()),
});

export default connect(
  null,
  mapDispatchToProps
)(GroupsAndFormsPermissionsDialog);
