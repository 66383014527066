import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchHasSingleScheduledForm,
  fetchOrganization,
  logout,
  logout as loogutAction,
  smallFontSize,
  mediumFontSize,
  largeFontSize,
  toggleGlobalTheme,
  fetchForms,
} from "../../redux/actions";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import MenuIcon from "@material-ui/icons/Menu";
import HelpIcon from "@material-ui/icons/Help";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import { AccountCircle, UnfoldMore } from "@material-ui/icons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PeopleIcon from "@material-ui/icons/People";
import FolderIcon from "@material-ui/icons/Folder";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Can from "../can";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DoneIcon from "@material-ui/icons/Done";
import Button from "@material-ui/core/Button";
import { Tooltip } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FontDownloadIcon from "@material-ui/icons/FontDownload";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Breadcrumbs from "../breadcrumbs/index";
import UnsupportedBrowserAlert from "../unsupported-browser-alert";
import LanguageSelect from "../select-language";
import { useTranslation } from "react-i18next";
import ContextSelect from "../context";
import VideocamIcon from "@material-ui/icons/Videocam";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import imageHeader from "../../assets/logo-menu.png";

export const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  fontSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.fontSize,
  },
  mainContent: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      marginTop: 90,
      minHeight: `calc(100vh -90px)`,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(
        theme.typography.body1.fontSize === "1.5rem" ? 13 : 8
      ),
      minHeight: `calc(100vh - ${theme.spacing(
        theme.typography.body1.fontSize === "1.5rem" ? 13 : 11.5
      )}px)`,
    },
  },
  mainContentShift: {
    [theme.breakpoints.up("sm")]: {
      marginLeft:
        theme.typography.body1.fontSize === "1.5rem"
          ? theme.spacing(9) + 15
          : theme.spacing(9) + 1,
      width: `calc(100% - ${
        theme.typography.body1.fontSize === "1.5rem"
          ? theme.spacing(9) + 15
          : theme.spacing(9) + 1
      }px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  mainContentShiftLarge: {
    [theme.breakpoints.up("sm")]: {
      marginLeft:
        theme.typography.body1.fontSize === "1.5rem"
          ? drawerWidth * 1.5
          : drawerWidth,
      width: `calc(100% - ${
        theme.typography.body1.fontSize === "1.5rem"
          ? drawerWidth * 1.5
          : drawerWidth
      }px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    // backgroundColor: theme.drawer.backgroundColor,
  },
  drawerOpen: {
    width:
      theme.typography.body1.fontSize === "1.5rem"
        ? drawerWidth * 1.5
        : drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderRight: `1px solid ${theme.border?.primary}`,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width:
      theme.typography.body1.fontSize === "1.5rem"
        ? theme.spacing(9) + 15
        : theme.spacing(9) + 1,
    borderRight: `1px solid ${theme.border?.primary}`,
  },
  drawerActive: {
    backgroundColor: "#ffbe00",
  },
  drawerActiveContrast: {
    border: `3px solid ${theme.palette.text.primary}`,
  },
  drawerPaper: {
    width:
      theme.typography.body1.fontSize === "1.5rem"
        ? drawerWidth * 1.5
        : drawerWidth,
  },
  toolbar: {
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    backgroundColor: theme.palette.primary.main,
    ...theme.mixins.toolbar,
    [theme.breakpoints.up("sm")]: {
      minHeight: `90px`,
    },
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    color: theme.palette.background.default,
  },
  customBadge: {
    right: -7,
    top: 5,
    width: 5,
    height: 15,
    fontSize: 11,
  },
  onHover: {
    "&:hover": {
      border: `1px solid ${theme.palette.text.primary}`,
    },
  },
  icon: {
    color: `${
      theme.palette.text.primary === "#fff200" && theme.palette.text.primary
    }`,
    marginRight: `${theme.typography.body1.iconSize === "60px" && "30px"}`,
  },
  iconSize: {
    "& .MuiAlert-icon": {
      fontSize: theme.typography.body1.iconSize,
    },
    fontSize: theme.typography.body1.iconSize,
  },
  iconHeader: {
    color: theme.palette.background.default,
    padding: "5px",
  },
  selectedIconHeader: {
    border: `1px solid ${theme.palette.background.default}`,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      border: `1px solid ${theme.palette.background.default}`,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
    },
    padding: "10px",
  },
  alertIcon: {
    color: theme.palette.text.primary === "#fff200" ? "" : "orange",
    marginRight: theme.spacing(2),
  },
}));

const Header = ({
  history,
  loggedIn,
  my,
  // cartItems,
  location,
  children,
  ...rest
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const upMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const organization = useSelector((s) => s.organization);
  const globalTheme = useSelector((s) => s.globalTheme);
  const baseFontSize = useSelector((s) => s.baseFontSize);
  const appBarTitle = useSelector((s) => s.appBarTitle);
  const forms = useSelector((s) => s.orgForms);
  const roles = useSelector((s) => s.roles);
  const hasSingleScheduledForm = useSelector((s) => s.hasSingleScheduledForm);
  const unsupportedBrowser = useSelector((s) => s.unsupportedBrowser);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const [menuOpened, setMenuOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const unsupportedBrowserAlert = unsupportedBrowser
    ? "Korzystasz z niewspieranej wersji przeglądarki, niektóre funkcje mogą nie działać prawidłowo. "
    : "";

  useEffect(() => {
    if (loggedIn && !organization) {
      dispatch(fetchOrganization());
    }
  }, [organization, loggedIn]);

  useEffect(() => {
    if (
      roles.indexOf("ROLE_OWNER") !== -1 ||
      roles.indexOf("ROLE_EDITOR") !== -1
    ) {
      dispatch(fetchHasSingleScheduledForm());
      dispatch(fetchForms({ page: 0, pageSize: 100 }));
    }
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleInstructionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();

  const isOwner = !!my?.user.authorities.includes("ROLE_OWNER");
  const isEditor = my?.user.authorities.localeCompare("ROLE_EDITOR") === 0;
  const isUser = my?.user.authorities.localeCompare("ROLE_USER") === 0;
  const isStudOwner =
    my?.user.authorities.localeCompare("ROLE_STUD_OWNER") === 0;

  let instructionPathForRole = "";
  if (my?.user.authorities.localeCompare("ROLE_ADMIN") === 0) {
    instructionPathForRole = "/org-media/files/public/admin1.mp4";
  } else if (isOwner) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_wlasciciela_organizacji.pdf";
  } else if (isEditor) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_koordynatora.pdf";
  } else if (isUser) {
    instructionPathForRole =
      "/org-media/files/public/instrukcja_uzytkownika.pdf";
  }

  let instructionPathForPage = "";
  if (location.pathname.match("^/$")) {
    if (isUser) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_start_user.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_start_owner_editor.pdf";
    }
  } else if (location.pathname.match("^/profile/api-keys")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_api-keys.pdf";
  } else if (location.pathname.match("^/profile")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_profil_owner.pdf";
    } else if (isEditor) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_profil_editor.pdf";
    } else if (isUser) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_profil_user.pdf";
    }
  } else if (location.pathname.match("^/filled-forms")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_filled_forms.pdf";
  } else if (
    location.pathname.match("^/(formdata/add|formdata/fill-scheduled-test)")
  ) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_formdata_add_fill-scheduled.pdf";
  } else if (location.pathname.match("^/formdata/edit")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_formdata_edit.pdf";
  } else if (location.pathname.match("^/formdata")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_formdata.pdf";
  } else if (location.pathname.match("^/results")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_results.pdf";
  } else if (location.pathname.match("^/forms/display")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_forms_display.pdf";
  } else if (location.pathname.match("^/forms/add")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_forms_add.pdf";
  } else if (location.pathname.match("^/forms/edit")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_forms_edit.pdf";
  } else if (location.pathname.match("^/forms/active")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_forms_active.pdf";
  } else if (location.pathname.match("^/forms")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_forms_owner.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_forms_editor.pdf";
    }
  } else if (location.pathname.match("^/person/add")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_owner_person_add.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_editor_person_add.pdf";
    }
  } else if (location.pathname.match("^/(persons|person)")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_persons_owner.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_persons_editor.pdf";
    }
  } else if (location.pathname.match("^/groups")) {
    if (isOwner) {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_groups_owner.pdf";
    } else {
      instructionPathForPage =
        "/org-media/files/public/instrukcja_widoku_groups_editor.pdf";
    }
  } else if (location.pathname.match("^/form_group")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_form_group.pdf";
  } else if (location.pathname.match("^/settings")) {
    instructionPathForPage =
      "/org-media/files/public/instrukcja_widoku_settings.pdf";
  }

  const browserAlert = unsupportedBrowserAlert ? (
    mediumScreen ? (
      <Tooltip
        title={`${unsupportedBrowserAlert} 
      Rekomendujemy zaktualizowanie do najnowszej wersji.`}
      >
        <ReportProblemOutlinedIcon className={classes.alertIcon} />
      </Tooltip>
    ) : (
      <UnsupportedBrowserAlert forHeader={true} />
    )
  ) : (
    ""
  );

  const drawer = (
    <div>
      <div className={classes.toolbar}></div>
      <Divider />
      <List>
        <Tooltip title="Start" placement="right">
          <ListItem
            button
            key="Start"
            className={clsx({
              [!(globalTheme === "high-contrast")
                ? classes.drawerActive
                : classes.drawerActiveContrast]: location.pathname.match(
                "^/($|start|profile)"
              ),
              [classes.onHover]: globalTheme === "high-contrast" ? true : false,
            })}
            onClick={() => {
              window.screen.width < 600 && setDrawerOpen(false);
              history.push("/");
            }}
          >
            <ListItemIcon>
              <HomeIcon
                // style={{ marginRight: "13px" }}
                className={clsx({
                  [classes.icon]: true,
                  [classes.iconSize]: true,
                })}
              />
            </ListItemIcon>
            <ListItemText primary="Start" />
          </ListItem>
        </Tooltip>
        {/* <Can
          permission="filled-forms-page:view"
          ok={() => (
            <Tooltip title="Wypełnione formularze" placement="right">
              <ListItem
                button
                key="Wypełnione formularze"
                onClick={() => {
                  window.screen.width < 600 && setDrawerOpen(false);
                  hasSingleScheduledForm && forms[0]
                    ? history.push(`/results/${forms[0].id}`)
                    : history.push("/filled-forms");
                }}
                className={clsx({
                  [!(globalTheme === "high-contrast")
                    ? classes.drawerActive
                    : classes.drawerActiveContrast]: location.pathname.match(
                    "^/(filled-forms|formdata|results)"
                  ),
                  [classes.onHover]:
                    globalTheme === "high-contrast" ? true : false,
                })}
              >
                <ListItemIcon>
                  <DoneIcon
                    className={clsx({
                      [classes.icon]: true,
                      [classes.iconSize]: true,
                    })}
                    // className={clsx({
                    //   [classes.icon]:
                    //     globalTheme === "high-contrast" ? true : false,
                    //   [classes.iconSize]: true,
                    // })}
                  />
                </ListItemIcon>
                <ListItemText primary="Wypełnione formularze" />
              </ListItem>
            </Tooltip>
          )}
          not={() => (
            <Can
              permission="activeforms-page:view"
              ok={() => (
                <Tooltip title="Formularze" placement="right">
                  <ListItem
                    button
                    key="Formularze"
                    onClick={() => {
                      window.screen.width < 600 && setDrawerOpen(false);
                      history.push("/forms/active");
                    }}
                    className={clsx({
                      [!(globalTheme === "high-contrast")
                        ? classes.drawerActive
                        : classes.drawerActiveContrast]: location.pathname.match(
                        "^/(forms|formdata)"
                      ),
                    })}
                  >
                    <ListItemIcon>
                      <AssignmentIcon
                        className={clsx({
                          [classes.icon]: true,
                          [classes.iconSize]: true,
                        })}
                      />
                    </ListItemIcon>
                    <ListItemText primary="Formularze" />
                  </ListItem>
                </Tooltip>
              )}
            />
          )}
        /> */}
        <Can
          permission="forms-page:view"
          ok={() => (
            <Tooltip title={t("menu.cameras")} placement="right">
              <ListItem
                button
                key={t("menu.cameras")}
                onClick={() => {
                  window.screen.width < 600 && setDrawerOpen(false);
                  history.push("/cameras");
                }}
                className={clsx({
                  [!(globalTheme === "high-contrast")
                    ? classes.drawerActive
                    : classes.drawerActiveContrast]: location.pathname.match(
                    "^/cameras"
                  ),
                  [classes.onHover]:
                    globalTheme === "high-contrast" ? true : false,
                })}
              >
                <ListItemIcon>
                  <VideocamIcon
                    className={clsx({
                      [classes.icon]: true,
                      [classes.iconSize]: true,
                    })}
                  />
                </ListItemIcon>
                <ListItemText primary={t("menu.cameras")} />
              </ListItem>
            </Tooltip>
          )}
        />
        <Can
          permission="forms-page:view"
          ok={() => (
            <Tooltip title={t("menu.boxes")} placement="right">
              <ListItem
                button
                key={t("menu.boxes")}
                onClick={() => {
                  window.screen.width < 600 && setDrawerOpen(false);
                  history.push("/boxes");
                }}
                className={clsx({
                  [!(globalTheme === "high-contrast")
                    ? classes.drawerActive
                    : classes.drawerActiveContrast]: location.pathname.match(
                    "^/boxes"
                  ),
                  [classes.onHover]:
                    globalTheme === "high-contrast" ? true : false,
                })}
              >
                <ListItemIcon>
                  <CheckBoxOutlineBlankIcon
                    className={clsx({
                      [classes.icon]: true,
                      [classes.iconSize]: true,
                    })}
                  />
                </ListItemIcon>
                <ListItemText primary={t("menu.boxes")} />
              </ListItem>
            </Tooltip>
          )}
        />
        <Can
          permission="forms-page:view"
          ok={() => (
            <Tooltip title={t("menu.horses")} placement="right">
              <ListItem
                button
                key={t("menu.horses")}
                onClick={() => {
                  window.screen.width < 600 && setDrawerOpen(false);
                  history.push("/horses");
                }}
                className={clsx({
                  [!(globalTheme === "high-contrast")
                    ? classes.drawerActive
                    : classes.drawerActiveContrast]: location.pathname.match(
                    "^/(horses)"
                  ),
                  [classes.onHover]:
                    globalTheme === "high-contrast" ? true : false,
                })}
              >
                <ListItemIcon>
                  <AssignmentIcon
                    className={clsx({
                      [classes.icon]: true,
                      [classes.iconSize]: true,
                    })}
                  />
                </ListItemIcon>
                <ListItemText primary={t("menu.horses")} />
              </ListItem>
            </Tooltip>
          )}
        />
        {/*<Can*/}
        {/*  permission="shopping-cart:page"*/}
        {/*  ok={() => (*/}
        {/*    <ListItem*/}
        {/*      button*/}
        {/*      key="Koszyk"*/}
        {/*      onClick={() => history.push("/shopping-cart")}*/}
        {/*      className={clsx({*/}
        {/*        [classes.drawerActive]: location.pathname.match(*/}
        {/*          "^/shopping-cart"*/}
        {/*        ),*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <ListItemIcon>*/}
        {/*        <Badge*/}
        {/*          classes={{ badge: classes.customBadge }}*/}
        {/*          badgeContent={cartItems.length}*/}
        {/*          color="primary"*/}
        {/*        >*/}
        {/*          <ShoppingCartIcon />*/}
        {/*        </Badge>*/}
        {/*        {displayShoppingCartNotificationIcon && (*/}
        {/*          <NotificationsIcon*/}
        {/*            style={{ fill: "#27AE60", fontSize: "12px" }}*/}
        {/*          />*/}
        {/*        )}*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText primary="Koszyk" />*/}
        {/*    </ListItem>*/}
        {/*  )}*/}
        {/*/>*/}
        {/*<Can*/}
        {/*  permission="orders:page"*/}
        {/*  ok={() => (*/}
        {/*    <ListItem*/}
        {/*      button*/}
        {/*      key="Zamówienia"*/}
        {/*      onClick={() => history.push("/orders")}*/}
        {/*      className={clsx({*/}
        {/*        [classes.drawerActive]: location.pathname.match("^/orders"),*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <ListItemIcon>*/}
        {/*        <Category style={{ marginRight: "13px" }} />*/}
        {/*        {displayOrderNotificationIcon && (*/}
        {/*          <NotificationsIcon*/}
        {/*            style={{ fill: "#27AE60", fontSize: "12px" }}*/}
        {/*          />*/}
        {/*        )}*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText primary="Zamówienia" />*/}
        {/*    </ListItem>*/}
        {/*  )}*/}
        {/*/>*/}
        <Can
          permission="admin:fetchOrgUsers"
          ok={() => (
            <Tooltip title={t("menu.users")} placement="right">
              <ListItem
                button
                key={t("menu.users")}
                onClick={() => {
                  window.screen.width < 600 && setDrawerOpen(false);
                  history.push("/persons");
                }}
                className={clsx({
                  [!(globalTheme === "high-contrast")
                    ? classes.drawerActive
                    : classes.drawerActiveContrast]: location.pathname.match(
                    "^/(persons|person)"
                  ),
                  [classes.onHover]:
                    globalTheme === "high-contrast" ? true : false,
                })}
              >
                <ListItemIcon>
                  <PeopleIcon
                    className={clsx({
                      [classes.icon]: true,
                      [classes.iconSize]: true,
                    })}
                  />
                </ListItemIcon>
                <ListItemText primary={t("menu.users")} />
              </ListItem>
            </Tooltip>
          )}
        />
        <Can
          permission="groups-page:view"
          ok={() => (
            <Tooltip title={t("menu.horse-farm")} placement="right">
              <ListItem
                button
                key={t("menu.horse-farm")}
                onClick={() => {
                  window.screen.width < 600 && setDrawerOpen(false);
                  history.push("/groups");
                }}
                className={clsx({
                  [!(globalTheme === "high-contrast")
                    ? classes.drawerActive
                    : classes.drawerActiveContrast]: location.pathname.match(
                    "^/groups"
                  ),
                  [classes.onHover]:
                    globalTheme === "high-contrast" ? true : false,
                })}
              >
                <ListItemIcon>
                  <FolderIcon
                    className={clsx({
                      [classes.icon]: true,
                      [classes.iconSize]: true,
                    })}
                  />
                </ListItemIcon>
                <ListItemText primary={t("menu.horse-farm")} />
              </ListItem>
            </Tooltip>
          )}
        />
        <Can
          permission="adminSettings:view"
          ok={() => (
            <Tooltip title="Ustawienia" placement="right">
              <ListItem
                button
                key="Ustawienia"
                onClick={() => {
                  window.screen.width < 600 && setDrawerOpen(false);
                  history.push("/settings");
                }}
                className={clsx({
                  [!(globalTheme === "high-contrast")
                    ? classes.drawerActive
                    : classes.drawerActiveContrast]: location.pathname.match(
                    "^/settings"
                  ),
                  [classes.onHover]:
                    globalTheme === "high-contrast" ? true : false,
                })}
              >
                <ListItemIcon>
                  <SettingsIcon
                    style={{ marginRight: "14px" }}
                    className={clsx({
                      [classes.icon]:
                        globalTheme === "high-contrast" ? true : false,
                    })}
                  />
                </ListItemIcon>
                <ListItemText primary="Ustawienia" />
              </ListItem>
            </Tooltip>
          )}
        />
        {/*<Can*/}
        {/*  permission="activated-kits-page:view"*/}
        {/*  ok={() => (*/}
        {/*    <ListItem*/}
        {/*      button*/}
        {/*      key="Aktywowane"*/}
        {/*      onClick={() => history.push("/forms")}*/}
        {/*      className={clsx({*/}
        {/*        [classes.drawerActive]: location.pathname.match(*/}
        {/*          "^/(testdata|tests)"*/}
        {/*        ),*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <ListItemIcon>*/}
        {/*        <AssignmentIcon style={{ marginRight: "13px" }} />*/}
        {/*        {displayFormNotificationIcon && (*/}
        {/*          <NotificationsIcon*/}
        {/*            style={{ fill: "#27AE60", fontSize: "12px" }}*/}
        {/*          />*/}
        {/*        )}*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText primary="Aktywowane" />*/}
        {/*    </ListItem>*/}
        {/*  )}*/}
        {/*  not={() => (*/}
        {/*    <Can*/}
        {/*      permission="activetest-page:view"*/}
        {/*      ok={() => (*/}
        {/*        <ListItem*/}
        {/*          button*/}
        {/*          key="Testy"*/}
        {/*          onClick={() => history.push("/forms/active")}*/}
        {/*          className={clsx({*/}
        {/*            [classes.drawerActive]: location.pathname.match(*/}
        {/*              "^/(testdata)"*/}
        {/*            ),*/}
        {/*          })}*/}
        {/*        >*/}
        {/*          <ListItemIcon>*/}
        {/*            <AssignmentIcon />*/}
        {/*          </ListItemIcon>*/}
        {/*          <ListItemText primary="Formularze" />*/}
        {/*        </ListItem>*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}
        {/*<Can*/}
        {/*  permission="sat-form:page"*/}
        {/*  ok={() => (*/}
        {/*    <ListItem*/}
        {/*      button*/}
        {/*      key="Tests"*/}
        {/*      onClick={() => history.push("/forms")}*/}
        {/*      className={clsx({*/}
        {/*        [classes.drawerActive]: location.pathname.match("^/forms"),*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <ListItemIcon>*/}
        {/*        <AssignmentIcon style={{ marginRight: "14px" }} />*/}
        {/*        {displayFormNotificationIcon && (*/}
        {/*          <NotificationsIcon*/}
        {/*            style={{ fill: "#27AE60", fontSize: "12px" }}*/}
        {/*          />*/}
        {/*        )}*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText primary="Testy" />*/}
        {/*    </ListItem>*/}
        {/*  )}*/}
        {/*/>*/}
        {/*<Can*/}
        {/*  permission="filled-kits:page"*/}
        {/*  ok={() => (*/}
        {/*    <ListItem*/}
        {/*      button*/}
        {/*      key="Zrealizowane"*/}
        {/*      onClick={() => history.push("/filled-kits")}*/}
        {/*      className={clsx({*/}
        {/*        [classes.drawerActive]: location.pathname.match(*/}
        {/*          "^/filled-kits"*/}
        {/*        ),*/}
        {/*      })}*/}
        {/*    >*/}
        {/*      <ListItemIcon>*/}
        {/*        <AssignmentTurnedInIcon />*/}
        {/*      </ListItemIcon>*/}
        {/*      <ListItemText primary="Zrealizowane" />*/}
        {/*    </ListItem>*/}
        {/*  )}*/}
        {/*/>*/}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      {loggedIn && (
        <>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: drawerOpen,
            })}
          >
            <Toolbar className={classes.toolbar}>
              {smallScreen ? (
                <Grid container direction="column">
                  <Grid
                    xs={12}
                    justify="space-around"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {" "}
                    <IconButton
                      className={classes.fontSize}
                      edge="start"
                      style={{ left: "8px" }}
                      color="inherit"
                      aria-label="menu"
                      onClick={handleDrawerToggle}
                    >
                      <MenuIcon />
                    </IconButton>{" "}
                    <Typography variant="h6" className={classes.title}>
                      {appBarTitle}
                    </Typography>
                    {isStudOwner && <ContextSelect />}
                    <LanguageSelect />
                    {browserAlert}
                    {my.firstName && (
                      <span color="inherit">
                        {my.firstName} {my.surname}
                      </span>
                    )}
                    <Box ml={1} position="relative">
                      <Avatar
                        style={{ cursor: "pointer" }}
                        onClick={() => setMenuOpened(!menuOpened)}
                        src={`/org-media/files${my.avatarPath}`}
                      >
                        <AccountCircle />
                      </Avatar>

                      {menuOpened && (
                        <ClickAwayListener
                          onClickAway={() => setMenuOpened(false)}
                        >
                          <Box
                            position="absolute"
                            right={0}
                            style={{ zIndex: "99" }}
                          >
                            <Card variant="outlined" p={1}>
                              <CardContent p={1}>
                                <List>
                                  <ListItem
                                    button
                                    onClick={() => {
                                      history.push("/profile");
                                      setMenuOpened(false);
                                    }}
                                  >
                                    <ListItemText>Profil</ListItemText>
                                  </ListItem>
                                  <Divider />
                                  <ListItem
                                    button
                                    onClick={() => {
                                      dispatch(logout({ isUser: !isOwner }));
                                      setMenuOpened(false);
                                    }}
                                  >
                                    <ListItemText>Wyloguj</ListItemText>
                                  </ListItem>
                                </List>
                              </CardContent>
                            </Card>
                          </Box>
                        </ClickAwayListener>
                      )}
                    </Box>
                  </Grid>{" "}
                  {/* <Grid
                    xs={12}
                    alignItems="center"
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <Tooltip title="Zmień rozmiar czcionki">
                      <IconButton
                        className={
                          baseFontSize === 0
                            ? classes.selectedIconHeader
                            : classes.iconHeader
                        }
                        style={{
                          // left: "12px",
                          color:
                            globalTheme === "high-contrast" ? "" : "lightgray",
                        }}
                        onClick={() => dispatch(smallFontSize())}
                      >
                        <FontDownloadIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Zmień rozmiar czcionki">
                      <IconButton
                        className={
                          baseFontSize === 1
                            ? classes.selectedIconHeader
                            : classes.iconHeader
                        }
                        style={{
                          // left: "5px",
                          color:
                            globalTheme === "high-contrast" ? "" : "lightgray",
                        }}
                        onClick={() => dispatch(mediumFontSize())}
                      >
                        <FontDownloadIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Zmień rozmiar czcionki">
                      <IconButton
                        className={
                          baseFontSize === 2
                            ? classes.selectedIconHeader
                            : classes.iconHeader
                        }
                        style={{
                          // left: "5px",
                          color:
                            globalTheme === "high-contrast" ? "" : "lightgray",
                        }}
                        onClick={() => dispatch(largeFontSize())}
                      >
                        <FontDownloadIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Przełącz kontrastowy tryb strony">
                      <IconButton
                        className={`${classes.iconHeader} ${classes.fontSize}`}
                        style={{
                          color:
                            globalTheme === "high-contrast" ? "" : "lightgray",
                        }}
                        onClick={() => dispatch(toggleGlobalTheme())}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Instrukcja dla aktualnego widoku">
                      <IconButton
                        className={classes.iconHeader}
                        style={{
                          color:
                            globalTheme === "high-contrast" ? "" : "lightgray",
                        }}
                        onClick={() =>
                          window.open(instructionPathForPage, "_blank")
                        }
                      >
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>

                    {isUser ? (
                      <Box>
                        <Button
                          size="small"
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          style={{
                            maxWidth: theme.spacing(9),
                            // marginLeft: "10px",
                            // marginRight: "15px",
                            color:
                              globalTheme === "high-contrast"
                                ? "#000"
                                : "lightgray",
                          }}
                          variant="outlined"
                          onClick={handleInstructionClick}
                        >
                          POMOC
                        </Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                              window.open(instructionPathForRole, "_blank");
                            }}
                          >
                            Instrukcja dla{" "}
                            {process.env.REACT_APP_USE_PATIENT === "true"
                              ? "pacjenta "
                              : "użytkownika "}
                            PDF
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setAnchorEl(null);
                              window.open(
                                "/org-media/files/public/zrodlo.mp4",
                                "_blank"
                              );
                            }}
                          >
                            Film instruktażowy
                          </MenuItem>
                        </Menu>
                      </Box>
                    ) : (
                      <Button
                        size="small"
                        style={{
                          maxWidth: theme.spacing(9),
                          marginLeft: "10px",
                          marginRight: "15px",
                          color:
                            globalTheme === "high-contrast"
                              ? "#000"
                              : "lightgray",
                        }}
                        variant="outlined"
                        onClick={() =>
                          window.open(instructionPathForRole, "_blank")
                        }
                      >
                        POMOC
                      </Button>
                    )}
                  </Grid> */}
                </Grid>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box style={{ left: "8px", paddingRight: "40px" }}>
                    <IconButton
                      className={classes.fontSize}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleDrawerToggle}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>
                  <Grid container>
                    <Grid
                      item
                      xs={7}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {!isStudOwner ? (
                        <img
                          style={{
                            boxSizing: "border-box",
                            height: "65px",
                            margin: "10px",
                            cursor: "pointer",
                          }}
                          src={imageHeader}
                          alt="Equinity Solutions logo"
                          onClick={() => history.push("/")}
                        />
                      ) : (
                        upMediumScreen && (
                          <img
                            style={{
                              boxSizing: "border-box",
                              height: "65px",
                              margin: "10px",
                              cursor: "pointer",
                            }}
                            src={imageHeader}
                            alt="Equinity Solutions logo"
                            onClick={() => history.push("/")}
                          />
                        )
                      )}

                      {isStudOwner && <ContextSelect />}
                      {browserAlert}
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <LanguageSelect />
                      {/* <Tooltip title={t("header.instruction")}>
                        <IconButton
                          className={classes.iconHeader}
                          style={{
                            color:
                              globalTheme === "high-contrast"
                                ? ""
                                : "lightgray",
                          }}
                          onClick={() =>
                            window.open(instructionPathForPage, "_blank")
                          }
                        >
                          <HelpIcon />
                        </IconButton>
                      </Tooltip> */}
                      {isUser ? (
                        <Box>
                          <Button
                            size="small"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            style={{
                              maxWidth: theme.spacing(9),
                              // marginLeft: "10px",
                              // marginRight: "15px",
                              color:
                                globalTheme === "high-contrast"
                                  ? "#000"
                                  : "lightgray",
                            }}
                            variant="outlined"
                            onClick={handleInstructionClick}
                          >
                            {t("header.help")}
                          </Button>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                window.open(instructionPathForRole, "_blank");
                              }}
                            >
                              Instrukcja dla{" "}
                              {process.env.REACT_APP_USE_PATIENT === "true"
                                ? "pacjenta "
                                : "użytkownika "}
                              PDF
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAnchorEl(null);
                                window.open(
                                  "/org-media/files/public/zrodlo.mp4",
                                  "_blank"
                                );
                              }}
                            >
                              Film instruktażowy
                            </MenuItem>
                          </Menu>
                        </Box>
                      ) : (
                        <Button
                          size="small"
                          style={{
                            maxWidth: theme.spacing(9),
                            marginLeft: "10px",
                            marginRight: "15px",
                            color:
                              globalTheme === "high-contrast"
                                ? "#000"
                                : "lightgray",
                          }}
                          variant="outlined"
                          onClick={() =>
                            window.open(instructionPathForRole, "_blank")
                          }
                        >
                          {t("header.help")}
                        </Button>
                      )}{" "}
                      {my.firstName && (
                        <span color="inherit">
                          {my.firstName} {my.surname}
                        </span>
                      )}
                      <Box ml={1} position="relative">
                        <Avatar
                          style={{ cursor: "pointer" }}
                          onClick={() => setMenuOpened(!menuOpened)}
                          src={`/org-media/files${my.avatarPath}`}
                        >
                          <AccountCircle />
                        </Avatar>

                        {menuOpened && (
                          <ClickAwayListener
                            onClickAway={() => setMenuOpened(false)}
                          >
                            <Box position="absolute" right={0}>
                              <Card variant="outlined" p={1}>
                                <CardContent p={1}>
                                  <List>
                                    <ListItem
                                      button
                                      onClick={() => {
                                        history.push("/profile");
                                        setMenuOpened(false);
                                      }}
                                    >
                                      <ListItemText>
                                        {t("header.profile")}
                                      </ListItemText>
                                    </ListItem>
                                    <Divider />

                                    <ListItem
                                      button
                                      onClick={() => {
                                        dispatch(logout({ isUser: !isOwner }));
                                        setMenuOpened(false);
                                      }}
                                    >
                                      <ListItemText
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        {t("header.log_out")}
                                      </ListItemText>
                                    </ListItem>
                                  </List>
                                </CardContent>
                              </Card>
                            </Box>
                          </ClickAwayListener>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Toolbar>
          </AppBar>
          <Hidden xsDown>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: drawerOpen,
                  [classes.drawerClose]: !drawerOpen,
                }),
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smUp>
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={drawerOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>

          <Box
            className={clsx(classes.mainContent, {
              [classes.mainContentShift]: !drawerOpen,
              [classes.mainContentShiftLarge]: drawerOpen,
            })}
          >
            <Breadcrumbs />
            {children}
          </Box>
        </>
      )}
      {!loggedIn && children}
    </>
  );
};

export default connect(
  (state) => ({
    loggedIn: state.loggedIn,
    my: state.my,
    // cartItems: state.cartItems,
  }),
  (dispatch) => ({
    logout: () => dispatch(loogutAction()),
  })
)(Header);
