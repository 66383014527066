import {
  ACTION_1,
  ACTIVATE_FORM,
  ACTIVATE_USER,
  ADD_ITEM_TO_CART,
  ALERT_HIDE,
  ALERT_SHOW,
  ALERT_TIMEOUT,
  CHECK_AUTH_DONE,
  CHECK_AUTH_FAILED,
  CHECK_AUTH_START,
  CHECK_CONTEXT_DONE,
  CHECK_CONTEXT_FAILED,
  CHECK_CONTEXT_START,
  COPY_FORM_TO_FORM_GROUP_DONE,
  COPY_FORM_TO_FORM_GROUP_FAILED,
  COPY_FORM_TO_FORM_GROUP_START,
  CREATE_FILLED_FORM_DATA_DONE,
  CREATE_FILLED_FORM_DATA_FAILED,
  CREATE_FILLED_FORM_DATA_START,
  DEACTIVATE_FORM,
  DONE,
  DUPLICATE_FORM,
  EDIT_ITEM_CART,
  FAILED,
  FETCH_ACTIVATED_KITS,
  FETCH_ACTIVE_FORMS,
  FETCH_ACTIVE_FORMS_ALL,
  FETCH_USER_ALL_FORMS,
  FETCH_USER_ALL_AVAILABLE_FORMS,
  FETCH_PLANNED_FORMS,
  FETCH_PLANNED_FORMS_ALL,
  FETCH_ARCHIVED_FORMS,
  FETCH_ARCHIVED_FORMS_BAG,
  FETCH_ALL_MY_TESTS,
  FETCH_CONFIG_GLOBAL_DONE,
  FETCH_CONFIG_GLOBAL_FAILED,
  FETCH_CONFIG_GLOBAL_START,
  FETCH_DATA_AS_CSV,
  FETCH_FILLED_FORMS_DONE,
  FETCH_FILLED_FORMS_EXTENDED,
  FETCH_FILLED_FORMS_FAILED,
  FETCH_FILLED_FORMS_START,
  FETCH_FILLED_TESTS,
  FETCH_FORM_TEMPLATE_DONE,
  FETCH_FORM_TEMPLATE_FAILED,
  FETCH_FORM_TEMPLATE_START,
  FETCH_FORM_DONE,
  FETCH_FORM_EDITIONS,
  FETCH_FORM_FAILED,
  FETCH_FORM_GROUP_DONE,
  FETCH_FORM_GROUP_FAILED,
  FETCH_FORM_GROUP_START,
  FETCH_GLOBAL_DICTIONARIES_DONE,
  FETCH_GLOBAL_DICTIONARIES_FAILED,
  FETCH_GLOBAL_DICTIONARIES_START,
  FETCH_FORM_START,
  FETCH_FORMS_DONE,
  FETCH_FORMS_FAILED,
  FETCH_FORMS_GROUPS_DONE,
  FETCH_FORMS_GROUPS_FAILED,
  FETCH_FORMS_GROUPS_START,
  FETCH_ALL_HORSES_DONE,
  FETCH_ALL_HORSES_FAILED,
  FETCH_ALL_HORSES_START,
  FETCH_ALL_FORMS_GROUPS_DONE,
  FETCH_ALL_FORMS_GROUPS_FAILED,
  FETCH_ALL_FORMS_GROUPS_START,
  FETCH_FORMS_START,
  FETCH_OLD_FORM_EDITIONS,
  FETCH_ORDER_DETAILS_BY_ORDER_ID,
  FETCH_ORG_NAMES_DONE,
  FETCH_ORG_NAMES_FAILED,
  FETCH_ORG_NAMES_START,
  FETCH_ORG_USERS_DONE,
  FETCH_ORG_USERS_FAILED,
  FETCH_ORG_USERS_START,
  FETCH_ALL_ORG_USERS_DONE,
  FETCH_ALL_ORG_USERS_FAILED,
  FETCH_ALL_ORG_USERS_START,
  FETCH_ORGANIZATION_DONE,
  FETCH_ORGANIZATION_FAILED,
  FETCH_ORGANIZATION_START,
  FETCH_SCHEDULED_FORMS,
  FETCH_HORSE_NOTIFICATIONS,
  INVITE_MULTIPLE_PEOPLE_DONE,
  INVITE_MULTIPLE_PEOPLE_FAILED,
  INVITE_MULTIPLE_PEOPLE_START,
  JOIN_ORGANIZATION_DONE,
  JOIN_ORGANIZATION_FAILED,
  JOIN_ORGANIZATION_START,
  LOGIN_DONE,
  LOGIN_FAILED,
  LOGIN_START,
  LOGOUT_DONE,
  LOGOUT_FAILED,
  LOGOUT_START,
  MOVE_FORM_TO_FORM_GROUP_DONE,
  MOVE_FORM_TO_FORM_GROUP_FAILED,
  MOVE_FORM_TO_FORM_GROUP_START,
  RECOVER_PASSWORD_DONE,
  RECOVER_PASSWORD_FAILED,
  RECOVER_PASSWORD_START,
  RESET_BY_MAIL_PASSWORD_DONE,
  RESET_BY_MAIL_PASSWORD_FAILED,
  RESET_BY_MAIL_PASSWORD_START,
  REGISTER_ORGANIZATION_DONE,
  REGISTER_ORGANIZATION_FAILED,
  REGISTER_ORGANIZATION_START,
  REMOVE_FORM_DONE,
  REMOVE_FORM_START,
  REMOVE_FORM_FAILED,
  REMOVE_FORM_FROM_FORM_GROUP_DONE,
  REMOVE_FORM_FROM_FORM_GROUP_FAILED,
  REMOVE_FORM_FROM_FORM_GROUP_START,
  REMOVE_ITEM_FROM_CART,
  REMOVE_USER,
  EDIT_USER,
  SAVE_FILLED_FORM_DATA_DONE,
  SAVE_FILLED_FORM_DATA_FAILED,
  SAVE_FILLED_FORM_DATA_START,
  EDIT_FILLED_FORM_DATA_DONE,
  EDIT_FILLED_FORM_DATA_FAILED,
  EDIT_FILLED_FORM_DATA_START,
  SAVE_HORSE_DONE,
  SAVE_HORSE_FAILED,
  SAVE_HORSE_START,
  SAVE_FORM_GROUP_DONE,
  SAVE_FORM_GROUP_FAILED,
  SAVE_FORM_GROUP_START,
  SEND_COMMENT_DONE,
  SEND_COMMENT_FAILED,
  SEND_COMMENT_START,
  SET_APP_BAR_TITLE,
  START,
  TOGGLE_REQUESTED_SHARING_BY_OWNER,
  UPDATE_ENTITLED_USERS,
  UPDATE_FORM_DATA_IS_SHARED,
  UPDATE_FORM_DONE,
  UPDATE_FORM_FAILED,
  UPDATE_FORM_START,
  UPDATE_REQUESTED_SHARING_BY_OWNER,
  UPDATE_HORSE_NOTIFICATIONS,
  REMOVE_FORM_GROUP,
  ADD_PERSON,
  DUPLICATE_FORM_GROUP,
  FETCH_ALLOWED_USERS_TO_GROUP_START,
  FETCH_ALLOWED_USERS_TO_GROUP_DONE,
  FETCH_ALLOWED_USERS_TO_GROUP_FAILED,
  FETCH_ALLOWED_USERS_TO_FORM_START,
  FETCH_ALLOWED_USERS_TO_FORM_DONE,
  FETCH_ALLOWED_USERS_TO_FORM_FAILED,
  UPDATE_USERS_ACCESS_TO_GROUP,
  UPDATE_USERS_ACCESS_TO_FORM,
  SAVE_USERS_ACCESS_TO_HORSE,
  FETCH_FORM_DATA,
  FETCH_UPLOADABLE_FILE_TYPES,
  FETCH_SAVED_FORM_DATA,
  FETCH_FILLED_FORM_DATA,
  REMOVE_FORM_DATA_DRAFT,
  FETCH_MATCHING_USERS,
  CLEAR_MATCHING_USERS,
  FETCH_FORMS_SCHEDULED_FOR_FUTURE,
  RESET_GIVEN_USER_PASSWORD,
  FETCH_API_KEYS,
  ADD_API_KEY,
  DELEETE_API_KEY,
  FETCH_SCHEDULED_FORM,
  FETCH_HAS_SINGLE_SCHEDULED_FORM,
  ALERT_HIDE_CUSTOM_ID,
  TOGGLE_GLOBAL_THEME,
  READ_GLOBAL_THEME,
  SMALL_FONT_SIZE,
  MEDIUM_FONT_SIZE,
  LARGE_FONT_SIZE,
  FETCH_CONFIG,
  CLEAR_ALL_FORM,
  FETCH_TERMS_ACCEPTED_BY_USER_WITH_ID,
  FETCH_API_VERSION,
  UNSUPPORTED_BROWSER,
  ADD_EXISTING_PERSON,
  FETCH_ALLOWED_STUD_USERS_START,
  FETCH_ALLOWED_STUD_USERS_DONE,
  FETCH_ALLOWED_STUD_USERS_FAILED,
  FETCH_HORSES_DONE,
  FETCH_HORSES_START,
  FETCH_HORSES_FAILED,
  FETCH_HORSES_BY_STUD_DONE,
  FETCH_HORSES_BY_STUD_START,
  FETCH_HORSES_BY_STUD_FAILED,
  FETCH_VACCINATIONS,
  ADD_VACCINATION,
  REMOVE_VACCINATION,
  EDIT_VACCINATION,
  FETCH_VETERINARY_VISITS,
  ADD_VETERINARY_VISIT,
  REMOVE_VETERINARY_VISIT,
  EDIT_VETERINARY_VISIT,
  FETCH_FOALINGS,
  ADD_FOALING,
  REMOVE_FOALING,
  EDIT_FOALING,
  TOGGLE_PREGNANT,
  FETCH_ALERTS,
  FETCH_ANOMALIES,
  FETCH_CAMERAS,
  ADD_CAMERA,
  EDIT_CAMERA,
  FETCH_CAMERA,
  REMOVE_CAMERA,
  FETCH_BOXES,
  ADD_BOX,
  EDIT_BOX,
  FETCH_BOX,
  REMOVE_BOX,
  FETCH_IMAGE_DATA,
  FETCH_IMAGE_BY_URL,
  FETCH_SIGNAL_INFO,
  FETCH_PRIVATE_KEY_START,
  FETCH_PRIVATE_KEY_DONE,
  FETCH_PRIVATE_KEY_FAILED,
} from "../../constants";
import { getAxiosInstance, refreshAxios } from "../common";

refreshAxios();

export const action1 = (payload) => ({ type: ACTION_1, payload });

/**
 *
 * @param payload {name, mail, password}
 * @returns {Function}
 */
export const register_organization = (payload) => async (dispatch) => {
  dispatch(register_organization_start());
  try {
    refreshAxios();

    const result = await getAxiosInstance().post(
      "/api/organizations/register",
      {
        orgName: payload.name,
        mail: payload.mail,
        password: payload.password,
      }
    );

    dispatch(register_organization_done(result.data));
    return result;
  } catch (e) {
    dispatch(register_organization_failed(e));
    return e.response;
  }
};

export const join_organization = (payload) => async (dispatch) => {
  dispatch(join_organization_start());
  try {
    refreshAxios();

    const result = await getAxiosInstance().post(
      `/api/organizations/${payload.selectedOrg}/join`,
      {
        mail: payload.mail,
        password: payload.password,
        ...(payload.orgCode ? { orgCode: payload.orgCode } : {}),
        acceptedTerms: payload.acceptedTerms,
      }
    );

    dispatch(join_organization_done(result.data));
    return result.data;
  } catch (e) {
    dispatch(join_organization_failed(e));
    throw e;
  }
};

export const register_organization_start = (payload) => ({
  type: REGISTER_ORGANIZATION_START,
  payload,
});
export const register_organization_done = (payload) => ({
  type: REGISTER_ORGANIZATION_DONE,
  payload,
});
export const register_organization_failed = (payload) => ({
  type: REGISTER_ORGANIZATION_FAILED,
  payload,
});

export const join_organization_start = (payload) => ({
  type: JOIN_ORGANIZATION_START,
  payload,
});
export const join_organization_done = (payload) => ({
  type: JOIN_ORGANIZATION_DONE,
  payload,
});
export const join_organization_failed = (payload) => ({
  type: JOIN_ORGANIZATION_FAILED,
  payload,
});

/**
 *
 * @param payload {username, password}
 * @returns {Function}
 */
export const login = (payload, loginType) => async (dispatch) => {
  dispatch(login_start());
  try {
    refreshAxios();
    const result =
      loginType !== "patient"
        ? await getAxiosInstance().post("/employees/v1/login", {
            email: payload.username,
            password: payload.password,
          })
        : await getAxiosInstance().post(
            "/api/auth/login",
            payload.username === "" || payload.username === undefined
              ? {
                  phone: payload.phone,
                  password: payload.password,
                  globalTosAccepted: payload.globalTosAccepted,
                }
              : {
                  username: payload.username,
                  password: payload.password,
                  globalTosAccepted: payload.globalTosAccepted,
                }
          );

    refreshAxios(result.data.token);

    const my = await getAxiosInstance().get("/api/persons/my");

    dispatch(
      login_done({
        data: result.data,
        my: my.data,
      })
    );

    window &&
      window.localStorage &&
      window.localStorage.setItem("loggedIn", new Date().toISOString());

    return result;
  } catch (e) {
    dispatch(login_failed(e));
    return e.response;
  }
};

export const login_start = (payload) => ({ type: LOGIN_START, payload });
export const login_done = (payload) => ({ type: LOGIN_DONE, payload });
export const login_failed = (payload) => ({
  type: LOGIN_FAILED,
  errorMessage: payload.response.data.message,
  payload,
});

export const checkContext = () => async (dispatch) => {
  dispatch(checkContext_start());
  try {
    // const result = await getAxiosInstance().get("/api/auth/refresh");

    const r = await getAxiosInstance().get("/api/stud_context");
    const rStudAll = await getAxiosInstance().post("/api/stud/search", {
      page: 0,
      pageSize: 100,
    });

    dispatch(
      checkContext_done(
        // token: result.data.token,
        {
          acutalContext: r.data,
          myStuds: rStudAll.data.content,
        }
      )
    );
    return true;
  } catch (e) {
    dispatch(checkContext_failed(e));
    return false;
  }
};

export const contextChange = (id) => async (dispatch) => {
  try {
    // const result = await getAxiosInstance().get("/api/auth/refresh");

    const payload = { studId: id };
    const r = await getAxiosInstance().put("/api/stud_context", payload);

    return true;
  } catch (e) {
    dispatch(checkAuth_failed(e));
    return false;
  }
};

export const checkAuth = () => async (dispatch) => {
  dispatch(checkAuth_start());
  try {
    await refreshAxios();

    // const result = await getAxiosInstance().get("/api/auth/refresh");

    const my = await getAxiosInstance().get("/api/persons/my");

    dispatch(
      checkAuth_done({
        // token: result.data.token,
        my: my.data,
      })
    );
    return true;
  } catch (e) {
    dispatch(checkAuth_failed(e));
    return false;
  }
};

export const checkAuth_start = (payload) => ({
  type: CHECK_AUTH_START,
  payload,
});
export const checkAuth_done = (payload) => ({ type: CHECK_AUTH_DONE, payload });
export const checkAuth_failed = (payload) => ({
  type: CHECK_AUTH_FAILED,
  payload,
});

export const checkContext_start = (payload) => ({
  type: CHECK_CONTEXT_START,
  payload,
});
export const checkContext_done = (payload) => ({
  type: CHECK_CONTEXT_DONE,
  payload,
});
export const checkContext_failed = (payload) => ({
  type: CHECK_CONTEXT_FAILED,
  payload,
});

export const fetchOrgUsers = (payload) => async (dispatch) => {
  dispatch(fetchAllOrgUsers_start());
  try {
    const result = await getAxiosInstance().get("/api/persons");

    dispatch(fetchAllOrgUsers_done({ users: result.data }));
    return result.data;
  } catch (e) {
    dispatch(fetchAllOrgUsers_failed(e));
    return false;
  }
};

export const fetchAllOrgUsersPaginated = ({
  page,
  pageSize,
  sort,
  filterRole,
  q,
}) => async (dispatch) => {
  dispatch(fetchOrgUsers_start());
  try {
    const result = await getAxiosInstance().post("/api/v2/persons/search", {
      page: page < 0 ? 0 : page,
      ...(pageSize ? { pageSize: parseInt(pageSize) } : {}),
      ...(sort ? { sort } : {}),
      ...(q ? { q } : {}),
    });

    dispatch(fetchOrgUsers_done({ users: result.data }));
    return true;
  } catch (e) {
    dispatch(fetchOrgUsers_failed(e));
    return false;
  }
};

export const fetchUsersByStudContext = ({ page, pageSize, sort, q }) => async (
  dispatch,
  getState
) => {
  dispatch(fetchOrgUsers_start());
  try {
    const currentContext = getState().currentContext?.studId;
    const result = await getAxiosInstance().post(
      `/api/stud/${currentContext}/persons`,
      {
        page,
        ...(pageSize ? { pageSize: parseInt(pageSize) } : {}),
        ...(sort ? { sort } : {}),
        ...(q ? { q } : {}),
      }
    );

    dispatch(fetchOrgUsers_done({ users: result.data }));
    return true;
  } catch (e) {
    dispatch(fetchOrgUsers_failed(e));
    return false;
  }
};

export const fetchOrgUsersWithTerms = ({
  page,
  pageSize,
  sort,
  filterRole,
  q,
}) => async (dispatch) => {
  dispatch(fetchOrgUsers_start());
  try {
    const result = await getAxiosInstance().get("/api/v2/persons/with_terms", {
      params: {
        page,
        ...(pageSize ? { pageSize: parseInt(pageSize) } : {}),
        ...(sort ? { sort } : {}),
        ...(filterRole ? { filterRole } : {}),
        ...(q ? { q } : {}),
      },
    });

    dispatch(fetchOrgUsers_done({ users: result.data }));
    return true;
  } catch (e) {
    dispatch(fetchOrgUsers_failed(e));
    return false;
  }
};

export const fetchOrgUsersPagedByStudId = ({
  studId,
  page,
  pageSize,
  sort,
  filterRole,
  q,
}) => async (dispatch) => {
  dispatch(fetchAllowedStudUsers_start());
  try {
    const result = await getAxiosInstance().get(
      `/api/stud/${studId}/allowed_users`,
      {
        params: {
          page,
          ...(pageSize ? { pageSize: parseInt(pageSize) } : {}),
          ...(sort ? { sort } : {}),
          ...(filterRole ? { filterRole } : {}),
          ...(q ? { q } : {}),
        },
      }
    );

    dispatch(fetchAllowedStudUsers_done(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchAllowedStudUsers_failed(e));
    return false;
  }
};
export const fetchOrgUsersPaged = ({
  page,
  pageSize,
  sort,
  filterRole,
  q,
}) => async (dispatch, getState) => {
  dispatch(fetchAllowedStudUsers_start());
  try {
    const currentContext = getState().currentContext?.studId;
    const result = await getAxiosInstance().get(
      `/api/stud/${currentContext}/allowed_users`,
      {
        params: {
          page,
          ...(pageSize ? { pageSize: parseInt(pageSize) } : {}),
          ...(sort ? { sort } : {}),
          ...(filterRole ? { filterRole } : {}),
          ...(q ? { q } : {}),
        },
      }
    );

    dispatch(fetchAllowedStudUsers_done(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchAllowedStudUsers_failed(e));
    return false;
  }
};

export const fetchAllowedStudUsers_start = (payload) => ({
  type: FETCH_ALLOWED_STUD_USERS_START,
  payload,
});

export const fetchAllowedStudUsers_done = (payload) => ({
  type: FETCH_ALLOWED_STUD_USERS_DONE,
  payload,
});

export const fetchAllowedStudUsers_failed = (payload) => ({
  type: FETCH_ALLOWED_STUD_USERS_FAILED,
  payload,
});

export const fetchOrgUsers_start = (payload) => ({
  type: FETCH_ORG_USERS_START,
  payload,
});

export const fetchOrgUsers_done = (payload) => ({
  type: FETCH_ORG_USERS_DONE,
  payload,
});

export const fetchOrgUsers_failed = (payload) => ({
  type: FETCH_ORG_USERS_FAILED,
  payload,
});

export const fetchAllOrgUsers_start = (payload) => ({
  type: FETCH_ALL_ORG_USERS_START,
  payload,
});

export const fetchAllOrgUsers_done = (payload) => ({
  type: FETCH_ALL_ORG_USERS_DONE,
  payload,
});

export const fetchAllOrgUsers_failed = (payload) => ({
  type: FETCH_ALL_ORG_USERS_FAILED,
  payload,
});

export const fetchOrgNames = (payload) => async (dispatch) => {
  dispatch(fetchOrgNames_start());
  try {
    const result = await getAxiosInstance().get("/api/organizations/names");
    dispatch(fetchOrgNames_done({ orgNames: result.data }));
    return result.data;
  } catch (e) {
    dispatch(fetchOrgNames_failed(e));
    return false;
  }
};

export const fetchOrgNames_start = (payload) => ({
  type: FETCH_ORG_NAMES_START,
  payload,
});
export const fetchOrgNames_done = (payload) => ({
  type: FETCH_ORG_NAMES_DONE,
  payload,
});
export const fetchOrgNames_failed = (payload) => ({
  type: FETCH_ORG_NAMES_FAILED,
  payload,
});

export const addPerson = (person) => async (dispatch) => {
  dispatch({ type: ADD_PERSON + START });

  try {
    const res = await getAxiosInstance().post(
      `/api/organizations/my/add_user`,
      person
    );
    dispatch({ type: ADD_PERSON + DONE, payload: res.data });
    return res.data;
  } catch (e) {
    dispatch({ type: ADD_PERSON + FAILED, payload: e });
    throw e;
  }
};
export const addPersonByStudAdmin = (person) => async (dispatch) => {
  dispatch({ type: ADD_PERSON + START });

  try {
    const res = await getAxiosInstance().post(`/api/stud/add_person`, person);
    dispatch({ type: ADD_PERSON + DONE, payload: res.data });
    return res.data;
  } catch (e) {
    dispatch({ type: ADD_PERSON + FAILED, payload: e });
    throw e;
  }
};

export const addExistingPersonToStud = (payload) => async (
  dispatch,
  getState
) => {
  dispatch({ type: ADD_EXISTING_PERSON + START });

  try {
    const currentContext = getState().currentContext?.studId;
    const res = await getAxiosInstance().post(
      `/api/stud/${currentContext}/allowed_users`,
      payload
    );
    dispatch({ type: ADD_EXISTING_PERSON + DONE, payload: res.data });
    return true;
  } catch (e) {
    dispatch({ type: ADD_EXISTING_PERSON + FAILED, payload: e });
    throw e;
  }
};

export const addExistingPersonByStudId = (payload, id) => async (dispatch) => {
  dispatch({ type: ADD_EXISTING_PERSON + START });

  try {
    const res = await getAxiosInstance().post(
      `/api/stud/${id}/allowed_users`,
      payload
    );
    dispatch({ type: ADD_EXISTING_PERSON + DONE, payload: res.data });
    return true;
  } catch (e) {
    dispatch({ type: ADD_EXISTING_PERSON + FAILED, payload: e });
    throw e;
  }
};

export const inviteMultiplePeople = (payload) => async (dispatch) => {
  dispatch(inviteMultiplePeople_start());

  try {
    const res = await getAxiosInstance().post(
      `/api/organizations/${payload.orgId}/add_users`,
      payload.persons
    );

    dispatch(inviteMultiplePeople_done({ users: res }));
    return res.data;
  } catch (e) {
    dispatch(inviteMultiplePeople_failed(e));
    return {
      error: e,
    };
  }
};

export const inviteMultiplePeople_start = (payload) => ({
  type: INVITE_MULTIPLE_PEOPLE_START,
  payload,
});

export const inviteMultiplePeople_done = (payload) => ({
  type: INVITE_MULTIPLE_PEOPLE_DONE,
  payload,
});

export const inviteMultiplePeople_failed = (payload) => ({
  type: INVITE_MULTIPLE_PEOPLE_FAILED,
  payload,
});

//////////// logout

export const logout = ({ isUser }) => async (dispatch) => {
  dispatch(logoutStart());

  try {
    const result = isUser
      ? await getAxiosInstance().post("/api/auth/logout")
      : await getAxiosInstance().post("/employees/v1/logout");
    dispatch(logoutDone(result));

    if (window && window.localStorage) {
      window.localStorage.setItem("loggedOut", new Date().toISOString());
      window.localStorage.setItem("firstUrl", "/");
    }

    return true;
  } catch (e) {
    dispatch(logoutFailed());
  }

  return false;
};

export const logoutStart = (payload) => ({ type: LOGOUT_START, payload });

export const logoutDone = (payload) => ({ type: LOGOUT_DONE, payload });

export const logoutFailed = (payload) => ({ type: LOGOUT_FAILED, payload });

//// save  form

export const saveHorse = (payload) => async (dispatch) => {
  dispatch(saveHorseStart());
  try {
    const result = await getAxiosInstance().post(`/api/horse`, payload);
    dispatch(saveHorseDone(result.data));
    return result;
  } catch (e) {
    dispatch(saveHorseFailed(e));
    return e.response;
  }
};

export const saveHorseStart = (payload) => ({
  type: SAVE_HORSE_START,
  payload,
});

export const saveHorseDone = (payload) => ({ type: SAVE_HORSE_DONE, payload });

export const saveHorseFailed = (payload) => ({
  type: SAVE_HORSE_FAILED,
  payload,
});

//// update  form

export const updateForm = (id, payload) => async (dispatch) => {
  dispatch(updateFormStart());
  try {
    const result = await getAxiosInstance().put(`/api/forms/${id}`, payload);
    dispatch(updateFormDone(result.data));
    dispatch(fetchHasSingleScheduledForm());
    return result.data;
  } catch (e) {
    dispatch(updateFormFailed(e));
    return null;
  }
};

export const updateHorse = (id, payload) => async (dispatch) => {
  dispatch(updateFormStart());
  try {
    const result = await getAxiosInstance().put(`/api/horse/${id}`, payload);
    dispatch(updateFormDone(result.data));
    dispatch(fetchHasSingleScheduledForm());
    return result;
  } catch (e) {
    dispatch(updateFormFailed(e));
    return null;
  }
};
const updateFormStart = (payload) => ({ type: UPDATE_FORM_START, payload });
const updateFormDone = (payload) => ({ type: UPDATE_FORM_DONE, payload });
const updateFormFailed = (payload) => ({ type: UPDATE_FORM_FAILED, payload });

//// save  form group

export const saveHorseFarm = (payload) => async (dispatch) => {
  dispatch(saveHorseFarmStart());
  try {
    const result = await getAxiosInstance().post(`/api/stud`, payload);
    dispatch(saveHorseFarmDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(saveHorseFarmFailed(e));
    throw e;
  }
};

export const saveHorseFarmStart = (payload) => ({
  type: SAVE_FORM_GROUP_START,
  payload,
});

export const saveHorseFarmDone = (payload) => ({
  type: SAVE_FORM_GROUP_DONE,
  payload,
});

export const saveHorseFarmFailed = (payload) => ({
  type: SAVE_FORM_GROUP_FAILED,
  payload,
});

//// remove form group

export const removeFormGroup = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_FORM_GROUP + START });
  try {
    const result = await getAxiosInstance().delete(`/api/stud/${id}`);
    dispatch({ type: REMOVE_FORM_GROUP + DONE, payload: id });
    return result.data;
  } catch (e) {
    dispatch({ type: REMOVE_FORM_GROUP + FAILED, payload: e });
  }
};

//// update  form group

export const updateHorseFarm = (id, payload) => async (dispatch) => {
  dispatch(updateHorseFarmStart());
  try {
    const result = await getAxiosInstance().put(`/api/stud/${id}`, {
      ...payload,
    });
    dispatch(updateHorseFarmDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(updateHorseFarmFailed(e));
    throw e;
  }
};
const updateHorseFarmStart = (payload) => ({
  type: UPDATE_FORM_START,
  payload,
});
const updateHorseFarmDone = (payload) => ({ type: UPDATE_FORM_DONE, payload });
const updateHorseFarmFailed = (payload) => ({
  type: UPDATE_FORM_FAILED,
  payload,
});

export const duplicateFormGroup = (id, payload) => async (dispatch) => {
  dispatch({ type: DUPLICATE_FORM_GROUP + START, id });
  try {
    const result = await getAxiosInstance().put(
      `/api/form_group/${id}/duplicate`,
      payload
    );

    dispatch({ type: DUPLICATE_FORM_GROUP + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: DUPLICATE_FORM_GROUP + FAILED, id });
    return null;
  }
};

export const duplicateForm = (id) => async (dispatch) => {
  dispatch({ type: DUPLICATE_FORM + START, id });
  try {
    const result = await getAxiosInstance().put(`/api/forms/${id}/duplicate`);
    dispatch({ type: DUPLICATE_FORM + DONE, payload: result.data });
    dispatch(fetchHasSingleScheduledForm());
    return result.data;
  } catch (e) {
    dispatch({ type: DUPLICATE_FORM + FAILED, id });
    return null;
  }
};

export const deactivateForm = (id) => async (dispatch) => {
  dispatch({ type: DEACTIVATE_FORM + START, id });
  try {
    const result = await getAxiosInstance().put(`/api/forms/${id}/deactivate`);
    dispatch({ type: DEACTIVATE_FORM + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: DEACTIVATE_FORM + FAILED, id });
    return null;
  }
};

//// fetch org

export const fetchOrganization = (payload, orgId) => async (dispatch) => {
  dispatch(fetchOrganizationStart());
  try {
    const result = await getAxiosInstance().get(
      "/api/persons/my/organizations"
    );
    dispatch(fetchOrganizationDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchOrganizationFailed(e));
  }
};

export const fetchOrganizationStart = (payload) => ({
  type: FETCH_ORGANIZATION_START,
  payload,
});

export const fetchOrganizationDone = (payload) => ({
  type: FETCH_ORGANIZATION_DONE,
  payload,
});

export const fetchOrganizationFailed = (payload) => ({
  type: FETCH_ORGANIZATION_FAILED,
  payload,
});

/////////////

export const fetchConfigGlobal = (payload, orgId) => async (dispatch) => {
  dispatch(fetchConfigGlobalStart());
  try {
    const result = await getAxiosInstance().get("/api/config_global");
    dispatch(fetchConfigGlobalDone(result.data));
    return result.data;
  } catch (e) {
    dispatch(fetchConfigGlobalFailed(e));
  }
};

export const fetchConfigGlobalStart = (payload) => ({
  type: FETCH_CONFIG_GLOBAL_START,
  payload,
});

export const fetchConfigGlobalDone = (payload) => ({
  type: FETCH_CONFIG_GLOBAL_DONE,
  payload,
});

export const fetchConfigGlobalFailed = (payload) => ({
  type: FETCH_CONFIG_GLOBAL_FAILED,
  payload,
});

export const fetchHorsesByStudStart = (payload) => ({
  type: FETCH_HORSES_START,
  payload,
});
export const fetchHorsesByStudDone = (payload) => ({
  type: FETCH_HORSES_DONE,
  payload,
});
export const fetchHorsesByStudFailed = (payload) => ({
  type: FETCH_HORSES_FAILED,
  payload,
});

export const fetchHorsesByStudIdStart = (payload) => ({
  type: FETCH_HORSES_BY_STUD_START,
  payload,
});
export const fetchHorsesByStudIdDone = (payload) => ({
  type: FETCH_HORSES_BY_STUD_DONE,
  payload,
});
export const fetchHorsesByStudIdFailed = (payload) => ({
  type: FETCH_HORSES_BY_STUD_FAILED,
  payload,
});

export const fetchFormsStart = (payload) => ({
  type: FETCH_FORMS_START,
  payload,
});
export const fetchFormsDone = (payload) => ({
  type: FETCH_FORMS_DONE,
  payload,
});
export const fetchFormsFailed = (payload) => ({
  type: FETCH_FORMS_FAILED,
  payload,
});

export const fetchForms = ({ page, pageSize, sortBy, q }) => async (
  dispatch
) => {
  dispatch(fetchFormsStart());
  try {
    //         ...(sort ? { sortBy: sort } : {}),

    const forms = (
      await getAxiosInstance().post(`/api/horse/search`, {
        page,
        ...(pageSize ? { pageSize } : {}),
        ...(sortBy ? { sort: sortBy } : {}),
        ...(q ? { q } : {}),
      })
    ).data;

    dispatch(fetchFormsDone(forms));
    return true;
  } catch (e) {
    dispatch(fetchFormsFailed(e));
    return false;
  }
};

export const fetchHorsesByStud = ({ page, pageSize, sort, q }) => async (
  dispatch,
  getState
) => {
  dispatch(fetchHorsesByStudStart());
  try {
    //         ...(sort ? { sortBy: sort } : {}),
    const currentContext = getState().currentContext?.studId;
    const horses = (
      await getAxiosInstance().post(
        `/api/horse/by_stud/${currentContext}/search`,
        {
          page,
          ...(pageSize ? { pageSize } : {}),
          ...(sort ? { sort } : {}),
          ...(q ? { q } : {}),
        }
      )
    ).data;

    dispatch(fetchHorsesByStudDone(horses));
    return horses;
  } catch (e) {
    dispatch(fetchHorsesByStudFailed(e));
    return false;
  }
};

export const fetchHorsesByStudId = ({ page, pageSize, sort, stud }) => async (
  dispatch
) => {
  dispatch(fetchHorsesByStudIdStart());
  try {
    const horses = (await getAxiosInstance().get(`/api/horse`)).data;

    dispatch(fetchHorsesByStudIdDone(horses.filter((h) => h.studId === stud)));
    return horses.filter((h) => h.studId === stud);
  } catch (e) {
    dispatch(fetchHorsesByStudIdFailed(e));
    return false;
  }
};

export const fetchFormsGroupsStart = (payload) => ({
  type: FETCH_FORMS_GROUPS_START,
  payload,
});
export const fetchFormsGroupsDone = (payload) => ({
  type: FETCH_FORMS_GROUPS_DONE,
  payload,
});
export const fetchFormsGroupsFailed = (payload) => ({
  type: FETCH_FORMS_GROUPS_FAILED,
  payload,
});

export const fetchAllHorses = () => async (dispatch) => {
  dispatch(fetchAllHorsesStart());
  try {
    const horses = (await getAxiosInstance().get(`/api/horse`)).data;

    dispatch(fetchAllHorsesDone(horses));
    return horses;
  } catch (e) {
    dispatch(fetchAllHorsesFailed(e));
    return false;
  }
};

export const fetchAllHorsesStart = (payload) => ({
  type: FETCH_ALL_HORSES_START,
  payload,
});
export const fetchAllHorsesDone = (payload) => ({
  type: FETCH_ALL_HORSES_DONE,
  payload,
});
export const fetchAllHorsesFailed = (payload) => ({
  type: FETCH_ALL_HORSES_FAILED,
  payload,
});

export const fetchHorseFarmsStart = (payload) => ({
  type: FETCH_ALL_FORMS_GROUPS_START,
  payload,
});
export const fetchHorseFarmsDone = (payload) => ({
  type: FETCH_ALL_FORMS_GROUPS_DONE,
  payload,
});
export const fetchHorseFarmsFailed = (payload) => ({
  type: FETCH_ALL_FORMS_GROUPS_FAILED,
  payload,
});

export const fetchHorseFarms = (payload) => async (dispatch) => {
  dispatch(fetchHorseFarmsStart());
  try {
    const data = (
      await getAxiosInstance().post(`/api/stud/search`, payload, {
        params: { checkContainedHorses: true },
      })
    ).data;

    dispatch(fetchHorseFarmsDone(data));
    return true;
  } catch (e) {
    dispatch(fetchHorseFarmsFailed(e));
    return false;
  }
};

export const fetchFormsGroups = ({ page, pageSize, sortBy }) => async (
  dispatch
) => {
  dispatch(fetchFormsGroupsStart());
  try {
    const data = (
      await getAxiosInstance().get(`/api/stud`, {
        params: {
          page,
          ...(pageSize ? { pageSize } : {}),
          ...(sortBy ? { sortBy } : {}),
        },
      })
    ).data;

    dispatch(fetchFormsGroupsDone(data));
    return true;
  } catch (e) {
    dispatch(fetchFormsGroupsFailed(e));
    return false;
  }
};

export const fetchAllowedUsersToGroupStart = (payload) => ({
  type: FETCH_ALLOWED_USERS_TO_GROUP_START,
  payload,
});
export const fetchAllowedUsersToGroupDone = (payload) => ({
  type: FETCH_ALLOWED_USERS_TO_GROUP_DONE,
  payload,
});
export const fetchAllowedUsersToGroupFailed = (payload) => ({
  type: FETCH_ALLOWED_USERS_TO_GROUP_FAILED,
  payload,
});

export const fetchAllowedUsersToGroup = (groupId) => async (dispatch) => {
  dispatch(fetchAllowedUsersToGroupStart());
  try {
    const data = (
      await getAxiosInstance().get(`/api/stud/${groupId}/allowed_users`)
    ).data;
    dispatch(fetchAllowedUsersToGroupDone(data));
    return true;
  } catch (e) {
    dispatch(fetchAllowedUsersToGroupFailed(e));
    return false;
  }
};

export const fetchAllowedUsersToFormStart = (payload) => ({
  type: FETCH_ALLOWED_USERS_TO_FORM_START,
  payload,
});
export const fetchAllowedUsersToFormDone = (payload) => ({
  type: FETCH_ALLOWED_USERS_TO_FORM_DONE,
  payload,
});
export const fetchAllowedUsersToFormFailed = (payload) => ({
  type: FETCH_ALLOWED_USERS_TO_FORM_FAILED,
  payload,
});

export const fetchAllowedUsersToForm = (formId) => async (dispatch) => {
  dispatch(fetchAllowedUsersToFormStart());
  try {
    const data = (
      await getAxiosInstance().get(`/api/horse/${formId}/allowed_users`)
    ).data;
    dispatch(fetchAllowedUsersToFormDone(data));
    return data;
  } catch (e) {
    dispatch(fetchAllowedUsersToFormFailed(e));
    return false;
  }
};

export const copyFormToFormGroupStart = (payload) => ({
  type: COPY_FORM_TO_FORM_GROUP_START,
  payload,
});
export const copyFormToFormGroupDone = (payload) => ({
  type: COPY_FORM_TO_FORM_GROUP_DONE,
  payload,
});
export const copyFormToFormGroupFailed = (payload) => ({
  type: COPY_FORM_TO_FORM_GROUP_FAILED,
  payload,
});

export const copyFormToFormGroup = (formId, groupId) => async (dispatch) => {
  dispatch(copyFormToFormGroupStart());
  try {
    const data = (
      await getAxiosInstance().post(
        `/api/form_group/clone_form_to_group/${formId}/${groupId}`
      )
    ).data;

    dispatch(copyFormToFormGroupDone(data));
    return true;
  } catch (e) {
    dispatch(copyFormToFormGroupFailed(e));
    return false;
  }
};

export const removeFormFromFormGroupStart = (payload) => ({
  type: REMOVE_FORM_FROM_FORM_GROUP_START,
  payload,
});
export const removeFormFromFormGroupDone = (payload) => ({
  type: REMOVE_FORM_FROM_FORM_GROUP_DONE,
  payload,
});
export const removeFormFromFormGroupFailed = (payload) => ({
  type: REMOVE_FORM_FROM_FORM_GROUP_FAILED,
  payload,
});

export const removeFormFromFormGroup = (formId) => async (dispatch) => {
  dispatch(removeFormFromFormGroupStart());
  try {
    const data = (
      await getAxiosInstance().post(
        `/api/form_group/detach_form_from_group/${formId}`
      )
    ).data;

    dispatch(removeFormFromFormGroupDone(data));
    return true;
  } catch (e) {
    dispatch(removeFormFromFormGroupFailed(e));
    return false;
  }
};

export const moveFormToFormGroupStart = (payload) => ({
  type: MOVE_FORM_TO_FORM_GROUP_START,
  payload,
});
export const moveFormToFormGroupDone = (payload) => ({
  type: MOVE_FORM_TO_FORM_GROUP_DONE,
  payload,
});
export const moveFormToFormGroupFailed = (payload) => ({
  type: MOVE_FORM_TO_FORM_GROUP_FAILED,
  payload,
});

export const moveFormToFormGroup = (formId, targetGroupId) => async (
  dispatch
) => {
  dispatch(moveFormToFormGroupStart());
  try {
    const data = (
      await getAxiosInstance().post(
        `/api/form_group/move_form_to_group/${formId}/${targetGroupId}`
      )
    ).data;

    dispatch(moveFormToFormGroupDone(data));
    return true;
  } catch (e) {
    dispatch(moveFormToFormGroupFailed(e));
    return false;
  }
};

export const fetchScheduledForms = (payload) => async (dispatch) => {
  dispatch({ type: FETCH_SCHEDULED_FORMS + START });
  try {
    const forms = (await getAxiosInstance().get("/api/forms/scheduled")).data;

    dispatch({ type: FETCH_SCHEDULED_FORMS + DONE, payload: forms });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_SCHEDULED_FORMS + FAILED, payload: e });
    return false;
  }
};

export const fetchActiveForms = ({ page, pageSize, sortBy }) => async (
  dispatch
) => {
  dispatch({ type: FETCH_ACTIVE_FORMS + START });
  try {
    const forms = await getAxiosInstance().get("/api/v2/forms/active", {
      params: {
        page,
        ...(pageSize ? { pageSize } : {}),
        ...(sortBy ? { sortBy } : {}),
      },
    });

    dispatch({ type: FETCH_ACTIVE_FORMS + DONE, payload: forms.data });
    return forms;
  } catch (e) {
    dispatch({ type: FETCH_ACTIVE_FORMS + FAILED });
  }
};

export const fetchActiveFormsAll = () => async (dispatch) => {
  dispatch({ type: FETCH_ACTIVE_FORMS_ALL + START });
  try {
    const forms = await getAxiosInstance().get("/api/forms/active");

    dispatch({ type: FETCH_ACTIVE_FORMS_ALL + DONE, payload: forms.data });
    return forms;
  } catch (e) {
    dispatch({ type: FETCH_ACTIVE_FORMS_ALL + FAILED });
  }
};

export const fetchUserAllForms = (personId) => async (dispatch) => {
  dispatch({ type: FETCH_USER_ALL_FORMS + START });
  try {
    const forms = (
      await getAxiosInstance().get(`/api/forms/active-by-person/${personId}`)
    ).data;

    dispatch({ type: FETCH_USER_ALL_FORMS + DONE, payload: forms });
  } catch (e) {
    dispatch({ type: FETCH_USER_ALL_FORMS + FAILED });
  }
};

export const fetchUserAllAvailableForms = (personId, groupId) => async (
  dispatch
) => {
  dispatch({ type: FETCH_USER_ALL_AVAILABLE_FORMS + START });
  try {
    const forms = (
      await getAxiosInstance().get(
        `/api/forms/available-for-person/${personId}`,
        {
          params: {
            groupId,
          },
        }
      )
    ).data;

    dispatch({ type: FETCH_USER_ALL_AVAILABLE_FORMS + DONE, payload: forms });
  } catch (e) {
    dispatch({ type: FETCH_USER_ALL_AVAILABLE_FORMS + FAILED });
  }
};

export const fetchPlannedForms = ({ page, pageSize, sortBy }) => async (
  dispatch
) => {
  dispatch({ type: FETCH_PLANNED_FORMS + START });
  try {
    const forms = (
      await getAxiosInstance().get("/api/v2/forms/scheduled", {
        params: {
          page,
          ...(pageSize ? { pageSize } : {}),
          ...(sortBy ? { sortBy } : {}),
        },
      })
    ).data;

    dispatch({ type: FETCH_PLANNED_FORMS + DONE, payload: forms });
    return forms;
  } catch (e) {
    dispatch({ type: FETCH_PLANNED_FORMS + FAILED });
  }
};
export const fetchPlannedFormsAll = () => async (dispatch) => {
  dispatch({ type: FETCH_PLANNED_FORMS_ALL + START });
  try {
    const forms = (await getAxiosInstance().get("/api/forms/scheduled")).data;

    dispatch({ type: FETCH_PLANNED_FORMS_ALL + DONE, payload: forms });
    return forms;
  } catch (e) {
    dispatch({ type: FETCH_PLANNED_FORMS_ALL + FAILED });
  }
};

export const fetchArchivedForms = ({ page, pageSize, sortBy }) => async (
  dispatch
) => {
  dispatch({ type: FETCH_ARCHIVED_FORMS + START });
  try {
    const forms = (
      await getAxiosInstance().get("/api/v2/forms/all-my-filled-unique-bags", {
        params: {
          page,
          ...(pageSize ? { pageSize } : {}),
          ...(sortBy ? { sortBy } : {}),
        },
      })
    ).data;

    dispatch({ type: FETCH_ARCHIVED_FORMS + DONE, payload: forms });
    return forms;
  } catch (e) {
    dispatch({ type: FETCH_ARCHIVED_FORMS + FAILED });
  }
};

export const fetchArchivedFormsBag = ({ bagId }) => async (dispatch) => {
  dispatch({ type: FETCH_ARCHIVED_FORMS_BAG + START });
  try {
    const forms = (
      await getAxiosInstance().get(
        `/api/v2/forms/all-my-filled-previous/${bagId}`
      )
    ).data;

    dispatch({ type: FETCH_ARCHIVED_FORMS_BAG + DONE, payload: forms, bagId });
    return forms;
  } catch (e) {
    dispatch({ type: FETCH_ARCHIVED_FORMS_BAG + FAILED });
  }
};

export const fetchSavedFormData = ({ page, pageSize, sortBy }) => async (
  dispatch
) => {
  dispatch({ type: FETCH_SAVED_FORM_DATA + START });
  try {
    const forms = (
      await getAxiosInstance().get("/api/v2/form_data/all/my", {
        params: {
          state: "SAVED",
          page,
          ...(pageSize ? { pageSize } : {}),
          ...(sortBy ? { sortBy } : {}),
        },
      })
    ).data;

    dispatch({ type: FETCH_SAVED_FORM_DATA + DONE, payload: forms });
  } catch (e) {
    dispatch({ type: FETCH_SAVED_FORM_DATA + FAILED });
  }
};

export const fetchFilledFormData = () => async (dispatch) => {
  dispatch({ type: FETCH_FILLED_FORM_DATA + START });
  try {
    const forms = (
      await getAxiosInstance().get("/api/form_data/all/my", {
        params: {
          state: "COMPLETED",
        },
      })
    ).data;

    dispatch({ type: FETCH_FILLED_FORM_DATA + DONE, payload: forms });
  } catch (e) {
    dispatch({ type: FETCH_FILLED_FORM_DATA + FAILED });
  }
};

export const removeDraft = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_FORM_DATA_DRAFT + START });
  try {
    const response = await getAxiosInstance().delete(`/api/form_data/${id}`);
    dispatch({ type: REMOVE_FORM_DATA_DRAFT + DONE, payload: { id } });
    return response;
  } catch (e) {
    dispatch({ type: REMOVE_FORM_DATA_DRAFT + FAILED });
    return e.response;
  }
};

/////////////

export const removeFormStart = (payload) => ({
  type: REMOVE_FORM_START,
  payload,
});
export const removeFormDone = (payload) => ({
  type: REMOVE_FORM_DONE,
  payload,
});
export const removeFormFailed = (payload) => ({
  type: REMOVE_FORM_FAILED,
  payload,
});

export const removeForm = (payload) => async (dispatch) => {
  dispatch(removeFormStart());
  try {
    await getAxiosInstance().delete(`/api/forms/${payload}`);

    dispatch(removeFormDone());
    dispatch(fetchHasSingleScheduledForm());
    return true;
  } catch (e) {
    dispatch(removeFormFailed(e));
    return false;
  }
};

export const removeHorse = (payload) => async (dispatch) => {
  dispatch(removeFormStart());
  try {
    await getAxiosInstance().delete(`/api/horse/${payload}`);

    dispatch(removeFormDone());
    dispatch(fetchHasSingleScheduledForm());
    return true;
  } catch (e) {
    dispatch(removeFormFailed(e));
    throw e;
  }
};

/////////////

export const fetchFormTemplateStart = (payload) => ({
  type: FETCH_FORM_TEMPLATE_START,
  payload,
});
export const fetchFormTemplateDone = (payload) => ({
  type: FETCH_FORM_TEMPLATE_DONE,
  payload,
});
export const fetchFormTemplateFailed = (payload) => ({
  type: FETCH_FORM_TEMPLATE_FAILED,
  payload,
});

export const fetchFormTemplate = (formDataId) => async (dispatch) => {
  dispatch(fetchFormTemplateStart());
  try {
    const data = (
      await getAxiosInstance().get(`/api/form_template/${formDataId}`)
    ).data;

    dispatch(fetchFormTemplateDone(data));
    return data;
  } catch (e) {
    dispatch(fetchFormTemplateFailed(e));
    return null;
  }
};

/////////////

export const fetchFormStart = (payload) => ({
  type: FETCH_FORM_START,
  payload,
});
export const fetchFormDone = (payload) => ({ type: FETCH_FORM_DONE, payload });
export const fetchFormFailed = (payload) => ({
  type: FETCH_FORM_FAILED,
  payload,
});

export const fetchForm = (id) => async (dispatch) => {
  dispatch(fetchFormStart());
  try {
    const data = (await getAxiosInstance().get(`/api/horse/${id}`)).data;

    dispatch(fetchFormDone(data));
    return data;
  } catch (e) {
    dispatch(fetchFormFailed(e));
    return null;
  }
};

export const fetchHorse = (id) => async (dispatch) => {
  dispatch(fetchFormStart());
  try {
    const data = (await getAxiosInstance().get(`/api/horse/${id}`)).data;

    dispatch(fetchFormDone(data));
    return data;
  } catch (e) {
    dispatch(fetchFormFailed(e));
    return null;
  }
};

/////////////
export const fetchPrivateKeyStart = (payload) => ({
  type: FETCH_PRIVATE_KEY_START,
  payload,
});
export const fetchPrivateKeyDone = (payload) => ({
  type: FETCH_PRIVATE_KEY_DONE,
  payload,
});
export const fetchPrivateKeyFailed = (payload) => ({
  type: FETCH_PRIVATE_KEY_FAILED,
  payload,
});

export const fetchFormGroupStart = (payload) => ({
  type: FETCH_FORM_GROUP_START,
  payload,
});
export const fetchFormGroupDone = (payload) => ({
  type: FETCH_FORM_GROUP_DONE,
  payload,
});
export const fetchFormGroupFailed = (payload) => ({
  type: FETCH_FORM_GROUP_FAILED,
  payload,
});

export const fetchFormGroup = (id) => async (dispatch) => {
  dispatch(fetchFormGroupStart());
  try {
    const data = (await getAxiosInstance().get(`/api/stud/${id}`)).data;

    dispatch(fetchFormGroupDone(data));
    return data;
  } catch (e) {
    dispatch(fetchFormGroupFailed(e));
    return false;
  }
};
export const fetchPrivateKey = (id) => async (dispatch) => {
  dispatch(fetchPrivateKeyStart());
  try {
    const data = (await getAxiosInstance().get(`/api/${id}/private_key`)).data;

    dispatch(fetchPrivateKeyDone(data));
    return data;
  } catch (e) {
    dispatch(fetchPrivateKeyFailed(e));
    throw e;
  }
};
/////////////

/////////////

export const fetchGlobalDictionariesStart = (payload) => ({
  type: FETCH_GLOBAL_DICTIONARIES_START,
  payload,
});
export const fetchGlobalDictionariesDone = (payload) => ({
  type: FETCH_GLOBAL_DICTIONARIES_DONE,
  payload,
});
export const fetchGlobalDictionariesFailed = (payload) => ({
  type: FETCH_GLOBAL_DICTIONARIES_FAILED,
  payload,
});

export const fetchGlobalDictionaries = () => async (dispatch) => {
  dispatch(fetchGlobalDictionariesStart());
  try {
    const data = (
      await getAxiosInstance().get(`/api/form_layouts/saved_fields`)
    ).data;

    dispatch(fetchGlobalDictionariesDone(data));
    return data;
  } catch (e) {
    dispatch(fetchGlobalDictionariesFailed(e));
    return null;
  }
};

/////////////

export const createFilledFormDataStart = (payload) => ({
  type: CREATE_FILLED_FORM_DATA_START,
  payload,
});
export const createFilledFormDataDone = (payload) => ({
  type: CREATE_FILLED_FORM_DATA_DONE,
  payload,
});
export const createFilledFormDataFailed = (payload) => ({
  type: CREATE_FILLED_FORM_DATA_FAILED,
  payload,
});

export const createFilledFormData = ({
  formId,
  scheduledFormId,
  values,
  arrayValues,
  files,
  notes,
  fieldAlternativeAnswer,
  draft,
}) => async (dispatch) => {
  dispatch(createFilledFormDataStart());
  try {
    const payload = {
      form: {
        id: formId,
      },
    };
    const formMetadata = (
      await getAxiosInstance().post(`/api/form_metadata/`, payload)
    ).data;
    dispatch(createFilledFormDataDone(formMetadata));

    return await dispatch(
      saveFilledFormData({
        formId,
        scheduledFormId,
        formMetadataId: formMetadata.id,
        values,
        arrayValues,
        files,
        notes,
        fieldAlternativeAnswer,
        draft,
      })
    );
  } catch (e) {
    dispatch(createFilledFormDataFailed(e));
    throw e;
  }
};

/////////////

export const saveFilledFormDataStart = (payload) => ({
  type: SAVE_FILLED_FORM_DATA_START,
  payload,
});
export const saveFilledFormDataDone = (payload) => ({
  type: SAVE_FILLED_FORM_DATA_DONE,
  payload,
});
export const saveFilledFormDataFailed = (payload) => ({
  type: SAVE_FILLED_FORM_DATA_FAILED,
  payload,
});

export const saveFilledFormData = ({
  formId,
  formMetadataId,
  scheduledFormId,
  values,
  arrayValues,
  files,
  fieldAlternativeAnswer,
  draft,
  notes,
}) => async (dispatch) => {
  dispatch(saveFilledFormDataStart());
  try {
    const payload = {
      formId,
      scheduledFormId,
      data: values,
      arrayData: arrayValues,
      noteData: notes,
      filesDataUUID: files,
      formMetadataId,
      fieldAlternativeAnswer,
    };
    const result = (
      await getAxiosInstance().post(`/api/form_data/`, payload, {
        params: { draft },
      })
    ).data;
    dispatch(saveFilledFormDataDone(result));

    return result;
  } catch (e) {
    dispatch(saveFilledFormDataFailed(e));
    throw e;
  }
};

export const editFilledFormDataStart = (payload) => ({
  type: EDIT_FILLED_FORM_DATA_START,
  payload,
});
export const editFilledFormDataDone = (payload) => ({
  type: EDIT_FILLED_FORM_DATA_DONE,
  payload,
});
export const editFilledFormDataFailed = (payload) => ({
  type: EDIT_FILLED_FORM_DATA_FAILED,
  payload,
});

export const editFilledFormData = (
  {
    formId,
    formMetadataId,
    scheduledFormId,
    values,
    arrayValues,
    fieldAlternativeAnswer,
    files,
    draft,
    notes,
  },
  formDataId
) => async (dispatch) => {
  dispatch(editFilledFormDataStart());
  try {
    const payload = {
      formId,
      scheduledFormId,
      data: values,
      arrayData: arrayValues,
      formMetadataId,
      fieldAlternativeAnswer,
      filesDataUUID: files,
      noteData: notes,
    };
    const result = (
      await getAxiosInstance().put(`/api/form_data/${formDataId}`, payload, {
        params: {
          draft,
        },
      })
    ).data;
    dispatch(editFilledFormDataDone(result));

    return result;
  } catch (e) {
    dispatch(editFilledFormDataFailed(e));
    if (
      e.response.data.code === "500" &&
      e.response.data.message === "HTTP 404 Not Found"
    ) {
      return false;
    }
    throw e;
  }
};

/////////////

export const fetchFilledFormsStart = (payload) => ({
  type: FETCH_FILLED_FORMS_START,
  payload,
});
export const fetchFilledFormsDone = (payload) => ({
  type: FETCH_FILLED_FORMS_DONE,
  payload,
});
export const fetchFilledFormsFailed = (payload) => ({
  type: FETCH_FILLED_FORMS_FAILED,
  payload,
});

export const fetchFilledForms = (formId) => async (dispatch) => {
  dispatch(fetchFilledFormsStart());
  try {
    const result = (
      await getAxiosInstance().get(`/api/form_data/by_form_id/${formId}`)
    ).data;
    dispatch(
      fetchFilledFormsDone({
        formId,
        data: result,
      })
    );

    return result;
  } catch (e) {
    dispatch(fetchFilledFormsFailed(e));
    return false;
  }
};

export const fetchFilledFormsExtended = (
  formId,
  { showMissedForms, ...body }
) => async (dispatch) => {
  dispatch({ type: FETCH_FILLED_FORMS_EXTENDED + START });
  try {
    const result = (
      await getAxiosInstance().post(
        `/api/form_data/by_form_id_extended/${formId}`,
        body,
        {
          params: {
            showMissedForms,
          },
        }
      )
    ).data;
    dispatch({
      type: FETCH_FILLED_FORMS_EXTENDED + DONE,
      payload: {
        formId,
        result,
      },
    });

    return result;
  } catch (e) {
    dispatch({ type: FETCH_FILLED_FORMS_EXTENDED + FAILED, payload: e });
    return false;
  }
};

const recoverPasswordStart = (payload) => ({
  type: RECOVER_PASSWORD_START,
  payload,
});
const recoverPasswordDone = (payload) => ({
  type: RECOVER_PASSWORD_DONE,
  payload,
});
const recoverPasswordFailed = (payload) => ({
  type: RECOVER_PASSWORD_FAILED,
  payload,
});
export const recoverPassword = ({ email, phone, role }) => async (dispatch) => {
  dispatch(recoverPasswordStart());
  try {
    const result =
      role !== "patient"
        ? await getAxiosInstance().post(`/employees/v1/password/reset/ask`, {
            email,
            lang: "pl",
          })
        : await getAxiosInstance().post(`/api/auth/recover_password`, {
            email,
            phone,
            lang: "pl",
          });
    const data = result.data;
    dispatch(recoverPasswordDone(data));

    return result;
  } catch (e) {
    dispatch(recoverPasswordFailed(e));
    return false;
  }
};

const resetByMailPasswordStart = (payload) => ({
  type: RESET_BY_MAIL_PASSWORD_START,
  payload,
});
const resetByMailPasswordDone = (payload) => ({
  type: RESET_BY_MAIL_PASSWORD_DONE,
  payload,
});
const resetByMailPasswordFailed = (payload) => ({
  type: RESET_BY_MAIL_PASSWORD_FAILED,
  payload,
});

export const resetByMailPassword = ({
  email,
  resetId,
  role,
  newPassword,
}) => async (dispatch) => {
  dispatch(resetByMailPasswordStart());
  try {
    role !== "user"
      ? await getAxiosInstance().post(`/employees/v1/password/reset`, {
          email,
          resetId,
          newPassword,
        })
      : await getAxiosInstance().post(`/api/auth/change_password`, {
          email,
          resetId,
          newPassword,
        });
    dispatch(resetByMailPasswordDone());
    return true;
  } catch (e) {
    dispatch(resetByMailPasswordFailed(e));
    return false;
  }
};

export const alertAdd = (payload) => ({ type: ALERT_SHOW, payload });
export const alertHide = (payload) => ({ type: ALERT_HIDE, payload });
export const alertHideCustomId = (payload) => ({
  type: ALERT_HIDE_CUSTOM_ID,
  payload,
});
export const alertTimeout = (payload) => ({ type: ALERT_TIMEOUT, payload });

const sendCommentStart = () => ({ type: SEND_COMMENT_START });
const sendCommentDone = () => ({ type: SEND_COMMENT_DONE });
const sendCommentFailed = () => ({ type: SEND_COMMENT_FAILED });
export const sendComment = ({ url, text }) => async (dispatch) => {
  dispatch(sendCommentStart());
  try {
    const result = await getAxiosInstance().post(`/api/site-comment`, {
      url,
      text,
    });
    dispatch(sendCommentDone());

    return result;
  } catch (e) {
    dispatch(sendCommentFailed(e));
    return false;
  }
};

export const activateForm = ({ id }) => async (dispatch) => {
  dispatch({ type: ACTIVATE_FORM + START });
  try {
    const result = await getAxiosInstance().put(`/api/forms/${id}/activate`);
    dispatch({ type: ACTIVATE_FORM + DONE, payload: result.data });

    return result.data;
  } catch (e) {
    dispatch({ type: ACTIVATE_FORM + FAILED, payload: e });
    throw e;
  }
};

export const removeUserStart = (payload) => ({
  type: REMOVE_USER + START,
  payload,
});
export const removeUserDone = (payload) => ({
  type: REMOVE_USER + DONE,
  payload,
});
export const removeUserFailed = (payload) => ({
  type: REMOVE_USER + FAILED,
  payload,
});

export const removeUser = (userId, canSeeTerms) => async (dispatch) => {
  dispatch(removeUserStart());
  try {
    const result = await getAxiosInstance().delete(`/api/v1/users/${userId}`);
    dispatch(removeUserDone(result));
    // canSeeTerms
    //   ? dispatch(fetchOrgUsersWithTerms())
    //   : dispatch(fetchOrgUsersPaged(page, rowsPerPage, sortBy));

    return result;
  } catch (e) {
    dispatch(removeUserFailed(e));
    return false;
  }
};

export const removeUserFromStud = (userId, canSeeTerms) => async (
  dispatch,
  getState
) => {
  dispatch(removeUserStart());
  try {
    const currentContext = getState().currentContext?.studId;

    const result = await getAxiosInstance().delete(
      `/api/stud/${currentContext}/allowed_users/${userId}`
    );
    dispatch(removeUserDone(result));
    // canSeeTerms
    //   ? dispatch(fetchOrgUsersWithTerms())
    //   : dispatch(fetchOrgUsersPaged(page, rowsPerPage, sortBy));

    return result;
  } catch (e) {
    dispatch(removeUserFailed(e));
    return false;
  }
};

export const editUserStart = (payload) => ({ type: EDIT_USER, payload });
export const editUserDone = (payload) => ({
  type: EDIT_USER + DONE,
  payload,
});
export const editUserFailed = (payload) => ({
  type: EDIT_USER + FAILED,
  payload,
});

export const editUser = (user) => async (dispatch) => {
  dispatch(editUserStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/persons/${user.id}/update`,
      user
    );
    const my = await getAxiosInstance().get("/api/persons/my");

    dispatch(editUserDone({ result: result, my: my.data }));
    // dispatch(fetchOrgUsers());

    return result;
  } catch (e) {
    dispatch(editUserFailed(e));
    return e.response;
  }
};

export const fetchMatchingUsersStart = (payload) => ({
  type: FETCH_MATCHING_USERS + START,
  payload,
});
export const fetchMatchingUsersDone = (payload) => ({
  type: FETCH_MATCHING_USERS + DONE,
  payload,
});
export const fetchMatchingUsersFailed = (payload) => ({
  type: FETCH_MATCHING_USERS + FAILED,
  payload,
});

export const clearMatchingUser = () => {
  return {
    type: CLEAR_MATCHING_USERS,
  };
};

export const fetchMatchingUsers = (data) => async (dispatch) => {
  dispatch(fetchMatchingUsersStart());
  try {
    const result = await getAxiosInstance().post(
      `/api/organizations/fetch_matching_users`,
      data
    );
    dispatch(fetchMatchingUsersDone(result.data));
    return result;
  } catch (e) {
    dispatch(fetchMatchingUsersFailed(e));
    return e.response;
  }
};

export const activateUserStart = (payload) => ({
  type: ACTIVATE_USER + START,
  payload,
});
export const activateUserDone = (payload) => ({
  type: ACTIVATE_USER + DONE,
  payload,
});
export const activateUserFailed = (payload) => ({
  type: ACTIVATE_USER + FAILED,
  payload,
});

export const activateUser = (userId) => async (dispatch) => {
  dispatch(activateUserStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/v1/users/${userId}/activate`
    );
    dispatch(activateUserDone(result));
    // dispatch(fetchOrgUsers());

    return result;
  } catch (e) {
    dispatch(activateUserFailed(e));
    return false;
  }
};
export const activateUserByDoc = (userId) => async (dispatch) => {
  dispatch(activateUserStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/v2/forms/editor-managed-persons/${userId}/activate`
    );
    dispatch(activateUserDone(result));
    // dispatch(fetchOrgUsers());

    return result;
  } catch (e) {
    dispatch(activateUserFailed(e));
    return false;
  }
};

export const updateEntitledUsersStart = (payload) => ({
  type: UPDATE_ENTITLED_USERS + START,
  payload,
});
export const updateEntitledUsersDone = (payload) => ({
  type: UPDATE_ENTITLED_USERS + DONE,
  payload,
});
export const updateEntitledUsersFailed = (payload) => ({
  type: UPDATE_ENTITLED_USERS + FAILED,
  payload,
});

export const updateEntitledUsers = (formId, emails) => async (dispatch) => {
  dispatch(updateEntitledUsersStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/forms/${formId}/entitled_users`,
      {
        users: emails.map((email) => ({
          username: email,
        })),
      }
    );
    dispatch(updateEntitledUsersDone(result.data));

    return result.data;
  } catch (e) {
    dispatch(updateEntitledUsersFailed(e));
    return false;
  }
};

export const updateUsersAccessToGroupStart = (payload) => ({
  type: UPDATE_USERS_ACCESS_TO_GROUP + START,
  payload,
});
export const updateUsersAccessToGroupDone = (payload) => ({
  type: UPDATE_USERS_ACCESS_TO_GROUP + DONE,
  payload,
});
export const updateUsersAccessToGroupFailed = (payload) => ({
  type: UPDATE_USERS_ACCESS_TO_GROUP + FAILED,
  payload,
});

export const updateUsersAccessToGroup = (groupId, usersIDs) => async (
  dispatch
) => {
  dispatch(updateUsersAccessToGroupStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/stud/${groupId}/allowed_users`,
      usersIDs
    );
    dispatch(updateUsersAccessToGroupDone(result.data));

    return result.data;
  } catch (e) {
    dispatch(updateUsersAccessToGroupFailed(e));
    return false;
  }
};

export const updateUsersAccessToFormStart = (payload) => ({
  type: UPDATE_USERS_ACCESS_TO_FORM + START,
  payload,
});
export const updateUsersAccessToFormDone = (payload) => ({
  type: UPDATE_USERS_ACCESS_TO_FORM + DONE,
  payload,
});
export const updateUsersAccessToFormFailed = (payload) => ({
  type: UPDATE_USERS_ACCESS_TO_FORM + FAILED,
  payload,
});

export const saveUsersAccessToHorseStart = (payload) => ({
  type: SAVE_USERS_ACCESS_TO_HORSE + FAILED,
  payload,
});
export const saveUsersAccessToHorseDone = (payload) => ({
  type: SAVE_USERS_ACCESS_TO_HORSE + FAILED,
  payload,
});
export const saveUsersAccessToHorseFailed = (payload) => ({
  type: SAVE_USERS_ACCESS_TO_HORSE + FAILED,
  payload,
});

export const updateUsersAccessToForm = (formId, selectedUsers) => async (
  dispatch
) => {
  dispatch(updateUsersAccessToFormStart());
  try {
    const result = await getAxiosInstance().put(
      `/api/horse/${formId}/allowed_users`,
      selectedUsers
    );
    dispatch(updateUsersAccessToFormDone(result.data));

    return result.data;
  } catch (e) {
    dispatch(updateUsersAccessToFormFailed(e));
    throw e.response;
  }
};

export const saveUsersAccessToHorse = (horseId, userId) => async (dispatch) => {
  dispatch(saveUsersAccessToHorseStart());
  try {
    const result = await getAxiosInstance().post(
      `/api/horse/${horseId}/allowed_users`,
      { userId, selected: true }
    );
    dispatch(saveUsersAccessToHorseDone(result.data));

    return true;
  } catch (e) {
    dispatch(saveUsersAccessToHorseFailed(e));
    return false;
  }
};

export const fetchFormEditions = (formId) => async (dispatch) => {
  dispatch({ type: FETCH_FORM_EDITIONS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/form_data/editions_by_form_id/${formId}`
    );
    dispatch({ type: FETCH_FORM_EDITIONS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_FORM_EDITIONS + FAILED, payload: e });
  }
};

export const fetchOldFormEditions = (formId) => async (dispatch) => {
  dispatch({ type: FETCH_OLD_FORM_EDITIONS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/forms/${formId}/old_versions`
    );
    dispatch({ type: FETCH_OLD_FORM_EDITIONS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_OLD_FORM_EDITIONS + FAILED, payload: e });
  }
};

export const fetchDataAsCSV = ({
  formId,
  includePersonalData,
  personId,
  showMissedForms,
}) => async (dispatch) => {
  dispatch({ type: FETCH_DATA_AS_CSV + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/form_data/csv/${formId}`,
      {
        params: {
          includePersonalData,
          includeMissingData: showMissedForms,
          personId,
        },
      }
    );
    dispatch({ type: FETCH_DATA_AS_CSV + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_DATA_AS_CSV + FAILED, payload: e });
  }
};

export const addItemToCart = (payload) => async (dispatch) => {
  dispatch({ type: ADD_ITEM_TO_CART + START });
  try {
    const result = await getAxiosInstance().post(`/api/cart`, payload);
    dispatch({ type: ADD_ITEM_TO_CART + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: ADD_ITEM_TO_CART + FAILED, payload: e });
  }
};

export const editItemCart = (id, payload) => async (dispatch) => {
  dispatch({ type: EDIT_ITEM_CART + START });
  try {
    const result = await getAxiosInstance().put(`/api/cart/${id}`, payload);
    dispatch({ type: EDIT_ITEM_CART + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: EDIT_ITEM_CART + FAILED, payload: e });
  }
};

export const removeFromCart = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_ITEM_FROM_CART + START });
  try {
    const result = await getAxiosInstance().delete(`/api/cart/${id}`);
    dispatch({ type: REMOVE_ITEM_FROM_CART + DONE, payload: id });
    return result.data;
  } catch (e) {
    dispatch({ type: REMOVE_ITEM_FROM_CART + FAILED, payload: e });
  }
};

export const fetchAllMyTests = () => async (dispatch) => {
  dispatch({ type: FETCH_ALL_MY_TESTS + START });
  try {
    const result = await getAxiosInstance().get(`/api/form_data/all/my`, {
      params: {
        sortBy: "createdBy",
        sortOrder: "asc",
      },
    });
    dispatch({ type: FETCH_ALL_MY_TESTS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ALL_MY_TESTS + FAILED, payload: e });
  }
};

export const fetchFilledTests = (params) => async (dispatch) => {
  dispatch({ type: FETCH_FILLED_TESTS + START });
  try {
    const result = await getAxiosInstance().get("/api/form_data/all/my", {
      params,
    });
    dispatch({ type: FETCH_FILLED_TESTS + DONE, payload: result.data });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_FILLED_TESTS + FAILED, payload });
    return false;
  }
};

export const fetchActivatedKits = () => async (dispatch) => {
  dispatch({ type: FETCH_ACTIVATED_KITS + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/forms/scheduled/activated`
    );
    dispatch({ type: FETCH_ACTIVATED_KITS + DONE, payload: result.data });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ACTIVATED_KITS + FAILED, payload: e });
  }
};

export const toggleRequestedSharingByOwner = (scheduledFormId) => async (
  dispatch
) => {
  dispatch({ type: TOGGLE_REQUESTED_SHARING_BY_OWNER + START });
  try {
    const result = await getAxiosInstance().patch(
      `/api/forms/scheduled/${scheduledFormId}/toggle-requested-sharing-by-owner`
    );
    dispatch({
      type: TOGGLE_REQUESTED_SHARING_BY_OWNER + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: TOGGLE_REQUESTED_SHARING_BY_OWNER + FAILED, payload: e });
  }
};

export const updateRequestedSharingByOwner = (
  scheduledFormId,
  enable
) => async (dispatch) => {
  dispatch({ type: UPDATE_REQUESTED_SHARING_BY_OWNER + START });
  try {
    const result = await getAxiosInstance().patch(
      `/api/forms/scheduled/${scheduledFormId}/${
        enable ? "enable" : "disable"
      }-requested-sharing-by-owner`
    );
    dispatch({
      type: UPDATE_REQUESTED_SHARING_BY_OWNER + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: UPDATE_REQUESTED_SHARING_BY_OWNER + FAILED, payload: e });
  }
};

export const updateSharingFormData = (formDataId, enable) => async (
  dispatch
) => {
  dispatch({ type: UPDATE_FORM_DATA_IS_SHARED + START });
  try {
    const result = await getAxiosInstance().patch(
      `/api/form_data/${formDataId}/${enable ? "enable" : "disable"}-sharing`
    );
    dispatch({
      type: UPDATE_FORM_DATA_IS_SHARED + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: UPDATE_FORM_DATA_IS_SHARED + FAILED, payload: e });
  }
};

export const setAppBarTitle = (title) => async (dispatch) => {
  dispatch({
    type: SET_APP_BAR_TITLE,
    payload: { title },
  });
};

/**
 * This will fetch the following order details:
 * - all kits that were part of this order
 * - all tests that were in the kit
 * - all results of the tests that were already conducted
 * @param data
 * @returns {function(*): Promise<*|undefined>}
 */
export const fetchOrderDetailsByOrderId = (orderId) => async (dispatch) => {
  dispatch({ type: FETCH_ORDER_DETAILS_BY_ORDER_ID + START });
  try {
    const result = await getAxiosInstance().get(`/api/orders/${orderId}`);
    dispatch({
      type: FETCH_ORDER_DETAILS_BY_ORDER_ID + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_ORDER_DETAILS_BY_ORDER_ID + FAILED, payload: e });
  }
};

export const fetchFormData = (formDataId) => async (dispatch) => {
  dispatch({ type: FETCH_FORM_DATA + START });
  try {
    const result = await getAxiosInstance().get(`/api/form_data/${formDataId}`);
    dispatch({
      type: FETCH_FORM_DATA + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_FORM_DATA + FAILED, payload: e });
    return e.response;
  }
};

export const fetchUploadableFileTypes = (formDataId) => async (dispatch) => {
  dispatch({ type: FETCH_UPLOADABLE_FILE_TYPES + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/form_data/upload/file_types`
    );
    dispatch({
      type: FETCH_UPLOADABLE_FILE_TYPES + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_UPLOADABLE_FILE_TYPES + FAILED, payload: e });
  }
};

export const fetchFormsScheduledForFuture = ({
  page,
  pageSize,
  sortBy,
}) => async (dispatch) => {
  dispatch({ type: FETCH_FORMS_SCHEDULED_FOR_FUTURE + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/v2/forms/scheduled-for-future`,
      {
        params: {
          page,
          ...(pageSize ? { pageSize } : {}),
          ...(sortBy ? { sortBy } : {}),
        },
      }
    );
    dispatch({
      type: FETCH_FORMS_SCHEDULED_FOR_FUTURE + DONE,
      payload: result.data,
    });
    return result.data;
  } catch (e) {
    dispatch({ type: FETCH_FORMS_SCHEDULED_FOR_FUTURE + FAILED, payload: e });
  }
};

export const resetGivenUserPassword = (id) => async (dispatch) => {
  dispatch({ type: RESET_GIVEN_USER_PASSWORD + START });
  try {
    const result = await getAxiosInstance().put(
      `/api/persons/${id}/reset_password`
    );
    dispatch({
      type: RESET_GIVEN_USER_PASSWORD + DONE,
      payload: result.data,
    });
    return result;
  } catch (e) {
    dispatch({ type: RESET_GIVEN_USER_PASSWORD + FAILED, payload: e });
    return e.response;
  }
};

export const fetchApiKeys = () => async (dispatch) => {
  dispatch({ type: FETCH_API_KEYS + START });
  try {
    const result = await getAxiosInstance().get(`/employees/v1/tokens`);
    dispatch({
      type: FETCH_API_KEYS + DONE,
    });
    return result;
  } catch (e) {
    dispatch({ type: FETCH_API_KEYS + FAILED, payload: e });
    return e.response;
  }
};

export const addApiKey = (payload) => async (dispatch) => {
  dispatch({ type: ADD_API_KEY + START });
  try {
    const result = await getAxiosInstance().post(`/employees/v1/tokens`, {
      ...payload,
    });
    dispatch({
      type: ADD_API_KEY + DONE,
      payload: result.data,
    });
    return result;
  } catch (e) {
    dispatch({ type: ADD_API_KEY + FAILED, payload: e });
    return e.response;
  }
};

export const deleteApiKey = (tokenId) => async (dispatch) => {
  dispatch({ type: DELEETE_API_KEY + START });
  try {
    const result = await getAxiosInstance().delete(`/employees/v1/tokens`, {
      params: {
        tokenId,
      },
    });
    dispatch({
      type: DELEETE_API_KEY + DONE,
    });
    return result;
  } catch (e) {
    dispatch({ type: DELEETE_API_KEY + FAILED, payload: e });
    return e.response;
  }
};

export const fetchScheduledForm = (id) => async (dispatch) => {
  dispatch({ type: FETCH_SCHEDULED_FORM + START });
  try {
    const form = (await getAxiosInstance().get(`/api/forms/scheduled/${id}`))
      .data;

    dispatch({ type: FETCH_SCHEDULED_FORM + DONE, payload: form });
    return form;
  } catch (e) {
    dispatch({ type: FETCH_SCHEDULED_FORM + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchHasSingleScheduledForm = () => async (dispatch) => {
  dispatch({ type: FETCH_HAS_SINGLE_SCHEDULED_FORM + START });
  try {
    const data = (
      await getAxiosInstance().get(`/api/forms/has-only-one-scheduled-form`)
    ).data;

    dispatch({ type: FETCH_HAS_SINGLE_SCHEDULED_FORM + DONE, payload: data });
    return data;
  } catch (e) {
    dispatch({ type: FETCH_HAS_SINGLE_SCHEDULED_FORM + FAILED, payload: e });
    throw e.response;
  }
};

export const checkMyIdentity = () => async (dispatch) => {
  try {
    const result = await getAxiosInstance().get(`/api/persons/my`);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const toggleGlobalTheme = () => {
  return {
    type: TOGGLE_GLOBAL_THEME,
  };
};

export const readGlobalTheme = () => {
  return {
    type: READ_GLOBAL_THEME,
  };
};

export const smallFontSize = () => {
  return {
    type: SMALL_FONT_SIZE,
  };
};
export const mediumFontSize = () => {
  return {
    type: MEDIUM_FONT_SIZE,
  };
};
export const largeFontSize = () => {
  return {
    type: LARGE_FONT_SIZE,
  };
};

export const fetchConfig = () => async (dispatch) => {
  dispatch({ type: FETCH_CONFIG + START });
  try {
    const result = await getAxiosInstance().get(`/api/config`);
    dispatch({ type: FETCH_CONFIG + DONE, payload: result.data });
    return result.response;
  } catch (e) {
    dispatch({ type: FETCH_CONFIG + FAILED });
    return e.response;
  }
};

export const clearAllFormData = () => {
  return {
    type: CLEAR_ALL_FORM,
  };
};

export const fetchTermsAcceptedByUserWithId = (userId) => async (dispatch) => {
  dispatch({ type: FETCH_TERMS_ACCEPTED_BY_USER_WITH_ID + START });
  try {
    const result = await getAxiosInstance().get(
      `/api/organizations/terms/accepted/${userId}`
    );
    dispatch({
      type: FETCH_TERMS_ACCEPTED_BY_USER_WITH_ID + DONE,
      payload: result.data,
    });

    return result.data;
  } catch (payload) {
    dispatch({ type: FETCH_TERMS_ACCEPTED_BY_USER_WITH_ID + FAILED, payload });
    return false;
  }
};

export const checkApiVersion = () => async (dispatch) => {
  dispatch({ type: FETCH_API_VERSION + START });
  try {
    const result = await getAxiosInstance().get(`/api/version`);
    dispatch({ type: FETCH_API_VERSION + DONE, payload: result.data.version });
  } catch (error) {
    dispatch({ type: FETCH_API_VERSION + FAILED });
  }
};

export const setUnsupportedBrowser = (value) => {
  return {
    type: UNSUPPORTED_BROWSER,
    payload: value,
  };
};

export const fetchVaccinations = ({ page, pageSize, sort, q }) => async (
  dispatch
) => {
  dispatch({ type: FETCH_VACCINATIONS + START });
  try {
    const vaccinations = await getAxiosInstance().post(
      `/api/horse_vaccination_history_event/search`,
      {
        page,
        ...(pageSize ? { pageSize: parseInt(pageSize) } : {}),
        ...(sort ? { sort } : {}),
        ...(q ? { q } : {}),
      }
    );

    dispatch({ type: FETCH_VACCINATIONS + DONE, payload: vaccinations.data });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_VACCINATIONS + FAILED, payload: e });
    throw e.response;
  }
};

export const addVaccination = (payload) => async (dispatch) => {
  dispatch({ type: ADD_VACCINATION + START });
  try {
    const vaccination = await getAxiosInstance().post(
      `/api/horse_vaccination_history_event`,
      payload
    );

    dispatch({ type: ADD_VACCINATION + DONE, payload: vaccination });
    return true;
  } catch (e) {
    dispatch({ type: ADD_VACCINATION + FAILED, payload: e });
    throw e.response;
  }
};

export const editVaccination = (payload, id) => async (dispatch) => {
  dispatch({ type: EDIT_VACCINATION + START });
  try {
    const vaccination = await getAxiosInstance().put(
      `/api/horse_vaccination_history_event/${id}`,
      payload
    );

    dispatch({ type: EDIT_VACCINATION + DONE, payload: vaccination });
    return true;
  } catch (e) {
    dispatch({ type: EDIT_VACCINATION + FAILED, payload: e });
    throw e.response;
  }
};

export const removeVaccination = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_VACCINATION + START });
  try {
    await getAxiosInstance().delete(
      `/api/horse_vaccination_history_event/${id}`
    );

    dispatch({ type: REMOVE_VACCINATION + DONE });
    return true;
  } catch (e) {
    dispatch({ type: REMOVE_VACCINATION + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchVeterinaryVisits = ({ page, pageSize, sort, q }) => async (
  dispatch
) => {
  dispatch({ type: FETCH_VETERINARY_VISITS + START });
  try {
    const visits = await getAxiosInstance().post(
      `/api/horse_veterinary_visit_history_event/search`,
      {
        page,
        ...(pageSize ? { pageSize: parseInt(pageSize) } : {}),
        ...(sort ? { sort } : {}),
        ...(q ? { q } : {}),
      }
    );

    dispatch({ type: FETCH_VETERINARY_VISITS + DONE, payload: visits.data });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_VETERINARY_VISITS + FAILED, payload: e });
    throw e.response;
  }
};

export const addVeterinaryVisit = (payload) => async (dispatch) => {
  dispatch({ type: ADD_VETERINARY_VISIT + START });
  try {
    const vaccination = await getAxiosInstance().post(
      `/api/horse_veterinary_visit_history_event`,
      payload
    );

    dispatch({ type: ADD_VETERINARY_VISIT + DONE, payload: vaccination });
    return true;
  } catch (e) {
    dispatch({ type: ADD_VETERINARY_VISIT + FAILED, payload: e });
    throw e.response;
  }
};

export const editVeterinaryVisit = (payload, id) => async (dispatch) => {
  dispatch({ type: EDIT_VETERINARY_VISIT + START });
  try {
    const vaccination = await getAxiosInstance().put(
      `/api/horse_veterinary_visit_history_event/${id}`,
      payload
    );

    dispatch({ type: EDIT_VETERINARY_VISIT + DONE, payload: vaccination });
    return true;
  } catch (e) {
    dispatch({ type: EDIT_VETERINARY_VISIT + FAILED, payload: e });
    throw e.response;
  }
};

export const removeVeterinaryVisit = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_VETERINARY_VISIT + START });
  try {
    await getAxiosInstance().delete(
      `/api/horse_veterinary_visit_history_event/${id}`
    );

    dispatch({ type: REMOVE_VETERINARY_VISIT + DONE });
    return true;
  } catch (e) {
    dispatch({ type: REMOVE_VETERINARY_VISIT + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchFoalings = ({ page, pageSize, sort, q }) => async (
  dispatch
) => {
  dispatch({ type: FETCH_FOALINGS + START });
  try {
    const visits = await getAxiosInstance().post(
      `/api/horse_foaling_history_event/search`,
      {
        page,
        ...(pageSize ? { pageSize: parseInt(pageSize) } : {}),
        ...(sort ? { sort } : {}),
        ...(q ? { q } : {}),
      }
    );

    dispatch({ type: FETCH_FOALINGS + DONE, payload: visits.data });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_FOALINGS + FAILED, payload: e });
    throw e.response;
  }
};

export const addFoaling = (payload) => async (dispatch) => {
  dispatch({ type: ADD_FOALING + START });
  try {
    const vaccination = await getAxiosInstance().post(
      `/api/horse_foaling_history_event`,
      payload
    );

    dispatch({ type: ADD_FOALING + DONE, payload: vaccination });
    return true;
  } catch (e) {
    dispatch({ type: ADD_FOALING + FAILED, payload: e });
    throw e.response;
  }
};

export const editFoaling = (payload, id) => async (dispatch) => {
  dispatch({ type: EDIT_FOALING + START });
  try {
    const vaccination = await getAxiosInstance().put(
      `/api/horse_foaling_history_event/${id}`,
      payload
    );

    dispatch({ type: EDIT_FOALING + DONE, payload: vaccination });
    return true;
  } catch (e) {
    dispatch({ type: EDIT_FOALING + FAILED, payload: e });
    throw e.response;
  }
};

export const removeFoaling = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_FOALING + START });
  try {
    await getAxiosInstance().delete(`/api/horse_foaling_history_event/${id}`);

    dispatch({ type: REMOVE_FOALING + DONE });
    return true;
  } catch (e) {
    dispatch({ type: REMOVE_FOALING + FAILED, payload: e });
    throw e.response;
  }
};

export const togglePregnant = (payload, id) => async (dispatch) => {
  dispatch({ type: TOGGLE_PREGNANT + START });
  try {
    await getAxiosInstance().put(`/api/horse/${id}/pregnant`, payload);

    dispatch({ type: TOGGLE_PREGNANT + DONE });
    return true;
  } catch (e) {
    dispatch({ type: TOGGLE_PREGNANT + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchAlerts = (payload) => async (dispatch, getState) => {
  const { isOwner } = payload;
  dispatch({ type: FETCH_ALERTS + START });
  try {
    const currentContext = getState().currentContext?.studId;

    const alerts = await getAxiosInstance().post(
      isOwner
        ? `/api/alert/search`
        : `/api/alert/by_stud/${currentContext}/search`,
      payload
    );

    dispatch({ type: FETCH_ALERTS + DONE, payload: alerts.data });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_ALERTS + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchAnomalies = (payload) => async (dispatch, getState) => {
  dispatch({ type: FETCH_ANOMALIES + START });
  try {
    const { isOwner } = payload;
    const currentContext = getState().currentContext?.studId;
    const anomalies = await getAxiosInstance().post(
      isOwner
        ? `/api/anomaly/search`
        : `/api/anomaly/by_stud/${currentContext}/search`,
      payload
    );

    dispatch({ type: FETCH_ANOMALIES + DONE, payload: anomalies.data });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_ANOMALIES + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchCameras = (payload) => async (dispatch, getState) => {
  dispatch({ type: FETCH_CAMERAS + START });
  try {
    const { isOwner, studId, page, pageSize, sort } = payload;
    const currentContext = getState().currentContext?.studId;

    const cameras = await getAxiosInstance().post(
      isOwner
        ? `/api/camera/${studId}/search`
        : `/api/camera/by_stud/${currentContext}/search`,
      { page, pageSize, sort }
    );

    dispatch({ type: FETCH_CAMERAS + DONE, payload: cameras.data });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_CAMERAS + FAILED, payload: e });
    throw e.response;
  }
};

export const addCamera = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADD_CAMERA + START });
  try {
    const {
      isOwner,
      studId,
      recorderChannel,
      recorderIP,
      vendor,
      credentials,
      credentialsType,
    } = payload;
    const currentContext = getState().currentContext?.studId;

    const result = await getAxiosInstance().post(
      isOwner
        ? `/api/camera/${studId}`
        : `/api/camera/by_stud/${currentContext}`,
      {
        recorderChannel,
        recorderIP,
        vendor,
        ...(credentials && { credentials }),
        ...(credentialsType && { credentialsType }),
      }
    );
    dispatch({
      type: ADD_CAMERA + DONE,
      payload: result.data,
    });
    return result;
  } catch (e) {
    dispatch({ type: ADD_CAMERA + FAILED, payload: e });
    throw e;
  }
};
export const editCamera = (payload) => async (dispatch, getState) => {
  dispatch({ type: EDIT_CAMERA + START });
  try {
    const {
      isOwner,
      studId,
      recorderChannel,
      recorderIP,
      vendor,
      id,
      credentials,
      credentialsType,
    } = payload;
    const currentContext = getState().currentContext?.studId;

    const result = await getAxiosInstance().put(
      isOwner
        ? `/api/camera/${studId}/${id}`
        : `/api/camera/by_stud/${currentContext}/${id}`,
      {
        recorderChannel,
        recorderIP,
        vendor,
        ...(credentials && credentialsType === "Custom" && { credentials }),
        ...(credentialsType && { credentialsType }),
      }
    );
    dispatch({
      type: EDIT_CAMERA + DONE,
      payload: result.data,
    });
    return result;
  } catch (e) {
    dispatch({ type: EDIT_CAMERA + FAILED, payload: e });
    throw e;
  }
};
export const fetchCamera = (payload) => async (dispatch, getState) => {
  dispatch({ type: FETCH_CAMERA + START });
  try {
    const { isOwner, studId, cameraId } = payload;
    const currentContext = getState().currentContext?.studId;

    const camera = await getAxiosInstance().get(
      isOwner
        ? `/api/camera/${studId}/${cameraId}`
        : `/api/camera/by_stud/${currentContext}/${cameraId}`
    );

    dispatch({ type: FETCH_CAMERA + DONE, payload: camera.data });
    return camera.data;
  } catch (e) {
    dispatch({ type: FETCH_CAMERA + FAILED, payload: e });
    throw e.response;
  }
};

export const removeCamera = (payload) => async (dispatch, getState) => {
  dispatch({ type: REMOVE_CAMERA + START });
  try {
    const { isOwner, studId, cameraId } = payload;
    const currentContext = getState().currentContext?.studId;

    await getAxiosInstance().delete(
      isOwner
        ? `/api/camera/${studId}/${cameraId}`
        : `/api/camera/by_stud/${currentContext}/${cameraId}`
    );
    dispatch({ type: REMOVE_CAMERA + DONE });
    return true;
  } catch (e) {
    dispatch({ type: REMOVE_CAMERA + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchBoxes = (payload) => async (dispatch, getState) => {
  dispatch({ type: FETCH_BOXES + START });
  try {
    const currentContext = getState().currentContext?.studId;

    const { isOwner, studId, page, pageSize, sort } = payload;
    const boxes = await getAxiosInstance().post(
      isOwner
        ? `/api/box/${studId}/search`
        : `/api/box/by_stud/${currentContext}/search`,
      { page, pageSize, sort }
    );

    dispatch({ type: FETCH_BOXES + DONE, payload: boxes.data });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_BOXES + FAILED, payload: e });
    throw e.response;
  }
};

export const addBox = (payload) => async (dispatch, getState) => {
  dispatch({ type: ADD_BOX + START });
  try {
    const currentContext = getState().currentContext?.studId;
    const { isOwner, studId, cameraId, name } = payload;

    const result = await getAxiosInstance().post(
      isOwner ? `/api/box/${studId}` : `/api/box/by_stud/${currentContext}`,
      {
        cameraId,
        name,
      }
    );
    dispatch({
      type: ADD_BOX + DONE,
      payload: result.data,
    });
    return result;
  } catch (e) {
    dispatch({ type: ADD_BOX + FAILED, payload: e });
    throw e;
  }
};
export const editBox = (payload) => async (dispatch, getState) => {
  dispatch({ type: EDIT_BOX + START });
  try {
    const currentContext = getState().currentContext?.studId;
    const { isOwner, studId, cameraId, horseId, name, id } = payload;

    const result = await getAxiosInstance().put(
      isOwner
        ? `/api/box/${studId}/${id}`
        : `/api/box/by_stud/${currentContext}/${id}`,
      {
        horseId,
        cameraId,
        name,
      }
    );
    dispatch({
      type: EDIT_BOX + DONE,
      payload: result.data,
    });
    return result;
  } catch (e) {
    dispatch({ type: EDIT_BOX + FAILED, payload: e });
    throw e;
  }
};
export const fetchBox = (payload) => async (dispatch, getState) => {
  dispatch({ type: FETCH_BOX + START });
  try {
    const currentContext = getState().currentContext?.studId;
    const { isOwner, studId, boxId } = payload;

    const box = await getAxiosInstance().get(
      isOwner
        ? `/api/box/${studId}/${boxId}`
        : `/api/box/by_stud/${currentContext}/${boxId}`
    );

    dispatch({ type: FETCH_BOX + DONE, payload: box.data });
    return box.data;
  } catch (e) {
    dispatch({ type: FETCH_BOX + FAILED, payload: e });
    throw e.response;
  }
};

export const removeBox = (payload) => async (dispatch, getState) => {
  dispatch({ type: REMOVE_BOX + START });
  try {
    const currentContext = getState().currentContext?.studId;
    const { isOwner, studId, boxId } = payload;
    await getAxiosInstance().delete(
      isOwner
        ? `/api/box/${studId}/${boxId}`
        : `/api/box/by_stud/${currentContext}/${boxId}`
    );
    dispatch({ type: REMOVE_BOX + DONE });
    return true;
  } catch (e) {
    dispatch({ type: REMOVE_BOX + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchImageData = (horseId) => async (dispatch) => {
  dispatch({ type: FETCH_IMAGE_DATA + START });
  try {
    const r = await getAxiosInstance().get(
      `/api/horse/${horseId}/image/latest`
    );
    dispatch({ type: FETCH_IMAGE_DATA + DONE });
    return r;
  } catch (e) {
    dispatch({ type: FETCH_IMAGE_DATA + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchImageByUrl = (url) => async (dispatch) => {
  dispatch({ type: FETCH_IMAGE_BY_URL + START });
  try {
    const r = await getAxiosInstance().get(url + "/serve");
    dispatch({ type: FETCH_IMAGE_BY_URL + DONE });
    return r;
  } catch (e) {
    dispatch({ type: FETCH_IMAGE_BY_URL + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchSignalInfo = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_SIGNAL_INFO + START });
  try {
    const currentContext = getState().currentContext?.studId;
    const r = await getAxiosInstance().get(
      `/api/stud/${currentContext}/connectivity`
    );
    dispatch({ type: FETCH_SIGNAL_INFO + DONE, payload: r.data });
    return r;
  } catch (e) {
    dispatch({ type: FETCH_SIGNAL_INFO + FAILED, payload: e });
    throw e.response;
  }
};

export const fetchHorseNotifications = (horseID) => async (dispatch) => {
  dispatch({ type: FETCH_HORSE_NOTIFICATIONS + START });
  try {
    const r = await getAxiosInstance().get(
      `/api/horse/${horseID}/notification_preferences`
    );
    dispatch({ type: FETCH_HORSE_NOTIFICATIONS + DONE, payload: r.data });
    return true;
  } catch (e) {
    dispatch({ type: FETCH_HORSE_NOTIFICATIONS + FAILED, payload: e });
    throw e.response;
  }
};

export const updateHorseNotifications = (horseID, payload) => async (
  dispatch
) => {
  dispatch({ type: UPDATE_HORSE_NOTIFICATIONS + START });
  try {
    await getAxiosInstance().put(
      `/api/horse/${horseID}/notification_preferences`,
      payload
    );
    dispatch({ type: UPDATE_HORSE_NOTIFICATIONS + DONE });
    return true;
  } catch (e) {
    dispatch({ type: UPDATE_HORSE_NOTIFICATIONS + FAILED, payload: e });
    throw e.response;
  }
};
