import React, { useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Can from "../../components/can";
import { Box } from "@material-ui/core";
import PageTitle from "../../components/page-title";
import {
  fetchHorseFarms,
  fetchFormGroup,
  saveHorseFarm,
  updateHorseFarm,
  alertAdd,
  fetchPrivateKey,
} from "../../redux/actions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Prompt from "react-router-dom/es/Prompt";
import MuiPhoneNumber from "material-ui-phone-number";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import s from "./index.module.css";
import { useTranslation } from "react-i18next";

import { ConfirmDialogMUI } from "../../components/confirm-dialog-mui";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
}));

const AddNewFormGroupPage = ({
  orgFormsGroups,
  fetchHorseFarms,
  saveHorseFarm,
  fetchFormGroup,
  fetchPrivateKey,
  reinitializeRSAKeys,
  editFormGroup,
  updateHorseFarm,
  alertAdd,
  history,
  match: {
    params: { id },
  },
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [addMode, setAddMode] = useState();
  const [fetching, setFetching] = useState();

  const [editingFormGroup, setEditingFormGroup] = useState();

  const [busyPrivateKey, setBusyPrivateKey] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [privateKey, setPrivateKey] = useState(null);
  const [createdStudID, setCreatedStudID] = useState(null);

  const [name, setName] = useState("");
  const [internalDescription, setInternalDescription] = useState("");
  const [welcomeSmsMessage, setWelcomeSmsMessage] = useState("");
  const [welcomeEmailMessage, setWelcomeEmailMessage] = useState("");
  const [smsPhone, setSmsPhone] = useState("");
  const [externalAddress, setExternalAddress] = useState("");
  const [enableAllFormAccess, setEnableAllFormAccess] = useState(true);

  const [phoneLostFocus, setPhoneLostFocus] = useState(false);

  const [valid, setValid] = useState(false);
  const [busy, setBusy] = useState(false);

  let formRef = useRef();

  const [dataUnsaved, setDataUnsaved] = useState(false);

  const formsGroups = useSelector((s) => s.orgFormsGroups);

  useEffect(() => {
    if (
      name === "" ||
      !name.replace(/\s/g, "").length
      // ||
      // (smsPhone && smsPhone.length !== 12 && smsPhone !== "+48")
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [name, smsPhone]);

  useEffect(() => {
    setAddMode(id === undefined);
  }, [id]);

  useEffect(() => {
    addMode === false && fetchHorseFarms({});
  }, [addMode]);

  useEffect(() => {
    if (addMode !== undefined) {
      if (addMode) {
        setDataUnsaved(
          name !== "" ||
            externalAddress !== "" ||
            internalDescription !== "" ||
            smsPhone !== "" ||
            welcomeSmsMessage !== "" ||
            welcomeEmailMessage !== "" ||
            !enableAllFormAccess ||
            enableAllFormAccess
        );
      }
      if (!addMode && editingFormGroup) {
        setDataUnsaved(
          name !== editingFormGroup?.name ||
            externalAddress !== editingFormGroup.externalAddress ||
            internalDescription !== editingFormGroup.description ||
            smsPhone !== editingFormGroup.phone ||
            welcomeEmailMessage !== editingFormGroup.welcomeMessage
        );
      }
    }
  }, [
    name,
    internalDescription,
    externalAddress,
    welcomeSmsMessage,
    smsPhone,
    welcomeEmailMessage,
    enableAllFormAccess,
    editingFormGroup,
  ]);

  useEffect(() => {
    (async () => {
      try {
        if (id) {
          const actualFormGroup = await fetchFormGroup(id);

          setEditingFormGroup(actualFormGroup);
          if (actualFormGroup) {
            if (!addMode) {
              setEditingFormGroup(actualFormGroup);
            }

            setName((actualFormGroup && actualFormGroup.name) || "");
            setInternalDescription(
              (actualFormGroup && actualFormGroup.description) || ""
            );
            setSmsPhone((actualFormGroup && actualFormGroup.phone) || "");

            setWelcomeEmailMessage(
              (actualFormGroup && actualFormGroup.welcomeMessage) || ""
            );
            setExternalAddress(
              (actualFormGroup && actualFormGroup.externalAddress) || ""
            );
          }
        }
      } catch (e) {
        alertAdd({
          text: t("add_horse_farm.error"),
          isError: true,
        });
      }
    })();
  }, [formsGroups, id]);

  const save = async (e) => {
    e.preventDefault();
    setBusy(true);
    const payload = {
      name,
      description: internalDescription,
      externalAddress,
      phone: smsPhone?.replace(/[^+\d]+/g, ""),
      welcomeMessage: welcomeEmailMessage,
    };
    try {
      if (addMode) {
        const { id: studId, privateKey: pKey } = await saveHorseFarm(payload);
        setPrivateKey(pKey);
        setCreatedStudID(studId);
        setDataUnsaved(false);
        setBusy(false);
        setOpenModal(true);
      } else {
        await updateHorseFarm(id, payload);
        setDataUnsaved(false);
        setBusy(false);
        history.push(`/groups`);
      }
    } catch (e) {
      alertAdd({
        text: t("horse_farm.name_exists_error"),
        isError: true,
      });
      setBusy(false);
    }
  };

  const cancel = (e) => {
    history.push("/groups");
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      formRef.submit();
    }
  };

  const downloadTxtPrivateKeyFile = async () => {
    try {
      if (privateKey && createdStudID) {
        setBusyPrivateKey(true);
        const element = document.createElement("a");
        const file = new Blob([privateKey], {
          type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = `private_key_stud_id_${createdStudID}.txt`;
        document.body.appendChild(element);
        element.click();
        element.parentNode.removeChild(element);
        setBusyPrivateKey(false);
      }
    } catch (e) {
      setBusyPrivateKey(false);
      alertAdd({
        text: t("add_horse_farm.error"),
        isError: true,
      });
    }
  };

  const page = () => (
    <Box>
      <Prompt when={dataUnsaved} message={t("add_horse_farm.leave_page_msg")} />

      <Box p={1}>
        {addMode ? (
          <PageTitle title={t("add_horse_farm.label")} />
        ) : (
          <PageTitle title={t("add_horse_farm.label_edition")} />
        )}
      </Box>
      {/* <form ref={(el) => (formRef = el)} onSubmit={save}> */}
      <Box p={1}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Box width="100%">
                <Typography variant="h6" gutterBottom>
                  {t("add_horse_farm.fields_label")}
                </Typography>
                <Box mt={1} width={1 / 2}>
                  <TextField
                    autoFocus
                    required
                    label={t("add_horse_farm.name")}
                    variant="outlined"
                    type="text"
                    value={name || ""}
                    fullWidth
                    onChange={({ target: { value } }) => setName(value)}
                  />
                </Box>
                <Box mt={1} width={1 / 2}>
                  <TextField
                    label={t("add_horse_farm.external_address")}
                    variant="outlined"
                    type="text"
                    value={externalAddress || ""}
                    fullWidth
                    onChange={({ target: { value } }) =>
                      setExternalAddress(value)
                    }
                  />
                </Box>
                <Box mt={1} width={1 / 2}>
                  <TextField
                    label={t("add_horse_farm.internal_desc")}
                    variant="outlined"
                    type="text"
                    multiline
                    value={internalDescription || ""}
                    fullWidth
                    rows={2}
                    rowsMax={4}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && !e.shiftKey) {
                        e.preventDefault();
                        save(e);
                      }
                    }}
                    onChange={({ target: { value } }) =>
                      setInternalDescription(value)
                    }
                  />
                </Box>
                <Box mt={1} width={1 / 2}>
                  <MuiPhoneNumber
                    name={"phone"}
                    label={t("add_horse_farm.phone")}
                    data-cy="user-phone"
                    defaultCountry={"pl"}
                    fullWidth
                    variant="outlined"
                    value={smsPhone || ""}
                    onChange={(e, { dialCode }) => {
                      if (e) {
                        setSmsPhone(
                          e.length > "+".concat(dialCode).length ? e : ""
                        );
                      }
                    }}
                  />
                  {/* <MuiPhoneNumber
                      name="phone"
                      label="Numer telefonu"
                      variant="outlined"
                      data-cy="user-phone"
                      defaultCountry={"pl"}
                      regions={"europe"}
                      autoFormat={false}
                      countryCodeEditable={false}
                      fullWidth
                      value={smsPhone || ""}
                      onChange={(e) => {
                        if (e !== "+48") {
                          setSmsPhone(e);
                        } else {
                          setSmsPhone("");
                        }
                        setPhoneLostFocus(false);
                      }}
                      onFocus={() => setPhoneLostFocus(false)}
                      onBlur={() => setPhoneLostFocus(true)}
                      error={
                        phoneLostFocus &&
                        smsPhone &&
                        smsPhone.length !== 12 &&
                        smsPhone !== "+48"
                      }
                      helperText={
                        phoneLostFocus &&
                        smsPhone &&
                        smsPhone.length !== 12 &&
                        smsPhone !== "+48" &&
                        "Nieprawidłowy format numeru telefonu"
                      }
                      inputProps={{
                        maxLength: 12,
                      }}
                    /> */}
                </Box>
                {/* <Box mt={1} width={1 / 2}>
                    <TextField
                      label="Powitalna wiadomość SMS"
                      variant="outlined"
                      type="text"
                      multiline
                      value={welcomeSmsMessage || ""}
                      fullWidth
                      rows={2}
                      rowsMax={4}
                      // onKeyDown={(e) => {
                      //   if (e.keyCode == 13 && !e.shiftKey) {
                      //     e.preventDefault();
                      //     save(e);
                      //   }
                      // }}
                      onChange={({ target: { value } }) =>
                        setWelcomeSmsMessage(value)
                      }
                    />
                    <span className={s.hintText}>
                      {`Jedna wiadomość SMS zawiera około 140 znaków.`}
                    </span>
                    <br />
                    <span className={s.hintText}>
                      {`Ilośc znaków Twojej powitalnej wiadomości SMS: ${welcomeSmsMessage.length}`}
                    </span>
                  </Box> */}
                <Box mt={1} width={1 / 2}>
                  <TextField
                    label={t("add_horse_farm.email_text")}
                    variant="outlined"
                    type="text"
                    multiline
                    value={welcomeEmailMessage || ""}
                    fullWidth
                    rows={2}
                    rowsMax={4}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13 && !e.shiftKey) {
                        e.preventDefault();
                        save(e);
                      }
                    }}
                    onChange={({ target: { value } }) =>
                      setWelcomeEmailMessage(value)
                    }
                  />
                </Box>

                {! addMode && (
                  <Box mt={1} width={1 / 2} display="flex" justifyContent="flex-end">
                    <a target="_blank" href={`/api/stud/${id}/mm_configuration`}>{t("add_horse_farm.get_connection_config")}</a>
                  </Box>
                )}

                {/* <Box mt={1} width={1 / 2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={enableAllFormAccess || false}
                          name="checkedB"
                          color="primary"
                          onChange={(e) =>
                            setEnableAllFormAccess(e.target.checked)
                          }
                        />
                      }
                      label="Automatyczne nadawanie dostępu użytkownikom do formularzy w ramach tego projektu"
                    /> 
                   </Box> */}
              </Box>
              <Box mt={1}>
                <span className={s.formHint}>
                  * {t("add_horse_farm.required")}
                </span>
              </Box>
              <Box mt={1} display="center">
                {addMode ? (
                  <Button
                    variant={"contained"}
                    color="primary"
                    type="submit"
                    disabled={!valid || busy}
                    onClick={save}
                  >
                    {busy && (
                      <CircularProgress size={24} style={{ color: "white" }} />
                    )}
                    {!busy && t("save")}
                  </Button>
                ) : (
                  <Button
                    variant={"contained"}
                    color="primary"
                    type="submit"
                    disabled={!valid}
                    onClick={save}
                  >
                    {t("edit")}
                  </Button>
                )}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant={"contained"}
                  color="secondary"
                  onClick={cancel}
                >
                  {t("cancel")}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      {/* </form> */}
      <ConfirmDialogMUI
        handleClose={(e, reason) => {
          if (reason && reason === "backdropClick") return;
          history.push(`/groups`);
        }}
        open={openModal}
        text={t("add_horse_farm.modal_text")}
        yesAction={() => {
          downloadTxtPrivateKeyFile();
          history.push(`/groups`);
        }}
        noAction={() => {
          history.push(`/groups`);
        }}
      />
    </Box>
  );

  return (
    <Can
      permission="form-group-add-page:view"
      ok={page}
      not={() => <Redirect to="/" />}
    />
  );
};

const mapStateToProps = (state) => ({ orgFormsGroups: state.orgFormsGroups });

const mapDispatchToProps = (dispatch) => ({
  fetchFormGroup: (id) => dispatch(fetchFormGroup(id)),
  fetchPrivateKey: (id) => dispatch(fetchPrivateKey(id)),
  fetchHorseFarms: ({}) => dispatch(fetchHorseFarms({})),
  saveHorseFarm: (payload) => dispatch(saveHorseFarm(payload)),
  updateHorseFarm: (id, payload) => dispatch(updateHorseFarm(id, payload)),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddNewFormGroupPage);
