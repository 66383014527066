import React, { useEffect, useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { height } from "@material-ui/system";
import { darken } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelection } from "@material-ui/data-grid";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  label: {
    color: "#787878",
    fontSize: "1.1rem",
  },
  labelOffline: {
    color: "#000000",
    fontSize: "1.1rem",
  },
  sizingBox: {
    width: 50,
    height: 20,
    marginLeft: "5px",
  },
  signalBars: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
  },
  bar: {
    width: "12%",
    marginLeft: "5%",
    minHeight: "20%",
    display: "inline-block",
    border: "1px solid black",
  },
  firstBar: {
    height: "20%",
  },
  secondBar: {
    height: "40%",
  },
  thirdBar: {
    height: "60%",
  },
  fourthBar: {
    height: "80%",
  },
  fifthBar: {
    height: "90%",
  },

  good: {
    "& $bar": {
      backgroundColor: "#16a085",
      border: `thin solid ${darken("#16a085", 0.07)}`,
    },
  },
  ok: {
    "& $bar": {
      backgroundColor: "#f1c40f",
      border: `thin solid ${darken("#f1c40f", 0.07)}`,
    },
  },
  bad: {
    "& $bar": {
      backgroundColor: "#e74c3c",
      border: `thin solid ${darken("#e74c3c", 0.07)}`,
    },
  },

  fourBars: {
    "& $fifthBar": {
      backgroundColor: "#e2e2e2",
      border: "thin solid #b1b1b1",
    },
  },

  threeBars: {
    "& $fifthBar": {
      backgroundColor: "#e2e2e2",
      border: "thin solid #b1b1b1",
    },
    "& $fourthBar": {
      backgroundColor: "#e2e2e2",
      border: "thin solid #b1b1b1",
    },
  },

  oneBar: {
    "& $bar:not($firstBar)": {
      backgroundColor: "#e2e2e2",
      border: "thin solid #b1b1b1",
    },
  },
  twoBars: {
    "& $bar:not($firstBar):not($secondBar)": {
      backgroundColor: "#e2e2e2",
      border: "thin solid #b1b1b1",
    },
  },
}));

const signalMapper = {
  Critical: {
    type: "bad",
    barsAmount: "oneBar",
  },
  Poor: {
    type: "bad",
    barsAmount: "twoBars",
  },
  Fair: {
    type: "ok",
    barsAmount: "threeBars",
  },
  Good: {
    type: "good",
    barsAmount: "fourBars",
  },
  Excellent: {
    type: "good",
    barsAmount: "fiveBars",
  },
};

function SignalPower() {
  const classes = useStyles();
  const { status: initialStatus } = useSelector((s) => s.signalInfo);
  const currentContext = useSelector((s) => s.currentContext);
  const { t } = useTranslation();

  const [currentStatus, setCurrentStatus] = useState(initialStatus);

  const [type, setType] = useState({});
  const [barsAmount, setBarsAmount] = useState({});

  const ws = useRef(null);

  useEffect(() => {
    if (initialStatus && currentContext.studId) {
      const domain = window.location.hostname;
      const proto = window.location.protocol === "https:" ? "wss" : "ws";
      const port = window.location.port;
      const optionalPort = port ? `:${port}` : "";

      ws.current = new WebSocket(
        // `wss://stadnina-qa.it-learn.pl/ws/horse/image/latest`
        `${proto}://${domain}${optionalPort}/ws/stud/connectivity`
      );
      ws.current.onopen = function (e) {
        ws.current.send(currentContext.studId);
      };
      ws.current.onmessage = function (e) {
        const { status } = JSON.parse(e.data);
        setCurrentStatus(status);
      };
      ws.current.onclose = function (e) {};
      ws.current.onerror = function (e) {};
      const wsCurrent = ws.current;

      return () => {
        if (wsCurrent.readyState === 1) {
          wsCurrent.close();
        }
      };
    }
  }, [initialStatus]);

  useEffect(() => {
    if (currentStatus) {
      setType(`${classes[signalMapper[currentStatus]?.type]}`);
      setBarsAmount(`${classes[signalMapper[currentStatus]?.barsAmount]}`);
    }
  }, [currentStatus]);

  return (
    <Box style={{ display: "flex", flexDirection: "row-reverse" }}>
      {currentStatus && currentStatus !== "Offline" ? (
        <Box
          className={`${classes.sizingBox} ${classes.signalBars} ${type} ${barsAmount}`}
        >
          <Box className={`${classes.bar} ${classes.firstBar}`}></Box>
          <Box className={`${classes.bar} ${classes.secondBar}`}></Box>
          <Box className={`${classes.bar} ${classes.thirdBar}`}></Box>
          <Box className={`${classes.bar} ${classes.fourthBar}`}></Box>
          <Box className={`${classes.bar} ${classes.fifthBar}`}></Box>
        </Box>
      ) : (
        <span className={classes.labelOffline}>OFFLINE</span>
      )}
      <span className={classes.label}>{t("signal")} </span>
    </Box>
  );
}

export default SignalPower;
